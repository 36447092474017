import { FC } from "react";

import {
  shortAddress,
  ethscanAddressLink,
} from "../../../../../services/formatter";

import ResultLink from "../result-link";

interface WalletProps {
  item: {
    wallet_address: string;
    token_address: string;
    balance: string;
    token_symbol: string;
  };
  i: number;
  pageNumber: number;
  searchId?: string;
  pagination: { item_per_page: number };
}
const Wallet: FC<WalletProps> = ({
  item,
  i,
  pageNumber,
  searchId,
  pagination,
}) => {
  const { item_per_page } = pagination;
  const { wallet_address, token_address, balance, token_symbol } = item;
  return (
    <div className="search-list__item" key={`search-result${i}`}>
      <div className="search-list__item__number u-color-dark">
        <span className="u-weight-medium">
          Top{" "}
          {((pageNumber > 1 ? pageNumber : 1) - 1) * item_per_page + (i + 1)}
        </span>
      </div>
      <div className="item-top-list">
        <small className="user-emoji"></small>
      </div>

      <div className="search-list__item__image u-align-right u-max-width-188px">
        {token_address && (
          <ResultLink
            href={ethscanAddressLink(wallet_address)}
            target="_blank"
            className="address-wallet"
            searchId={searchId}
          >
            {shortAddress(wallet_address)}
          </ResultLink>
        )}
        <span className="amount-smartphone">
          {balance} {token_symbol}
        </span>
      </div>
      <div
        className={`search-list__item__amount ${
          balance.length > 20 && "amount-large-size"
        }`}
      >
        {balance} {token_symbol}
      </div>
    </div>
  );
};

export default Wallet;
