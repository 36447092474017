import React, { FC, useState } from "react";
import { Grid } from "gridjs-react";
import { h } from "gridjs";
import FilterQuestions from "./components/filter-questions";
import { Question } from "../../app/models/question";
import { listResources } from "../../app/models/admincrud";
import { useHistory } from "react-router-dom";

interface QuestionPageProps {}

const QuestionPage: FC<QuestionPageProps> = () => {
  let history = useHistory();

  const [, setFilter] = useState({});
  const columns = [
    {
      id: "id",
      name: "Actions",
      formatter: (cell, row) => {
        return h(
          "a",
          {
            href: `/admin/questions/${row.cells[0].data}`,
          },
          "Details"
        );
      },
    },
    "Id",
    "Created",
    "Query",
    "Input Type",
    "Found",
    "Click Through",
    "ENS",
    "Twitter Account",
  ];

  const classNa = { table: "question-table" };

  const [dataQuestions, setDataQuestions] = useState<Question[]>([]);

  const asyncLoadQuestions = async (filterModel?: {
    [key: string]: string;
  }) => {
    const r = await listResources("questions", filterModel);
    setDataQuestions(
      r.map((x) => [
        x.id,
        x.created_at,
        x.query,
        x.input_type,
        x.hit_miss ? "hit" : "miss",
        x.click_through ? "yes" : "no",
        x.ens,
        x.twitter_account,
      ])
    );
  };

  const onFilterChange = (filterModel) => {
    setFilter(filterModel);
    asyncLoadQuestions(filterModel);
    history.push("?" + new URLSearchParams(filterModel).toString());
  };

  return (
    <div>
      <FilterQuestions onChange={onFilterChange} />
      <Grid
        data={dataQuestions}
        search={false}
        columns={columns}
        className={classNa}
        pagination={{
          enabled: false,
          limit: 1000000,
        }}
      />
    </div>
  );
};

export default QuestionPage;
