import {
  flagshipBase
} from '../services/appenv';

export const DEFAULT_NFT_PLACEHOLDER = "https://qa.openstory.io/icon/nft/placeholder.svg";
const DEFAULT_NFTIMAGE_SIZE = "200";

export const generateAvatar = (address: string) => {
  return flagshipBase + "/avatar/" + address;
}

export interface NftImageRequest {
  address: string;
  token_id: string;
  event_id?: string;
  size?: string;
  placeholder?: string;
}

// Only use this with trusted input
export const nftImage = (r: NftImageRequest, type?: string): string => {
  const paramsThumb = {
    placeholder: r.placeholder || DEFAULT_NFT_PLACEHOLDER,
    thumb: r.size || DEFAULT_NFTIMAGE_SIZE,
  };
  const paramsFullWith = {
    placeholder: r.placeholder || DEFAULT_NFT_PLACEHOLDER,
    w: r.size || DEFAULT_NFTIMAGE_SIZE
  };
  const qs = new URLSearchParams(type === "full" ? paramsFullWith : paramsThumb).toString();

  if (r.event_id && r.event_id !== "") {
    return flagshipBase + "/nftpoap/" + encodeURIComponent(r.event_id) + "/" + encodeURIComponent(r.token_id) + "?" + qs
  }

  return flagshipBase + "/nft/" + encodeURIComponent(r.address) + "/" + encodeURIComponent(r.token_id) + "?" + qs
}


export const imageUrl = (urlImage: string) => {
  return urlImage ? urlImage : DEFAULT_NFT_PLACEHOLDER
}