import React, {FC, useState, useEffect} from "react";
import {useParams, useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
  FormControl,
  Button,
  Grid,
  Link,
  TextField,
  Chip
} from "@material-ui/core";

import {
  ActivityLabel,
  getActivityLabel,
  updateActivityLabel,
  deleteActivityLabel,
  addActivityLabel,
} from "../../app/models/activity-label";

import {
  FormIndicator
} from "./components/indicator";

import {toast} from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import {object} from "underscore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    spacing: {
      margin: "0.25rem",
    },
    tiny: {
      margin: "0.25rem",
    }
  })
);

const ActivityLabelsDetailsPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const {id} = useParams<ActivityLabel>();

  const qs = new URLSearchParams(history.location.search);

  const emptyLabel = {
    id: null,
    method: qs.get("method") || "",
    address: qs.get("address") || "",
    translation: "",
    signature: qs.get("signature") || "",
    full_argument: "",
    keywords: [],
    sample_transactions: "",
    script: "",
  }
  const [activityLabel, setActivityLabel] = useState<ActivityLabel>(emptyLabel);

  const [formIndicator, setFormIndicator] = useState<FormIndicator>({})
  const [phase, setPhase] = useState<"ready" | "loading">("ready");
  const asyncLoadLabel = async (labelId: number) => {
    setPhase("loading");
    try {
      const label = await getActivityLabel(labelId);
      if (!label.keywords) {
        label.keywords = []
      }
      setActivityLabel(label);
    } catch (e) {
      toast.error("Error loading activity label: " + e.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    } finally {
      setPhase("ready");
    }
  }

  useEffect(() => {
    if (id === "create") {
      setActivityLabel(emptyLabel);
    } else {
      asyncLoadLabel(parseInt(id));
    }
  }, [id]);

  const handleInputValue = (e: React.ChangeEvent) => {
    const {name, value} = e.target;
    if (name) {
      setActivityLabel({...activityLabel, [name]: (value as string) || ""});
    }
  };

  const handleKeywordInputValue = (e: React.ChangeEvent, index: number) => {
    const {name, value} = e.target;
    if (name) {
      let list = activityLabel.keywords;
      list[index][name] = (value as string) || "";
      setActivityLabel({...activityLabel, keywords: list});
    }
  };

  const handleAddKeywordInputClick = () => {
    const list = (activityLabel.keywords || []);
    list.push({keyword: "", description: ""});
    setActivityLabel({...activityLabel, keywords: list});
  };

  const handleRemoveKeywordInputClick = (index: number) => {
    const list = activityLabel.keywords;
    list.splice(index, 1);
    setActivityLabel({...activityLabel, keywords: list});
  };

  const validate = (editedModel: ActivityLabel) => {
    const errors: FormIndicator = {};
    if (editedModel.signature.trim() == "" || editedModel.signature.length !== 10 || !editedModel.signature.startsWith("0x")) {
      errors["signature"] = {
        error: true,
        helperText: "Signature is 10 characters and must start with 0x",
      }
    }
    if (editedModel.method.trim() == "") {
      errors["method"] = {
        error: true,
        helperText: "method name cannot be empty",
      }
    }

    if (editedModel.translation.trim() == "") {
      errors["translation"] = {
        error: true,
        helperText: "translation cannot be empty",
      }
    }

    setFormIndicator(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSave = React.useCallback(async (next: "edit" | "new" | "list") => {
    if (validate(activityLabel)) {
      setPhase("loading");
      try {
        let result = {}
        if (activityLabel.id) {
          result = await updateActivityLabel("/activity_labels/" + activityLabel.id, activityLabel)
        } else {
          result = await addActivityLabel(activityLabel)
        }
        switch (next) {
          case "new":
            setActivityLabel(emptyLabel);
            history.push("/admin/activity-labels/create");
            break;
          case "edit":
            history.push("/admin/activity-labels/" + result.id);
            break;
          case "list":
            history.push("/admin/activity-labels/");
            break;
        }

        toast.success(`saving ${activityLabel?.method} succeeded`, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });


      } catch (e) {
        toast.error("error saving: " + e.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        })
      } finally {
        setPhase("ready");
      }
    }
  }, [activityLabel])

  const handleDelete = async (evt: React.MouseEventHandler<HTMLButtonElement>) => {
    setPhase("loading");
    try {
      await deleteActivityLabel("/activity_labels/" + activityLabel.id)
      history.push("/admin/activity-labels");
      toast.success("Delete succeeded", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } catch (e) {
      toast.error("error delete: " + e.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    } finally {
      setPhase("ready");
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          label="Contract Address: leave empty for common method"
          variant="outlined"
          value={activityLabel?.address}
          onChange={handleInputValue}
          name="address"
        />
      </FormControl>
      <br/>

      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          required
          label="Signature: is the MethodID on intput data on etherscan"
          variant="outlined"
          value={activityLabel?.signature}
          onChange={handleInputValue}
          name="signature"
          {...formIndicator['signature']}
        />
      </FormControl>
      <p>
        <span>Signature is method id on etherscan on Input Data field</span>&nbsp;
        <Link
          href="https://etherscan.io/tx/0xa968addaa7c978ff6ac3866225281859c42dc3907e12b6a47cd61e48a1a5cb62">
          https://etherscan.io/tx/0xa968addaa7c978ff6ac3866225281859c42dc3907e12b6a47cd61e48a1a5cb62
        </Link>
      </p>
      <br/>

      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          required
          label="Method: such as transfer, deposit"
          variant="outlined"
          value={activityLabel?.method}
          onChange={handleInputValue}
          name="method"
          {...formIndicator['method']}
        />
      </FormControl>
      <p>
        <span>Method can also be found from 4byte</span>&nbsp;
        <Link
          target='_blank'
          href={`https://www.4byte.directory/signatures/?bytes4_signature=${activityLabel?.signature}`}>
          https://www.4byte.directory/signatures/?bytes4_signature={activityLabel?.signature}
        </Link>
      </p>
      <br/>

      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          required
          label="Translation"
          placeholder="human friendly text to explain the transaction"
          variant="outlined"
          value={activityLabel?.translation}
          onChange={handleInputValue}
          name="translation"
          {...formIndicator['translation']}
        />
      </FormControl>
      <p>
        We can also use variables to add dynamic data into the label itself. Make sure check to avoid redundant variable
        on UI with the design. The supported lists are:
      </p>
      <div className={classes.tiny}>
        <Chip size="small" label="&#123;token_symbol&#125;" />: example "MATIC"
        <br/>
        <Chip size="small" label="&#123;nft_name&#125;" />: example: "bored app"
        <br/>
        <Chip size="small" label="&#123;nft_id&#125;" />: the token id itself, can be very long
        <br/>
        <Chip size="small" label="&#123;ens_name&#125;" />
        <br/>
        <Chip size="small" label="&#123;swap_symbol1&#125;" />
        <br/>
        <Chip size="small" label="&#123;swap_symbol2&#125;" />
        <br/>
        <Chip size="small" label="&#123;contract_name&#125;" /> if we don't have name on etherscan, or it does existed on etherscan but we got rate limited when got contract name(we will re-fetch in background), we will display contract address as its name
        <br/>
      </div>
      <p>
        We don't have an easy way to simulate the render yet, one has to manually hit the API to test out example,
        visit <a
        href="https://qa.openstory.io/flagship/stream/process/label_tx/0x52d6ee0bb783e32594a0f3ec4522a9cc3e4a84a0180876a131afb81918a6f1b1">https://qa.openstory.io/flagship/stream/process/label_tx/0x52d6ee0bb783e32594a0f3ec4522a9cc3e4a84a0180876a131afb81918a6f1b1</a>.
        Replace the TX with any transaction, then looks at action.label. We prettify JSON to make view it easiser.
      </p>
      <br/>

      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          variant="outlined"
          placeholder="full argument of the method"
          label="Full argument"
          name="full_argument"
          value={activityLabel?.full_argument}
          onChange={handleInputValue}
        >
        </TextField>
      </FormControl>
      <p>
        Full argument is the part after Function on etherscan after Input Data field
      </p>
      <br/>
      <p>
        Highlight keywords.
        <br/>
        Keyword is any piece of text that we assign to the transaction has this label.
        Keyword can be a static text such as "swap", or having some dynamic text such as `swap &#123;token_symbol&#125;` or `&#123;contract_name&#125;`<br />
        The supported lists for variables currently are:
      </p>
      <div className={classes.root}>
        <Chip size="small" label="&#123;exchange_name&#125;" />
        <Chip size="small" label="&#123;token_symbol&#125;" />
        <Chip size="small" label="&#123;contract_name&#125;" />
        <Chip size="small" label="&#123;nft_name&#125;" />
        <Chip size="small" label="&#123;ens_name&#125;" />
        <Chip size="small" label="&#123;swap_symbol1&#125;" />
        <Chip size="small" label="&#123;swap_symbol2&#125;" />
        <Chip size="small" label="&#123;nft_or_not&#125;" />
      </div>

      {activityLabel.keywords?.map((item, i: number) => (
        <div key={i.toString()} className="u-margin-bottom-1 filter-admin-content">
          <br/>
          <FormControl className="u-margin-right-ui-1 u-width-fc-500">
            <TextField
              variant="outlined"
              name="keyword"
              label="Keyword, example: buy {token_name}"
              value={item.keyword}
              onChange={e => handleKeywordInputValue(e, i)}
              placeholder="Keyword: buy-{token_name}"
            >
            </TextField>
            <br/>
            <TextField
              variant="outlined"
              className="ml10"
              name="description"
              label="Description: {total} transaction last 24h"
              placeholder="example '{total} transaction last 24h'"
              value={item.description}
              onChange={e => handleKeywordInputValue(e, i)}
            >
            </TextField>
            <br/>
            <Grid
              container
              direction="row"
              alignItems="stretch"
              justifyContent="space-between"
            >
              <Grid item><Button variant="contained" color="default"
                                 onClick={() => handleRemoveKeywordInputClick(i)}>Remove</Button></Grid>
            </Grid>
          </FormControl>
        </div>
      ))}

      <Button variant="contained" color="default"
              onClick={handleAddKeywordInputClick}>Add Keyword</Button>

      <br/>
      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          variant="outlined"
          label="Sample Transactions(Optional)"
          placeholder=""
          name="sample_transactions"
          onChange={handleInputValue}
          value={activityLabel?.sample_transactions || ""}
          multiline
          rows={2}
        />
      </FormControl>

      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          variant="outlined"
          label="Script(Optional)"
          name="script"
          value={activityLabel?.script || ""}
          onChange={handleInputValue}
          multiline
          rows={4}
        />
      </FormControl>
      <p>
        Script is an advanced feature where we will also evaluate this and return extra data about this information. More doc to come but it can be
      </p>
      <pre><code>
        tx.To == "0x12345" ? self.category = ["defi", "nft"]

        tx.From == "0x000h4ck3" ? self.keywords = ["wormhole hacker"]
      </code></pre>
      <p>
        The purpose of script is to advance further processing without relying on dev team to write code process transaction.
      </p>

      {phase === "ready" ? (
        <Grid
          container
          direction="row"
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSave("edit")}
            >
              Save
            </Button>
            &nbsp;
            <Button
              variant="contained"
              color="default"
              onClick={() => handleSave("new")}
            >
              Save and Add another
            </Button>

            &nbsp;
            <Button
              variant="contained"
              color="default"
              onClick={() => handleSave("list")}
            >
              Save and go back to the list
            </Button>
          </Grid>

          {activityLabel.id !== null && (
            <Grid item>
              <Button variant="contained" color="secondary" onClick={handleDelete}>
                Delete
              </Button>
            </Grid>)}
        </Grid>
      ) : (
        <CircularProgress/>
      )}
    </form>
  );
};

export default ActivityLabelsDetailsPage;
