import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";
import { Text } from "../../ui/styled";
export const ProgressBar = styled.div({
    width: "145px",
    height: "10px",
    borderRadius: "10px",
    background: theme.colors.darkDCDC,
    ".progress": {
        position: "relative",
        height: "10px",
        borderRadius: "10px",
        background: theme.colors.primaryMediumLight,
    },
});
export const TextDescriptionProgress = styled(Text)({
    fontSize: "12px",
    lineHeight: "20px",
    marginBottom: "2em",
    color: theme.colors.dark7474,
    fontFamily: theme.weight.medium2,
    ".progress": {
        position: "relative",
        height: "10px",
        borderRadius: "10px",
        background: theme.colors.primaryMediumLight,
    },
    span: {
        "&:before": {
            content: "'s, '",
        },
        "&:last-child": {
            "&:before": {
                content: "'s and '",
            },
            "&:after": {
                content: "'s'",
            },
        },

        "&:nth-child(1)": {
            "&:before": {
                content: "''",
            },
        },
    }
});
