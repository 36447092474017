import * as apiClient from "../services/api";

export interface CommandState {
  name: string;
  value: string;
}

export const startCommand = (command: string): Promise<CommandKV> => {
  return apiClient.flagshipCreate<CommandKV>(`/cnc`, {name: command, value: "run"});
}

export const stopCommand = (command: string): Promise<CommandKV> => {
  return apiClient.flagshipCreate<CommandKV, CommandKV>(`/cnc`, {name: command, value: "stop"});
}
