import { FC, useEffect, useState } from "react";
import { Loader } from "../../../ui/Loader";

import { getProfilesPerTag } from "../../../../models/address";
import { AddressProfile } from "../../../../models/address";
import { Box } from "@material-ui/core";
import { toast } from "react-toastify";
import AddressComponent from "../search-results/address-component/address-component";


interface UsersPerTitleProps {
  tag: string;
  collection_slug?: string;
}

const UsersPerTitle: FC<UsersPerTitleProps> = ({ tag, collection_slug }) => {
  const [users, setUsers] = useState<AddressProfile[]>([]);
  const [pending, setPending] = useState(false);

  const getUsers = async (before?: string) => {
    setPending(true);

    try {
      const result = await getProfilesPerTag({ tag, before, collection_slug });

      setUsers(result);
    } catch (e) {
      console.log("error load users by tag", e);
      toast.error("Fail to load users. Try again");
    }

    setPending(false);
  };
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
  }, [users]);
  return (
    <>
      <Box
        mb={8}
        width="100%"
        className="title-page"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {users.map((item, index: number) => (
          <AddressComponent
            key={index}
            item={item}
            position={index}
            variant="title"
          />
        ))}
        {pending && (
          <div className="u-display-flex u-justify-center u-margin-top-5 u-margin-bottom-5">
            <Loader color="primary" className="label-loader-icon" />
          </div>
        )}
      </Box>
    </>
  );
};

export default UsersPerTitle;
