import { FC } from "react";


import ResultLink from '../result-link';
interface ContractProps {
  item: { address: string; name: string };
  i: number;
  searchId?: string;    
}
  
const Contract: FC<ContractProps> = ({ item, i, searchId }) => {
  const { address, name } = item;
  return (
    <div className="search-list">
      <div className="search-list__item" key={`search-result${i}`}>
        <div className="search-list__item__number">
          <span>{i + 1}</span>
        </div>
        <div className="item-top-list">
          <small className="brain-emoji"></small>
        </div>
        <div className="no-show-smartphone search-list__item__text u-color-dark">
          <span>{name}</span>
        </div>

        <div className="search-list__item__image">
          <span className="amount-smartphone u-color-dark u-text-left">
            {name}
          </span>
          {address && (
            <ResultLink
              href={`https://etherscan.io/address/${address}`}
              target="_blank"
              className="address-wallet"
              searchId={searchId}
            >
              {address.substr(0, 8) + "..." + address.slice(-7)}
            </ResultLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contract;
