import { FC } from "react";
import {
  shortAddress,
  ethscanAddressLink,
} from "../../../../../services/formatter";

import ResultLink from "../result-link";

import { Portfolio } from "../../../../../models/search";

interface TopPortfolioProps {
  item: Portfolio;
  i: number;
  searchId?: string;
}

const TopPortfolio: FC<TopPortfolioProps> = ({
  item,
  i,
  searchId,
}) => {
  const { portfolio, wallet_address, token_address, balance } = item;  
  return (
    <>
      <div className="search-list__item" key={`search-result${i}`}>
        <div className="search-list__item__number">
          <span>Top {i + 1}</span>
        </div>
        <div className="item-top-list">
          <small className="user-emoji"></small>
        </div>
        <div className="search-list__item__image u-align-right u-max-width-188px">
          <div className="address-and-balance">
            {token_address && (
              <ResultLink
                href={`${ethscanAddressLink(wallet_address)}`}
                target="_blank"
                className="address-wallet"
                searchId={searchId}
              >
                {shortAddress(wallet_address)}
              </ResultLink>
            )}

            <span> (${balance}) </span>
          </div>

          <span className="amount-smartphone ">{balance}</span>
          <table className="table-portfolio">
            <tbody>
              {portfolio.map((x, j) => {
                const { token_address, balance, token_symbol } =
                  x;
                return (
                  <tr>
                    <td>Top {j + 1}</td>
                    <td>
                      <ResultLink
                        href={`${ethscanAddressLink(token_address)}`}
                        target="_blank"
                        className="address-wallet"
                        searchId={searchId}
                      >
                        {shortAddress(token_address)}
                      </ResultLink>
                    </td>
                    <td>
                      ${balance} {token_symbol}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TopPortfolio;
