import { FC, useEffect, useRef, useState } from "react";
import { FeedFilters } from "../../../../models/search";
import {
  Badge,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ArrowDropUp, ExpandMore } from "@material-ui/icons";
import { chainNameFromID, getChainIcon } from "../../../../models/chain";
import { FilterType } from "../../../../models/filter";
import styled from "@emotion/styled";
import { theme } from "../../../../theme/theme";
import { isQA } from "../../../../services/appenv";

const StyledTabs = styled(Tabs)<{ ismobile?: any }>(({ ismobile }) => ({
  ".MuiTabs-indicator": {
    backgroundColor: theme.colors.primary,
    display: ismobile ? "none" : "",
  },
  ".MuiTabs-flexContainer": ismobile
    ? {
        width: 300,
        marginLeft: "auto",
        marginRight: "auto",
        flexWrap: "wrap",
      }
    : {},
}));

const StyledTab = styled(Tab)({
  minWidth: "unset",
  textTransform: "unset",
  paddingLeft: 16,
  paddingRight: 16,
  color: theme.colors.dark7474,
  fontSize: "13px",
  fontFamily: "Roboto Medium",
  fontWeight: 500,
  opacity: 1,
  "&.Mui-selected": {
    color: theme.colors.primary,
    fontSize: "15px",
  },
  ".MuiTouchRipple-root": {
    display: "none",
  },
  "&:not(.Mui-disabled):not(.Mui-selected):hover .MuiTab-wrapper": {
    transform: "scale(1.1)",
  },
  "&.Mui-disabled": {
    background: "transparent !important",
    color: theme.colors.tertiaryLight,
    position: "relative",
    pointerEvents: "unset",
    fontFamily: "Roboto",
    fontWeight: 400,
    "&::before": {
      content: "'Coming soon'",
      visibility: "hidden",
      position: "absolute",
      left: "calc(50% - 35px)",
      top: 0,
      fontSize: theme.size.extraSmall,
    },
    ".MuiTab-wrapper": {
      alignItems: "center",
      textAlign: "center",
    },
    "&:hover": {
      "&::before": {
        visibility: "visible",
      },
    },
  },
});

const StyledBadge = styled(Badge)({
  ".MuiBadge-badge": {
    background: theme.colors.primary,
    color: "white",
  },
});

const PaperArrow = styled(ArrowDropUp)({
  position: "absolute",
  fontSize: 7,
  width: 48,
  height: 48,
  top: -24,
  left: "calc(50% - 24px)",
  color: "white",
});

interface FilterTabsProps {
  filter: FilterType;
  onChangeFilter: (newFilter: FilterType) => void;
}

const FilterTabs: FC<FilterTabsProps> = ({ filter, onChangeFilter }) => {
  // TODO: Update this chain IDs or fetch from API
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const availableChainIDs = [1, 137];
  const [chainIDs, setChainIDs] = useState<number[]>([]);
  const isChainDisabled = true;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const arrowRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (anchorEl) {
      setChainIDs([...(filter.chainIDs || [])]);
    }
  }, [filter.chainIDs, anchorEl]);

  return (
    <>
      <StyledTabs value={filter.category} centered ismobile={isMobile}>
        {FeedFilters.map((filter) =>
          ["Smart money", "DeFi", "NFTs"].includes(filter.name) &&
          !isQA() ? null : (
            <StyledTab
              key={filter.value}
              label={filter.name}
              value={filter.name === "Everyone" && !isQA() ? "nft" : filter.value}
              onClick={() =>
                onChangeFilter({ ...filter, category: filter.value })
              }
              disabled={filter.disabled}
            />
          )
        )}

        <StyledTab
          label={
            <>
              <Box
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  if (!isChainDisabled) {
                    e.stopPropagation();
                    setAnchorEl(Boolean(anchorEl) ? null : e.currentTarget);
                  }
                }}
              >
                <StyledBadge badgeContent={filter.chainIDs?.length || 0}>
                  Chains
                </StyledBadge>
                {!isChainDisabled && (
                  <ExpandMore fontSize="small" style={{ marginLeft: 4 }} />
                )}
              </Box>
            </>
          }
          disabled={isChainDisabled}
        />
      </StyledTabs>

      <Popper
        placement="bottom"
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
      >
        <PaperArrow ref={arrowRef} />
        <Paper>
          <List>
            {availableChainIDs.map((chainID) => (
              <ListItem
                button
                key={chainID}
                onClick={(e) => {
                  e.stopPropagation();

                  if (chainIDs.includes(chainID)) {
                    chainIDs.splice(chainIDs.indexOf(chainID), 1);
                  } else {
                    chainIDs.push(chainID);
                  }
                  setChainIDs([...chainIDs]);
                }}
                selected={chainIDs.includes(chainID)}
              >
                <ListItemIcon>
                  <img
                    src={getChainIcon(chainID)}
                    width="16px"
                    height="16px"
                    alt="icon"
                  />
                </ListItemIcon>
                <ListItemText primary={chainNameFromID(chainID)} />
              </ListItem>
            ))}
            <Divider />
            <Box display="flex" justifyContent="flex-end" mt={1} mx={2}>
              <Button
                color="secondary"
                onClick={() => {
                  onChangeFilter({ ...filter, chainIDs: [] });
                  setAnchorEl(null);
                }}
              >
                Clear
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  onChangeFilter({ ...filter, chainIDs });
                  setAnchorEl(null);
                }}
              >
                Apply
              </Button>
            </Box>
          </List>
        </Paper>
      </Popper>
    </>
  );
};

export default FilterTabs;
