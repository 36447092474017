import { FC, useState } from "react";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import { nftImage } from "../../../../models/avatar";
import { generateUrl, NftAsset } from "../../../../models/nft";
interface NftAssetsListProps {
  assets: [];
}

const NftAssetsList: FC<NftAssetsListProps> = ({ assets }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <div className="u-scroll-y u-max-scroll-600">
        {assets
          .slice(0, showMore ? assets.length : 3)
          .map((asset: NftAsset, i: number) => (
            <NavLink
              to={generateUrl("nft", asset.address, asset.token_id)}
              className="nft-widget__row"
              key={i}
            >
              <img src={nftImage(asset)} alt="" loading="lazy" />
              <div
                className="nft-widget__text"
                style={{ justifyContent: "center" }}
              >
                <span
                  className={`${
                    asset.name.startsWith("0x") && "u-text-ellipsis"
                  }`}
                >
                  {asset.name}
                </span>

                {/* {asset.price && <span>${asset.price}</span>}
                <span
                  className={
                    asset.price_change_24h > 0
                      ? "gain-color"
                      : asset.price_change_24h < 0
                      ? "lost-color"
                      : "color-no-gain-lost"
                  }
                >
                  {asset.price_change_24h > 0 ? (
                    <CgArrowLongUp style={{ height: 11 }} />
                  ) : asset.price_change_24h < 0 ? (
                    <CgArrowLongDown style={{ height: 11 }} />
                  ) : null}{" "}
                  {asset.price_change_24h} %
                </span> */}
              </div>
            </NavLink>
          ))}
      </div>
      {assets.length > 3 && (
        <button
          onClick={() => setShowMore((showMore) => !showMore)}
          className="btn-top-all"
        >
          {!showMore ? "Show more" : "Show less"}
        </button>
      )}
    </>
  );
};

export default NftAssetsList;
