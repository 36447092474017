import { FC } from "react";
import { CgArrowLongUp } from "react-icons/cg";
import CollapseWidget from "../../widgets/collapse-widget";
interface TokenUserStatsProps {}

const TokenUserStats: FC<TokenUserStatsProps> = () => {
  return (
    <CollapseWidget title="Users">
      <div className="collapse-box">
        <div className="u-display-flex user-statistics__price">
          <p>108,247</p>
          <span>
            <CgArrowLongUp /> 0.5 %
          </span>
        </div>
        <div className="progress-line-bar">
          <div className="progress" style={{ width: "65%" }}>
            <span className="value-progress">new: 65%</span>
          </div>
        </div>
        <div className="progress-line-bar">
          <div className="progress" style={{ width: "35%" }}>
            <span className="value-progress">returning : 35%</span>
          </div>
        </div>
      </div>
    </CollapseWidget>
  );
};

export default TokenUserStats;
