import {FC, useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {AiOutlineFilter} from "react-icons/ai";
import Button from "@material-ui/core/Button";

import {useLocation} from "react-router-dom";

interface FilterActivityLabelProps {
    onChange: (model) => void;
    initialFilter: any;
}

const FilterActivityLabel: FC<FilterActivityLabelProps> = ({
   onChange,
   initialFilter
}) => {
    const [filter, setFilter] = useState(initialFilter);
    const onFilterChange = (e) => {
        if (e.target.value && e.target.value !== "" && e.target.value !== "any" && e.target.value !== "null") {
            setFilter({...filter, [e.target.name]: e.target.value})
        } else {
            delete filter[e.target.name];
            setFilter({...filter});
        }
    }
    const onSearch = () => {
        onChange({...filter});
    }

    const location = useLocation();
    useEffect(() => {
        let newData = {};
        let qs = new URLSearchParams(location.search);

        if (qs.get('address') && qs.get('address') !== 'any') {
          newData.address = qs.get('address');
        } else {
            delete newData['address'];
        }

        if (qs.get('method') && qs.get('method') !== 'any') {
          newData.method = qs.get('method');
        } else {
            delete newData['method'];
        }

        if (qs.get('signature') && qs.get('signature') !== 'any') {
            newData.signature = qs.get('signature');
        } else {
            delete newData['signature'];
        }
        setFilter(newData);
        onChange(newData);
    }, [location.search])
    return (
        <div className="u-margin-bottom-1 filter-admin-content">
          <span>
            <AiOutlineFilter/> Filter
          </span>
            <div>
                <FormControl className="u-margin-right-ui-1 u-width-fc-200">
                    <TextField
                        label="Address"
                        variant="standard"
                        value={filter?.address}
                        name="address"
                        onChange={onFilterChange}
                    />
                </FormControl>
                <FormControl className="u-margin-right-ui-1 u-width-fc-200">
                    <TextField
                        label="Method"
                        variant="standard"
                        value={filter?.method}
                        name="method"
                        onChange={onFilterChange}
                    />
                </FormControl>
                <FormControl className="u-margin-right-ui-1 u-width-fc-200">
                    <TextField
                      label="Signature"
                      variant="standard"
                      value={filter?.signature}
                      name="signature"
                      onChange={onFilterChange}
                    />
                </FormControl>
                <FormControl className="u-margin-right-ui-1 u-width-fc-200">
                    <Button
                        variant="contained"
                        size="small"
                        onClick={onSearch}
                    >
                        Filter
                    </Button>
                </FormControl>
            </div>
        </div>
    );
};

export default FilterActivityLabel;
