// Components import
import Header from "../components/base/header";
import SideBar from "../../pages/admin/components/sidebar";
import { Box, Container, Divider } from "@material-ui/core";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// Page
import { SiDiscord } from "react-icons/si";
import LoginPage, { RequiredAuth, RequiredAdmin } from "../../pages/login.page";

import AdminPage from "../../pages/admin/admin.page";

import QueryPage from "../../pages/query.page";
import WhiteListPage from "../../pages/whitelist.page";

// admin
import PendingReviewContract from "../../pages/admin/pending-review.page";
import ContractPage from "../../pages/admin/contract.page";
import ScanTokenPage from "../../pages/admin/scan-token.page";
import TransactionPage from "../../pages/admin/transaction.page";
import BlockPage from "../../pages/admin/block.page";
import TokenListPage from "../../pages/admin/token-list";
import TokenDetailsPage from "../../pages/admin/token-details.page";

import QuestionsPage from "../../pages/admin/questions-page";
import QuestionsDetailsPage from "../../pages/admin/questions-details.page";
import ActivityLabelListPage from "../../pages/admin/activity-label-list";
import ActivityLabelDetailsPage from "../../pages/admin/activity-label-details.page";

import TrendingQueryPage from "../../pages/admin/trending_query-list";
import TrendingQueryDetailsPage from "../../pages/admin/trending-query-details.page";

import HighlightPage from "../../pages/admin/highlight-list";
import HighlightDetailsPage from "../../pages/admin/highlight-details.page";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import FooterSearch from "../components/pages/search/footer-search";
import AboutPage from "../../pages/about.page";
import NotFoundPage from "../../pages/not-found.page";
import CatchError from "../components/base/catch-errors";

import TitlesPage from "../../pages/titles.page";
import TokensPage from "../../pages/tokens.page";

import { LaunchGate } from "../providers/launch";
import NftsPage from "../../pages/nfts.page";
import { styled } from "@material-ui/styles";
import CollectionsPage from "../../pages/collections.page";
import { IoLogoTwitter } from "react-icons/io5";
import { assets } from "../models/company_asset";
import ApikeysPage from "../../pages/apikeys.page";
import ApiLandingPage from "../../pages/api-landing-page";

// New Page Design
import FeedPage from "../../pages/feed.page";
import Profiles0Page from "../../pages/profilesv0-page";
import ProfilesPage from "../../pages/profiles.page";

const ContentContainer = styled(Container)(({ theme }) => ({
  "&.MuiContainer-root": {
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
  },
}));

const Routing = () => {
  return (
    <CatchError>
      <Router>
        <ToastContainer
          draggable={false}
          autoClose={4000}
          pauseOnHover={false}
          position={toast.POSITION.TOP_CENTER}
        />
        <Header />

        <Route path={["/tags", "/tokens"]}>
          <Divider />
        </Route>

        <ContentContainer fixed>
          <Switch>
            <Route exact path="/data-api" component={ApiLandingPage} />
            <Route exact path="/apikeys">
              <ApikeysPage requestOrCreate="request" />
            </Route>

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/waitlist" component={WhiteListPage} />

            <Route exact path="/feed1">
              <LaunchGate>
                <QueryPage />
              </LaunchGate>
            </Route>

            <Route exact path="/profiles0/:address">
              <LaunchGate>
                <Profiles0Page />
              </LaunchGate>
            </Route>

            <Route exact path="/tags/:tag">
              <LaunchGate>
                <TitlesPage />
              </LaunchGate>
            </Route>

            <Route exact path="/tokens/:address">
              <LaunchGate>
                <TokensPage />
              </LaunchGate>
            </Route>
            <Route exact path="/nfts/:address/:tokenId">
              <LaunchGate>
                <NftsPage />
              </LaunchGate>
            </Route>
            <Route exact path="/collections/:slug">
              <LaunchGate>
                <CollectionsPage />
              </LaunchGate>
            </Route>

            <Route exact path="/collections/:collection_slug/tags/:tag">
              <LaunchGate>
                <TitlesPage />
              </LaunchGate>
            </Route>
            {/* New pages */}

            <Route exact path={["/", "/feed"]}>
              <LaunchGate>
                <FeedPage />
              </LaunchGate>
            </Route>

            <Route exact path={"/profiles/:address"}>
              <LaunchGate>
                <ProfilesPage />
              </LaunchGate>
            </Route>

            <div className="admin-content">
              <Route exact path="/admin*">
                <SideBar />
                <RequiredAuth>
                  <RequiredAdmin>
                    <Box sx={{ display: "flex" }}>
                      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Route exact path="/admin" component={AdminPage} />
                        <Route exact path="/admin/apikeys">
                          <ApikeysPage requestOrCreate="create" />
                        </Route>
                        <Route
                          exact
                          path="/admin/pending-review"
                          render={() => (
                            <Redirect to="/admin/contracts/?contract_status=pending_review" />
                          )}
                        />
                        <Route
                          exact
                          path="/admin/contracts/?contract_status=pending_review"
                          component={PendingReviewContract}
                        />
                        <Route
                          exact
                          path="/admin/contracts/"
                          component={PendingReviewContract}
                        />
                        <Route
                          exact
                          path="/admin/contracts/:address"
                          component={ContractPage}
                        />
                        <Route
                          exact
                          path="/admin/tokens"
                          component={TokenListPage}
                        />
                        <Route
                          exact
                          path="/admin/tokens/:address"
                          component={TokenDetailsPage}
                        />
                        <Route
                          exact
                          path="/admin/contract-extraction"
                          component={ScanTokenPage}
                        />
                        <Route
                          exact
                          path="/admin/block"
                          component={BlockPage}
                        />
                        <Route
                          exact
                          path="/admin/questions"
                          component={QuestionsPage}
                        />
                        <Route
                          exact
                          path="/admin/questions/:questionId"
                          component={QuestionsDetailsPage}
                        />
                        <Route
                          exact
                          path="/admin/transaction"
                          component={TransactionPage}
                        />
                        <Route
                          exact
                          path="/admin/activity-labels"
                          component={ActivityLabelListPage}
                        />
                        <Route
                          exact
                          path="/admin/activity-labels/:id"
                          component={ActivityLabelDetailsPage}
                        />
                        <Route
                          exact
                          path="/admin/trending-queries"
                          component={TrendingQueryPage}
                        />
                        <Route
                          exact
                          path="/admin/trending-queries/:id"
                          component={TrendingQueryDetailsPage}
                        />
                        <Route
                          exact
                          path="/admin/highlights"
                          component={HighlightPage}
                        />
                        <Route
                          exact
                          path="/admin/highlights/:id"
                          component={HighlightDetailsPage}
                        />
                      </Box>
                    </Box>
                  </RequiredAdmin>
                </RequiredAuth>
              </Route>
            </div>

            <Route component={NotFoundPage} />
          </Switch>
          <FooterSearch />
        </ContentContainer>
      </Router>

      <div className="static-social">
        <a href={assets.discord} rel="noreferrer" target="_blank">
          <SiDiscord />
        </a>
        <a href={assets.twitter} rel="noreferrer" target="_blank">
          <IoLogoTwitter />
        </a>
      </div>
    </CatchError>
  );
};

export default Routing;
