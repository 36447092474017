import logo from "../../images/Openstory_logo_large.png";
import rolling_icon from "../../images/rolling.gif"
import iso_logo from "../../images/isotype.svg"

export const assets = {
  logo: logo,
  logoURL: () => `${process.env.PUBLIC_URL + assets.logo}`,
  isoLogo: iso_logo,
  loader: rolling_icon,
  companyName: "Openstory",
  twitter: "https://twitter.com/Openstory_io",
  discord: "https://discord.gg/Qn74hjgCzb",
  discord_invite: "https://discord.com/invite/Qn74hjgCzb",
  owner_company: "https://monday.capital",
  apidocs_url: "https://docs.openstory.io",
}


export const VIRALLOOP_WIDGET_ID = "i1SFo73WfWn4OhW8bU6NEUP8wzs"