import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UsersPerTitle from "../app/components/pages/search/widgets/users-per-title";
import SearchDropdown from "../app/components/SearchDropdown";
import { CircleBox } from "../app/components/ui/box";
import { StyledTitle, Text } from "../app/components/ui/styled";
import { getEmoji } from "../app/models/emojis";
import { getNftCollectionInfo, NftCollectionInfo } from "../app/models/nft";
import { getTagDescription, getTagName } from "../app/models/tag";

const TitlesPage: FC = () => {
  const { tag, collection_slug } = useParams<{
    tag: string;
    collection_slug: string;
  }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState<NftCollectionInfo>({});

  const loadCollectionData = async () => {
    if (!collection_slug) {
      return Promise.resolve(null)
    }

    setLoading(true);
    try {
      const dataInfo = await getNftCollectionInfo(collection_slug);
      setCollection(dataInfo);
    } catch (e) {
      console.log("error when loading collection info", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCollectionData();
  }, [collection_slug]);

  return (
    <>
      <div className="container">
        <SearchDropdown initInput={getTagName(tag)} />
      </div>

      <div className="container" style={{ position: "relative" }}>
        <div className="update-ago">updated 2 min ago</div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 525,
            mx: "auto",
            pt: 7,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircleBox>
              <span className={getEmoji(tag)}></span>
            </CircleBox>

            {collection_slug && collection?.name && (
              <StyledTitle
                style={{ fontSize: collection_slug ? "16px" : "30px" }}
              >
                {collection?.name} {" / "}{" "}
              </StyledTitle>
            )}

            <StyledTitle
              style={{ fontSize: collection_slug ? "16px" : "30px" }}
            >
              &nbsp; {getTagName(tag)}
            </StyledTitle>
          </Box>

          <Box mt={isMobile ? 4 : 5} mb={isMobile ? 6.5 : 9}>
            <Text
              color="tertiary"
              align="center"
              style={{ lineHeight: "24px", fontSize: "15px" }}
            >
              {getTagDescription(tag)}
            </Text>
          </Box>
          <UsersPerTitle tag={tag} collection_slug={collection_slug} />
        </Box>
      </div>
    </>
  );
};

export default TitlesPage;
