import { FC } from "react";
import { Text } from "../../../ui/styled";
import Numeral from "numeral";
import { SearchResult } from "../../../../models/search";

interface TextResultsProps {
  results: SearchResult;
}

const TextResults: FC<TextResultsProps> = ({ results }) => {
  return (
    <>
      {results.item_type === "activity" && (
        <Text className="text-results text-activity">
          {results.data.active ? (
            <>
              {results.data.week_count >= 10 && (
                <>
                  <span>Yes</span>, {results.data.token_symbol} is very active.
                  It has {Numeral(results.data.week_count).format("0,0")}{" "}
                  transactions in the last 7 days. Here are some recent
                  transactions.
                </>
              )}

              {results.data.week_count < 10 && (
                <>
                  <span>Yes</span>, {results.data.token_symbol} is active. It
                  has {results.data.month_count} transactions in the last 30
                  days. Here are some recent transactions.
                </>
              )}
            </>
          ) : (
            <>
              <span>No</span>, {results.data.token_symbol} is not active in the
              last 30 days.
            </>
          )}
        </Text>
      )}
      {results.item_type === "token_usage" && (
        <Text className="text-description">
          People currently use {results.data.token_symbol} token to{" "}
          {Object.keys(results.data.usages).map((usage: string, i: number) => (
            <span
              key={`people-use-${i}`}
              className={`${
                Object.keys(results.data.usages).length !== 1 && "no-commas"
              }`}
            >
              {usage}
            </span>
          ))}
        </Text>
      )}
      {results.item_type === "total_holder" && (
        <Text className="text-results">
          {results.data.token_symbol} is held by
          <span>{Numeral(results.data.total_holders).format("0,0")}</span>
          active holders.
        </Text>
      )}
      {results.item_type === "list_active_address" && (
        <Text className="text-results">
          <span>{Numeral(results.data.total_holders).format("0,0")}</span>
          addresses are active.
        </Text>
      )}

      {results.item_type === "contract" && (
        <>
          {results.data && (
            <Text className="text-results ">
              There
              {results.data.length <= 1 ? " is" : " are"}
              <span className="u-font-15">
                {results.data.length} smart contract
                {results.data.length <= 1 ? "" : "s"}
              </span>
              of {results.data[0].token_symbol}.
            </Text>
          )}
        </>
      )}

      {results.item_type === "contract_of_type" && (
        <>
          {results.data && (
            <Text className="text-results ">
              Found
              <span className="u-font-15">{results.data.length}</span>
              contract
            </Text>
          )}
        </>
      )}
      {results.item_type === "wallet" && (
        <Text className="text-results">
          Here is top {results.data && results.data.length} users of {""}
          {results.token.name} {results.token.symbol}.
        </Text>
      )}

      {results.item_type === "nft" && (
        <Text className="text-results">
          There are <> {results.data && results.data.length} </> results for{" "}
          <span>nft</span> dog in vest.
        </Text>
      )}

      {results.item_type === "most_trade_tokens" && results.data?.length > 0 && (
        <Text className="text-results">
          Top 10 most traded tokens in the past
          {results.metadata && (
            <>
              {results.metadata.window.unit === "day" &&
              results.metadata.window.value === 1
                ? " 24 hours"
                : results.metadata.window.unit === "week" &&
                  results.metadata.window.value === 1
                ? " 7 days"
                : results.metadata.window.unit === "month" &&
                  results.metadata.window.value === 1
                ? " last month"
                : " " +
                  results.metadata.window.value +
                  " " +
                  results.metadata.window.unit +
                  "s"}{" "}
            </>
          )}
        </Text>
      )}
      {results.item_type === "top_holder_portfolio" && (
        <Text className="text-results">
          Here is the portfolio of top {results.data && results.data.length}{" "}
          {results.token && results?.token.symbol}
        </Text>
      )}
    </>
  );
};

export default TextResults;
