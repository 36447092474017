import { FC, useState, useEffect } from "react";
import AddressComponent from "../search-results/address-component/address-component";
import { TopUser } from "../../../../models/search";
import { WidgetTitle } from "../../index";
import CollapseWidget from "./collapse-widget";
interface UserEngagementProps {
  data: any;
  token: any;
}

const UserEngagement: FC<UserEngagementProps> = ({ data, token }) => {
  const [pending, setPending] = useState(false);
  const [topUsers, setTopUsers] = useState<TopUser>();

 

  const [showMore, setShowMore] = useState(false);
  return (
    <div className="user-actively-accumulating">
      <div className="collapse-box">
        <WidgetTitle>Users actively accumulating {token.symbol}</WidgetTitle>
   
      </div>

      {data.length > 3 && (
        <button
          onClick={() => setShowMore((showMore) => !showMore)}
          className="btn-top-all"
        >
          {!showMore ? "see more" : "show less"}
        </button>
      )}
      {/* <button className="btn-top-all">see more</button> */}
    </div>
  );
};

export default UserEngagement;
