import { Text } from "../app/components/ui/styled/index";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { assets } from "../app/models/company_asset";
import { Box } from "@material-ui/core";
import { IoLogoTwitter } from "react-icons/io";
import { FiArrowRight } from "react-icons/fi";
import { FooterBox } from "../app/components/pages";
import { authURL } from "../app/services/appenv";

const ComingSoonPage = () => {
  const { pathname } = useLocation();
  return (
    <Box className="comming-soon-page">
      <Box>
        <NavLink
          to={`/`}
          activeClassName={pathname === "/" ? "active-menu-search " : ""}
        >
          {" "}
          <img src={`${process.env.PUBLIC_URL + assets.logo}`} alt="logo" />
        </NavLink>
        <Text color="tertiary-light" className="comming-soon-text">
          Coming soon
        </Text>
      </Box>

      <Box className="comming-soon-page__content">
        <Text
          color="dark"
          weight="medium2"
          className="comming-soon-page__content--title"
        >
          What’s happening on-chain
        </Text>
        <Text color="dark" className="comming-soon-page__content--description">
          Openstory is an indexer that focuses on scanning and unveiling
          on-chain truth like a story so everyone can easily make decisions
          based on objective information.
        </Text>
        <a
          href={assets.twitter}
          rel="noreferrer"
          target="_blank"
          className="comming-soon-page__content--link"
        >
          <IoLogoTwitter />
          follow on twitter to get early access
        </a>

        <div>
          <a href={authURL} className="btn-invite-link">
            Click here if you got an invite
          </a>
        </div>
      </Box>

      <footer className="footer-fixed">
        <p>© {assets.companyName} 2023</p>
      </footer>
    </Box>
  );
};

export default ComingSoonPage;
