import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  // Color primary
  palette: {
    primary: {
      main: "#8F4AE7",
      dark: "#7626DC",
      light: "#A670EB",
      "100": "#f7f0ff",
      "200": "#e9d9ff",
    },
    // Color secondary
    secondary: {
      main: "#4AC9DB",
      dark: "#04A2B8",
      light: "#65dded",
      "100": "#e9f6f8",
      "200": "#d4faff",
    },
    // Color danger
    error: {
      main: "#d80000",
      light: "#fdadad",
      "100": "#fff0f0",
    },
    // Color tertiary
    text: {
      primary: "#595757",
      secondary: "#100b0b",
      hint: "#999999",
      disabled: "#EFEFEF",
    },
    // Color test
    success: {
      main: "#63AE28",
      dark: "#315f0e",
      light: "#97d667",
      "200": "#f2ffe8",
      "100": "#efffe2",
    },
    // Color dark
    grey: {
      "900": "#000000",
      "800": "#222222",
      "300": "#929191",
      "200": "#f0f0f0",
      "100": "#fff",
    },
    // Color warning
    warning: {
      main: "#ffc800",
    },
  },
});
