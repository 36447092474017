import { FC } from "react";
import { ActivityBox, SectionTitle } from "../../..";

interface ActivityProps {
  dataResult: any;
}

const Activity: FC<ActivityProps> = ({ dataResult }) => {
  return (
    <div>
      <SectionTitle>What’s happening</SectionTitle>
      <ActivityBox>
        {dataResult.data && (
          <>
            {dataResult.data.active ? (
              <>
                {dataResult.data.week_count >= 10 && (
                  <>
                    <small className="fire-emoji"></small>
                    <span>High</span>
                  </>
                )}
                {dataResult.data.week_count < 10 && (
                  <>
                    <small className="normal-emoji"></small>
                    <span>Normal</span>
                  </>
                )}
              </>
            ) : (
              <>
                <small className="sleeping-emoji"></small>
                <span>Low</span>
              </>
            )}
          </>
        )}
        <p className="activity-tag">activity</p>
      </ActivityBox>
    </div>
  );
};

export default Activity;
