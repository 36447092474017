import { FC } from "react";
import {
  shortAddress,
  ethscanAddressLink,
} from "../../../../../services/formatter";

import ResultLink from "../result-link";

interface TotalHolderProps {
  item: {
    wallet_addresses: [];
    total_holders: string;
  };
  i: number;
  pageNumber: number;
  searchId?: string;
  pagination: { item_per_page: number };
}

const TotalHolder: FC<TotalHolderProps> = ({
  item,
  i,
  pageNumber,
  searchId,
  pagination,
}) => {
  const { wallet_addresses } = item;
  const { item_per_page } = pagination;
  return (
    <>
      {wallet_addresses.map((x: any, i: number) => {
        const { token_address, token_symbol, wallet_address, balance } = x;
        return (
          <div className="search-list__item" key={`search-result${i}`}>
            <div className="search-list__item__number">
              <span>
                {((pageNumber > 1 ? pageNumber : 1) - 1) * item_per_page +
                  (i + 1)}
              </span>
            </div>
            <div className="item-top-list">
              <small className="user-emoji"></small>
            </div>
            <div className="search-list__item__image u-align-right u-max-width-188px">
              {token_address && (
                <ResultLink
                  href={`${ethscanAddressLink(wallet_address)}`}
                  target="_blank"
                  className="address-wallet"
                  searchId={searchId}
                >
                  {shortAddress(wallet_address)}
                </ResultLink>
              )}
              <span className="amount-smartphone ">
                {balance} {token_symbol}
              </span>
            </div>
            <div
              className={`search-list__item__hold-token ${
                wallet_addresses[0].balance.length > 20 && "amount-large-size"
              }`}
            >
              {balance} {token_symbol}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default TotalHolder;
