import { Box } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import ActivityRowBody from "./activity-row-body";
import ActivityRowFooter from "./activity-row-footer";
import ActivityRowHeader from "./activity-row-header";
import _ from "underscore";
import { AddressProfile, getAddressProfile } from "../../../models/address";

interface ActvityRowAddressProps {
  item: any;
  token_id?: string;
  activity: string;
  type?: "front" | "profile" | "followers" | "collection" | "nftowner" | string;
  widthContainer?: string | "471px";
  filter?: any;
}

const ActvityRowAddress: FC<ActvityRowAddressProps> = ({
  filter,
  type,
  item,
  widthContainer,
}) => {

  const tags = _.groupBy(item.nft_assets, "tag");
  const groupTag = Object.values(tags);
  
  return (
    <Box
      className={`u-margin-bottom-medium u-hover-box u-margin-bottom-3 u-position-relative ${(type === "front" || type === "followers") && "u-margin-left-2-7"
        }`}
      style={{
        maxWidth: "1000px",
        minWidth: widthContainer,
        paddingRight: "4em",
        width: "fit-content",
      }}
    >
      <ActivityRowHeader tags={tags} type={type} item={item} params={filter} />
      {groupTag.length === 0 && type !== "front" ? (
        <ActivityRowBody type={type} item={item} params={filter} asset_type={item.asset_type} />
      ) : (
        <>
          {groupTag.map((group) => (
            <>
              <ActivityRowBody type={type} item={group} params={filter} asset_type={item.asset_type} />
              <ActivityRowFooter
                tags={tags}
                type={type}
                item={group}
                params={filter}

              />
            </>
          ))}
        </>
      )}
    </Box>
  );
};

export default ActvityRowAddress;
