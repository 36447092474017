import { FC, useState } from "react";

import { Timeline, TimelineConnector, TimelineDot } from "@material-ui/lab";
import {
  LeftContent,
  RightContent,
  Separator,
  TextDate,
  TimeLineItem,
  YearLabel,
} from "../../../../app/components/ui/styled/time-line-tools";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import ActvityRowAddress from "../activity-row-address";
import { Profile } from "../../../models/search";
import { getActivities, Web3Activity } from "../../../models/activity";
import InfinityScroll from "../../ui/infinite-scroll";
import { useTransition, animated, config } from "@react-spring/web";

import { temp_data_followers_tab } from "../../../models/address-data";
import { useParams, useHistory, useLocation } from "react-router-dom";

import ActivityTitle from "../search/search-results/address-component/activity-title";
interface TimelineRecordProps {
  params: any;
  profile: any;
}
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
const TimelineRecord: FC<TimelineRecordProps> = ({ profile, params }) => {
  const [pending, setPending] = useState(false);
  const [activities, setActivities] = useState<Web3Activity[]>(
    [] as Web3Activity[]
  );

  const transitions = useTransition(activities, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    delay: 200,
    config: config.molasses,
    //onRest: () => setItems([]),
  });

  const asyncActivities = async () => {
    return await getActivities({ wallet_address: profile.address, source: params.tab, asset_type: params.asset_type });
  };

  const fetchMore = async () => {
    if (!activities || activities.length <= 0) {
      return Promise.resolve([]);
    }
    return await getActivities({
      wallet_address: profile.address,
      source: params.tab,
      asset_type: params.asset_type,
      before: activities[activities.length - 1].surrogate_key,
    });
  };

  return (
    <div className="left-content">
      {(params.tab === "followers" || params.tab === "following") ? (
        <>
          {temp_data_followers_tab.map((activity) => (
            <ActvityRowAddress
              type="followers"
              item={activity}
              widthContainer="365px"
              filter={params}
            />
          ))}
        </>
      ) : (
        <Timeline className="u-padding-none" style={{ position: "relative" }}>
          <InfinityScroll
            activities={activities}
            setActivities={setActivities}
            setPending={setPending}
            pending={pending}
            fetchNew={asyncActivities}
            fetchMore={fetchMore}
          >
            {activities.length >= 1 && <YearLabel>2022</YearLabel>}
            {activities.length >= 1 &&
              transitions(({ opacity }, activity, t, index) => (
                <animated.div
                  style={{
                    opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                  }}
                >
                  <TimeLineItem >
                    <LeftContent>
                      <ActvityRowAddress
                        type="profile"
                        item={activity}
                        widthContainer="365px"
                      />
                    </LeftContent>
                    <Separator>
                      <TimelineDot>
                        <TextDate>
                          {activity.block_timestamp &&
                            dayjs(activity.block_timestamp).format("MMM DD")}
                        </TextDate>
                      </TimelineDot>
                      <TimelineConnector />
                    </Separator>
                    <RightContent>
                      <span>Sold to a colletor for 1.21 ETH</span>
                    </RightContent>
                  </TimeLineItem>
                </animated.div>
              ))}
          </InfinityScroll>
        </Timeline>
      )}
    </div>
  );
};

export default TimelineRecord;
