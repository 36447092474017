import { FC, useState, Fragment } from "react";
import { NavLink, useParams } from "react-router-dom";
import { NftCharacteristics, SectionTitle } from "../../../index";
import CurrentOwner from "../../widgets/current-owner";
import NftsHistory from "../../widgets/nfts-history";
import TitleNft from "./title-nft";

import { Skeleton } from "@material-ui/lab";
import { PictureNft } from "./styled";
import { ActivityStatus } from "../../widgets/activity-on-web";

import ethLogo from "../../../../../../images/temp_img/image 131.png";

import {
  getLooksRareUrl,
  getOpenSeaUrl,
  NftBasicInfo,
  maybeAnimationUrlType
} from "../../../../../models/nft";
import { shortAddress, formatEther } from "../../../../../services/formatter";
import { getTagName, tagToSlug } from "../../../../../models/tag";
import NftActivity from "../../widgets/nft-activity";
import { Link } from "../../../../ui/styled";
import { displayLogo } from "../../../../../models/address";
import { NftPictureItem } from "../address-component/profile-nft-assets";
import days from "../../../../../services/daysjs";
import { HeaderMeta } from "../../../../base/header-meta";
import { nftImage } from "../../../../../models/avatar";
import VideoPlayer from "../../../../ui/video-player";
import AudioPlayer from "../../../../ui/audio-player";
import ModelViewer from "../../../../ui/model-viwer";

interface NftDetailsProps {
  nft: NftBasicInfo;
}


const NftDetails: FC<NftDetailsProps> = ({ nft }) => {
  const { address, tokenId } = useParams<{
    address: string;
    tokenId: string;
  }>();
  const [pending] = useState(false);



  return (
    <>
      {HeaderMeta({
        title: nft?.name && nft?.name,
        description: nft?.name && nft?.name,
        image: nftImage({ address: address, token_id: tokenId, size: "400" }, "full"),
      })}
      <div className="nft-content nft-owner-timeline">
        <div className="update-ago">updated 2 min ago</div>

        <div className="nft-details u-display-flex ">
          <div className="u-only-smartphone">
            <TitleNft nft={nft} />
          </div>
          {pending && <Skeleton width={300} height={300} />}
          <PictureNft className="nft-no-link">
            {maybeAnimationUrlType(nft?.animation_url) === "video" && (
              <VideoPlayer controls="all" source={nft?.animation_url} />
            )}

            {maybeAnimationUrlType(nft?.animation_url) === "3dmodel" && (
              <ModelViewer source={nft?.animation_url} />
            )}

            {maybeAnimationUrlType(nft?.animation_url) === "audio" && (
              <AudioPlayer source={nft?.animation_url} />
            )}

            {maybeAnimationUrlType(nft?.animation_url) === "image" && <NftPictureItem
              nft={{
                token_id: tokenId,
                address: address,
                size: "400",
              }}
              type="full"
            />}
            {(nft.price?.amount || 0) > 0 && <small className="nft-price">
              <img
                className="eth-logo"
                src={`${process.env.PUBLIC_URL + ethLogo}`}
                alt=""
              />
              {formatEther(nft.price?.amount)}
            </small>}
          </PictureNft>
          <NftCharacteristics>
            <div className="u-only-desktop">
              <TitleNft nft={nft} />
            </div>
            {/* {nft?.list_price_eth !== "" && (
              <div className="nft-coin">
                <img
                  src={displayLogo(
                    "0x0000000000000000000000000000000000000000.png"
                  )}
                  alt=""
                />

                <Link
                  href={getOpenSeaUrl(address, tokenId)}
                  rel="noreferrer"
                  target="_blank"
                >
                  Live Auction
                </Link>
              </div>
            )} */}

            <div className="u-margin-top-3 ntf-characteristics">
              <div className="nft-description">
                {nft?.creator?.address && (
                  <>
                    <span>Creator</span>
                    <p className="nft-profile-address">
                      <NavLink to={`/profiles0/${nft?.creator.address}`}>
                        {nft?.creator?.ens
                          ? nft?.creator.ens
                          : shortAddress(nft?.creator.address, 5, 0)}
                      </NavLink>
                      {(nft?.creator.tags?.slice(0, 2) || []).map((tag, index) => (
                        <Fragment key={index}>
                          <NavLink
                            className="u-text-lowercase tag-button"
                            to={`/tags/${tagToSlug(tag)}`}
                          >
                            {getTagName(tag)}
                          </NavLink>
                        </Fragment>
                      ))}
                    </p>
                  </>
                )}
              </div>
              <div className="nft-description">
                {nft?.created_at && (
                  <>
                    <span>Created</span>
                    <p
                      className="nft-profile-address u-font-regular"
                      style={{ fontSize: "14px" }}
                    >
                      {days(nft?.created_at).fromNow()}
                    </p>
                  </>
                )}
              </div>
              <div className="nft-description">
                {nft?.created_at && (
                  <>
                    <span>Status</span>
                    {nft?.for_sale && <p
                      className="nft-profile-address u-font-regular"
                      style={{ fontSize: "14px" }}
                    >
                      ON SALE
                      {(nft.price?.amount || 0) > 0 && " for " + formatEther(nft.price?.amount)}
                    </p>}
                  </>
                )}
              </div>
              <div className="nft-description buy-links">
                <span>You can buy at</span>
                <p>
                  <Link
                    href={getOpenSeaUrl(address, tokenId)}
                    rel="noreferrer"
                    target="_blank"
                  >
                    opensea
                  </Link>
                  ,{" "}
                  <Link
                    href={getLooksRareUrl(address, tokenId)}
                    rel="noreferrer"
                    target="_blank"
                  >
                    looksrare
                  </Link>
                </p>
              </div>
            </div>
          </NftCharacteristics>
        </div>
        <div className="current-owner-and-activity">
          <div className="nft-left-section">
            <div className="u-padding-bottom-05 nft-left-section__sections">
              {nft?.holding_duration ? (
                <>
                  {(nft?.owner || nft?.owner !== "") &&
                    <CurrentOwner
                      token_id={tokenId}
                      owner={nft?.owner}
                      holding_duration={nft?.holding_duration}
                      list_price={nft?.list_price_eth}
                    />
                  }
                </>
              ) : (
                <>
                  <SectionTitle>Current owner</SectionTitle>
                  <span className="text-placeholder">No records</span>
                </>
              )}
            </div>
            <div className="u-margin-top-1 nft-left-section__sections">
              <NftsHistory />
            </div>
          </div>
          <div className="nft-center-section">
            <div className="nft-center-section__content">
              <SectionTitle className="u-margin-bottom-3">What’s happening</SectionTitle>
              {/* <ActivityStatus status="medium" />  */}
              <NftActivity />
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default NftDetails;
