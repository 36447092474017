import { FC } from "react";
import {
  shortAddress,
  ethscanAddressLink,
} from "../../../../../services/formatter";


import ResultLink from '../result-link';        
interface ListActiveAddressProps {
  item: { wallet_addresses: [] };
  i: number;
  searchId?: string;    
}

const ListActiveAddress: FC<ListActiveAddressProps> = ({ item, i, searchId }) => {
  const { wallet_addresses } = item;

  return (
    <>
      {wallet_addresses.map((x: any, i: number) => {
        const { token_address, token_symbol, wallet_address, balance } = x;
        return (
          <div className="search-list__item" key={`search-result${i}`}>
            <div className="search-list__item__number">
              <span>{i + 1}</span>
            </div>

            <div className="item-top-list">
              <small className="user-emoji"></small>
            </div>
            <div className="search-list__item__image">
              {token_address && (
                <ResultLink
                  href={`${ethscanAddressLink(wallet_address)}`}
                  target="_blank"
                  className="address-wallet"
                  searchId={searchId}
                >
                  {shortAddress(wallet_address)}
                </ResultLink>
              )}
              <span className="amount-smartphone">
                {balance} {token_symbol}
              </span>
            </div>
            <div
              className={`search-list__item__hold-token ${
                wallet_addresses[0].balance.length > 20 && "amount-large-size"
              }`}
            >
              {balance} {token_symbol}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ListActiveAddress;
