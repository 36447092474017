import styled from "@emotion/styled";
import { theme } from "../../theme/theme";

interface WidgetTitleProps {
  collapse?: boolean;
}
export const Login = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-buttons {
    img {
      display: none;
    }
  }
  @media screen and (max-width: ${theme.breakpoint.smartphone}) {
    .login-buttons {
      align-items: center;
      flex-direction: column;
      img {
        display: none !important;
      }
      button {
        margin-bottom: 1em;
      }
    }
  }
`;
export const BoxLogin = styled.div`
  img {
    width: 127px !important;
  }
  width: 600px;
  display: inherit;
  align-items: center;
  flex-direction: column;
  .login-text {
    width: 430px;
    line-height: 2em;
    @media screen and (max-width: 450px) {
      width: 320px;
    }
  }
  .login-text-2 {
    font-size: 1rem;
  }
  div {
    width: 100%;
    display: inherit;
    justify-content: space-between;
  }
`;

export const BoxAbout = styled.div`
  img {
    width: 127px !important;
  }
  width: 600px;
  display: inherit;
  align-items: center;
  flex-direction: column;
  .login-text {
    width: 430px;
    line-height: 2em;
    @media screen and (max-width: 450px) {
      width: 320px;
    }
  }
  div {
    width: 100%;
    display: inherit;
    justify-content: space-between;
  }
`;

export const HeaderBox = styled.header`
  height: 90px;
  display: flex;
  justify-content: flex-end;
  // background: ${theme.colors.contrast} !important;
  @media screen and (max-width: ${theme.breakpoint.xl}) {
    height: 80px;
  }
  @media screen and (max-width: 1080px) {
    height: 70px;
  }
  @media screen and (max-width: ${theme.breakpoint.lg}) {
    top: 0;
    right: 0;
    z-index: 2;
    width: 0;
    height: 65px;
    max-width: inherit;
    background: ${theme.colors.contrast};
  }
  ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      a {
        font-size: 16px;
        color: ${theme.colors.dark};
        font-family: "Roboto Light";
      }
    }
    li {
      a {
        display: block;
        margin-right: 3em;
        @media screen and (max-width: ${theme.breakpoint.lg}) {
          padding: 0;
          margin: 1.3em 0;
          display: inline-block;
        }
      }
    }
    li:last-child {
      a {
        display: block;
        margin-right: 0;
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoint.lg}) {
    .list-search-menu {
      display: none;
      margin-top: 7em;
      text-align: center;
      
    }
    .show-menu-search {
      left: 0;
      right: 0;
      width: 100%;
      z-index: 100;
      height: 100vh;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100vw;
      background: ${theme.colors.contrast};
      .list-search-menu {
        display: block;
      }
    }
  }
`;

export const MenuButton = styled.button`
  top: 0;
  right: 0;
  border: 0;
  width: 9%;
  height: 65px;
  cursor: pointer;
  position: absolute;
  svg {
    font-size: 1.3rem;
    color: ${theme.colors.dark};
  }
  background: ${theme.colors.contrast};
  display: none;
  color: ${theme.colors.dark};
  @media screen and (max-width: ${theme.breakpoint.lg}) {
    display: block;
    position: fixed;
  }
  @media screen and (max-width: ${theme.breakpoint.smartphone}) {
    width: 12%;
  }
`;

export const FooterBox = styled.footer`
  margin-top: 3em;
  text-align: center;
  padding-bottom: 1.5em;
  p {
    margin-top: 1em;
    font-size: 12px;
    color: ${theme.colors.dark777};
  }
  a {
    font-size: 0.9rem;
    svg {
      color: ${theme.colors.primary};
      transform: scale(1.5);
    }
  }
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 0.5em 0;
    padding-bottom: 1em;
    background: ${theme.colors.contrast};
    p {
      margin-top: 1em;
      font-size: 10px;
    }
  }
`;
export const SectionTitle = styled.p<WidgetTitleProps>`
  width: 100%;
  font-size: 12px;
  margin-bottom: 25px;
  position: relative;
  font-family: Roboto Medium;
  color: ${theme.colors.dark7474};
  svg {
    display: none;
  }
  .badge-count{
    margin-left: 15px;
    .MuiBadge-badge{
      color: ${theme.colors.contrast};
      background: ${theme.colors.dark7474};
      height: 13px;
      font-size: 10px;
    }
  }
  @media screen and (max-width: ${theme.breakpoint.tablet}) {
    fontsize: 10px;
    cursor: pointer;
    height: ${({ collapse }) => collapse && "30px"};
    display: ${({ collapse }) => collapse && "flex"};
    align-items: ${({ collapse }) => collapse && "center"};
    margin-bottom: ${({ collapse }) => collapse && "10px"};
    justify-content: ${({ collapse }) => collapse && "center"};
    background: ${({ collapse }) => collapse && `${theme.colors.darkFAFA}`};
    svg {
      right: 13px;
      display: block;
      font-size: 1.5em;
      position: absolute;
    }
  }
`;

export const ActivityBox = styled.div`
  display: flex;
  font-size: 30px;
  margin-top: 0.8em;
  margin-bottom: 1em;
  span {
    margin-left: 0.5em;
    color: ${theme.colors.dark};
    font-family: "Roboto Medium";
  }
  @media screen and (max-width: 870px) {
    font-size: 20px;
    margin-left: 0;
  }
`;

export const WidgetTitle = styled.p`
  font-size: 15px;
  margin-bottom: 1em;
  font-family: ${theme.weight.medium2};
  @media screen and (max-width: $tablet) {
    font-size: 14px;
  }
  a {
    display: inline-block;
    color: ${theme.colors.primary};
    font-family: ${theme.weight.medium2};
    &:hover {
      transform: scale(1.03);
    }
  }
`;

export const HighlightWidgetTitle = styled.p`
  font-size: 15px;
  margin-bottom: 8px;
  color: ${theme.colors.tertiary};
  font-family: ${theme.weight.medium};
  @media screen and (max-width: $tablet) {
    font-size: 14px;
  }
  a {
    font-size: 15px;
    display: inline-block;
    font-family: ${theme.weight.medium2};
    &:hover {
      transform: scale(1.03) !important;
    }
  }
`;

export const HighlightWidgetSubtitle = styled.p`
  font-size: 13px;
  &:not(:last-child) {
    margin-bottom: 25px;
  }
  color: ${theme.colors.tertiaryLight};
  @media screen and (max-width: $tablet) {
    font-size: 14px;
  }
  a {
    display: inline-block;
    color: ${theme.colors.primary};
    font-family: ${theme.weight.medium2};
    &:hover {
      transform: scale(1.03);
    }
  }
`;
export const NftName = styled.div`
  .nft-tag {
    font-size: 18px;
    margin-bottom: 16px;
    display: inline-block;
    color: ${theme.colors.primary};
    font-family: ${theme.weight.medium};
  }
  .nft-name {
    color: ${theme.colors.dark};
    margin: 0.2em 0;
    font-size: 30px;
    font-family: ${theme.weight.medium2};
    @media screen and (max-width: ${theme.breakpoint.tablet}) {
      font-size: 25px;
    }
    @media screen and (max-width: ${theme.breakpoint.smartphone}) {
      font-size: 18px;
    }
  }
`;
export const NftCharacteristics = styled.div`
  width: 60%;
  padding-left: 3em;
  @media screen and (max-width: ${theme.breakpoint.xl}) {
    padding-left: 80px;
  }
  @media screen and (max-width: ${theme.breakpoint.tablet}) {
    width: 100%;
    padding-left: 0;
  }
  .nft-coin {
    display: flex;
    margin: 0.5em 0;
    align-items: center;
    img {
      width: 20px;
    }
    a {
      font-size: 14px;
      margin-left: 15px;
      color: ${theme.colors.primary};
    }
  }
  .nft-description {
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
    span {
      display: flex;
      width: 180px;
      font-size: 12px;
      align-items: center;
      color: ${theme.colors.tertiary};
      @media screen and (max-width: ${theme.breakpoint.xl}) {
        width: 160px !important;
      }
    }
    .nft-profile-address {
      font-family: ${theme.weight.medium2};
    }
    p {
      width: auto;
      font-size: 15px;
      color: ${theme.colors.tertiary};
      a {
        &:nth-child(1) {
          margin-right: 3px;
        }
        display: inline-block;
        color: ${theme.colors.tertiary};
        font-family: ${theme.weight.medium2};
        &:first-child {
          color: ${theme.colors.tertiary};
        }
        &:hover {
          transform: scale(1.03);
        }
      }
      span {
        font-size: 14px;
        margin-left: 0.5em;
        font-family: ${theme.weight.medium2};
        color: ${theme.colors.extra};
        svg {
          top: 2px;
          position: relative;
        }
      }
      @media screen and (max-width: ${theme.breakpoint.tablet}) {
        width: 100%;
      }
    }
  }

  .buy-links {
    p {
      a {
        font-size: 14px;
        color: ${theme.colors.primary};
        font-family: ${theme.weight.medium};
        &:first-child {
          color: ${theme.colors.primary};
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoint.tablet}) {
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    .nft-description {
      flex-direction: column;
    }
    .ntf-characteristics {
      margin: 0 !important;
    }
  }
`;

export const ProfileBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
  .profile-avatar {
    width: 50px;
    height: 50px;
    display: flex;
    max-width: 20%;
    font-size: 21px;
    margin-right: 15px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: ${theme.colors.darkFAFA};
    border: 1px solid ${theme.colors.darkF3F3};
  }
  .profile-owner {
    width: 76%;
    display: flex;
    flex-direction: column;
    span {
      font-size: 13px;
      color: ${theme.colors.tertiary};
    }
    &__links {
      display: flex;
      flex-wrap: wrap;
    }
    .current-profile-address {
      font-size: 13px;
      margin-left: 10px;
      color: ${theme.colors.tertiary};
      font-family: ${theme.weight.medium2};
    }
    .tag-chip {
      display: block;
      font-size: 13px;
      font-family: ${theme.weight.medium2};
      color: ${theme.colors.primary};
      background: ${theme.colors.darkFAFA};
      cursor: pointer;
      margin: 6px 6px 4px 0;
      border-radius: 20px;
      border: 1px solid transparent;
      background: rgb(250, 250, 250) none repeat scroll 0% 0%;
      &:hover {
        border-color: ${theme.colors.primary};
      }
      .MuiChip-label {
        color: ${theme.colors.primary};
        font-family: ${theme.weight.medium2};
        padding-left: 10px;
        padding-right: 10px;
      }
      @media screen and (max-width: $tablet) {
        font-size: 15px;
      }
      .MuiChip-root {
        height: 22px;
        cursor: pointer;
        background: none;
      }
    }
  }
`;

export const CurrentOwnerTitle = styled.div`
  font-size: 13px;
  color: ${theme.colors.tertiaryLight};
  font-family: ${theme.weight.medium};
  .text-price {
    display: block;
    font-size: 13px;
    font-family: ${theme.weight.medium};
    color: ${theme.colors.dark};
  }
`;

export const PLText = styled.p`
  display: flex;
  margin: 0.5em 0;
  font-size: 13px;
  align-items: center;
  font-family: ${theme.weight.medium};
  color: ${theme.colors.tertiaryLight};
  span {
    margin-left: 0.5em;
    font-family: ${theme.weight.medium2};
    color: ${theme.colors.extra};
    svg {
      top: 2px;
      position: relative;
    }
  }
`;

export const NftsImages = styled.div`
  width: fit-content;
  margin: 0.5em 0;
  .nft-images {
    margin: 0.3em 0;
    .profile-nfts {
      margin: 0;
      a {
        display: inline-block;
      }
      img {
        margin: 0;
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-right: 0.4em;
      }
    }
  }
  p {
    margin: 0.3em 0;
    font-size: 13px;
    color: ${theme.colors.tertiaryLight};
    span {
      font-size: 15px;
      font-family: ${theme.weight.medium2};
      color: ${theme.colors.dark};
    }
  }
`;

export const TextsInfo = styled.div`
  margin-bottom: 30px;
  p {
    font-size: 13px;
    padding-bottom: 5px;
    font-family: ${theme.weight.medium};
    color: ${theme.colors.dark};
  }
  span {
    font-size: 23px;
    color: ${theme.colors.dark};
    font-family: ${theme.weight.medium2};
  }
`;

export const TabContent = styled.div`
  padding-top: 50px;
  position: sticky;
  top: 40px;
  background: white;
  z-index: 9;
  @media screen and (max-width: ${theme.breakpoint.smartphone}) {
    top: 55px;
    padding-top: 0;
  }
`;
