import { BigNumber } from "ethers";
import { toast } from "react-toastify";
import * as apiClient from "../services/api";
import { Web3Activity } from "./activity";

export interface NftUserInfo {
  id?: number | string;
  name?: string;
  address: string;
  tags?: string[] | null;
  ens?: string | null;
}

export interface NftCollectionInfo {
  name?: string;
  slug: string;
  contract_address: string;

  image_url?: string;
  banner_image_url?: string;
  created_at?: string;
  stats?: CollectionStat;
  description?: string;
  hidden?: boolean;
  large_image_url?: string;
  owner?: string;
}

export interface ExchangeStat {
  total_item?: number;
  total_address?: number;
  text?: string;
  duration?: {
    duration: number;
    unit: string;
  };
}

export interface CollectionStat {
  one_day_volume?: number;
  one_day_change?: number;
  one_day_sales?: number;
  one_day_average_price?: number;
  seven_day_volume?: number;
  seven_day_change?: number;
  seven_day_sales?: number;
  seven_day_average_price?: number;
  thirty_day_volume?: number;
  thirty_day_change?: number;
  thirty_day_sales?: number;
  thirty_day_average_price?: number;
  total_volume?: number;
  total_sales?: number;
  total_supply?: number;
  count?: number;
  num_owners?: number;
  average_price?: number;
  num_reports?: number;
  market_cap?: number;
  floor_price?: number;

  total_minter?: number;
  total_buyer?: number;
  new_owner_24h?: number;
  median_holding?: NftHoldingDuration;

  unique_item_exchanged?: ExchangeStat;
}

export interface NftAsset {
  name?: string;
  contract_address?: string;
  token_id?: string;
  address?: string;
  price?: number;
  price_change_24h?: number;
  owner?: { address: string; tags: [] };
  collection?: NftCollectionInfo;
}

export interface NftBasicInfo {
  animation_url?: string;
  name: string;
  creator: NftUserInfo;
  owner: NftUserInfo;
  created_at: string | number;
  collection: NftCollectionInfo;
  holding_duration: NftHoldingDuration;
  last_price_eth: string | number;
  last_price_usd: string | number;
  list_price_eth: string | number;
  list_price_usd: string | number;
}

export interface NftHoldingDuration {
  duration: number;
  unit: string;
  value?: number;
}

export interface NftHistoryInfo {
  title: string;
  sub_title: string;
  created_at: string | number;
}

export const generateUrl = (
  type?: "nft" | "token" | "profile",
  address?: string,
  token_id?: string,
  params = {}
): string => {
  let qs = "";
  if (params && Object.keys(params).length > 0) {
    qs += "?" + new URLSearchParams(params).toString();
  }

  if (type === "nft") {
    return `/nfts/${address}/${token_id}${qs}`;
  }

  if (type === "token") {
    return tokenUrl(address, params);
  }

  if (type === "profile") {
    return `/profiles0/${address}${qs}`;
  }
};

export const tokenUrl = (address: string, params = {}): string => {
  let qs = "";
  if (params && Object.keys(params).length > 0) {
    qs += "?" + new URLSearchParams(params).toString();
  }

  return `/tokens/${address}${qs}`;
};

export const collectionUrl = (slug: string, params = {}): string => {
  let qs = "";
  if (params && Object.keys(params).length > 0) {
    qs += "?" + new URLSearchParams(params).toString();
  }
  return `/collections/${slug}${qs}`;
};

export const getOpenSeaUrl = (address: string, tokenId: string) => {
  const urlOpensea = "https://opensea.io/assets";
  return `${urlOpensea}/${address}/${
    tokenId.startsWith("0x") ? BigNumber.from(tokenId || 0) : tokenId
  }`;
};

export const getLooksRareUrl = (address: string, tokenId: string) => {
  const urlLooksrare = "https://looksrare.org";
  return `${urlLooksrare}/collections/${address}/${
    tokenId.startsWith("0x") ? BigNumber.from(tokenId || 0) : tokenId
  }`;
};

export const getNftBasicInfo = async (
  address: string,
  tokenId: string
): Promise<NftBasicInfo | null> => {
  try {
    if (!address || !tokenId) {
      throw new Error("Address and tokenId cannot be empty");
    }

    const response = await apiClient.flagshipRequest<{ data: NftBasicInfo }>(
      `/nftinfo/${address}/${tokenId}`
    );
    return response?.data;
  } catch (e) {
    console.log(e);
    toast.error("Error while loading NFT data");
  }
  return null;
};

export const getNftHistory = async (
  address: string,
  tokenId: string
): Promise<NftHistoryInfo[]> => {
  try {
    if (!address || !tokenId) {
      throw new Error("Address and tokenId cannot be empty");
    }

    const response = await apiClient.flagshipRequest<{
      data: NftHistoryInfo[];
    }>(`/nft_history/${address}/${tokenId}`);
    return response?.data;
  } catch (e) {
    console.log(e);
    toast.error("Error while loading NFT history");
  }
  return [];
};

export const getNftActivity = async (
  address: string,
  tokenId: string,
  { before }
): Promise<Web3Activity[]> => {
  const qs = new URLSearchParams();
  before && qs.append("before", before);

  try {
    if (!address || !tokenId) {
      throw new Error("Address and tokenId cannot be empty");
    }

    const response = await apiClient.flagshipRequest<{
      data: Web3Activity[];
    }>(`/nft_activitiy/${address}/${tokenId}?${qs.toString()}`);
    return response?.data;
  } catch (e) {
    console.log(e);
    toast.error("Error while loading NFT activity");
  }
  return [];
};

export const getNftCollectionInfo = async (
  slug: string
): Promise<NftCollectionInfo | null> => {
  try {
    if (!slug) {
      throw new Error("Address and tokenId cannot be empty");
    }

    const response = await apiClient.flagshipRequest<{ data: NftBasicInfo }>(
      `/collections/${slug}`
    );

    return response?.data;
  } catch (e) {
    console.log(e);
    toast.error("Error while loading NFT data");
  }
  return null;
};

export const getNftCollectionAssets = async (
  slug: string,
  { after, limit }
): Promise<NftAsset[] | null> => {
  const q = new URLSearchParams();
  after && q.append("after", after);
  limit && q.append("limit", limit);
  try {
    if (!slug) {
      throw new Error("Address and tokenId cannot be empty");
    }
    const response = await apiClient.flagshipRequest<{ data: NftAsset[] }>(
      `/collections/${slug}/assets?${q.toString()}`
    );

    return response?.data;
  } catch (e) {
    console.log(e);
    toast.error("Error while loading NFT data");
  }
  return null;
};

export const getNftCollectionActivities = async (
  slug?: string,
  before?: string
): Promise<Web3Activity[]> => {
  const qs = new URLSearchParams();

  before && qs.append("before", before);

  try {
    if (!slug) {
      throw new Error("Address and tokenId cannot be empty");
    }
    const response = await apiClient.flagshipRequest<{
      data: Web3Activity[];
    }>(`/collections/${slug}/activities?${qs}`);

    return response?.data;
  } catch (e) {
    console.log("error loading activities", e);
  }

  return [];
};

export const percentageOwnerStats = (ownersStat: CollectionStat) => {
  const sumMinterBuyer =
    (ownersStat?.total_buyer || 0 + ownersStat?.total_minter || 0) * 100;
  if (sumMinterBuyer === 0) {
    return 0 + "%";
  }
  return (
    (ownersStat.new_owner_24h
      ? ((ownersStat.new_owner_24h / sumMinterBuyer) * 100).toFixed(0)
      : 0) + "%"
  );
};

export const getNftCollectionStats = async (
  slug: string
): Promise<CollectionStat | null> => {
  try {
    if (!slug) {
      throw new Error("Address and tokenId cannot be empty");
    }

    const response = await apiClient.flagshipRequest<{ data: NftAsset[] }>(
      `/collections/${slug}/stats`
    );

    return response?.data;
  } catch (e) {
    console.log(e);
    toast.error("Error while loading collection stat");
  }
  return null;
};

export const getNftCollectionOwnerRecentTrades = async (
  slug: string
): Promise<NftAsset[] | null> => {
  try {
    if (!slug) {
      throw new Error("Address and tokenId cannot be empty");
    }

    const response = await apiClient.flagshipRequest<{ data: NftAsset[] }>(
      `/collections/${slug}/recent_trades`
    );

    return response?.data;
  } catch (e) {
    console.log("Error while loading collection owner recent trades", e);
  }
  return null;
};

export const getNftCollectionOwnerBluechip = async (
  slug: string
): Promise<NftAsset[] | null> => {
  try {
    if (!slug) {
      throw new Error("Address and tokenId cannot be empty");
    }

    const response = await apiClient.flagshipRequest<{ data: NftAsset[] }>(
      `/collections/${slug}/whales`
    );

    return response?.data;
  } catch (e) {
    console.log("Error while loading collection owner recent trades", e);
  }
  return null;
};

export const nftFriendlyName = (nft: NftAsset, prefix: string) => {
  return nft.name
    ? nft.name
    : nft?.collection?.name
    ? nft?.collection?.name + prefix + nft.token_id
    : prefix + nft.token_id;
};

export const currentTimeVideo = (currentTime: number) => {
  const currentMinutes = Math.floor(currentTime / 60)
  const currentSeconds = Math.floor(currentTime - currentMinutes * 60)
  if (currentSeconds < 10) {
      return currentMinutes + ":" + 0 + currentSeconds
  }
  return currentMinutes + ":" + currentSeconds
}
export const durationTimeVideo = (duration: number) => {
  const durationMinutes = Math.floor(duration / 60)
  const durationSeconds = Math.floor(duration - durationMinutes * 60)
  if (durationSeconds < 10) {
      return durationMinutes + ":" + 0 + durationSeconds
  }
  return durationMinutes + ":" + durationSeconds
}

export const maybeAnimationUrlType = (url?: string): "video" | "image" | "audio" | "3dmodel" => {
  if (!url || url === "") {
    return "image";
  }

  if (url.endsWith(".jpg") || url.endsWith(".png") || url.endsWith(".gif")) {
    return "image";
  }

  if (url.endsWith("wav") || url.endsWith(".mp3")) {
    return "audio";
  }

  if (url.endsWith("glb")) {
    return "3dmodel";
  }

  return "video";
}
