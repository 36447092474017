import { Box } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { SearchBar } from "../app/components/pages/search/search-bar";
import NftDetails from "../app/components/pages/search/search-results/nfts-details";
import SimilarProjects from "../app/components/pages/search/search-results/similar-projects";

import { useParams } from "react-router-dom";
import { getNftBasicInfo, NftBasicInfo } from "../app/models/nft";
import { toast } from "react-toastify";
import { Loader } from "../app/components/ui/Loader";
import SearchDropdown from "../app/components/SearchDropdown";

interface NftsPageProps {}

const NftsPage: FC<NftsPageProps> = () => {
  const [nft, setNft] = useState<NftBasicInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const { address, tokenId } = useParams<{
    address: string;
    tokenId: string;
  }>();

  const getData = async (address: string, tokenId: string) => {
    try {
      setLoading(true);
      const result = await getNftBasicInfo(address, tokenId);
      setNft(result);
    } catch (e) {
      console.log(e);
      toast.error("Error while loading NFT data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(address, tokenId);
  }, [address, tokenId]);  
  return (
    <>
      <div className="container">
        <SearchDropdown initInput={nft?.name && nft?.name} />
      </div>
      <hr className="line-bottom" />

      <div
        className="container content-min-height"
        style={{ position: "relative" }}
      >
        <Box mb={8} width="100%">
          {loading ? (
            <div className="loader-results">
              <Loader />
            </div>
          ) : (
            nft && <NftDetails nft={nft} />
          )}
        </Box>
      </div>
    </>
  );
};

export default NftsPage;
