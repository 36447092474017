import React, { createContext, useContext } from "react";

export interface UserPreferences {
  PopularTopics?: boolean;
  ActiveUsersStats?: boolean;
  Characteristics?: boolean;
  HighlightTransactions?: boolean;
}

interface ApplicationConfigType {
  userPrefs: UserPreferences;
}

const ApplicationConfigContext = createContext<
  ApplicationConfigType | undefined
>(undefined);

const initialValues: ApplicationConfigType = {
  userPrefs: {
    PopularTopics: false,
    ActiveUsersStats: true,
    Characteristics: false,
    HighlightTransactions: true,
  },
};

export const ApplicationConfigProvider: React.FC<
  React.PropsWithChildren<ApplicationConfigType>
> = ({ userPrefs, children, ...rest }) => {
  return (
    <ApplicationConfigContext.Provider value={initialValues}>
      {children}
    </ApplicationConfigContext.Provider>
  );
};

export const useApplicationConfig = () => {
  const result = useContext(ApplicationConfigContext);
  if (!result) {
    throw new Error("This hook must be inside a Provider with a value");
  }

  return result;
};
