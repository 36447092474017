import * as apiClient from "../services/api";

export interface ResouceFilter {
  after?: number|string;
  before?: number|string;
}

export const listResources = <Res>(path: string, o?: ResouceFilter): Promise<Res[]> => {
  let q = "";

  if (o) {
    q += new URLSearchParams(o).toString();
  }

  return apiClient.list<Res>(`/${path}?${q}`);
}

export const getResource = <Res>(path: string, o?: ResouceFilter): Promise<Res[]> => {
  let q = "";

  if (o) {
    q += new URLSearchParams(o).toString();
  }

  return apiClient.find<Res>(`/${path}?${q}`);
}

export const saveResource = <Req, Res>(path: string, res: Req): Promise<Res> => {
  return apiClient.save<Res>(path, res);
}


export const deleteResource = <Res>(path: string): Promise<Res> => {
  return apiClient.destroy(path);
}

export const updateResource = <Res>(path: string, body: any): Promise<Res> => {
  return apiClient.update(path, body)
}
