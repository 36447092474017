import * as apiClient from "../services/api";
import { Subject } from 'rxjs';

import { Token } from './token';

export interface CompanyAdmin {
  name?: string;
  address: string;
}

export interface Company {
  name: string;
  id?: string;
  user_id?: string;
  tokens?: Token[];
  order_weight?: number;
  admins?: CompanyAdmin[];
};


export const saveCompany = (company: Company): Promise<Company> => {
  if (!company.id) {
    throw new Error("Missing company id");
  }

  return apiClient.save("/companies/" + company.id, company);
}

export const addCompany = (company: Company): Promise<Company> => {
  return apiClient.create("/companies", company);
}

const companiesSubject = new Subject<Company[]>();
export const listCompanies = async (): Promise<Company[]> => {
  const companies = await apiClient.list<Company>("/companies");
  companiesSubject.next(companies);

  return companies;
}


export const deleteCompany = async (c: Company): Promise<Company> => {
  if (!c.id) {
    throw new Error("Missing comapny id");
  }

  return await apiClient.destroy<Company>("/companies/" + c.id);
}

export const saveCompanyOrder = async (order: string[]) => {
  return apiClient.save("/companies/order", {order});
}

export const addAdmin = async (company: Company, newAdmin: CompanyAdmin): Promise<boolean> => {
  // TODO: IMPL
  // for now we can just update the company's admin array
  return Promise.resolve(true);
}

export const revokeAdmin = async (company: Company, admin: CompanyAdmin): Promise<boolean> => {
  // TODO: IMPL
  // for now we can just update the company's admin array
  return Promise.resolve(true);
}
