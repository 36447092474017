import  { FC, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { modalStyles } from "../../../app/components/ui/styled/index";
import { Text } from "../../components/ui/styled/index";
import { MetaMask, WalletConnect } from "../account/add_wallet";
import { saveCompany } from "../../../app/models/company";
import { useParams } from "react-router-dom";
import { Loader } from "../ui/Loader";

export interface ModalProps {
  openModal: boolean;
  setOpenModal: any;
}
declare let window: any;
const getModalStyle = () => {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

type Phase = "loading" | "ready";
type LoginParams = {
  actas?: string;
};

const ModalConnect: FC<ModalProps> = ({ openModal, setOpenModal }) => {
  const params = useParams<LoginParams>();
  const [phase, setPhase] = useState<Phase>("ready");

  const classes = modalStyles();
  const [modalStyle] = useState(getModalStyle);
  const handleCloseModalCompany = () => {
    setOpenModal(false);
  };
  const onUserSuccessLogin = () => {
    window.location.href = "/";
  };
  const onCompanySuccessLogin = async () => {
    await saveCompany({
      name: "an awesome name",
    });
    window.location.href = "/project";
  };
  const onSuccessLogin = () => {
    if (params.actas && params.actas === "company") {
      setPhase("loading");
      return onCompanySuccessLogin();
    } else {
      return onUserSuccessLogin();
    }
  };
  const { ethereum } = window;

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModalCompany}
      className="modal-connect"
    >
      <div style={modalStyle} className={`modal-connect ${classes.paper}`}>
        <Text
          color="primary"
          size="large"
          className="u-margin-bottom-1"
          align="center"
          weight="medium2"
        >
          CONNECT WALLET
        </Text>
        <Text size="small" className="u-margin-bottom-4" align="center">
          Connect with your available wallet or create new <br /> wallet to use
          peaks
        </Text>

        <div className={`outline-buttons u-margin-bottom-3 spinner-login-size`}>
          {phase === "loading" ? (
            <Loader color="primary" />
          ) : (
            <div className="u-display-flex u-flex-direction-column">
              <div
                className={`${!ethereum && "u-flex-order-down"}`}
              >
                <MetaMask onSuccess={onSuccessLogin} />
              </div>
              <WalletConnect onSuccess={onSuccessLogin} />
            </div>
          )}
        </div>

        <Text
          size="extra-small"
          className="text-terms-conditions"
          align="center"
        >
          We do not own your private keys and cannot access your funds. See
          Terms Of Use.
          {/* We do not own your private keys and cannot access your <br /> funds
          without your confirmation. See Term Of Use */}
        </Text>
      </div>
    </Modal>
  );
};

export default ModalConnect;
