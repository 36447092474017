import { utils, BigNumber } from "ethers";
import Numeral from "numeral";

export const formatEther = (a: Number | BigNumber | string): string => {
  let s;

  if (a instanceof BigNumber) {
    s = utils.commify(
      utils.formatEther(a instanceof BigNumber ? a : BigNumber.from(a))
    );
  } else {
    s = utils.commify(utils.formatEther(BigNumber.from(a)));
  }

  const [num, decimal] = s.split(".");

  return num + "." + decimal.substr(0, 4);
};

export const commify = (a: Number): string => {
  let s = utils.commify(a);
  if (s.indexOf(".") >= 0) {
    const [num, decimal] = s.split(".");
    if (parseInt(decimal) === 0) {
      return num;
    }
    return num + "." + decimal.substr(0, 4);
  }

  return s;
};

export const formatSign = (n: Number, prefix = "", suffix = ""): string => {
  if (!n) {
    return "0%";
  }
  if (isNaN(n)) return "0%";
  let s = n < 0 ? "-" : "+";

  s += prefix;
  s += `${Numeral(Math.abs(n)).format("0,0.00")}`;
  s += suffix;

  return s;
};

export const getOnlyNumber = (n: string): string => {
  if (!n) {
    return "";
  }

  return (Numeral(utils.commify(parseFloat(n))).format("0.00a") + "").split(
    "."
  )[0];
};

export const getOnlyDecimal = (n: string): string => {
  if (!n) {
    return "";
  }

  const d = Numeral(utils.commify(parseFloat(n))).format("0.00a");
  const r = Math.trunc((parseFloat(n) + Number.EPSILON) * 100) / 100;
  return d.toLowerCase() !== d.toUpperCase()
    ? "." + (d + "").split(".")[1]
    : (r + "").split(".")[1]
      ? "." + (r + "").split(".")[1]
      : "";
};

export const formatNumber = (n: string, decimal = 2): string => {
  if (!n) return "";
  if (decimal === 1) {
    return Numeral(utils.commify(parseFloat(n)))
      .format("0.0a")
      .toUpperCase();
  }
  if (decimal === 0) {
    return Numeral(utils.commify(parseFloat(n)))
      .format("0a")
      .toUpperCase();
  }
  return Numeral(utils.commify(parseFloat(n)))
    .format(decimal == 2 ? "0.00a" : "0.000a")
    .toUpperCase();
};

export const shortAddress = (address = "", start = 8, end = 7) => {
  const head = start ? address.slice(0, start) : "";
  const tail = end ? address.slice(-end) : "";
  return address.length > start + end ? [head, tail].join("...") : address;
};

export const wrapText = (address: string, size: number) => {
  if (!address) {
    return "";
  }

  return address.length > size
    ? shortAddress(address)
    : shortAddress(address, size, 0)
}

export const ethscanAddressLink = (address: string): string => {
  return `https://etherscan.io/address/${address}`;
};


export const wrapNumber = (number: string, suffix: string, prefix: string) => {
  return suffix + number + prefix
}
