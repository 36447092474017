import * as apiClient from "../services/api";

export interface ApiKeyRequest {
  email: string;
  wallet_address: string;
  app_name: string;
  grant_request?: {
    app_url?: string;
    description?: string;
  };
}

export interface ApiRequest {
  message: string;
  success: boolean;
}

export interface ApiKey {
  app_name?: string;
  secret_key?: string;
}

export const createApiKey = async (data: ApiKeyRequest): Promise<ApiKey> => {
  try {
    const response = await apiClient.flagshipCreate(`/apikeys`, data);
    return response.data;
  } catch (error) {
    console.log("error create api key", error);
  }
};

export const createApiKeyRequest = async (data: ApiKey): Promise<ApiRequest> => {
  try {
    const response = await apiClient.flagshipCreate(`/apikey-requests`, data);
    return response.data;
  } catch (error) {
    console.log("error request api key", error);
  }
};
