export type TagType =
  | "explorer"
  | "voter"
  | "flipper"
  | "nft-collector"
  | "collector"
  | "dev-builder"
  | "artist"
  | "land-owner"
  | "holder"
  | "defi"
  | "minter"
  | "pfp-minter"
  | "creator"
  | "bluechipper"
  | "whale"
  | "serial-minter"
  | "newcomer";

export const TAGS_DATA: {
  [key in TagType]: {
    emoji: string;
    description: string;
    name?: string;
  };
} = {
  explorer: {
    emoji: "explorer-emoji",
    description:
      "They are constantly exploring new tokens before they become a trend.",
  },
  voter: {
    emoji: "voter-emoji",
    name: "voter",
    description: "Actively participating in DAO votes and proposals.",
  },
  flipper: {
    emoji: "flipper-emoji",
    description:
      'Their activity shows that they are high-frequency traders seeking alpha. They are "fast in, fast out" and interact with different contracts like staking, swapping, liquidity providing, etc.',
  },
  "nft-collector": {
    emoji: "collector-emoji",
    description: "They own at least 5 NFTs.",
  },
  "dev-builder": {
    emoji: "dev-emoji",
    description: "They have created at least one smart contract.",
  },

  artist: {
    emoji: "artist-emoji",
    description:
      "They have launched a collection or created at least 3 nft items.",
  },
  minter: {
    emoji: "artist-emoji",
    description:
      "They have launched a collection or created at least 3 nft items.",
  },

  "land-owner": {
    emoji: "land-emoji",
    description: "They own at least two NFT lands.",
  },
  holder: {
    emoji: "holder-emoji",
    name: "holder",
    description:
      "They have low interaction with other wallets and smart contracts. They mainly buy and hold.",
  },
  defi: {
    emoji: "defi-emoji",
    description:
      "The majority of their assets and activity are in the defi space.",
  },

  creator: {
    emoji: "creator-emoji",
    description: "deployed at least one smart contract and/or created a collection/item using a contract from a platform.",
  },

  bluechipper: {
    emoji: "bluechipper-emoji",
    description: "They own an item that reached at least 8 eth in value.",
  },
  whale: {
    emoji: "whale-emoji",
    description: "They have $1,000,000+ [NFT] holding value",
  },

  "serial-minter": {
    emoji: "artist-emoji",
    description: "They mint assets frequently.",
  },

  newcomer: {
    emoji: "newcomer-emoji",
    description: "Their wallet is less than two months old.",
  },
};

export const tagToSlug = (tag: string) => tag.toLowerCase().replace(/ /g, "-");

export const slugToTag = (slug: string) => slug.replace(/-/g, " ");

export const getTagName = (tag: string) =>
  TAGS_DATA[tagToSlug(tag) as TagType]?.name || slugToTag(tag);
export const getTagDescription = (tag: string) =>
  TAGS_DATA[tagToSlug(tag) as TagType].description;

export const getTagUrl = (tag: string) => `/tags/${tagToSlug(tag)}`;
