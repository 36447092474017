import { FC, useEffect, useRef, useState } from "react";
import { VscSearch } from "react-icons/vsc";
import { GrList } from "react-icons/gr";
import {
  InputDropdown,
  ContentDropdown,
  DropdownItem,
  DropdownItemTitle,
} from "../components/ui/styled/Dropdown";

import { NftPictureItem } from "../components/pages/search/search-results/address-component/profile-nft-assets";
import { NavLink, Link } from "react-router-dom";
import { querySearch } from "../models/search";
import { generateUrl, tokenUrl, collectionUrl } from "../models/nft";
import { assets } from "../models/company_asset";
import { displayLogo } from "../models/address";
import { isHomePath } from "../services/navigation";
import { Box, useMediaQuery } from "@material-ui/core";

import { useLocation } from "react-router-dom";
import { wrapText, shortAddress } from "../services/formatter";
import { IoCloseOutline } from "react-icons/io5";
import { imageUrl } from "../models/avatar";
import SearchLoader from "./ui/search-loader";
interface SearchDropdownProps {
  initInput?: string;
}

export const DropdownBody = ({ query, isActive, switchMenu }: any) => {
  const [searchCounter, setSearchCounter] = useState(0);
  const [searchResult, setSearchResult] = useState({});
  const [pending, setPending] = useState(false);
  const isMobile = useMediaQuery("(max-width: 992px)");
  const asyncSearch = async (q: string) => {
    try {
      setPending(true);
      setSearchCounter((old) => old + 1);
      const response = await querySearch({ q, counter: searchCounter + 1 });
      if (
        response?.data &&
        response?.counter &&
        response.counter >= searchCounter + 1
      ) {
        setSearchResult(response?.data);
      }
    } catch {
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    if (query && query.length >= 2) {
      asyncSearch(query);
    }
  }, [query]);
  return (
    <ContentDropdown>
      <div
        className={`card-suggestion-space ${isActive &&
          Object.keys(searchResult).length > 0 &&
          (searchResult.collections.data ||
            searchResult.nfts.data ||
            searchResult.users.data ||
            searchResult.tokens.data) &&
          "show-drop-down-menu"
          }`}
      >
        <div
          className={`card-suggestion-space__scroll ${switchMenu ? "active-dropdown-search" : "inactive-dropdown-search"
            }`}
        >
          {(searchResult?.collections?.data || []).length > 0 && (
            <>
              <DropdownItemTitle>
                {searchResult?.collections?.title} <GrList />
              </DropdownItemTitle>

              {pending ? (
                <SearchLoader type="default" />
              ) : (
                <>
                  {searchResult.collections.data.map(
                    (collection: NftCollectionInfo, i: number) => (
                      <DropdownItem key={collection.slug}>
                        <li>
                          <NavLink
                            className="suggestion-link"
                            to={collectionUrl(collection.slug, {
                              ref: "search",
                              query,
                            })}
                          >
                            <img src={imageUrl(collection.image_url)} alt="" />
                            <p>
                              {shortAddress(
                                collection.name,
                                isMobile ? 20 : 28,
                                0
                              )}
                            </p>
                          </NavLink>
                        </li>
                      </DropdownItem>
                    )
                  )}
                </>
              )}
            </>
          )}

          {(searchResult?.nfts?.data || []).length > 0 && (
            <>
              <DropdownItemTitle>
                {searchResult.nfts?.title} <GrList />
              </DropdownItemTitle>

              {pending ? (
                <SearchLoader type="nfts" />
              ) : (
                <>
                  {searchResult.nfts.data.map((nft: any, i: number) => (
                    <DropdownItem key={nft + i}>
                      <li>
                        <NftPictureItem
                          nft={{
                            token_id: nft.token_id,
                            address: nft.address,
                            size: "200",
                          }}
                        />

                        <NavLink
                          className="nfts-texts"
                          to={generateUrl("nft", nft.address, nft.token_id, {
                            ref: "search",
                            query,
                          })}
                        >
                          {shortAddress(
                            nft.collection?.name,
                            isMobile ? 20 : 37,
                            0
                          )}

                          <div className="suggestion-link u-flex-direction-column u-align-item-start">
                            <p>
                              {shortAddress(nft.name, isMobile ? 20 : 33, 0)}
                            </p>
                          </div>
                        </NavLink>
                      </li>
                    </DropdownItem>
                  ))}
                </>
              )}
            </>
          )}
          {(searchResult?.users?.data || []).length > 0 && (
            <>
              <DropdownItemTitle>
                {searchResult.users?.title} <GrList />
              </DropdownItemTitle>

              {pending ? (
                <SearchLoader type="users" />
              ) : (
                <>
                  {searchResult.users.data.map((user: any, i: number) => (
                    <DropdownItem key={user + i}>
                      <li className="profile-user-suggestion">
                        <NavLink
                          to={generateUrl("profile", user.address, null, {
                            ref: "search",
                            query,
                          })}
                          className="profile-user-ens"
                        >
                          <small className="collector-emoji"></small>
                          <p className="user-ens-name">
                            {wrapText(user.ens || user.address || "", 10)}
                          </p>
                          <div className="tags-profile-suggestions">
                            {(user.tags || [])
                              .filter((x: any) => x !== "Holder") // TODO: remove this line when we find a better name for Holder
                              .slice(0, 2)
                              .map((tag: string) => (
                                <p key={tag} className="u-text-lowercase">
                                  {tag}
                                </p>
                              ))}
                          </div>
                        </NavLink>
                      </li>
                    </DropdownItem>
                  ))}
                </>
              )}
            </>
          )}
          {(searchResult?.tokens?.data || []).length > 0 && (
            <>
              <DropdownItemTitle>
                {searchResult.tokens?.title} <GrList />
              </DropdownItemTitle>

              {pending ? (
                <SearchLoader type="default" />
              ) : (
                <>
                  {searchResult.tokens.data.map((token: any, i: number) => (
                    <DropdownItem key={token.address}>
                      <li>
                        <NavLink
                          to={tokenUrl(token.ethereum_address, {
                            ref: "search",
                            query,
                          })}
                          className="suggestion-link"
                        >
                          <img
                            src={displayLogo(token.ethereum_address)}
                            alt=""
                          />
                          <p>
                            {shortAddress(token.name, isMobile ? 20 : 33, 0)}
                          </p>
                        </NavLink>
                      </li>
                    </DropdownItem>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </ContentDropdown>
  );
};
const SearchDropdown: FC<SearchDropdownProps> = ({ initInput = "" }) => {
  const [query, setQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  /* Check if the location is at home page */
  const [isHome, setIsHome] = useState<boolean>(
    isHomePath(location.pathname, location.search)
  );

  useEffect(() => {
    const isHome = isHomePath(location.pathname, location.search);
    setIsHome(isHome);
    setPreviousQuery(initInput);
    setQuery(initInput);
  }, [location, initInput]);
  useEffect(() => {
    const pageClickEvent = (e: any) => {
      if (
        dropdownRef.current !== null &&
        dropdownRef?.current?.contains(e.target)
      ) {
        setIsActive(true);
      } else {
        setIsActive(false);
        setQuery(previousQuery);
      }
    };
    window.addEventListener("mousedown", pageClickEvent);
    return () => {
      window.removeEventListener("mousedown", pageClickEvent);
    };
  }, [dropdownRef, previousQuery]);
  useEffect(() => {
    setIsActive(false);
  }, [location]);
  const searchElement = useRef(null);
  const [switchMenu, setSwitchMenu] = useState(false);
  useEffect(() => {
    if (isHome) {
      const scrollHandling = () => {        
        if (window.scrollY >= 131) {
          searchElement.current?.classList.add("height-search-box");
          searchElement.current?.classList.add("sticky-search-bar");
          searchElement.current?.parentElement.parentElement.children[1].classList.add("u-bg-contrast")
          setSwitchMenu(true);
        }
        if(window.scrollY < 130) {          
          searchElement.current?.classList.remove("height-search-box");
          searchElement.current?.classList.remove("sticky-search-bar");
          searchElement.current?.parentElement.parentElement.children[1].classList.remove("u-bg-contrast")
          setSwitchMenu(false);
        }
      };
      window.addEventListener("scroll", scrollHandling);
      return () => window.removeEventListener("scroll", scrollHandling);
    }
  }, []);

  return (
    <Box
      ref={searchElement}
      className={`search-element search-dropdown-container ${!isHome ? "height-search-box" : "dropdown-out-home"
        }`}
    >
      <a href="/" className="nocliking">
        <img
          src={`${process.env.PUBLIC_URL + assets.logo}`}
          className="logo-search"
          alt={assets.companyName}
        />
      </a>
      <div ref={dropdownRef} style={{ width: "100%" }}>
        <InputDropdown>
          <a href="/" className="isotype-logo-link">
            <img src={assets.isoLogo} alt="" className="isotype-logo" />
          </a>
          <input
            type="text"
            placeholder={"search web3"}
            value={query}
            onFocus={() => setQuery("")}
            onChange={(e) => {
              setQuery(e.target.value);
              setPreviousQuery(e.target.value);
            }}
          />

          {query !== "" && (
            <IoCloseOutline
              className="btn-clear-text"
              onClick={() => setQuery("")}
            />
          )}
        </InputDropdown>
        <DropdownBody
          query={query}
          isActive={isActive}
          switchMenu={switchMenu}
        />
      </div>
    </Box>
  );
};

export default SearchDropdown;
