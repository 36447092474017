import React, { FC, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { h } from "gridjs";
import { ActivityLabel } from "../../app/models/activity-label";
import { listResources } from "../../app/models/admincrud";
import FilterActivityLabel from "./components/filter-activity-label";
import LabelManager from "./components/label-manager";
import { Grid as GridJS } from "gridjs-react";
import Grid from "@material-ui/core/Grid";

import { Button } from "@material-ui/core";

interface ActivityLabelPageProps {}

const ActivityLabelList: FC<ActivityLabelPageProps> = () => {
  let history = useHistory();

  const columns = [
    {
      id: "id",
      name: "Actions",
      formatter: (cell, row) => {
        return h(
          "a",
          {
            href: `/admin/activity-labels/${row.cells[0].data}`,
          },
          "Edit"
        );
      },
    },
    "Id",
    "Signature",
    "Method",
    "Translation",
    {
      id: "address",
      name: "Contract",
      formatter: (cell, row) => {
        return row.cells[4].data
          ? h(
              "a",
              {
                href: `https://etherscan.io/address/${row.cells[4].data}#code`,
                target: "_blank",
              },
              row.cells[4].data
            )
          : "any";
      },
    },
    {
      id: "keywords",
      name: "keywords",
      formatter: (cell, row) => {
        if (Array.isArray(cell)) {
          return h("span", {}, cell.map((x) => x.keyword).join(", "));
        }
      },
    },
  ];

  const classNa = { table: "question-table" };

  const [dataActivityLabels, setDataActivityLabels] = useState<ActivityLabel[]>(
    []
  );
  const asyncLoadActivityLabels = async (filterModel?: {
    [key: string]: string;
  }) => {
    const r = await listResources("activity_labels", filterModel);
    setDataActivityLabels(r);
  };

  const onFilterChange = (filterModel) => {
    asyncLoadActivityLabels(filterModel);
    history.push("?" + new URLSearchParams(filterModel).toString());
  };

  return (
    <div>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <FilterActivityLabel onChange={onFilterChange} />
        </Grid>
        <Grid item xs={6}>
          <LabelManager />
        </Grid>
      </Grid>

      <NavLink to="/admin/activity-labels/create" color="primary">
        <Button color="primary" variant="contained">
          Add New
        </Button>
      </NavLink>
      <br />
      <br />
      <GridJS
        data={dataActivityLabels}
        search={false}
        columns={columns}
        className={classNa}
        pagination={{
          enabled: true,
          limit: 1000000,
        }}
      />
    </div>
  );
};

export default ActivityLabelList;
