import { Skeleton } from "@material-ui/lab";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  convertTransaction,
  HighlightTransactionItem,
} from "../../../../models/highlight";
import { getHighlightTransactions } from "../../../../models/search";
import { Link } from "../../../ui/styled";
import { HighlightWidgetTitle, HighlightWidgetSubtitle } from "../../index";
import CollapseWidget from "./collapse-widget";

const UPDATE_HIGHLIGHT_DELAY = 1000 * 60; // 1 minutes

interface HighlightTopicProps {
  category?: string;
}
const HIGHLIGHT_TO_SHOW = 5;

const HighlightTransactions: FC<HighlightTopicProps> = ({ category }) => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<HighlightTransactionItem[]>(
    []
  );
  const timerRef = useRef<NodeJS.Timeout>();

  const getData = async (filter: any) => {
    try {
      setLoading(true);
      const result = await getHighlightTransactions(filter);
      setTransactions(result || []);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData({ category });

    timerRef.current = setInterval(
      () => getData({ category }),
      UPDATE_HIGHLIGHT_DELAY
    );
    return () => timerRef.current && clearInterval(timerRef.current);
  }, [category]);
  return (
    <CollapseWidget title="Highlights">
      {loading
        ? [0, 1, 2].map((item) => (
            <React.Fragment key={`placeholder-${item}`}>
              <HighlightWidgetTitle>
                <Skeleton width={120} />
              </HighlightWidgetTitle>
              <HighlightWidgetSubtitle>
                <Skeleton width={120} />
              </HighlightWidgetSubtitle>
            </React.Fragment>
          ))
        : (transactions?.slice(0, HIGHLIGHT_TO_SHOW) || []).map((item) => (
            <React.Fragment key={`highlight-${item.keyword}`}>
              <HighlightWidgetTitle>
                {item.keyword.indexOf(" ") < 1 ?
                  <Link href={item.permalink ? (item.permalink + `?ref=highlight&query=${encodeURIComponent(item.keyword)}`) : "#"} color="tertiary">{item.keyword}</Link>
                  :
                  <>
                    {item.keyword.split(" ")[0].toLowerCase()}{" "}
                    <Link href={item.permalink ? (item.permalink + `?ref=highlight&query=${encodeURIComponent(item.keyword)}`) : "#"} color="tertiary">{item.keyword.split(" ").slice(1).join(" ")}</Link>
                  </>
                }
                {item.hot && " 🔥"}
              </HighlightWidgetTitle>
              <HighlightWidgetSubtitle>
                {convertTransaction(item)}
              </HighlightWidgetSubtitle>
            </React.Fragment>
          ))}
    </CollapseWidget>
  );
};

export default HighlightTransactions;
