import { FC } from "react";

import Numeral from "numeral";

import {
  shortAddress,
  ethscanAddressLink,
} from "../../../../../services/formatter";

import ResultLink from "../result-link";

interface WalletProps {
  item: {
    token_address: string;
    token_symbol: string;
    activity_count: string;
    logo: string;
  };
  i: number;
  searchId?: string;
}

const MostTradeToken: FC<WalletProps> = ({ item, i, searchId }) => {
  const { token_address, token_symbol, activity_count, logo } = item;
  return (
    <div className="search-list__item" key={`search-result${i}`}>
      <div className="search-list__item__number u-color-extra-grey">
        <span className="u-weight-medium u-font-regular">Top {i + 1}</span>
      </div>
      <div className="token-symbol-content">
        <img className="logo-token" src={logo} alt="" />
        <p className="logo-symbol-token u-no-show-mobil">{token_symbol}</p>
      </div>

      <div className="search-list__item__image u-width-most-trade">
        <span className="amount-smartphone u-color-dark u-text-left">
          {token_symbol}
        </span>
        {token_address && (
          <ResultLink
            href={ethscanAddressLink(token_address)}
            target="_blank"
            className="address-wallet"
            searchId={searchId}
          >
            {shortAddress(token_address)}
          </ResultLink>
        )}
        <span className="amount-smartphone u-text-left">
          {Numeral(activity_count).format("0,0")} transactions
        </span>
      </div>
      <div className="amount-large-size u-width-most-trade">
        {Numeral(activity_count).format("0,0")} transactions
      </div>
    </div>
  );
};

export default MostTradeToken;
