import * as accountStore from "../stores/account";
import { toast } from "react-toastify";

import { errorSubject, ApiError } from './api';
import {
  setupWalletConnect
} from './wallet';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';


export const setupApp = async (): void => {
  Bugsnag.start({
    apiKey: '44aa1eb8398bf6c16c5e2fabf838b229',
    plugins: [new BugsnagPluginReact()]
  })

  errorSubject.subscribe({
    next: (error: ApiError) => {
      console.log("Error from api", error);

      Bugsnag.notify(error);

      if (error.error === 401) {
        // Auth error log user out
        accountStore.logoutAll();
        return;
      }
    }
  });

  //setupWalletConnect();
  //await accountStore.init();
}
