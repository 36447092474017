import * as apiClient from "../services/api";

export interface TrendingQuery {
  id: number;
  text: string;
  category: string;
  term: string;
  active: boolean;
};

export const addTrendingQuery = (params: TrendingQuery): Promise<TrendingQuery> => {
  return apiClient.create("/trending_queries", params);
}

export const listTrendingQueries = (): Promise<TrendingQuery[]> => {
  return apiClient.list<TrendingQuery>("/trending_queries");
}


export const getTrendingQuery = (id: string): Promise<TrendingQuery> => {
  return apiClient.find<TrendingQuery>("/trending_queries/" + id);
}


export const deleteTrendingQuery = <Res>(path: string): Promise<Res> => {
  return apiClient.destroy(path);
}


export const updateTrendingQuery = <Res>(path: string, body: any): Promise<Res> => {
  return apiClient.update(path, body)
}
