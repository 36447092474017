import { useHistory, useLocation } from "react-router-dom";
import {
  isAboutPath,
  showUserMenu,
} from "../../../services/navigation";
import { assets } from "../../../models/company_asset";
import { FooterBox } from "..";

const FooterSearch = () => {
  const history = useHistory();
  return showUserMenu(history.location.pathname) ? "" : <FooterBar />;
};

const FooterBar = () => {
  const location = useLocation();

  const isAbout = isAboutPath(location.pathname);
  return (
    <FooterBox style={{ textAlign: isAbout ? "center" : "left" }}>
      <p>© {assets.companyName} 2023</p>
    </FooterBox>
  );
};
export default FooterSearch;
