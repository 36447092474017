import * as React from "react";
import { InputContent } from "../ui/styled";
import { FaAngleRight, FaSearch, FaAngleDown } from "react-icons/fa";
import { VscSearch } from "react-icons/vsc";

interface InputProps {
  value?: string;
  disabled?: boolean;
  className?: string;
  transform?: string;
  align?: string;
  name: string;
  type?: string;
  label?: string;
  valid?: boolean;
  iconLeft?: string;
  iconRight?: string;
  placeholder?: string;
  typeValidate?: string;
  autofocus?: boolean;
  oninput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onchange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  size?: number;
  defaultValue?: any;
}
export const Input: React.SFC<InputProps> = ({
  className,
  id,
  size,
  transform,
  align,
  name,
  label,
  type,
  iconLeft,
  iconRight,
  placeholder,
  disabled,
  value,
  children,
  oninput,
  onchange,
  onKeyPress,
  autofocus,
  onFocus,
  onBlur,
}) => {
  return (
    <InputContent transform={transform} align={align} className={className}>
      {label ? <label htmlFor={`field_${name}`}>{label}</label> : ""}
      <input
        id={`field_${name}`}
        size={size}
        type={type}
        placeholder={placeholder}
        onChange={onchange}
        onInput={oninput}
        onKeyPress={onKeyPress}
        value={value}
        disabled={disabled}
        autoFocus={autofocus}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultValue={value}
      />
      {/* Icon left */}
      {iconLeft === "search" ? <VscSearch className="icon-left" /> : ""}
      {iconLeft === "arrow-right" ? <FaAngleRight className="icon-left" /> : ""}
      {iconLeft === "arrow-down" ? <FaAngleDown className="icon-left" /> : ""}
      {/* Icons right */}
      {iconRight === "search" ? <FaSearch className="icon-right" /> : ""}
      {iconRight === "arrow-right" ? (
        <FaAngleRight className="icon-right" />
      ) : (
        ""
      )}
      {iconRight === "arrow-down" ? <FaAngleDown className="icon-right" /> : ""}
      {children}
      {/* VALIDATIONS */}
      {/* TODO: Add validations in the component */}
    </InputContent>
  );
};
