import {
  FC,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  createRef,
} from "react";
import { TabCategory } from "../app/models/address-data";
import {
  TabBadge,
  TabItem,
  TabsContent,
} from "../app/components/ui/styled/tabs";
import { Box, Divider, Grid } from "@material-ui/core";
import FilterByProfile from "../app/components/pages/search/widgets/filter-by-profile";
import ActvityRowAddress from "../app/components/pages/activity-row-address";
import ProgressNumber from "../app/components/pages/search/widgets/progress-number";
import {
  Web3Activity,
  getActivities,
  getProfilesByActivity,
  ProfileActivities,
} from "../app/models/activity";
import { Loader } from "../app/components/ui/Loader";
import { useHistory, useLocation } from "react-router-dom";
import { FilterType } from "../app/models/filter";
import SearchDropdown from "../app/components/SearchDropdown";
import ConnectWallet from "../app/components/widgets/connect-wallet";
import * as accountStore from "../app/stores/account";
import SortComponent from "../app/components/pages/search/widgets/sort-by";

import { fromEvent } from "rxjs";
import { FaUser, FaUserCheck } from "react-icons/fa";
import { Text } from "../app/components/ui/styled";

interface FeedPageProps { }

const getFilters = (params: URLSearchParams) => {
  const filter = Object.fromEntries(params.entries());
  return {
    ...filter,
    tab: filter?.tab || "emerging",
    asset_type: filter?.asset_type || "pfp",
    tags: filter?.tags || "",
  };
};

interface PaginationParams {
  before?: number;
}

const FeedPage: FC<FeedPageProps> = () => {
  const currentAccount = accountStore.findByAddress(accountStore.getCurrentAccount());

  const history = useHistory();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [activities, setActivities] = useState<ProfileActivities[]>([]);

  const [filter, setFilter] = useState<FilterType>(getFilters(params));

  const filterRef = useRef(filter);
  filterRef.current = filter;

  const mouseOnViewportRef = useRef(false);
  const [pending, setPending] = useState(false);
  const pendingTs = useRef(0);

  useEffect(() => {
    // reset the feed when we change the data
    setActivities([]);
    loadProfiles();
  }, [filter]);

  const scrollHandling = (before: number) => {
    if (window.scrollY === document.documentElement.offsetTop) {
      return (mouseOnViewportRef.current = false);
    }

    if (window.screenY > 0) {
      mouseOnViewportRef.current = true;
    }

    if (
      window.innerHeight + window.scrollY >=
      document.documentElement.offsetHeight
    ) {
      if (activities.length > 0) {
        if (pendingTs.current == 0) {
          pendingTs.current = before; //activities[activities.length - 1].surrogate_key;
          setPending(true);
          loadProfiles({
            before: before,
          });
        }
        mouseOnViewportRef.current = true;
      }
    }
  };

  useEffect(() => {
    if (!activities || activities.length == 0) {
      return;
    }
    const before = activities[activities.length - 1].surrogate_key;
    const trackScroll = fromEvent(window, "scroll").subscribe(() =>
      scrollHandling(before)
    );
    return () => trackScroll?.unsubscribe();
  }, [activities]);

  const updateURL = (newValues: { [key: string]: any }) => {
    Object.keys(newValues).forEach((key) => {
      let value = newValues[key];
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (!value) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });
    history.push(`/?${params.toString()}`);
  };

  const loadProfiles = async (params: PaginationParams) => {
    setPending(true);
    if (!params) {
      params = {};
    }

    if (!filterRef) {
      return Promise.resolve();
    }
    try {
      const response = await getProfilesByActivity({
        q: "nft",
        source: filterRef?.current?.tab,
        asset_type: filterRef?.current?.asset_type,
        tags: filterRef?.current?.tags,
        ...params,
      });
      setActivities((old) => [...old, ...response]);
    } catch (error) {
      console.log("error loading profiles", error);
    } finally {
      setPending(false);
      pendingTs.current = 0;
    }
  };

  const onChangeFilter = (filter: any) => {
    updateURL(filter);
    setFilter(filter);
  };

  return (
    <>
      <SearchDropdown />
      <TabsContent
        value={filter.tab ?? "emerging"}
        centered
        className="u-margin-top-3"
      >
        {TabCategory.map((tab) => (
          <TabItem
            key={tab.value}
            label={<>
              <div>{tab.tab}</div>
              {tab.value === "following" && <small>{currentAccount.total_following || 0}</small>}
            </>}
            onClick={() => onChangeFilter({ ...filter, tab: tab.value })}
            value={tab.value}
          />
        ))}
      </TabsContent>
      <Divider light />
      {/* {accountStore.isConnectedWallet() && filter.tab === "following" ? (
        <ConnectWallet />
      ) : ( */}

      <Box className="u-margin-top-3">
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <FilterByProfile
              filter={filter}
              onChangeFilter={(filter) => {
                updateURL(filter);
                setFilter(filter);
              }}
            />
            {filterRef?.current?.tags ? <Divider light /> : ""}
            <ProgressNumber tags={filterRef?.current?.tags} />
          </Grid>
          <Grid item xs={8} style={{ marginLeft: "40px" }}>
            {!pending && (
              <>
                {filter.tab === "following" && activities.length === 0 ? (
                  ""
                ) : (
                  <SortComponent
                    type="front"
                    filter={filter}
                    onChangeFilter={(filter) => {
                      updateURL(filter);
                      setFilter(filter);
                    }}
                  />
                )}
              </>
            )}
            {activities.map((item) => (
              <ActvityRowAddress
                key={item?.address}
                type="front"
                item={item}
                widthContainer="535px"
                filter={filter}
              />
            ))}
            {!pending && (
              <>
                {filter.tab === "following" && activities.length === 0 ? (
                  <Box className="u-margin-top-3 no-followers">
                    <FaUser className="u-color-dark" />
                    <Text weight="medium2" className="no-followers__title">
                      Following
                    </Text>
                    <Text weight="light" className="no-followers__description">
                      Here you will see the users you follow
                    </Text>
                  </Box>
                ) : (
                  ""
                )}
              </>
            )}

            {pending && (
              <div className="u-display-flex u-justify-center u-margin-top-5 u-margin-bottom-5">
                <Loader color="primary" className="label-loader-icon" />
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
      {/* )} */}
    </>
  );
};

export default FeedPage;
