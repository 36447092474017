import { FC } from "react";

interface ErrorComponentProps {
  title: string;
  description: string;
}

const ErrorComponent: FC<ErrorComponentProps> = ({ title, description }) => {
  return (
    <div className="container u-padding-mobil-top-3">
      <div className="row page-not-found">
        <div>
          <h4>{title}</h4>
          <p>{description}</p>
          <a href="/">Back to home</a>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
