import * as apiClient from "../services/api";
import { formatNumber } from "../services/formatter";

export interface Highlight {
  id: number;
  text: string;
  category: string;
  sql_query: string;
  active: boolean;
}

export interface HighlightTransactionItem {
  keyword: string;
  subtitle: string;
  variables: {
    [key: string]: string | number;
  };
  permalink?: string;
  hot?: boolean;
}

export const addHighlight = (params: Highlight): Promise<Highlight> => {
  return apiClient.create("/highlights", params);
};

export const listHighlights = (): Promise<Highlight[]> => {
  return apiClient.list<Highlight>("/highlights");
};

export const getHighlight = (id: string): Promise<Highlight> => {
  return apiClient.find<Highlight>("/highlights/" + id);
};

export const deleteHighlight = <Res>(path: string): Promise<Res> => {
  return apiClient.destroy(path);
};

export const updateHighlight = <Res>(path: string, body: any): Promise<Res> => {
  return apiClient.update(path, body);
};

export const convertTransaction = ({
  subtitle,
  variables,
}: HighlightTransactionItem) => {
  let converted = subtitle;
  
  for (let variable in variables) {
    let value = variables[variable];
    if (typeof value === "number") {
      if (value > 1000) {
        value = formatNumber(String(value));
      } else {
        value = String(value);
      }
    }

    converted = converted.replace(
      new RegExp(`{${variable}}`, "g"),
      String(value)
    );
  }

  return converted;
};
