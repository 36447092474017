import { FC } from "react";
import SimilarProjects from "./similar-projects";
import SingleResult from "./single-result";
import TextResults from "./texts-results";
import TopHolders from "./top-hoders";

interface SearchListProps {
  searchResults: any;
  handleSearch: Function;
  pageNumber: number;
}
const SearchList: FC<SearchListProps> = ({
  searchResults,
  handleSearch,
  pageNumber,
}) => {
  // TODO: switch to template tag propperly from backend
  return (
    <div className="search-list">
      <div className="u-margin-bottom-1 similar-tokens">
        <TextResults results={searchResults} />
        <SimilarProjects
          similarProjects={searchResults.similar_projects}
          handleSearch={handleSearch}
        />
        <hr className="line-bottom" />
      </div>
      {searchResults?.item_type === "wallet" && (
        <TopHolders searchResults={searchResults} />
      )}

      {searchResults && searchResults.item_type !== "wallet" && (
        <>
          {searchResults?.data?.map((item: any, i: any) => (
            <div key={i}>
              <SingleResult
                searchResults={searchResults}
                item={item}
                pageNumber={pageNumber}
                i={i}
                item_type={searchResults.item_type}
                result_format={searchResults.result_format}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default SearchList;
