
import { FC } from "react"

interface ModelViewerProps {
    source: string;
}

const ModelViewer: FC<ModelViewerProps> = ({ source }) => {

    return (
        <model-viewer className="size-model-viwer" alt="nft glb" src={source} ar-modes="webxr scene-viewer quick-look" seamless-poster shadow-intensity="1" camera-controls enable-pan>
        </model-viewer>
    );
}

export default ModelViewer;


