import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";

export const HeaderContentRow = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ".small-chip": {
    border: "0",
    cursor: "pointer",
    marginBottom: "0",
    marginLeft: "5px",
    marginRight: "3px",
    height: "16px!important",
    backgroundColor: theme.colors.primaryf2e8ff,
    ".MuiChip-label": {
      fontSize: "12px",
      color: theme.colors.primary,
      fontFamily: theme.weight.medium2
    }
  },
  ".profile-title": {
    fontSize: "12px!important",
    fontFamily: theme.weight.medium + "!important",
    color: theme.colors.dark,
    span: {
      fontFamily: theme.weight.medium2 + "!important",
    },
    ".auxiliary-links": {
      display: "none"
    },
    ".tags-details": {
      fontSize: "12px",
      fontFamily: theme.weight.light,
      a: {
        fontSize: "12px"
      }
    }
  },
  ".profile-created-title": {
    fontSize: "15px!important",
    fontFamily: theme.weight.medium + "!important",
    color: theme.colors.dark,
    span: {
      fontFamily: theme.weight.medium2 + "!important",
    }
  },
  svg: {
    color: theme.colors.primary,
    cursor: "pointer"
  },
  ".activity": {
    position: "relative",
    ".circle-item": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      background: "#D0ACFF",
      position: "absolute",
      left: "-42px",
    },
    a: {
      display: "flex",
      fontSize: "17px",
      paddingRight: "5px",
      color: theme.colors.dark,
      fontFamily: theme.weight.medium2,
    },
    display: "flex",
    alignItems: "center",
    ".link-activity-row": {
      fontSize: "12px",
      marginLeft: "5px",
      color: theme.colors.dark
    },
    small: {
      fontSize: "12px",
      marginLeft: "5px",
      color: theme.colors.dark,
      "&::after": {
        content: "' /'"
      },
      "&:last-child": {
        "&::after": {
          content: "''"
        },
      },
    },
    ".action-activity": {
      fontSize: "12px",
      marginLeft: "5px",
      color: theme.colors.dark
    },
    ".tags-details": {
      width: "295px",
      a: {
        paddingRight: "0!important",
        display: "inline-block!important"
      }
    }
  }
});

export const AssetContainer = styled.div({
  margin: "8px 0px 25px",
  position: "relative",
  span: {
    top: "50%",
    right: "-23px",
    fontSize: "12px",
    position: "absolute",
    color: theme.colors.dark,
    transform: "translateY(-50%)",
  },
  ".images": {
    display: "flex",
    position: "relative",
    width: "fit-content",
    ".item-image": {
      marginRight: "11px",
      position: "relative",
      "&:nth-child(3)": {
        marginRight: "0"
      },
      "img.img-nft-assets": {
        width: "150px",
        height: "150px",
        display: "block",
      },
      small: {
        left: "-2px",
        bottom: "6px",
        height: "20px",
        display: "flex",
        padding: "0 7px",
        alignItems: "center",
        position: "absolute",
        justifyContent: "center",
        boxShadow: "0px 2px 4px rgba(0,0,0,0.25)",
        background: theme.colors.darkF5F5,
        img: {
          width: "7px",
          height: "12px",
          marginRight: "5px"
        }
      },
      "model-viewer": {
        width: "150px!important",
        height: "150px!important"
      },
      ".audio-image": {
        a: {
          zIndex: 2,
          width: "100%",
          height: "100%",
          position: "absolute",
        },
        ".controls": {
          top: "50%",
          zIndex: "2",
          left: "50%",
          padding: "0 5px",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          ".btn-play": {
            height: "40px",
            width: "40px",
            display: "flex",
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            background: "#40404073",
            borderRadius: "50%",
            color: theme.colors.contrast,
            svg: {
              fontSize: ".9em",
            }
          }
        },
      }
    },
  },
  ".video-controls": {
    height: "150px",
    video: {
      height: "150px"
    },

    ".video-controls__controls": {
      display: "none",

    }
  },

  ".oneonone-image": {
    "img.img-nft-assets": {
      width: "335px!important",
      height: "335px!important",
      display: "block",
    },
  },
  ".btn-controls": {
    display: "none!important"
  },
  ".btn-controls.btn-play": {
    display: "flex!important"
  },
  ".size-audio-controls": {
    width: "150px",
    height: "60px",
  },
  ".profile-images": {
    display: "flex",
    ".item-image": {
      marginRight: "8px",
      position: "relative",
      ".item": {
        width: "100px",
        height: "100px",
        display: "block",
      },
      "&:last-child": {
        marginRight: 0
      },
      small: {
        left: "-2px",
        bottom: "8px",
        height: "20px",
        display: "flex",
        padding: "0 5px",
        alignItems: "center",
        position: "absolute",
        justifyContent: "center",
        boxShadow: "0px 2px 4px rgba(0,0,0,0.25)",
        background: theme.colors.darkF5F5,
        img: {
          width: "7px",
          height: "12px",
          marginRight: "5px"
        }
      }
    },
  }
});


export const FooterContentRow = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
  p: {
    fontSize: "12px",
    color: theme.colors.dark,
    span: {
      color: theme.colors.dark,
      fontFamily: theme.weight.medium2
    }
  },
  ".text-size-oneonone": {
    width: "360px",
    lineHeight: "20px",
  },
  ".counter-assets": {
    fontSize: "12px",
    marginLeft: "calc(517px - 280px)",
    color: theme.colors.dark,
  }
});