import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";
import { ArrowDropUp } from "@material-ui/icons";
import {
    Badge,
    Tab,
    Tabs,

} from "@material-ui/core";

export const TabsContent = styled(Tabs)<{ ismobile?: any }>(({ ismobile }) => ({
    display: "flex",
    justifyContent: "center",
    ".MuiTabs-indicator": {
        backgroundColor: theme.colors.primary,
        display: ismobile ? "none" : "",
    },
    ".MuiTabs-flexContainer": ismobile
        ? {
            width: 300,
            marginLeft: "auto",
            marginRight: "auto",
            flexWrap: "wrap",
            background: "tomato",
        }
        : {
            display: "flex",
            justifyContent: "center",
        },
}));

export const TabItem = styled(Tab)({
    opacity: 1,
    fontWeight: 500,
    fontSize: "13px",
    minWidth: "unset",
    overflow: "inherit",
    paddingLeft: "25px",
    paddingRight: "25px",
    marginRight: "25px",
    textTransform: "unset",
    fontFamily: "Roboto Medium",
    color: theme.colors.dark7474,

    ".MuiTouchRipple-root": {
        display: "none",
    },
    // "&:not(.Mui-disabled):not(.Mui-selected):hover .MuiTab-wrapper": {
    //     transform: "scale(1.1)",
    // },
    ".MuiTab-wrapper": {
        display: "flex",
        flexDirection: "row",
        small: {            
            height: "17px",
            fontSize: "10px",
            marginLeft: "10px",
            padding: "0px 5px",
            borderRadius: "8px",
            color: theme.colors.contrast,
            background: theme.colors.dark7474
        }
    },
    "&.Mui-selected": {
        fontSize: "15px",
        color: theme.colors.primary,
        small: {
            color: theme.colors.contrast,
            background: theme.colors.primary
        },
    },
    "&.Mui-disabled": {
        fontWeight: 400,
        position: "relative",
        fontFamily: "Roboto",
        pointerEvents: "unset",
        color: theme.colors.tertiaryLight,
        background: "transparent !important",
        "&::before": {
            top: 0,
            visibility: "hidden",
            position: "absolute",
            content: "'Coming soon'",
            left: "calc(50% - 35px)",
            fontSize: theme.size.extraSmall,
        },
        ".MuiTab-wrapper": {
            textAlign: "center",
            alignItems: "center",
        },
        "&:hover": {
            "&::before": {
                visibility: "visible",
            },
        },
    },
});

export const TabBadge = styled(Badge)({
    ".MuiBadge-badge": {
        color: theme.colors.contrast,
        background: theme.colors.dark7474,
    },
});

export const PaperArrow = styled(ArrowDropUp)({
    top: -24,
    width: 48,
    height: 48,
    fontSize: 7,
    color: "white",
    position: "absolute",
    left: "calc(50% - 24px)",
});