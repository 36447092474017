
import { FC } from "react"

interface AudioPlayerProps {
    source: string;
}

const AudioPlayer: FC<AudioPlayerProps> = ({ source }) => {

    return (
        <audio autoPlay="" controls controlsList="nodownload" loop={true} playsInline preload="metadata" className="size-audio-controls">
            <source src={source} />
        </audio>
    );
}

export default AudioPlayer;
