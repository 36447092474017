import { FC, useState, useEffect } from "react";
import AddressComponent from "../search-results/address-component/address-component";
import { TopUser } from "../../../../models/search";
import { WidgetTitle } from "../../index";
import CollapseWidget from "./collapse-widget";
interface WidgetTopUsersProps {
  data?: any;
  token_address: string;
}

const WidgetTopUsers: FC<WidgetTopUsersProps> = ({ token_address }) => {
  const [pending, setPending] = useState(false);
  const [topUsers, setTopUsers] = useState<TopUser>();

  const [showMore, setShowMore] = useState(false);

  return (
    <div className="widget-characteristics">
      <div className="collapse-box">
        <WidgetTitle>Top Users</WidgetTitle>

      </div>
    </div>
  );
};

export default WidgetTopUsers;
