import { Subject } from 'rxjs';

export const apiBase = process.env.REACT_APP_APIURL || "https://qa.openstory.io/api";
export const flagshipBase = process.env.REACT_APP_FLAGSHIPURL || "https://qa.openstory.io/flagship";

export const authCallbackURL = (process.env.REACT_APP_APIURL || "https://qa.openstory.io/api") + "/auth/callback/" + btoa('https://' + window.location.host);
export const authURL = (process.env.REACT_APP_APIURL || "https://qa.openstory.io/api") + "/auth?callback_uri=" + authCallbackURL;

export const appVersion = process.env.REACT_APP_VERSION || "unknow";

const storage = window.localStorage;

export interface AppEnv {
  status: 'init' | 'ready';
  event: 'loaded' | 'auth_sucess' | 'account_changed' | 'chain_changed';
  chainid?: number | null;
};

export const appenvSubject = new Subject<AppEnv>({ status: 'init', event: 'loaded' });

let currentChainId;

export const setChain = (id: number, network?: string) => {
  storage.setItem("peaks_chainid", id);

  currentChainId = id;

  appenvSubject.next({
    status: 'ready',
    event: 'chain_changed',

    chainid: currentChainId,
  })
}

export const getChain = () => {
  if (currentChainId) {
    return {
      id: currentChainId,
      network: '',
    }
  }

  // Attempt to see if we have history and return that one for fast access
  // This is eventually consitency!!!
  const cacheChaindID = storage.getItem('peaks_chainid');
  if (cacheChaindID) {
    return { id: parseInt(cacheChaindID) }
  }
}

export const isQA = (): boolean => {
  const domain = window.location.hostname;
  return process.env.REACT_APP_STAGE !== "production" ||
    (domain.endsWith(".qa.openstory.io") || domain.startsWith("qa.openstory.io") || domain.startsWith("dev.openstory.io"));
}

export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
