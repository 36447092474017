import { useState, useEffect, useReducer, useRef } from "react";

import { Alert } from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import { Button } from "../../ui/styled";

import { flagshipURL } from "../../../services/api";
import { startCommand, stopCommand } from "../../../models/cnc";
import { Loader } from "../../ui/Loader";

export const TokenScan = () => {
  const [autoScroll, setAutoScroll] = useReducer((newState) => newState, true);

  const outputRef = useRef();
  const codeRef = useRef();
  const [loading, setLoading] = useState(false);

  const handleCommand = async (activate: boolean): Promise<void> => {
    setLoading(true);
    try {
      if (activate) {
        await startCommand("contract_extraction");
      } else {
        await stopCommand("contract_extraction");
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  function reducer(state, newData) {
    if (newData < 1) {
      return 0;
    }
    return state + newData;
  }

  const [log, updateLog] = useReducer(reducer, 0);

  const getRealtimeData = (msg) => {
    // raw dom access for performance issue
    var inp = document.createElement("code");
    inp.className = "console_window";
    inp.type = "text";
    inp.innerHTML = msg.data;
    updateLog(1);

    outputRef.current.appendChild(inp);
  };

  useEffect(() => {
    const sse = new EventSource(flagshipURL("/sse/realtime"), {
      withCredentials: true,
    });

    sse.onmessage = (e) => getRealtimeData(e);

    sse.onerror = () => {
      sse.close();
    };

    return () => {
      sse.close();
    };
  }, []);

  useEffect(() => {
    if (autoScroll) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
    if (log > 10000) {
      updateLog(0);
      outputRef.current.innerHTML = "";
    }
  }, [autoScroll, log]);

  return (
    <div>
      <h2>Contract Extraction Log Viewer</h2>
      <Alert severity="warning">
        log will be reset every 10000 lines to prevent brower from crashing. We
        only show log when we work on a contract, for transactions/events that
        aren't eligible for extraction we ignore and won't show any log here
      </Alert>

      <FormControl className="u-margin-right-ui-1 u-width-fc-200">
        <Button onClick={(e) => setAutoScroll(!autoScroll)}>
          {autoScroll ? "Pause" : "Start"} Auto Scroll Log
        </Button>
      </FormControl>

      {loading ? (
        <Loader color="primary" />
      ) : (
        <>
          <FormControl className="u-margin-right-ui-1 u-width-fc-200">
            <Button onClick={(e) => handleCommand(true)}>
              Start Contract Extraction
            </Button>
          </FormControl>

          <FormControl className="u-margin-right-ui-1 u-width-fc-200">
            <Button onClick={(e) => handleCommand(false)}>
              Stop Contract Extraction
            </Button>
          </FormControl>
        </>
      )}

      <pre
        className="async-job-status"
        ref={(node) => (outputRef.current = node)}
      >
        <code ref={(node) => (codeRef.current = node)}></code>
      </pre>
    </div>
  );
};
