import * as apiClient from "../services/api";

import _ from "underscore";
import {
  ActiveUsersStatsInfo,
  ActiveUsersTagsInfo,
  CurrentOwnerStatsInfo,
} from "./active-users";
import { HighlightTransactionItem } from "./highlight";
import { getTagUrl, TAGS_DATA, tagToSlug } from "./tag";

export enum InputType {
  Freeform = "freeform",
  Suggest = "suggest",

  // These are query that trigger navigation
  NavigationTerm = "navigation",

  // Inline search doesn't leave the page but refresh the inline feed on the page
  InlineFilter = "inline_filter",
}

export enum SearchItemType {
  Wallet = "wallet",
  Contract = "contract",
  ContractOfType = "contract_of_type",
  TotalHolder = "total_holder",
  ListActiveAddress = "list_active_address",
  Activity = "activity",
  TokenUsage = "token_usage",
  NFT = "nft",
  MostTradeToken = "most_trade_tokens",
  TopHolderPortfolio = "top_holder_portfolio",
  TokenProfile = "token_profile",
}

export interface SearchResult {
  result_format: "list" | "single" | "card";
  item_type: SearchItemType;
  data: any;
  similar_queries?: string[];
  title?: string;
  summarize?: string;
  search_type?: string;

  error?: string;
  metadata?: { window: { unit: string; value: number } };
}

// TODO: review and merge with above after demo
export interface SearchInputParameter {
  q: string;
  qs?: string;
  searchId?: string;
  page?: number;

  type?: InputType;
  query_type?: InputType;
  canonical_form?: string;

  counter?: number;
}

export interface SimilarProject {
  logo: string;
  symbol: string;
  q: string;
  name: string;
}

export interface DataPortfolio {
  balance: string;
  decimals: number;
  token_address: string;
  token_symbol: string;
  wallet_address: string;
}
export interface Portfolio {
  balance: string;
  decimals: number;
  portfolio: [DataPortfolio];
  token_address: string;
  token_symbol: string;
  wallet_address: string;
}

export interface Topic {
  frequency: number;
  icon: string;
  term: string;
  title: string;
}

export interface TopUser {
  data: any;
  balance: string;
  decimals: number;
  percentage: number;
  token_address: string;
  token_symbol: string;
  wallet_address: string;
}

export interface Profile {
  address: string;
  address_type: string;
  balance: number;
  created_at: string;
  ens: string;
  friends: [];
  nft_assets: [];
  portfolio: Portfolio[] | null;
  tags: string[];

  total_activity_7d?: number;
  total_activity_30d?: number;
  first_actived_at?: string;
}

export const querySearch = (
  searchParam: SearchInputParameter
): Promise<SearchResult> => {
  var queryString = Object.keys(searchParam)
    .map((key) => {
      if (searchParam[key]) {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(searchParam[key])
        );
      }

      return "";
    })
    .filter((x) => x !== "")
    .join("&");

  return apiClient.flagshipRequest<SearchResult>(`/search?${queryString}`);
};

export const suggestion = (q: string, count: number): Promise<any> => {
  return apiClient.flagshipRequest<string[]>(
    `/suggestion?q=${encodeURIComponent(q)}&count=${count}`
  );
};

export const visitResult = (search_id: string): Promise<any> => {
  return apiClient.flagshipCreate(`/questions/${search_id}`);
};

export const isSingle = (searchResult: SearchResult) => {
  return searchResult.result_format !== "list";
};

export const isList = (searchResult: SearchResult) => {
  return searchResult.result_format === "list";
};

// Poorman version that detect category(topic) we're searching on by matching keyword in query
export const queryToCategory = (query: string): string => {
  let theme = "";

  if (query) {
    if (query.indexOf("defi") >= 0) {
      theme = "defi";
    } else if (query.indexOf("nft") >= 0) {
      theme = "nft";
    } else if (query.indexOf("dao") >= 0) {
      theme = "dao";
    } else if (query.indexOf("ens") >= 0) {
      theme = "ens";
    } else if (query.indexOf("exchange") >= 0) {
      theme = "exchange";
    }
  }

  return theme;
};

export const getPopularTopics = (category?: string): Promise<Topic[]> => {
  if (!category) {
    category = "";
  }

  const staticData = [
    {
      icon: "",
      title: "Matic",
      term: "Matic characteristics",
      category: ["defi", "nft", "dao"],
    },
    //{
    //  icon: "",
    //  title: "Top gainers of Ape coin",
    //  term: "Top gainers of Ape coin",
    //},
    //{
    //  icon: "",
    //  title: "Tokens/NFTs with high on-chain engagement?",
    //  term: "Tokens/NFTs with high on-chain engagement?",
    //},
    //{
    //  icon: "",
    //  title: "Collections/tokens with most flippers?",
    //  term: "Collections/tokens with most flippers?",
    //},

    // defi
    {
      icon: "",
      title: "Top DeFi gainers",
      term: "Top DeFi gainers",
      category: ["defi"],
    },
    {
      icon: "",
      title: "Top DeFi losers",
      term: "Top DeFi losers",
      category: ["defi"],
    },
    {
      icon: "",
      title: "Most active dApp",
      term: "Most active dApp",
      category: ["defi"],
    },
    {
      icon: "",
      title: "Most engaging communities in DeFi",
      term: "Most active dApp",
      category: ["defi"],
    },
    {
      icon: "",
      title: "What DeFi users are doing today?",
      term: "What DeFi users are doing today?",
      category: ["defi"],
    },

    // NFT
    {
      icon: "",
      title: "What collectors are doing today?",
      term: "What collectors are doing today?",
      category: ["nft"],
    },
    {
      icon: "",
      title: "Most active collections",
      term: "What collectors are doing today?",
      category: ["nft"],
    },
    {
      icon: "",
      title: "New mints",
      term: "New mints",
      category: ["nft"],
    },

    // Exchange
    {
      icon: "",
      title: "Are people selling or buying today?",
      term: "Are people selling or buying today?",
      category: ["exchange"],
    },
    {
      icon: "",
      title: "What users withdraw today?",
      term: "What users withdraw today?",
      category: ["exchange"],
    },
    {
      icon: "",
      title: "Selling activity",
      term: "Selling activity",
      category: ["exchange"],
    },
    {
      icon: "",
      title: "Most active exchange",
      term: "Most active exchange",
      category: ["exchange", "defi"],
    },
  ];

  let queriesForCategory = staticData.filter(
    (elem) => category === "" || elem.category.includes(category)
  );
  queriesForCategory = _.shuffle(queriesForCategory).slice(0, 4);

  return Promise.resolve<Topic[]>(queriesForCategory);
  //return apiClient.flagshipRequest(`/topics/popular`)
};

export const getActiveUsersStats = async (
  filter: any
): Promise<ActiveUsersStatsInfo> => {
  const qs = new URLSearchParams();
  if (filter) {
    Object.keys(filter).forEach((key) => {
      qs.append(key, filter[key]);
    });
  }

  const result: any = await apiClient.flagshipRequest(
    `/stats/users?${qs.toString()}`
  );
  return result.data;
};

export const getActiveUsersStatsByTag = async (
  filter: any
): Promise<ActiveUsersStatsInfo> => {
  const qs = new URLSearchParams();
  if (filter) {
    Object.keys(filter).forEach((key) => {
      qs.append(key, filter[key]);
    });
  }
  const result: any = await apiClient.flagshipRequest(
    `/stats/users?${qs.toString()}`
  );
  return result.data;
};

export const getActiveUsersTags = async (filter: {
  collection?: string;
  category?: string;
}): Promise<ActiveUsersTagsInfo> => {
  const qs = new URLSearchParams();
  if (filter) {
    Object.keys(filter).forEach((key) => {
      qs.append(key, filter[key]);
    });
  }

  const result: any = await apiClient.flagshipRequest(
    `/stats/tags?${qs.toString()}`
  );

  if (filter.collection) {
    return result.data?.[filter?.collection] || {};
  }

  return result.data?.[filter?.category || "overall"] || {};
};

export const getHighlightTransactions = async (
  filter: any
): Promise<HighlightTransactionItem[]> => {
  const qs = new URLSearchParams();
  if (filter) {
    Object.keys(filter).forEach((key) => {
      qs.append(key, filter[key]);
    });
  }
  qs.append("count", "10");

  const result: any = await apiClient.flagshipRequest(
    `/highlights?${qs.toString()}`
  );
  return result.data;
};

export const getProfileAddress = (address: string): Promise<Profile> => {
  return apiClient.flagshipRequest(`/profiles/${address}`);
};

export const getTempDataActivityOnWeb = () => {
  return [
    {
      balance: "410,264,789,574,857.56",
      token_symbol: "SHIB",
      wallet_address: "0x3cd751e6b0078be393132286c442345e5dc49699",
    },
    {
      balance: "410,264,789,574,857.56",
      token_symbol: "SHIB",
      wallet_address: "0x28c6c06298d514db089934071355e5743bf21d60",
    },
    {
      balance: "410,264,789,574,857.56",
      token_symbol: "SHIB",
      wallet_address: "0x9c5083dd4838e120dbeac44c052179692aa5dac5",
    },
  ];
};

export const parseQuery = (q: string): SearchInputParameter => {
  const canonicalForm = q.trim().toLowerCase().replace(/\s+/, " ");
  const slug = tagToSlug(canonicalForm);

  if (q.endsWith(".eth") || q.endsWith(".ens")) {
    return {
      type: InputType.NavigationTerm,
      canonical_form: `/profiles/${q.trim()}`,
      q: q,
    };
  }

  if (slug in TAGS_DATA) {
    return {
      type: InputType.NavigationTerm,
      canonical_form: getTagUrl(slug),
      q: q,
    };
  }

  const category = queryToCategory(canonicalForm);
  if (
    category &&
    ["defi", "ens", "nft", "exchange", "dao"].includes(category)
  ) {
    return {
      type: InputType.InlineFilter,
      canonical_form: canonicalForm,
      q: q,
    };
  }

  return {
    type: InputType.Freeform,
    canonical_form: canonicalForm,
    q: q,
  };
};

export const queryIsInlineFilter = (p: UserQueryInput): bool => {
  return p.type === InputType.InlineFilter;
};

export const SIMILAR_PROJECT_THRESHOLD = 5;

export const NFT_NUMBER_TO_SHOW = 3;

export const FeedFilters = [
  {
    name: "Everyone",
    value: "",
  },
  {
    name: "For you",
    value: "for-you",
    disabled: true,
  },
  {
    name: "Smart money",
    value: "smart-money",
    disabled: true,
  },
  {
    name: "DeFi",
    value: "defi",
  },
  {
    name: "NFTs",
    value: "nft",
  },
];

export const temp_data_search = {
  collections: {
    title: "Collections",
    data: [
      {
        name: "Mutant Mob Vial",
        slug: "mutant-mob-vial",
        contract_address: "",
        temp_url: "/collections/mutant-mob-vial",
        temp_large_img:
          "https://openseauserdata.com/files/1602efa9efcca81ea89c2180a75a49e5.gif",
      },
      {
        name: "Troll Town wtf",
        slug: "troll-town.wtf",
        contract_address: "",
        temp_url: "/collections/troll-townwtf",
        temp_large_img:
          "https://lh3.googleusercontent.com/OtEVtVJJsZdNNp7Q5hKua95pDxiU3Tc0c_OetWpwjbfmqtNZwXbUPqJU58Grhxeq1jzJnu6pRtDwq_Bp9SOtRvbJPAHsUk9PIuTBzw=s300",
      },
    ],
  },
  nfts: {
    title: "Nfts",
    data: [
      {
        name: "Whoopsies Doopsies",
        token_id:
          "0x0000000000000000000000000000000000000000000000000000000000001157",
        contract_address: "0x565abc3feaa3bc3820b83620f4bbf16b5c4d47a3",
      },
      {
        name: "PXN: Ghost Division",
        token_id:
          "0x00000000000000000000000000000000000000000000000000000000000022b5",
        contract_address: "0x160c404b2b49cbc3240055ceaee026df1e8497a0",
      },
      {
        name: "High Lights Official",
        token_id:
          "0x00000000000000000000000000000000000000000000000000000000000000bd",
        contract_address: "0x64195bab73c76fdb059f667116b3c1d49dafdabe",
      },
    ],
  },
  users: {
    title: "Users",
    data: [
      {
        address: "0xdf47aad49a7ac36b7aa6895b2a867ceba9c70d45",
        ens: "nwaar.eth",
        tags: ["newcomer", "defi"],
      },
      {
        address: "0xf10ff6c03c5f951c9a4d02cb0dca51ba442b095e",
        ens: "altctrl.eth",
        tags: ["artist", "holder"],
      },
    ],
  },
};
