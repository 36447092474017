import { useRef, useState, FC, useEffect } from "react";
import { FaBackward, FaForward, FaPlay, FaPause } from "react-icons/fa";
import { VideoContent } from "./styled";

import { currentTimeVideo, durationTimeVideo } from "../../models/nft";

interface VideoPlayerProps {
  source: string;
  controls: "only-play" | "all";
}

type VideoAction = "play" | "pause";

const VideoPlayer: FC<VideoPlayerProps> = ({ source, controls }) => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [progress, setProgress] = useState(0);


  const videoHandler = (control: VideoAction) => {
    document.querySelectorAll('video').forEach(vid => {
      vid.pause();
      // videoRef?.current?.pause();
      vid.currentTime = 0
    });
    setVideoTime(videoRef?.current?.duration);
    if (control === "play") {
      videoRef?.current?.play();
    } else if (control === "pause") {
      videoRef?.current?.pause();
    }
  };

  const fastForward = () => {
    videoRef.current.currentTime += 5;
  };

  const revert = () => {
    videoRef.current.currentTime -= 5;
  };

  useEffect(() => {
    const timeVideo = setInterval(() => {
      setCurrentTime(videoRef.current?.currentTime);
      setProgress((videoRef.current?.currentTime / videoTime) * 100);
    });
    return () => clearInterval(timeVideo);
  }, [progress]);

  return (
    <>
      {controls === "only-play" && (
        <div className="controls">
          {!videoRef?.current?.paused ? (
            <div
              className="btn-controls btn-play"
              onClick={() => videoHandler("pause")}
            >
              <FaPause />
            </div>
          ) : (
            <div className="btn-play" onClick={() => videoHandler("play")}>
              <FaPlay />
            </div>
          )}
        </div>
      )}
      <VideoContent className="video-controls">
        <video
          controlsList="nodownload"
          loop
          playsInline
          preload="metadata"
          ref={videoRef}
          className="video-container"
          src={source}
        ></video>
        {controls === "all" && (
          <>
            {" "}
            <div className="controls">
              {!videoRef?.current?.paused && (
                <div className="btn-controls" onClick={revert}>
                  <FaBackward />
                </div>
              )}
              {!videoRef?.current?.paused ? (
                <div
                  className="btn-controls btn-play"
                  onClick={() => videoHandler("pause")}
                >
                  <FaPause />
                </div>
              ) : (
                <div className="btn-play" onClick={() => videoHandler("play")}>
                  <FaPlay />
                </div>
              )}
              {!videoRef?.current?.paused && (
                <div className="btn-controls" onClick={fastForward}>
                  <FaForward />
                </div>
              )}
            </div>
            <div className="video-controls__controls">
              <div className="timecontrols">
                <p className="controlsTime">{currentTimeVideo(currentTime)}</p>
                <div className="time_progressbarContainer">
                  <div
                    style={{ width: `${progress}%` }}
                    className="time_progressBar"
                  ></div>
                </div>
                <p className="controlsTime">{durationTimeVideo(videoTime)}</p>
              </div>
            </div>
          </>
        )}
      </VideoContent>
    </>
  );
};

export default VideoPlayer;
