import { FC } from "react";

interface NoDataProps {
  similarQuerys?: any;
  handleSearch?: any;
  querySubject?: {
    next: (qs: string) => void
  }
}

const NoData: FC<NoDataProps> = ({
  similarQuerys,
  handleSearch,
  querySubject,
}) => {
  const goSearch = (x: any) => {
    if (querySubject) {
      querySubject.next(qs);
    }
    handleSearch({qs: x});
  };

  return (
    <>
      <hr className="line-bottom" />
      <div className="search-not-data">
        <p>
          Oops, we’re sorry that we do not have an answer to your question at
          this moment. <br /> We’ll do better, please ask an another question.
        </p>
        <p>People are searching...</p>
        <ul>
          {similarQuerys.similar_queries && (
            <>
              {similarQuerys.similar_queries.map((x: any, i: number) => (
                <li key={`suggestion-${i}`}>
                  <small
                    onClick={() => {
                      goSearch(x);
                    }}
                  >
                    {x}
                  </small>
                </li>
              ))}
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default NoData;
