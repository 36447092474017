import { FC, useState, useEffect, Fragment } from "react";
import Numeral from "numeral";
import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  getAddressProfile,
  AddressProfile,
  displayName,
} from "../../../../../models/address";

import { getLabel, activityHasNft } from "../../../../../models/activity";

import ProfileTags from "./profile-tags";
import ProfileNftAssets from "./profile-nft-assets";

import dayjs from "../../../../../services/daysjs";

import ActivityTitle from "./activity-title";
import { shortAddress } from "../../../../../services/formatter";
import MultipleNfts from "../../widgets/multiple-nfts";

interface AddressComponentProps {
  item: any;
  position: number;
  variant?:
    | "lite"
    | "compact"
    | "full"
    | "front"
    | "gainers"
    | "accumulating"
    | "profile"
    | "title";
  token?: any;

  hideTransferAsset?: bool;
  name_from?: "collection_name" | "nft_name" | "no_nft_name";
  collection_slug?: string;
  token_id?:string;
  onMouseLeave?: any;
  onMouseEnter?: any;
}
const AddressComponent: FC<AddressComponentProps> = ({
  item,
  position,
  variant,
  token,
  hideTransferAsset,
  onMouseEnter,
  onMouseLeave,
  collection_slug,
  token_id,
  name_from,
}) => {
  let {
    balance,
    token_symbol,
    wallet_address,
    percentage,
    from_address,
    to_address,
    amount,
    block_timestamp,
    action,
  } = item;

  // swap direction of displaying when showing receving
  if (item.action?.payload?.is_cex && item.action.action == "received") {
    [from_address, to_address] = [to_address, from_address];
  }

  if (!wallet_address && from_address) {
    wallet_address = from_address;
  }

  const [pending, setPending] = useState(false);
  const [profileData, setProfileData] = useState<AddressProfile>();
  const asyncLoadProfile = async () => {
    setPending(true);
    try {
      const profile = await getAddressProfile(
        wallet_address ? wallet_address : item.address,
        variant == "front" ? "compact" : "full"
      );

      setProfileData(profile);
    } catch (err) {
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    asyncLoadProfile();
  }, []);
  return (
    <div className="top-holder-row">
      <div
        className="top-holder-row__content-item"
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
      >
        {variant !== "profile" && (
          <div className="top-holder-row__left">
            <div className="u-display-flex u-align-items-center">
              <div className="top-holder-row__details">
                {variant !== "front" && (
                  <>
                    {variant !== "title" && (
                      <span className="item-number">{position + 1}.</span>
                    )}
                  </>
                )}

                {profileData?.ens ? (
                  <Link
                    href={`/profiles/${
                      wallet_address ? wallet_address : item.address
                    }`}
                    className={`top-holder-row__address`}
                  >
                    {profileData?.ens && shortAddress(profileData?.ens, 40, 0)}
                  </Link>
                ) : (
                  <Link
                    href={`/profiles/${
                      wallet_address ? wallet_address : item.address
                    }`}
                    className={`top-holder-row__address`}
                  >
                    {displayName(
                      profileData?.ens || "",
                      wallet_address ? wallet_address : item.address
                    )}
                  </Link>
                )}
              </div>

              {pending && (
                <div className="loader-tags">
                  <Skeleton width={70} height={20} variant="text" />
                </div>
              )}

              {variant !== "profile" && (
                <>
                  {variant !== "title" && (
                    <div
                      className={`${
                        variant === "gainers" && "show-only-one-label"
                      } u-display-flex`}
                    >
                      {profileData?.tags?.length !== 0 && (
                        <ProfileTags data_tags={profileData} />
                      )}
                    </div>
                  )}
                </>
              )}
              {variant === "title" && (
                <>
                  {profileData?.tags?.length !== 0 && (
                    <ProfileTags data_tags={item} />
                  )}
                </>
              )}
            </div>

            <div className="tags-details">
              {profileData?.previous_tag &&
                `turned from ${profileData?.previous_tag}`}
            </div>

            {variant === "front" && (
              <ActivityTitle
                profileData={profileData}
                item={item}
                name_from={name_from}
                collection_slug={collection_slug}
              />
            )}
            {variant === "accumulating" && (
              <span className="box-bought">bought 100 {token.symbol}</span>
            )}
            {variant === "accumulating" && (
              <span className="box-bought-ago">2 min ago</span>
            )}
          </div>
        )}

        {variant === "full" && (
          <div className="top-holder-row__right">
            {percentage && (
              <div className="top-holder-row__percentage">
                {percentage.toFixed(2)}%
              </div>
            )}
            {balance ? (
              <span className="top-holder-row__total">
                {Numeral(balance).format("0a")} {token_symbol}
              </span>
            ) : (
              <span className="top-holder-row__total">
                {Numeral(amount).format("0a")} {token && token.symbol}
              </span>
            )}
          </div>
        )}

        {variant === "front" && (
          <div className="top-holder-row__right">
            <span className="top-holder-row__time-ago profile-time-ago">
              {block_timestamp &&
                dayjs(block_timestamp).add(30, "second").fromNow()}
            </span>
          </div>
        )}

        {variant === "profile" && (
          <div
            className={`top-holder-row__right ${
              variant === "profile" &&
              "u-width-100 u-display-flex u-justify-spacebetween"
            }`}
          >
            <div className="tags-details">
              {action.payload.title !== "" && (
                <NavLink to="#">
                  {action.payload.quantity && `${action.payload.quantity} `}
                  {getLabel(item)}
                </NavLink>
              )}
            </div>
            <span
              className="top-holder-row__time-ago profile-time-ago"
              title={tx?.transaction_hash}
            >
              {block_timestamp && dayjs(block_timestamp).fromNow()}
            </span>
          </div>
        )}

        {variant === "gainers" && <span className="gainers-tag">+ 300 %</span>}
      </div>

      {activityHasNft(item) && <MultipleNfts activity={item} collection_slug={collection_slug} token_id={token_id}/>}

      {variant !== "front" &&
      (profileData?.poap_assets?.length || profileData?.nft_assets) ? (
        <ProfileNftAssets data_nfts={profileData} />
      ) : null}
    </div>
  );
};

export default AddressComponent;
