import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";

export const SortContent = styled.div({
  display: "flex",
  alignItems: "center",
  ".text-description": {
    width: "auto",
    marginBottom: "0",
  },
  ".category": {
    padding: "0 5px",

    background: theme.colors.darkFAFA,
    svg: {
      fontSize: "1.5em",
      color: theme.colors.dark7474,
    },
    display: "flex",
    alignItems: "center",
    ul: {
      display: "flex",
      alignItems: "center",
      li: {
        marginLeft: "10px",
        marginRight: "10px",
        cursor: "pointer",
      },
      p: {
        fontSize: "12px",
      },
      ".active-category": {
        color: theme.colors.primary,
      },
      span: {
        fontSize: "12px",
        color: theme.colors.dark7474,
      },
    },
  },
  ".all-check-item": {
    p: {
      fontSize: "12px",
      color: theme.colors.dark7474,
      span: {
        marginLeft: "7px",
        color: theme.colors.colorLightGrey,
      },
    },
  },
  ".filter-profile": {
    background: theme.colors.darkFAFA,

    label: {
      padding: "0!important",
      marginRight: "11px!important",

    },
    svg: {
      margin: "0 5px",
      fontSize: "1.5em",
      color: theme.colors.dark7474,
    },
  },
});
