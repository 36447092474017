import * as apiClient from "../services/api";

import {
  Token,
  ContractAction
} from './token';

export interface Arg {
  name: string
  type: string
}
export enum ContractCategory {
  Staking           = "staking",
  StakingReward     = "staking_reward",
  StakingGovernance = "staking_governance",
  StakingNode       = "staking_node",

  Vesting         = "vesting",
  LP              = "lp",
  Proxy           = 'proxy',
  Dex            = 'dex',
  FrontController = 'front_controller',
  Token           = 'token',

  TokenClaim      = 'token_claim',
  TokenReward     = 'token_reward',
  Vault           = 'vault',

  PublicSale      = 'public_sale',
  PrivateSale     = 'private_sale',
  Presale         = 'presale',
  Swap            = 'swap',
  Bridge          = 'bridge',
  Fund            = 'fund',

  NFT             = 'nft',

  Other           = 'other',
  Airdrop         = 'airdrop',
  Governance      = 'governance',

  Insurance       = 'insurance',
  Treasury        = 'treasury',
  TokenBurner      = 'token_burner',
  MultiSig        = 'multisig',

  LiquidityManager = 'liquidity_manager',
  LpToken          = 'lp_token',
  LpTokenWrapper   = 'lp_token_wrapper',
  ExchangeDeposit  = 'exchange_deposit',
  Redeem           = 'redeem',

}

export const contractCategories = [
  {label: 'Staking',           value: ContractCategory.Staking},
  {label: 'Staking Reward',    value: ContractCategory.StakingReward},
  {label: 'Staking Node',      value: ContractCategory.StakingNode},
  {label: 'Staking Governance',value: ContractCategory.StakingGovernance},

  {label: 'Vesting',           value: ContractCategory.Vesting},
  {label: 'Liquidity Pool',    value: ContractCategory.LP},
  {label: 'Proxy',             value: ContractCategory.Proxy},
  {label: 'Dex',               value: ContractCategory.Dex},
  {label: 'Front Controller',  value: ContractCategory.FrontController},
  {label: 'Token',             value: ContractCategory.Token},
  // {label: 'Token Claim',       value: ContractCategory.TokenClaim},
  {label: 'Token Reward',      value: ContractCategory.TokenReward},
  {label: 'Vault',             value: ContractCategory.Vault},

  {label: 'Public Sale',       value: ContractCategory.PublicSale},
  {label: 'Private Sale',      value: ContractCategory.PrivateSale},
  {label: 'Presale',           value: ContractCategory.Presale},
  {label: 'Swap',              value: ContractCategory.Swap},
  {label: 'Bridge',            value: ContractCategory.Bridge},
  {label: 'Fund',              value: ContractCategory.Fund},

  {label: 'NFT',               value: ContractCategory.NFT},
  {label: 'Airdrop',           value: ContractCategory.Airdrop},
  {label: 'Governance',        value: ContractCategory.Governance},

  {label: 'Insurance',        value: ContractCategory.Insurance},
  {label: 'Treasury',        value: ContractCategory.Treasury},
  {label: 'TokenBurner',        value: ContractCategory.TokenBurner},
  {label: 'MultiSig',        value: ContractCategory.MultiSig},
  {label: 'Liquidity Manager',        value: ContractCategory.LiquidityManager},
  {label: 'LP Token',        value: ContractCategory.LpToken},
  {label: 'LP Token Wrapper',        value: ContractCategory.LpTokenWrapper},

  {label: 'Exchange Deposit',  value: ContractCategory.ExchangeDeposit},
  {label: 'Redeem',  value: ContractCategory.Redeem},
  {label: 'Other',             value: ContractCategory.Other},
]

enum ContractStatus {
  Confirmed = "confirmed",
  PendingReview = "pending_review",
  Analyze = "analyze",
  UserCreated   = "user_created",
  SystemCreated = 'system_created',
}

export { ContractStatus }

// https://github.com/ethereum/solidity/blob/develop/docs/abi-spec.rst
export interface ABI {
  constant: boolean
  inputs: Array<Arg>
  outputs: Array<Arg>
  name: string
  payable: boolean
  type: "function" | "event"
  stateMutability: "pure" | "view" | "nonpayable" | "payable"
}

export interface Contract {
  id?: number;
  name: string;
  address: string;
  chainid?: number;
  abi?: Array<ABI>;
  investor_addresses?: string[];
  contract_categories?: ContractCategory[];

  contract_actions?: ContractAction[];

  token_id?: number;
  token?: Token;
  contract_status?: ContractStatus;

  ownership_proof?: string;
  note?: string;

  detected_at_tx?: string;
  added_by?: string;
  classified_by?: string;
};

export interface ListContractFilter {
  tokenId: Number;
}

export const listContracts = (o?: ListContractFilter): Promise<Contract[]> => {
  let q = '';
  if (o && o.tokenId) {
    q += `token_id=${o.tokenId}`
  }

  return apiClient.list<Contract>(`/contracts?${q}`);
}

export const addContract = (contract: Contract): Promise<Contract> => {
  return apiClient.create("/contracts", contract);
}

export const getContract = (address: string): Promise<Contract> => {
  return apiClient.find<Contract>("/contracts/" + address);
}

export const deleteContract = (id: number): Promise<Contract> => {
  return apiClient.destroy<Contract>("/contracts/" + id);
}

export const extractFn = (c: Contract) => {
  let readFn: Arg[] = [];
  let writeFn: Arg[] = [];

  if (c && c.abi) {
    readFn = c.abi.filter(x => x.type === "function" && (x.stateMutability === "pure" || x.stateMutability === "view"))
  }

  if (c && c.abi) {
    writeFn = c.abi.filter(x => x.type === "function" && x.stateMutability !== "pure" && x.stateMutability !== "view")
  }

  return [readFn, writeFn];
}

interface WhitelistResult {
  is_whitelisted: boolean
}
export const isWhitelistAddress = async (contractAddress: string, userAddress: string): Promise<boolean> => {
  const r = await apiClient.request<WhitelistResult>("/contracts/" + encodeURI(contractAddress) + "/check_investor?user_address=" + encodeURI(userAddress));
  return r && r.is_whitelisted;
}

interface OptionValue {
  value: string;
  label: string;
}

export const getMethodsForOption = (c: Contract): OptionValue[] => {
  if (!c.abi) {
    return [];
  }

  return c.abi
    .filter(x => x.type === "function")
    .map(x => { return {value: x["name"], label: x["name"]} })
}

export const getInputParamForMethod = (c: Contract, method: string): any => {
  if (!c.abi) {
    return [];
  }

  const m = c.abi
    .find(x => x.type === "function" && x.name === method);

  if (m) {
    let inputs = m.inputs;
    // input name can be empty
    inputs = inputs.map((method, i) => {
      if (!method.name || method.name === "") {
        method.name = `input${i+1}`; // this is how ethscan handle for example https://etherscan.io/address/0x706d7f8b3445d8dfc790c524e3990ef014e7c578#readContract
      }

      return method;
    })
    return inputs;
  }

  return [];
}

export const isReadonlyMethod = (c: Contract, method: string): boolean => {
  if (c.abi) {
    return c.abi.find(x => x.name === method && (x.stateMutability === "pure" || x.stateMutability === "view")) != null
  }

  return false;
}

export const isVestingContract = (c: Contract): boolean => {
  return c.contract_categories?.includes(ContractCategory.Vesting);
}

export const buildSignaturesList = (c: Contract): string[] => {

  if (!c.abi) {
    return [];
  }

  return c.abi.filter(x => x['type'] === 'function').map(fn => {
    return {
      name: fn['name'],
      inputs: fn['inputs'].map(i => i.name),
      outputs: fn['outputs'].map(i => i.type),
    }
  });
}
