import * as React from "react";
import { LoaderContent } from "../ui/styled/index";
import { assets } from "../../models/company_asset";
interface LoaderProps {
  className?: string;
  color?: string;
}

export const Loader: React.SFC<LoaderProps> = ({ color, className }) => {
  return (
    <LoaderContent color={color} className="loader-btn">
      <div className={className}>
        <div className="content-loader">
          <img src={assets.loader} alt="" />

          {/* <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div> */}
        </div>
      </div>
    </LoaderContent>
  );
};
