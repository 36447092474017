import { Badge, Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC, useState, useEffect } from "react";
import { CgSortAz } from "react-icons/cg";

import _ from "underscore";
import { SectionTitle } from "../..";
import { getActivities } from "../../../../models/activity";
import {
  filterProfile,
  temp_data_filter,
} from "../../../../models/address-data";
import { getNftCategory, FilterCategory, getFilterProfile, FilterProfile } from "../../../../models/filter";
import { Text } from "../../../ui/styled";
import { ChipContent, FilterCheck, FilterItem, TagsSelection } from "../../../ui/styled/chipers";
import { SortContent } from "../../../ui/styled/sort";

interface SortByProps {
  type: "profile" | "front";
  filter?: any;
  onChangeFilter?: (newFilter: any) => void;
}

const DefaultSort: FC<SortByProps> = () => {
  return (
    <SortContent className="u-margin-bottom-1-7 u-margin-top-2">
      <div className="category u-margin-left-none">
        <Box display="flex" alignItems="center" className="all-check-item">
          <CgSortAz /> <Text>All</Text>
        </Box>
      </div>
    </SortContent>
  );
};

const SortProfile: FC<SortByProps> = ({ filter, onChangeFilter }) => {


  const [pending, setPending] = useState(false);
  const [filterProfile, setFilterProfile] = useState<FilterProfile[]>();
  const getFilter = () => {
    try {
      setPending(true);
      const filter = getFilterProfile();
      setFilterProfile(filter);

    } catch (error) {
      console.log("error loading category", error);
    } finally {
      setPending(false);
    }
  };
  const currentTags = filter.asset_type !== "" ? filter.asset_type.split(",") : [];  
  const [selected, setSelected] = useState(currentTags);
  const handleSelectionChanged = (tag: string) => {
    let tagsGroup: string[] = [];
    const newSet = new Set(selected);
    if (newSet.has(tag)) {
      newSet.delete(tag);
    } else {
      newSet.add(tag);
    }
    setSelected(newSet);
    newSet.forEach((element: any) => {
      tagsGroup.push(element);
    });
    onChangeFilter({ ...filter, asset_type: tagsGroup.toString() });
  };  
  useEffect(() => {
    getFilter();
  }, []);

  return (
    <SortContent className="u-margin-bottom-1-7 u-margin-top-1-5">
      {pending ? (
        <Skeleton width={150} height={30} />
      ) : (
        <Box className="filter-profile" display="flex" alignItems="center" justifyContent="center" >
          <CgSortAz />
          {filterProfile?.map((item, i) => (
            <FilterCheck
              key={item.key}
              onClick={() => handleSelectionChanged(item.key)}
              className={
                _.contains(currentTags, item.key) ? "check-asset" : "no-check-asset"
              }
            >
              <div className="filter-item"> <span>{item?.name}</span> {item.count ? <p>{item.count}</p> : ""} </div>
            </FilterCheck>
          ))}


        </Box>
      )}
    </SortContent>
  );
};

const SortFront: FC<SortByProps> = ({ filter, onChangeFilter }) => {
  const [pending, setPending] = useState(false);
  const [category, setCategory] = useState<FilterCategory[]>();
  const getFilterByCategory = () => {
    try {
      setPending(true);
      const categories = getNftCategory();
      setCategory(categories);

    } catch (error) {
      console.log("error loading category", error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    getFilterByCategory();
  }, []);

  return (
    <SortContent className="u-margin-bottom-1-7">
      {pending ? (
        <Skeleton width={150} height={30} />
      ) : (
        <div className="category">
          <CgSortAz />
          {category?.map((item, i) => (
            <FilterCheck key={i} className={`${filter.asset_type === item.asset_type ? "check-filter" : ""}`} onClick={() => onChangeFilter({ ...filter, asset_type: item.asset_type })}>
              <input type="radio" checked={filter.asset_type === item.asset_type ? true : false} name="filter" />
              <label htmlFor={item.asset_type}>{item?.name}</label>
            </FilterCheck>
          ))}
        </div>
      )
      }
    </SortContent >
  );
};

const typeActivity: { [key: string]: any } = {
  profile: SortProfile,
  front: SortFront,
};
const SortComponent: FC<SortByProps> = ({ type, filter, onChangeFilter, filterRef }) => {
  const Sort = typeActivity[type] || DefaultSort;
  return type ? <Sort filter={filter} onChangeFilter={onChangeFilter} filterRef={filterRef} /> : null;
};

export default SortComponent;
