import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import { styled } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import {
  Button
} from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

interface SideBarProps {}
const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const SideBar: FC<SideBarProps> = () => {
  const [expanded] = React.useState("panel1");
  return (
    <Drawer variant="permanent" className="drawer-material-ui">
      <Box sx={{ overflow: "auto" }}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === "panel1"}
        >
          <AccordionSummary>
            <Typography>Contracts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <NavLink
                  color="dark"
                  to={`/admin/contracts/?contract_status=pending_review`}
                ><Button>
                Pending Review</Button>
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  color="dark"
                  to={`/admin/contracts/`}
                >
                  <Button>All Contracts</Button>
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  color="dark"
                  to={`/admin/tokens`}
                >
                  <Button>Tokens</Button>
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  color="dark"
                  to={`/admin/contract-extraction`}
                >
                  <Button>Contract Scan</Button>
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  color="dark"
                  to={`/admin/questions`}
                >
                  <Button>Questions</Button>
                </NavLink>
              </ListItem>

              <ListItem>
                <NavLink
                    color="dark"
                    to={`/admin/activity-labels`}
                >
                  <Button>Activity Labels</Button>
                </NavLink>
              </ListItem>

              <ListItem>
                <NavLink
                  color="dark"
                  to={`/admin/trending-queries`}
                >
                  <Button>Trending Queries</Button>
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  color="dark"
                  to={`/admin/highlights`}
                >
                  <Button>Highlights</Button>
                </NavLink>
              </ListItem>

            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === "panel1"}
        >
          <AccordionSummary>
            <Typography>API</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <NavLink
                  color="dark"
                  to={`/admin/apikeys`}
                >
                  <Button>Gen Keys</Button>
                </NavLink>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Drawer>
  );
};

export default SideBar;
