import { TAGS_DATA, tagToSlug, TagType } from "./tag";

export type ActivityLevelType = "high" | "medium" | "low";

export const ACTIVITY_LEVEL = {
  high: {
    emoji: "fire-emoji",
    description: "High",
  },
  medium: {
    emoji: "normal-emoji",
    description: "Medium",
  },
  low: {
    emoji: "sleeping-emoji",
    description: "Low",
  },
};

export const getEmoji = (tag: string) => {
  const slug = tagToSlug(tag);
  if (slug in ACTIVITY_LEVEL) {
    return ACTIVITY_LEVEL[slug as ActivityLevelType].emoji;
  }
  if (slug in TAGS_DATA) {
    return TAGS_DATA[slug as TagType].emoji;
  }
  return "";
};
