import { BigNumber } from "ethers";
import _ from "underscore";
import * as apiClient from "../services/api";

export interface Web3Activity {
  action: {
    action: string;
    label: string;
    label_id: string | number;
    raw_label: string;
    payload: {
      link_to: string;
      title: string;
      [key: string]: string;
    };
  };
  amount: number;
  block_hash: string;
  block_number: number;
  block_timestamp: string;
  from_address: string;
  signature: string;
  to_address: string;
  tx_hash: string;
  surrogate_key: number;
}

export interface ProfileActivities {
  address:          string;
  address_type:     string;
  balance:          string;
  created_at:       Date | string;
  dao_activities:   string | null;
  ens:              string | number;
  first_actived_at: Date | string;
  friends:          boolean | null;
  nft_assets:       NftAsset[];
  poap_assets:      null;
  stats:            Stats;
  surrogate_key:    number;
  tags:             string[];
  updated_at:       Date;
}

export interface NftAsset {
  address:  string;
  name:     string;
  price:    Price;
  token_id: string;
}

export interface Price {
  amount:        string;
  payment_token: PaymentToken;
}

export interface PaymentToken {
  address:  string;
  decimals: number;
  symbol:   Symbol;
}

export interface Stats {
  nft_collected: number;
  nft_for_sale:  number;
  nft_minted:    number;
}

export interface ActivityRequest {
  wallet_address?: string;
  token_address?: string;
  since?: number;
  before?: number;
  q?: string;
  chainIDs?: number[];
  content_type?: string;
  sort?: string;
  asset_type?: string;
  tags?: string;
  source?: string;
}



export const getActivities = async (
  filter?: ActivityRequest
): Promise<Web3Activity[]> => {
  if (!filter) {
    filter = {};
  }

  Object.keys(filter).forEach((key) => {
    if (filter && !filter[key]) {
      filter[key] = "";
    }
  });
  const qs = new URLSearchParams(filter).toString();

  const resp = await apiClient.flagshipRequest(`/activities?${qs}`);
  return (resp?.data || []) as Web3Activity[];
};

export const getProfilesByActivity  = async (
  filter?: ActivityRequest
): Promise<Web3Activity[]> => {
  if (!filter) {
    filter = {};
  }

  Object.keys(filter).forEach((key) => {
    if (filter && !filter[key]) {
      filter[key] = "";
    }
  });
  const qs = new URLSearchParams(filter).toString();

  const resp = await apiClient.flagshipRequest(`/profiles/by_activity?${qs}`);
  return (resp?.data || []) as Web3Activity[];
};

export const getLabel = (tx: Web3Activity): string => {
  // This label is db translation return from backend, take it if we found one
  if (tx.action?.label) {
    return tx.action.label;
  }

  if (!tx?.action?.action) {
    return "";
  }

  return tx?.action?.action.toLowerCase();
};

export const activityHasNft = (tx: Web3Activity): boolean => {
  return !!(
    tx?.action?.payload?.token_address && tx?.action?.payload?.nft_token_id
  );
};

export const tagDecoratorText = (
  data: Array<any>,
  { separator = "", limit = 4 }
) => {
  return data.length < limit ? "" : data.length + " " + separator + " ";
};

export const tagTitleSeparator = (
  dataCollection: Array<[]>,
  position: number
) => {
  if (
    (dataCollection.length === 2 && position === 0) ||
    (dataCollection.length === 3 && position === 1)
  ) {
    return " and ";
  }

  if (
    (dataCollection.length > 2 && position === 0) ||
    (dataCollection.length > 3 && position === 1)
  ) {
    return ", ";
  }
  return "";
};

export const extractTransferEventAssets = (
  assets: Array<any>,
  filter: { collection_slug?: string; token_id?: string }
) => {
  const { collection_slug, token_id } = filter;

  // dedupe by token id because a token can appear multiple times in transaction
  const dedup = _.uniq(
    assets || [],
    false,
    _.iteratee((item) => item?.token_id)
  );

  return dedup.filter((item) => {
    if (token_id) {
      return BigNumber.from(item.token_id || 0).eq(
        BigNumber.from(token_id || 0)
      );
    }

    if (collection_slug) {
      return item.asset?.collection.slug === collection_slug;
    }

    // when not passing token id or collection slug, we return everything
    return true;
  });
};
