import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { assets, VIRALLOOP_WIDGET_ID } from "../models/company_asset";

import ComingSoonPage from "../../pages/coming-soon.page";

import * as accountStore from "../../app/stores/account";
import { Box, Container } from "@material-ui/core";

interface Props {
  children: any;
}

export const LaunchGate: FC<Props> = ({ children, ...props }) => {
  const [allow, setAllow] = useState(false);

  const [loading, setLoading] = useState(true);

  const asyncLoadAccount = async () => {
    await accountStore.loadAccounts();
    setLoading(false);
  };

  useEffect(() => {
    const a1 = accountStore.currentAccount.subscribe({
      next: (v) => {
        setAllow(true);
      },
    });

    asyncLoadAccount();

    return () => {
      a1.unsubscribe();
    };
  }, []);

  return loading ? (
    <Box p={3} textAlign="center" style={{ minHeight: "calc(100vh - 180px)" }}>
      Checking access...
    </Box>
  ) : allow ? (
    <>{children}</>
  ) : (
    <ComingSoonPage />
  );
};
