import styled from "@emotion/styled";
import { Box, Divider } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC, useEffect, useState } from "react";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
import { toast } from "react-toastify";
import { TextsInfo } from "../..";
import { formatNumber } from "../../../../services/formatter";
import { theme } from "../../../../theme/theme";
import { Text } from "../../../ui/styled";
import StatsBubbleWidget from "./active-users-bubble-widget";
import CollapseWidget from "./collapse-widget";

import {
  CollectionStat,
  getNftCollectionStats,
  percentageOwnerStats,
} from "../../../../models/nft";

const TitleBox = styled(Box)`
  display: flex;
  align-items: baseline;
  & svg {
    right: -4px;
    height: 12px;
    position: relative;
  }
`;

const ProgressBar = styled.div`
  width: 145px;
  height: 10px;
  border-radius: 10px;
  background: ${theme.colors.darkDCDC};
  .progress {
    position: relative;
    height: 10px;
    border-radius: 10px;
    background: ${theme.colors.primaryMediumLight};
  }
`;

interface CurrentOwnersStatsProps {
  category?: string;
  slug?: string;
}

interface PrecomputedCollectionStat {
  total_minter: number;
  total_buyer: number;
  total: number;
  percent_minter: number;
  percent_buyer: number;
}

const CurrentOwnersStats: FC<CurrentOwnersStatsProps> = ({
  category,
  slug,
}) => {
  const [loading, setLoading] = useState(false);
  const [ownersStat, setOwnersStat] = useState<CollectionStat | null>(null);

  const getData = async (filter: any) => {
    try {
      setLoading(true);
      const result = await getNftCollectionStats(slug);
      let computedStat = {
        total_minter: 0,
        total_buyer: 0,
        ...result,
      };
      computedStat.total = computedStat.total_minter + computedStat.total_buyer;
      computedStat.percent_minter = (
        computedStat.total === 0
          ? 0
          : (computedStat.total_minter * 100) / computedStat.total
      ).toFixed(0);
      computedStat.percent_buyer = (
        computedStat.total === 0
          ? 0
          : (computedStat.total_buyer * 100) / computedStat.total
      ).toFixed(0);
      setOwnersStat(computedStat);
    } catch (e) {
      console.log(e);
      toast.error("Error while loading active users stats");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [slug]);

  return (
    <CollapseWidget title="Unique owners">
      {loading && (
        <Box>
          <div className="u-display-flex u-align-items-bottom">
            <Skeleton width={100} height={30} />
            <Skeleton
              width={40}
              height={20}
              style={{ marginLeft: "10px", marginBottom: "3px" }}
            />
          </div>
          <div className="u-display-flex u-margin-top-05">
            <Skeleton width={150} height={20} style={{ marginRight: "15px" }} />
            <Skeleton width={80} height={20} />
          </div>
          <div className="u-display-flex u-margin-top-05">
            <Skeleton width={150} height={20} style={{ marginRight: "15px" }} />
            <Skeleton width={80} height={20} />
          </div>
        </Box>
      )}
      {!ownersStat ? null : (
        <>
          <TitleBox>
            <Text
              size="extra-large"
              color="dark"
              weight="medium2"
              style={{ fontWeight: 500 }}
            >
              {formatNumber(String(ownersStat.total), 0)}
            </Text>
            <Text
              color={ownersStat.new_owner_24h > 0 ? "extra" : "danger"}
              weight="medium2"
              style={{ fontWeight: 500 }}
            >
              {
                <>
                  {ownersStat.new_owner_24h > 0 ? (
                    <CgArrowLongUp />
                  ) : (
                    <CgArrowLongDown />
                  )}{" "}
                  {percentageOwnerStats(ownersStat)}
                </>
              }
            </Text>
          </TitleBox>

          <Box display="flex" alignItems="center" mt={2}>
            {
              <>
                <ProgressBar>
                  <div
                    className="progress"
                    style={{ width: `${ownersStat.percent_minter}%` }}
                  />
                </ProgressBar>
                <Box ml={1.5}>
                  <Text color="tertiary" size="small">
                    minters {ownersStat.percent_minter}%
                  </Text>
                </Box>
              </>
            }
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            {
              <>
                <ProgressBar>
                  <div
                    className="progress"
                    style={{ width: `${ownersStat.percent_buyer}%` }}
                  />
                </ProgressBar>
                <Box ml={1.5}>
                  <Text color="tertiary" size="small">
                    bought from market {ownersStat.percent_buyer}%
                  </Text>
                </Box>
              </>
            }
          </Box>
        </>
      )}

      <Box width="100%" className="hidde-last-time">
        <StatsBubbleWidget collection={slug} />
      </Box>
      <Box mt={3} width="100%">
        {loading && (
          <Box>
            <Skeleton width={120} height={20} style={{ marginBottom: "2px" }} />
            <Skeleton width={150} height={30} />
          </Box>
        )}
        {ownersStat?.median_holding && (
          <>
            {ownersStat?.median_holding.duration !== 0 &&
              ownersStat?.median_holding.unit !== "" && (
                <TextsInfo>
                  <p>Half of them hold for more than</p>
                  <span>
                    {Math.ceil(ownersStat.median_holding.duration)}{" "}
                    {ownersStat.median_holding.unit +
                      (ownersStat.median_holding.duration > 1 ? "s" : "")}{" "}
                  </span>
                </TextsInfo>
              )}
          </>
        )}

        {loading && (
          <Box>
            <Skeleton width={120} height={20} style={{ marginBottom: "2px" }} />
            <Skeleton width={150} height={30} />
          </Box>
        )}

        {ownersStat?.unique_item_exchanged && (
          <>
            {ownersStat?.unique_item_exchanged.text !== "" && (
              <TextsInfo>
                <p>Their actual demand for this collection is</p>
                <span>{ownersStat.unique_item_exchanged?.text}</span>
              </TextsInfo>
            )}
          </>
        )}
      </Box>
    </CollapseWidget>
  );
};

export default CurrentOwnersStats;
