import { Subject } from 'rxjs';
import { setCredential } from '../services/api';
import * as apiClient from '../services/api';
import {
  setChain,
  appenvSubject,
  AppEnv,
  authURL,
} from '../services/appenv';

import {
  getWalletConnectProvider,
  metamaskIcon,
  walletconnectIcon,
  magicIcon,
  getCurrentWalletName,
} from '../services/wallet';

export enum WalletProvider {
  MetaMask = 'metamask',
  WalletConnect = 'walletconnect',
  Magic = 'magic'
}


const ethereum = (window as any).ethereum;
const storage = window.localStorage;

export const currentAccount = new Subject<string>();
const newAccount = new Subject<string>();

export interface Balance {
  usd?: string;
  amount?: string;
  token_address?: string;
}

export class Account {
  provider: WalletProvider;
  account: string;
  name?: string;
  id?: string;
  is_company?: boolean;

  total_networth_fiat: string;
  wallet_balance_fiat: string;

  wallet_balance: {[key: string]: Balance};

  total_networth_perentage_change_24h: number;
  wallet_perentage_change_24h: number;
}

type Accounts = {
  [key: string]: Account;
};

let accounts: Accounts = {} as Accounts;

export async function init(): void {
  const rawStorage = storage.getItem('forehand_accounts');
  if (rawStorage) {
    accounts = JSON.parse(rawStorage);
  }

  const activeAccount = storage.getItem('forehand_active_account')
  if (activeAccount) {
    console.log("found active account", activeAccount);
    currentAccount.next(activeAccount);
    appenvSubject.next({activeAccount});
  }
}


export async function initWalletLoginDeprecate(): void {
  console.log("init wallet storage");
  if (!storage.getItem('forehand_accounts')) {
    // save blank state
    save();
  } else {
    const rawStorage = storage.getItem('forehand_accounts');
    if (rawStorage) {
      accounts = JSON.parse(rawStorage);
    }

    const activeAccount = storage.getItem('forehand_active_account')
    if (activeAccount) {
      console.log("found active account", activeAccount);
      setCredential({account: activeAccount, password: "dummykey"});
      currentAccount.next(activeAccount);
      appenvSubject.next({activeAccount});

      loadAccounts();
    }
  }

  await Promise.all([
    onMetaMaskAccountChanged(),
    onWalletConnectAccountChanged(),
  ])
}

export const isStaff = (): boolean => {
  return accounts && Object.values(accounts).some(x => x.staff === true);
}

export const loadAccounts = async(): Promise<void> => {
  try {
    let resp = await apiClient.flagshipRequest<Account[]>("/me");
    if (!resp || !resp.data) {
      throw "invalid login"
    }

    let profiles = resp.data

    if (profiles) {
      profiles.forEach(a => {
        accounts[a.address] = {
          provider: 'magic',
          id: a.id, name: a.name, is_company: a.is_company,
          total_networth_fiat: a.total_networth_fiat,
          wallet_balance_fiat: a.wallet_balance_fiat,
          wallet_balances: a.wallet_balances,

          total_networth_perentage_change_24h: a.total_networth_perentage_change_24h,
          wallet_perentage_change_24h: a.wallet_perentage_change_24h,

          staff: a.staff,
          total_following: a.total_following || 0,
        }
      })

      save()
      // Since we're using magic, some account association is done, we just need to login to the first one
      switchAccount(profiles[0].address);
    }
  } catch (e) {
    if (e.error === 401) {
      logoutAll();
    } else {
      console.log("error when loading auth", e);
    }
  }
}

export function add(account: string, provider: WalletProvider) {
  account = account.toLowerCase();
  console.log("add account to store", account, provider);
  accounts[account] = {
    provider,
    account,
  }

  save();
  newAccount.next(account);
  switchAccount(account);
}

export function switchAccount(account: string) {
  console.log("set active account in store", account);
  if (account && account.length >= 0) {
    storage.setItem('forehand_active_account', account);
  }

  // TODO: We should get back a token to pass to credential
  // Authentication is send over on cookie
  setCredential({account, password: "dummykey"});

  currentAccount.next(account);
  appenvSubject.next<AppEnv>({activeAccount: account});
}

export function findByAddress(address: string): Account | null {
  return accounts[address.toLowerCase()];
}

export function isConnectedWallet(): boolean {
  const raw = storage.getItem('forehand_active_account');
  // console.log("active account in localstorage", raw)
  if (raw) {
    return accounts[raw] != null;
  }

  return false;
}

export function getAllAccounts() {
  return accounts;
}

export function getCurrentAccount(): string | null {
  return storage.getItem('forehand_active_account');
}

export function clear(): void {
  storage.removeItem('forehand_accounts');
  storage.removeItem('forehand_active_account');
  storage.removeItem('walletconnect');
  accounts = {};
}

function save() {
  storage.setItem('forehand_accounts', JSON.stringify(accounts));
}

export function shortAccountAddress(a: string): string {
  if (accounts[a] && accounts[a].name && accounts[a].name !== "") {
    return accounts[a].name;
  }

  return a.substr(0, 6) + "..." + a.slice(-4)
}

export async function onMetaMaskAccountChanged(): Promise<void> {
  // TODO: Use https://www.npmjs.com/package/@metamask/detect-provider
  if (!window.ethereum) {
    return Promise.resolve();
  }

  ethereum && ethereum.on('accountsChanged', function (accs) {
    console.log("metamask accountsChange", accs);

    if  (accs && accs.length && accs[0] !== "") {
      console.log('metamask account is connected', accs);
      add(accs[0], WalletProvider.MetaMask);

      return;
    }

    console.log("metamask disconnected, will logout the user out of metamask account");
    Object.keys(accounts).forEach(address => {
      if (accounts[address].provider === WalletProvider.MetaMask) {
        delete accounts[address];
      }
    })

    if (Object.keys(accounts).length === 0) {
      logoutAll();
    } else {
      switchAccount(Object.keys(accounts)[0]);
    }
  });

  ethereum && ethereum.on('chainChanged', (chainId: string) => {
    if (getCurrentWalletName() === 'metamask') {
      setChain(Number(chainId));

      // Metamask recomend reload chain
      // https://docs.metamask.io/guide/ethereum-provider.html#events
      window.location.reload();

      console.log("metamask chain changed", chainId, "but ignore because current account isn't on walletconnect");
    }
    console.log("metamask chain changed to", chainId);
  });

  try {
    const chainId = await ethereum.request({
      method: 'eth_chainId'
    })

    console.log("metamask connect to", Number(chainId));
    if (getCurrentWalletName() === 'metamask') {
      console.log("set chain to ", Number(chainId), "from metamask");
      setChain(Number(chainId));
    }
  } catch (e) {
    console.log("Cannot detect current chain", e)
  }
}


export async function onWalletConnectAccountChanged(): void {
  // Subscribe to accounts change
  const wcProvider = getWalletConnectProvider();
  wcProvider.on("accountsChanged", (accs: any) => {
    console.log("wallet: wc account changed", accs);

    if  (accs && accs.length && accs[0] !== "") {
      console.log('metamask account is connected', accs);
      add(accs[0], WalletProvider.WalletConnect);

      return;
    }

    console.log("metamask disconnected, will logout the user out of metamask account");
    Object.keys(accounts).forEach(address => {
      if (accounts[address].provider === WalletProvider.WalletConnect) {
        delete accounts[address];
      }
    })

    if (Object.keys(accounts).length === 0) {
      logoutAll();
    } else {
      switchAccount(Object.keys.find(a => accounts[a].provider === WalletProvider.WalletConnect));
    }
  });

  // Subscribe to chainId change
  wcProvider.on("chainChanged", (chainId: number) => {
    if (getCurrentWalletName() === 'walletconnect') {
      setChain(Number(chainId));
      // Metamask recomend reload chain
      // https://docs.metamask.io/guide/ethereum-provider.html#events
      window.location.reload();

      return;
    }
    console.log("wc chain changed", chainId, "but ignore because current account isn't on walletconnect");
  });

  // Subscribe to session disconnection
  wcProvider.on("disconnect", (code: any, reason: any) => {
    console.log("wc disconnect", code, reason);
  });

  try {
    const chainId = await wcProvider.request({
      method: 'eth_chainId'
    })

    console.log("wc connect to", Number(chainId));
    if (getCurrentWalletName() === 'walletconnect') {
      setChain(Number(chainId));
    }
  } catch (e) {
    console.log("wc cannot detect current chain", e)
  }

}

export function logoutAll(): void {
  clear();
  //window.location.href = authURL;
}

export const getIcon = (addressOrAccount: string|Account) => {
  let a;

  if (typeof addressOrAccount == "string") {
    a = findByAddress(addressOrAccount.toLowerCase());
  } else {
    a = addressOrAccount;
  }

  if (!a) {
    // TODO: throw error
    return metamaskIcon;
  }

  if (a.provider === WalletProvider.MetaMask) {
    return metamaskIcon;
  }

  if (a.provider === WalletProvider.WalletConnect) {
    return walletconnectIcon;
  }

  if (a.provider === WalletProvider.Magic) {
    return magicIcon;
  }
}
