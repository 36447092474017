import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC, useEffect, useState } from "react";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
import { toast } from "react-toastify";
import { ActiveUsersStatsInfo } from "../../../../models/active-users";
import { FeedFilters, getActiveUsersStats } from "../../../../models/search";
import { formatNumber } from "../../../../services/formatter";
import { theme } from "../../../../theme/theme";
import { Link, Text } from "../../../ui/styled";
import StatsBubbleWidget from "./active-users-bubble-widget";
import CollapseWidget from "./collapse-widget";

const TitleBox = styled(Box)`
  display: flex;
  align-items: baseline;
  & svg {
    right: -4px;
    height: 12px;
    position: relative;
  }
`;

const ProgressBar = styled.div`
  width: 145px;
  height: 10px;
  border-radius: 10px;
  background: ${theme.colors.darkDCDC};
  .progress {
    position: relative;
    height: 10px;
    border-radius: 10px;
    background: ${theme.colors.primaryMediumLight};
  }
`;

interface ActiveUsersStatsProps {
  category?: string;
}

const ActiveUsersStats: FC<ActiveUsersStatsProps> = ({ category = "" }) => {
  const [loading, setLoading] = useState(false);
  const [statsInfo, setStatsInfo] = useState<ActiveUsersStatsInfo>({
    change_24: 2.4,
    daily_all: 50249,
    daily_new: 5717,
    daily_returning: 44532,
    overall_total: 20252579,
  });

  const newPercentage = (
    !statsInfo?.overall_total
      ? 0
      : (statsInfo.daily_new * 100) / (statsInfo.daily_all || 1)
  ).toFixed(0);

  const getData = async (filter: any) => {
    try {
      const result = await getActiveUsersStats(filter);
      setStatsInfo(result);
      setLoading(true);
      setTimeout(() => setLoading(false), 300);
    } catch (e) {
      console.log(e);
      toast.error("Error while loading active users stats");
    }
  };

  useEffect(() => {
    getData({ category });
  }, [category]);

  const categoryName = category
    ? FeedFilters.find((item) => item.value === category)?.name
    : "web3";
  return (
    <CollapseWidget title={`Active users today`}>
      {!statsInfo ? null : (
        <>
          <TitleBox>
            <Link
              color="dark"
              href="#"
              style={{ fontSize: "30px", fontFamily: "Roboto Medium" }}
            >
              {loading ? (
                <Skeleton width={120} />
              ) : (
                statsInfo.daily_all ? formatNumber(String(statsInfo.daily_all)) : 0
              )}
            </Link>
            <Text
              color={statsInfo.change_24 > 0 ? "extra" : "danger"}
              weight="medium2"
              style={{ fontWeight: 500 }}
            >
              {loading ? (
                <Skeleton width={60} style={{ marginLeft: 8 }} />
              ) : (
                <>
                  {statsInfo.change_24 > 0 ? (
                    <CgArrowLongUp />
                  ) : (
                    <CgArrowLongDown />
                  )}
                  {!statsInfo.change_24 || isNaN(statsInfo.change_24) ? 0 : <>{formatNumber(String(statsInfo.change_24), 1)}%</>}
                </>
              )}
            </Text>
          </TitleBox>
          <Text color="tertiary-light" size="small">
            {loading ? (
              <Skeleton width={80} />
            ) : (
              statsInfo.overall_total ? formatNumber(String(statsInfo.overall_total), 0) + " last 12 months" : 0
            )}
          </Text>

          <Box display="flex" alignItems="center" mt={2}>
            {loading ? (
              <Skeleton width="100%" />
            ) : (
              <>
                <ProgressBar>
                  <div
                    className="progress"
                    style={{ width: `${newPercentage}%` }}
                  />
                </ProgressBar>
                <Box ml={1.5}>
                  <Link href="#" color="tertiary" size="small">
                    new users {newPercentage}%
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </>
      )}

      <Box className="bubble-content" width="100%">
        <StatsBubbleWidget category={category} />
      </Box>
    </CollapseWidget>
  );
};

export default ActiveUsersStats;
