import { Subject } from 'rxjs';

import {
  apiBase,
  getChain,
  flagshipBase
} from "./appenv";


export interface ApiError {
  message: string;
  error: number;

  name?: string;
  stack?: string;


  response_code: number;
  response_body: string;
}

export const errorSubject = new Subject<ApiError>();

const options = {
  method: 'GET',
  mode: 'cors',
  credentials: "include",
}

const headers = {
  'Content-Type': 'application/json',
  'Accept': "application/json",
}

interface Credential {
  account: string
  password: string
}

let credential: Credential;
export const setCredential = (c: Credential) => {
  credential = c;
}

type Header = {
  [key: string]: string;
}

const buildHeaders = (): Header => {
  let extra: Header = {};

  if (credential) {
    // Authentication happens in JWT
    extra['accountid'] = credential.account;
  }

  if (getChain()) {
    extra['chainid'] = getChain().id;
  }

  return {...headers, ...extra}
}

const buildRequest = (opts?: {[key: string]: any}) => {
  return {
    ...options,
    ...(opts || {}),
    headers: buildHeaders(),
  }
}

const handleAPIError = async<T>(path: string, resp: any): Promise<T> => {
  try {
    const a = await resp.json();
    if (resp.status === 401) {
      errorSubject.next({error: 401, message: a.error_msg, name: `AuthError ${path}`});
      return Promise.reject({error: 401, message: a.error_msg, name: `AuthError ${path}`});
    } else {
      return Promise.reject({error: a.error, message: a.error_msg, name: `ApiError ${path}`});
    }
  } catch {
    return Promise.reject(new Error(`request failure ${path}. Error code ${resp.status}`));
  }
}

// create a resource at the particular path
// example: POST /resouces data
export const create = async <T>(path: string, body: T): Promise<T> => {
  console.log("create resource", JSON.stringify(body))
  const resp = await fetch(apiBase + path, buildRequest({method: 'POST', body: JSON.stringify(body)}) as any)

  if (resp.ok) {
    const json = await resp.json();
    return json['data'];
  }

  return handleAPIError(path, resp);
}

// update resource at the particular path with PUT
// example: POST /resouces data
export const save = async <T>(path: string, body: T): Promise<T> => {
  console.log("save(put) resource", path, JSON.stringify(body))
  const resp = await fetch(apiBase + path, buildRequest({method: 'PUT', body: JSON.stringify(body)}) as any)

  if (resp.ok) {
    const json = await resp.json();
    return json['data'];
  }

  return handleAPIError(path, resp);
}

// update resource at the particular path with PUT
// example: PATCH /resouces data
export const update = async <T>(path: string, body: T): Promise<T> => {
  console.log("save(patch) resource", path, JSON.stringify(body))
  const resp = await fetch(apiBase + path, buildRequest({method: 'PATCH', body: JSON.stringify(body)}) as any)

  if (resp.ok) {
    const json = await resp.json();
    return json['data'];
  }

  return handleAPIError(path, resp);
}


// retrieve list of resources from its path
// Example `resources`
export const list = async <T>(path: string): Promise<Array<T>> => {
  console.log("list resource", path)
  const resp = await fetch(apiBase + path, buildRequest() as any)

  if (resp.ok) {
    const json = await resp.json();
    return json['data'];
  }

  return handleAPIError(path, resp);
}

// find a single resource.
// Example `resources/id`
export const find = async <T>(path: string): Promise<T> => {
  console.log("find resource", path)
  const resp = await fetch(apiBase + path, buildRequest() as any)

  if (resp.ok) {
    const json = await resp.json();
    return json['data'];
  }

  return handleAPIError(path, resp);
}

export const destroy = async <T>(path: string): Promise<T> => {
  console.log("destroy resource", path)
  const resp = await fetch(apiBase + path, buildRequest({method: 'DELETE' }) as any);

  if (resp.ok) {
    const json = await resp.json();
    return json['data'];
  }

  return handleAPIError(path, resp);
}

// Make a general request.
// Example `resources/id`
export const request = async <T>(path: string): Promise<T> => {
  console.log("request data", path);
  const resp = await fetch(apiBase + path, buildRequest() as any)
  const json = await resp.json();
  return json['data'];
}

export const verifyAuth = async (address: string, signature: string): Promise<boolean> => {
  const resp = await fetch(apiBase + "/connect/verify", buildRequest({
    method: 'POST',
    body: JSON.stringify({ public_address: address, signature: signature }),
  }) as any)

  return resp.ok === true
}

export const authExchangeNonce = async (account: string): Promise<string> => {
  console.log("exchange JSON", JSON.stringify(account))
  const resp = await fetch(apiBase + "/connect/nonce", {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ public_address: account })
  })

  const json = await resp.json();
  return json.nonce;
}

// Get request to flagship
export const flagshipRequest = async <Res>(path: string): Promise<Res> => {
  let endpoint = wrapAnalyticParams(flagshipBase + path);

  const resp = await fetch(endpoint, buildRequest() as any)
  const json = await resp.json();
  return json;
}

export const flagshipCreate = async <Req, Res>(path: string, body: Req): Promise<Res> => {
  const resp = await fetch(flagshipBase + path, buildRequest({method: 'POST', body: JSON.stringify(body)}) as any)
  const json = await resp.json();
  return json;
}

export const flagshipURL = (path: string): string => {
  return flagshipBase + path;
}

export const wrapAnalyticParams = (url: string): string => {
  if (!window.location?.href || window.location.href.indexOf("ref=") < 0) {
    return url
  }

  let endpoint = url
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const extra = {};
  extra['utm_source'] = params.ref;
  extra['utm_campaign'] = params.query;

  if (endpoint.indexOf("?") < 0) {
    endpoint += '?'
  }

  endpoint += '&' + new URLSearchParams(extra).toString();

  return endpoint;
}
