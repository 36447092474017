import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  FormControl,
  FormLabel,
  Button,
  Grid,
  Typography,
  Chip,
  FormGroup,
  TextareaAutosize,
  List,
  ListItem, FormControlLabel, Checkbox,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";

import { updateResource } from "../../app/models/admincrud";

import {
  getToken,
  isTokenVerified,
  isTokenRejected,
} from "../../app/models/token";

import { toast } from "react-toastify";

interface TokenDetailsPageProps {}

const TokenDetailsPage: FC<TokenDetailsPageProps> = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          margin: theme.spacing(1),
        },
      },

      spacing: {
        margin: "0.25rem",
      },

      table: {
        minWidth: 300,
        maxHeight: 400,
        overflow: "scroll",
        border: "1px solid rgba(255, 255, 255, 0.12)",
      },
    })
  );
  const classes = useStyles();
  const { address } = useParams();
  const [token, setToken] = useState(null);
  useEffect(() => {
    getToken(address).then(setToken);
  }, [address]);

  let [phase, setPhase] = useState<"ready" | "loading">("ready");

  const handleApprove = async (e) => {
    setPhase("loading");
    try {
      const result = await updateResource(`/tokens/${token.id}/approve`, {
        note: token.note,
      });
      setToken(result);
      toast.success("update succeeded", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } catch (e) {
    } finally {
      setPhase("ready");
    }
  };
  const handleReject = async (e) => {
    setPhase("loading");
    try {
      const result = await updateResource(`/tokens/${token.id}/reject`, {
        note: token.note,
      });
      setToken(result);
      toast.success("update succeeded", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } catch (e) {
    } finally {
      setPhase("ready");
    }
  };
  const changeCategory = async(e: React.ChangeEvent) => {
    const value = e.target.value as string;
    let token_category = token.token_category
    if (token.token_category.indexOf(Number(value)) >= 0) {
      token_category = token.token_category.filter(x => x !== Number(value));
      setToken({...token, token_category: [...new Set(token_category)]})
    } else {
      token_category = [...token.token_category, Number(value)];
      setToken({...token, token_category: [...new Set(token_category)]})
    }
  };
  const saveCategory = async(e) => {
    setPhase("loading");
    try {
      const result = await updateResource(`/tokens/${token.id}/categories`, {
        token_category: token.token_category,
      });
      toast.success("update succeeded", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } catch (e) {
      toast.error("update failed", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } finally {
      setPhase("ready");
    }
  };
  return token === null ? (
    <div>Loading token...</div>
  ) : (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <Typography variant="h5">
          {token.name}
          <Chip label={token.symbol} variant="outlined" />
        </Typography>

        <Button
          variant="contained"
          href={`https://etherscan.io/address/${token.address}`}
          target="_blank"
        >
          Address: {token.address}
        </Button>
      </div>

      <List>
        <ListItem>
          <Typography variant="h5">
            <Chip
              label={token.status}
              color={isTokenVerified(token) ? "primary" : "secondary"}
            />
          </Typography>
        </ListItem>

        <ListItem>
          <Typography variant="subtitle2">
            Imported/Added at {token.created_at}
          </Typography>
        </ListItem>

        {token.claimed_by && (
          <ListItem>
            <Typography variant="subtitle2">
              Claimed/Submited by&nbsp;
              <a href={`https://etherscan.io/address/${token.claimed_by}`}>
                {token.claimed_by}
              </a>
            </Typography>
          </ListItem>
        )}

        {!token.claimed_by && (
          <ListItem>
            <Typography variant="subtitle2">
              Token was created automatically by our crawler from 3 coingecko,
              coinmarketcap, sushi, uni, and tokenlist.
            </Typography>
          </ListItem>
        )}

        <ListItem>
          <Typography variant="subtitle2">
            {isTokenRejected(token) && `Rejected at ${token.rejected_at}`}
            {isTokenVerified(token) && `Vefified at ${token.verified_at}`}
          </Typography>
        </ListItem>
      </List>
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">Token Category</FormLabel>
          <FormGroup aria-label="position" row>
            {token.categories.map(cat => ( <FormControlLabel
                key={cat.id}
                control={<Checkbox checked={token.token_category.indexOf(Number(cat.id)) >= 0} onChange={changeCategory} value={cat.id} name="category" />}
                label={cat.name}
            />))}
          </FormGroup>
        </FormControl>
      </div>
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Verification Note(Won't see by user)
          </FormLabel>
          <FormGroup>
            <TextareaAutosize
              onChange={(e) => setToken({ ...token, note: e.target.value })}
              aria-label="d"
              minRows={5}
              placeholder="Only admin see this note"
            >
              {token.note}
            </TextareaAutosize>
          </FormGroup>
        </FormControl>
      </div>

      <div>
        {phase === "ready" ? (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
          >
            {token.status !== "verified" && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApprove}
              >
                Approve
              </Button>
            </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={saveCategory}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleReject}
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </div>
    </form>
  );
};

export default TokenDetailsPage;
