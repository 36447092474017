import * as apiClient from "../services/api";
import { flagshipBase } from "../services/appenv";

export interface NftAsset {
  address: string;
  token_id: string;
}

export interface AddressProfile {
  address: string;
  ens: string;
  balance?: number;
  tags?: string[];
  poap_assets?: NftAsset[];
  nft_assets?: NftAsset[];
  friendls?: string[];
  updated_at?: string;
}

export interface ProfileTagTransition extends AddressProfile {
  previous_tag?: string;
  granted_at?: string;
}

export const getAddressProfile = (
  address: string,
  variant?: string
): Promise<any> => {
  if (!variant) {
    variant = "full";
  }

  return apiClient.flagshipRequest<AddressProfile>(
    `/profiles/${address}?variant=${variant}`
  );
};

export const displayName = (
  ens: string,
  address: string,
  type?: string
): string => {
  if (ens !== "") {
    return type ? ens : ens.substr(0, 5) + "...";
  }

  return type ? address : address.substr(0, 5) + "...";
};

export const characterLimit = (characters: [], limit: number) => {
  if (!characters) {
    return "";
  }
  if (characters.length < 1) {
    return "";
  }

  return (
    characters?.reduce(
      (totalLength: any, elem: any) => totalLength + elem.length,
      0
    ) > limit
  );
};

export const displayLogo = (tokenAddress: string) => {
  return flagshipBase + `/token_logo/` + tokenAddress;
};

export interface ProfileRequest {
  tag: string;
  before?: string;
}


export const getProfilesPerTag = async ({
  tag,
  before,
  collection_slug
}: ProfileRequest): Promise<AddressProfile[]> => {
  const qs = new URLSearchParams();
  qs.append("tag", tag);
  if (before) {
    qs.append("before", before);
  }

  if (collection_slug) {
    qs.append("collection_slug", collection_slug);
  }

  const resp: any = await apiClient.flagshipRequest(
    `/profiles?${qs}`
  );

  return (resp?.data || []) as AddressProfile[];
};
