import { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  getTempDataActivityOnWeb,
  SearchInputParameter,
  SearchResult as SearchResultType,
} from "../../../models/search";
import SearchResult from "./search-results/search-result";
import { Loader } from "../../ui/Loader";

import HighlightTopics from "./widgets/highlight-topics";
import PopularTopics from "./widgets/popular-topics";
import CharacteristicsOnWeb from "./widgets/characteristics-on-web";
import ActivityOnWeb from "./widgets/activity-on-web";

import { querySubject } from "./search-bar";
import {

  Divider,

  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import FilterTabs from "./widgets/filter-tabs";

import { isHomePath } from "../../../services/navigation";
import ActiveUsersStats from "./widgets/active-users";
import { useApplicationConfig } from "../../../providers/config";
import HighlightTransactions from "./widgets/highlight-transactions";
import { FilterType } from "../../../models/filter";

import styled from "@emotion/styled";
import { TabContent } from "..";
import SearchDropdown from "../../SearchDropdown";

import { isQA } from "../../../services/appenv";

const FiltersDivider = styled(Divider)(({ theme }) => ({
  "&.MuiDivider-root": {
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginLeft: -22,
    },
  },
}));

const getFilters = (params: URLSearchParams) => {
  const filter = Object.fromEntries(params.entries());
  let chainIDs: number[] = [];

  if (filter?.chains) {
    chainIDs = (filter?.chains || "")
      .split(",")
      .filter((item) => item)
      .map((item) => Number(item));
  }

  return { ...filter, category: filter?.category || !isQA() ? "nft" : "", chainIDs };
};

interface MainSearchProps { }

const MainSearch: FC<MainSearchProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  // Load feature flags
  const { userPrefs } = useApplicationConfig();

  /* Check if the location is at home page */
  const [isHome, setIsHome] = useState<boolean>(
    isHomePath(location.pathname, location.search)
  );

  useEffect(() => {
    const isHome = isHomePath(location.pathname, location.search);
    setIsHome(isHome);
  }, [location]);

  /* Category, Page, Filter information */
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(params.get("page") || "0")
  );
  const [filter, setFilter] = useState<FilterType>(getFilters(params));
  useEffect(() => {
    const filters = getFilters(params);
    setFilter(filters);
  }, [params]);

  /* Search Result */
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResultType | null>(
    null
  );

  const tmpActivityData = getTempDataActivityOnWeb();

  const handleSearch = (param: SearchInputParameter) => {
    param.qs && querySubject.next(param.qs);
  };

  const handleStartSearch = async () => {
    setIsLoading(true);
  };

  const handleEndSearch = async () => {
    setIsLoading(false);
  };

  const updateURL = (newValues: { [key: string]: any }) => {
    Object.keys(newValues).forEach((key) => {
      let value = newValues[key];
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (!value) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });

    history.push(`/?${params.toString()}`);
  };  
  return (
    <>
      {/* <SearchBar
        onSearchDone={({ data, currentPage }) => {
          setSearchResult(data);
          setCurrentPage(currentPage);
        }}
        onStartSearch={handleStartSearch}
        onEndSearch={handleEndSearch}
      />   */}

      <SearchDropdown />

      {/* ={{ paddingTop: "50px", paddingBottom: "64px" }} */}
      {isHome && (
        <TabContent>
          <FilterTabs
            filter={filter}
            onChangeFilter={(filter) => {
              updateURL(filter);
              setFilter(filter);
            }}
          />
          <FiltersDivider />
        </TabContent>
      )}

      {isLoading && !isHome && (
        <div className="loader-results">
          <Loader color="primary" />
        </div>
      )}

      {isHome && !searchResult && (
        <div className="home-page-search">
          {!isMobile && (
            <div className="home-page-search__left popular-topics-left u-sticky-content">
              {userPrefs.PopularTopics && (
                <PopularTopics
                  category={filter?.category}
                  handleSearch={handleSearch}
                />
              )}
              {userPrefs.ActiveUsersStats && (
                <ActiveUsersStats category={filter?.category || ""} />
              )}
            </div>
          )}

          <div className="home-page-search__center">
            <ActivityOnWeb
              filter={{ q: filter?.category, chainIDs: filter?.chainIDs }}
            />
          </div>

          <div className="home-page-search__right u-sticky-content">
            {isTablet && (
              <div className="home-page-search__left popular-topics-right">
                {userPrefs.PopularTopics && (
                  <PopularTopics handleSearch={handleSearch} />
                )}
                {userPrefs.ActiveUsersStats && (
                  <ActiveUsersStats category={filter?.category || ""} />
                )}
              </div>
            )}

            <div className="collapse-box u-width-100 u-border-none u-padding-top-none">
              {userPrefs.HighlightTransactions ? (
                <HighlightTransactions category={filter?.category} />
              ) : (
                <HighlightTopics category={filter?.category} />
              )}
              {!filter && userPrefs.Characteristics !== false && (
                <CharacteristicsOnWeb data={tmpActivityData} />
              )}
            </div>
          </div>
        </div>
      )}

      {!isHome && searchResult && (
        <SearchResult
          pageNumber={currentPage}
          searchResults={searchResult}
          similarQuerys={searchResult}
          handleSearch={handleSearch}
        />
      )}
    </>
  );
};

export default MainSearch;
