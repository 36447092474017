import { FC } from "react";
import dayjs from "dayjs";
import { displayName } from "../../../models/address";
import { Profile } from "../../../models/search";
import { getEmoji } from "../../../models/emojis";
import { tagToSlug } from "../../../models/tag";
import { Chip } from "@material-ui/core";

interface ProfileInfoProps {
  profile: Profile;
}

const ProfileInfo: FC<ProfileInfoProps> = ({ profile }) => {
  const { address, balance, ens, tags, first_actived_at } = profile;

  return (
    <div className="profile-user">
      <div className="profile-user__left">
        <div className="profile-user__avatar">
          <span className={tags?.length > 0 ? getEmoji(tags[0]) : ""}></span>
        </div>
        <div className="profile-user__info">
          <span className="profile-addres">
            {displayName(ens, address, "p")}
          </span>
          <div className="profile-user__info--links ">
            {tags
              .filter((t) => t !== "Holder") // TODO: remove this line when we find a better name for Holder
              .map((x: string) => (
                <a href={`/tags/${tagToSlug(x)}`} key={x}>
                  <Chip label={x.toLowerCase()} />
                </a>
              ))}
          </div>

          {first_actived_at !== "0001-01-01T00:00:00Z" && (
            <span className="active-since">
              Active since {dayjs(first_actived_at).format("YYYY-MM-DD")}{" "}
            </span>
          )}
        </div>
      </div>

      {/* <div className="profile-user__amount">
        <span className="u-text-uppercase">
          {balance.toString().length > 10 ? (
            <>${Numeral(parseFloat(balance.toString())).format("0a")} </>
          ) : (
            <>${Numeral(parseFloat(balance.toString())).format("0,000")}</>
          )}
        </span>
      </div> */}
      {/*temp hide <span>+ 0.5 %</span>*/}
    </div>
  );
};

export default ProfileInfo;
