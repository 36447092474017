import { Box } from "@material-ui/core";
import { FC, useState } from "react";
import { Text } from "../../ui/styled";
import { ChipContent } from "../../ui/styled/chipers";
import { FollowButton, ProfileIcon, ProfileInfo } from "./style";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import { Profile } from "../../../models/search";
import { displayName } from "../../../models/address";
import { tagToSlug } from "../../../models/tag";
import dayjs from "dayjs";
interface ProfileInformationProps {
  profile: Profile
}

const ProfileInformation: FC<ProfileInformationProps> = ({ profile }) => {
  const { address, balance, ens, tags, first_actived_at } = profile;
  const [follow, setFollow] = useState(false)

  return (
    <Box display="flex" justifyContent="center" style={{ paddingTop: "5em" }}>
      <ProfileIcon>
      </ProfileIcon>
      <ProfileInfo>
        {address && <Text className="name-profile"> {displayName(ens, address, "p")}</Text>}
        <Box display="flex" alignItems="center">
          {(tags || [])
            .slice(0, 3).map((x: string) => (
              <a href={`/tags/${tagToSlug(x)}`} key={x}>
                <ChipContent
                  size="small"
                  color="primary"
                  className="small-chip"
                  label={x.toLowerCase()}
                />
              </a>
            ))}
        </Box>
        {first_actived_at !== "0001-01-01T00:00:00Z" && (
          <Text className="active-since-date">Active since {dayjs(first_actived_at).format("YYYY-MM-DD")}</Text>
        )}
      </ProfileInfo>
      <FollowButton className={`u-margin-left-5 ${follow ? "u-color-primary" : "u-color-7474"}`} onClick={() => setFollow(!follow)}>
        {follow ? <><AiOutlinePlus />Follow</> : <><AiOutlineCheck />Following</>}
      </FollowButton>
    </Box>
  );
};

export default ProfileInformation;
