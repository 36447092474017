import { FC, useEffect, useRef, useState } from "react";
import { getNftActivity } from "../../../../models/nft";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Web3Activity } from "../../../../models/activity";
import AddressComponent from "../search-results/address-component/address-component";
import InfinityScroll from "../../../ui/infinite-scroll";
import { Loader } from "../../../ui/Loader";
import { Timeline, TimelineConnector, TimelineDot } from "@material-ui/lab";
import { LeftContent, RightContent, Separator, TextDate, TimeLineItem, YearLabel } from "../../../ui/styled/time-line-tools";

import ActvityRowAddress from "../../activity-row-address";
import { useTransition, animated, config } from "@react-spring/web";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

interface NftActivityProps { }

const NftActivity: FC<NftActivityProps> = () => {
  const [activities, setActivities] = useState<Web3Activity[]>([]);
  const [pending, setPending] = useState(false);

  const { address, tokenId } = useParams<{
    address: string;
    tokenId: string;
  }>();

  const [textLazyMint, setTextLazyMint] = useState("");


  const transitions = useTransition(activities, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    delay: 200,
    config: config.molasses,
    //onRest: () => setItems([]),
  });

  const fetchNew = async () => {
    const result = await getNftActivity(address, tokenId, {});

    if (result.length === 0) {
      setTextLazyMint(
        "This a lazy-minted NFT. It becomes minted after it is initiallypurchased."
      );
    }

    return result
  };


  const fetchMore = async () => {
    if (!activities || activities.length <= 0) {
      return Promise.resolve([]);
    }

    return await getNftActivity(address, tokenId, { before: activities[activities.length - 1].surrogate_key });
  };

  return (
    // <InfinityScroll
    //   activities={activities}
    //   setActivities={setActivities}
    //   setPending={setPending}
    //   pending={pending}

    //   fetchNew={() => getNftActivity(address, tokenId, {})}
    //   fetchMore={fetchMore}>

    //   {(activities || []).map((item, index) => (
    //     <AddressComponent
    //       key={`tx-${item.transaction_hash}`}
    //       item={item}
    //       position={index}
    //       variant="front"
    //       hideTransferAsset={true}
    //       name_from={"no_nft_name"}
    //       token_id={tokenId}
    //     />
    //   ))}
    //   {textLazyMint !== "" ? <span className="text-placeholder">{textLazyMint}</span> : null}
    // </InfinityScroll>

    <Timeline className="u-padding-none" style={{ position: "relative" }}>
      {activities.length >= 1 && <YearLabel className="date-nft-owner">2022</YearLabel>}
      <InfinityScroll
        activities={activities}
        setActivities={setActivities}
        setPending={setPending}
        pending={pending}
        fetchNew={() => getNftActivity(address, tokenId, {})}
        fetchMore={fetchMore}
      >
        {activities.length >= 1 &&
          transitions(({ opacity }, activity, t, index) => (
            <animated.div className="timeline-nft-item"
              style={{
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
              }}
            >
              <TimeLineItem>
                <LeftContent>
                  <ActvityRowAddress type="nftowner" item={activity} widthContainer="400px" />
                </LeftContent>
                <Separator>
                  <TimelineDot>
                    <TextDate >
                      {activity.block_timestamp &&
                        dayjs(activity.block_timestamp).format('MMM DD')}
                    </TextDate>
                  </TimelineDot>

                  <TimelineDot />
                  <TimelineConnector />
                </Separator>
              </TimeLineItem>
            </animated.div>
          ))}
      </InfinityScroll>
    </Timeline>

  );
};

export default NftActivity;
