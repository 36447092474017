import { FC } from "react";
interface TokenInfoProps {
  token: { name: string; symbol: string; logo: string };
}

const TokenInfo: FC<TokenInfoProps> = ({ token }) => {
  const { name, symbol, logo } = token;
  return (
    <div className="token-info">
      <div className="token-info__left">
        <img src={logo} alt={name} />
        <p>
          {name} {symbol}
        </p>
      </div>
    </div>
  );
};

export default TokenInfo;
