import { FC, useRef } from "react";
import TokenInfo from "../token-info";
import TopHoldersWrapper from "./top-holders-wrapper";
import TopHold from "../../widgets/top-hold";
import TopCoinList from "../../widgets/top-coin-list";
import UpAndComing from "../../widgets/up-and-coming";
import { IoIosArrowDown } from "react-icons/io";
import { DropDown } from "../../../../../services/drop-down";
interface TopHoldersProps {
  searchResults: any;
}

const TopHolders: FC<TopHoldersProps> = ({ searchResults }) => {
  const dropCharacteristics = useRef(null);
  const [isActive, setIsActive] = DropDown(dropCharacteristics, false);
  return (
    <div className="container-top-holder">
      <div className="update-ago">updated 2 min ago</div>
      <TokenInfo token={searchResults.token} />
      <div className="u-display-flex">
        <div className="container-top-holder__left">
          <TopHoldersWrapper searchResults={searchResults} />
        </div>
        <div className="container-top-holder__right">
          <div
            className="top-characteristics"
            ref={dropCharacteristics}
            onClick={() => setIsActive(!isActive)}
          >
            Top 10 characteristics{" "}
            <IoIosArrowDown className={`${isActive && "u-rotate-180"}`} />
          </div>
          <div
            className={`top-holder-widgets ${
              isActive && "show-characteristics"
            }`}
          >
            <UpAndComing />
            <TopCoinList />
            <TopHold />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHolders;
