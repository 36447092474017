import styled from "@emotion/styled";
import { theme } from "../../../../../theme/theme";

export const NftsProfileCollections = styled.div`
  text-align: center;
  img {
    width: 120px;
    height: 120px;
    margin: auto;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .name-collection {
    font-size: 18px;
    font-family: "Roboto Medium";
    color: ${theme.colors.dark};
  }
  .profile-address {
    margin-top: 10px;
    font-size: 15px;
    font-family: "Roboto Medium";
    &__tags {
      display: inline-block;
      color: ${theme.colors.tertiary};
    }
    a:first-child {
      display: inline-block;
      color: ${theme.colors.tertiary};
      &:hover {
        transform: scale(1.03);
      }
    }
    .profile-months-ago {
      font-size: 15px;
      margin-right: 7px;
      color: ${theme.colors.dark};
      font-family: ${theme.weight.medium};
    }
    a {
      display: inline-block;
      color: ${theme.colors.tertiary};

      margin: 5px 2px;
      margin-bottom: 2px;

      &:hover {
        transform: scale(1.03);
      }
    }
    &__by {
      font-size: 13px;
      margin-right: 7px;
      color: ${theme.colors.dark};
      font-family: ${theme.weight.medium};
    }
  }
`;

export const NftsDetailCollections = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 35px;
  align-items: flex-end;
  justify-content: center;
  p {
    display: flex;
    font-size: 30px;
    @media screen and (max-width: ${theme.breakpoint.tablet}) {
      font-size: 22px;
    }
    flex-direction: column;
    font-family: "Roboto Medium";
    small {
      font-size: 13px;
      font-family: "Roboto";
      color: ${theme.colors.tertiaryLight};
      margin-left: 5px;
    }
  }
  .price-percentage {
    text-align: center;
    @media screen and (max-width: ${theme.breakpoint.tablet}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__icon {
      display: flex;
      align-items: center;
    }
    img {
      width: 15px;
      height: 15px;
    }
    small {
      font-size: 13px;
      @media screen and (max-width: ${theme.breakpoint.tablet}) {
        font-size: 12px;
      }
      font-family: "Roboto";
      color: ${theme.colors.tertiaryLight};
      margin-left: 5px;
    }
    span {
      font-size: 14px;
      font-family: "Roboto Medium";
      color: ${theme.colors.extra};
      @media screen and (max-width: ${theme.breakpoint.tablet}) {
        font-size: 12px;
      }
      svg {
        top: 3px;
        position: relative;
      }
    }
  }
  .minters {
    flex-direction: column;
    div {
      small {
        font-size: 13px;
        color: ${theme.colors.tertiaryLight};
        &:first-child {
          margin-right: 3px;
        }
      }
      @media screen and (max-width: ${theme.breakpoint.tablet}) {
        display: flex;
        flex-direction: column;
        small {
          font-size: 12px;
          font-family: "Roboto";
        }
      }
    }
  }
  .minters,
  .price,
  .volume {
    text-align: center;
    display: flex;
    margin: 0 20px;
    @media screen and (max-width: ${theme.breakpoint.smartphone}) {
      margin: 0 5px;
    }
    justify-content: center;
    img {
      margin-right: 8px;
    }
  }
`;

export const AllNftsCollections = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 445px;
  padding-bottom: 0;
  overflow-x: hidden;
  justify-content: center;
  button {
    margin: 20px auto;
    display: flex;
    font-size: 12px;
    justify-content: center;
    font-family: "Roboto Medium";
  }
`;

export const ItemNft = styled.div`
  cursor: pointer;
  padding: 1em 1em 0.5em 1em;
  position: relative;
  img {
    width: 100px;
    height: auto;
    margin: auto;
    display: block;
  }
  small {
    left: 1em;
    top: 90px;
    display: flex;
    padding: 2px 8px;
    font-size: 12px;
    position: absolute;
    background: ${theme.colors.darkF5F5};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  img.eth-logo{
    width: 7px;
    height: 12px;
    margin-right: 3px;
  }
  .name-nft {
    width: 100px;
    margin: 8px 0px;
    font-size: 15px;
    padding: 0px 5px;
    /* word-break: break-all; */
    color: ${theme.colors.dark};
    font-family: "Roboto Medium";
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .price-nft {
    margin: 3px 0;
    font-size: 15px;
    font-family: "Roboto";
  }
  .status-nft {
    font-size: 13px;
    color: ${theme.colors.extra};
    font-family: "Roboto Medium";

    svg {
      top: 3px;
      position: relative;
    }
  }
  &:hover {
    img {
      opacity: 0.5;
    }
    .name-nft {
      transform: scale(1.03);
    }
  }
`;

export const NftRow = styled.div`
  cursor: pointer;
  &:first-child {
    margin-top: 0;
  }
  .owner-address {
    display: flex;
    font-size: 15px;
    margin-bottom: 3px;
    font-family: ${theme.weight.medium2};
    a {
      color: ${theme.colors.tertiary};
      &:hover {
        transform: scale(1.03);
      }
    }
    .owner-tags {
      margin-left: 5px;
      color: ${theme.colors.tertiary};
      span {
        color: ${theme.colors.tertiary};
      }
      a {
        display: inline-block;
        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }
  .owner-nfts-details {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    .owner-nfts-amounts {
      margin-top: 10px;
    }
    &:hover {
      img {
        opacity: 0.5;
      }
      a {
        transform: scale(1.03);
      }
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
    a {
      font-size: 15px;
      display: inline-block;
      color: ${theme.colors.dark};
      font-family: ${theme.weight.medium2};
    }
    .owner-nfts-amounts {
      margin-left: 15px;
      span:nth-child(1) {
        font-size: 15px;
        color: ${theme.colors.dark};
        font-family: ${theme.weight.medium};
      }
      span:nth-child(2) {
        font-size: 13px;
        margin-left: 40px;
        display: inline-block;
        color: ${theme.colors.extra};
        font-family: ${theme.weight.medium};
      }
    }
  }
  .owner-collection {
    padding: 5px 10px;
    margin-bottom: 10px;
  }
`;
