import React, { Component } from "react";
import { toast } from "react-toastify";

import { Button } from "../ui/styled";
import { BiChevronRight } from "react-icons/bi";

import {
  getWalletConnectProvider,
  resetWalletConnect,
  getWeb3Provider,
} from "../../services/wallet";

// TODO: Use wallet_sign
//import {
//  wallet_sign
//} from "../../services/blockchain";


import metamaskIcon from "../../../images/metamask.png";
import walletconnectIcon from "../../../images/walletconnect.svg";

import * as accountStore from "../../stores/account";
import * as apiClient from "../../services/api";

import { Loader } from "../ui/Loader";

export class UserRejectedRequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    this.message = "The user rejected the request.";
  }
}

interface LoginState {
  phase: "ready" | "loading" | "error";
}

interface LoginProp {
  onSuccess: () => void;
}

export class MetaMask extends Component<LoginProp, LoginState> {
  constructor(props: any) {
    super(props);
    this.state = {
      phase: "ready",
    };
  }

  async connect(e: React.MouseEvent<HTMLButtonElement>): Promise<any> {
    this.setState({ phase: "loading" });
    const ethereum = (window as any).ethereum;
    const W_ELEMENT = document.getElementById("walletConnect");
    if (!ethereum) {
      // TODO: Show error
      alert("Please install metamask");
      this.setState({ phase: "ready" });
      return;
    }
    W_ELEMENT?.classList.add("no-display");
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];

      const nonce = await this.exchangeNonce(account);

      const sign = await ethereum.request({
        method: "personal_sign",
        params: [account, nonce],
      });

      const signVerification = await this.verify(account, sign);

      if (signVerification) {
        accountStore.add(account, accountStore.WalletProvider.MetaMask);
        this.setState({ phase: "loading" });
        return this.props.onSuccess();
      }
    } catch (error) {
      switch (error.code) {
        case 4001:
          W_ELEMENT?.classList.remove("no-display");
          this.setState({ phase: "ready" });
          toast.error("Please sign metamask request to login");
          break;
        default:
          this.setState({ phase: "ready" });
          W_ELEMENT?.classList.remove("no-display");
          toast.error(error.message);
      }
    }
  }

  async exchangeNonce(account: string): Promise<string> {
    return await apiClient.authExchangeNonce(account);
  }

  async verify(address: string, signature: string): Promise<boolean> {
    return await apiClient.verifyAuth(address, signature);
  }

  render() {
    return (
      <>
        {this.state.phase === "loading" ? (
          <Loader color="primary" className="u-margin-top-1" />
        ) : (
          <Button
            color="primary"
            expand="large"
            icon="icon-right"
            id="metamaskConnect"
            onClick={this.connect.bind(this)}
          >
            <img src={`${process.env.PUBLIC_URL + metamaskIcon}`} alt="" />
            Login with metamask
            <BiChevronRight />
          </Button>
        )}
      </>
    );
  }
}

export class WalletConnect extends MetaMask {
  walletConnectProvider?: any;

  constructor(props: any) {
    super(props);
    this.state = {
      phase: "ready",
    };
  }

  async connect(e: React.MouseEvent<HTMLButtonElement>): Promise<any> {
    resetWalletConnect();

    this.setState({ phase: "loading" });
    const W_ELEMENT = document.getElementById("metamaskConnect");
    W_ELEMENT?.classList.add("no-display");
    try {
      this.walletConnectProvider = getWalletConnectProvider();

      //  Enable session (triggers QR Code modal)
      const account = await this.walletConnectProvider
        .enable()
        .then((accounts: string[]) => accounts[0])
        .catch((error: Error) => {
          console.log("Catch error", error);
          // TODO ideally this would be a better check
          if (error.message === "User closed modal") {
            throw new UserRejectedRequestError("User reject wallet pairing");
          }

          throw error;
        });

      console.log("received account from wallet connect", account);

      if (account) {
        const nonce = await this.exchangeNonce(account);
        const sign = await (getWeb3Provider().provider as any).request({
          method: "personal_sign",
          params: [nonce, account],
        });
        //const sign = await wallet_sign(nonce, account);

        if (!sign || sign === "") {
          throw new Error("Please confirm on your wallet to login");
        }

        const signVerification = await this.verify(account, sign);
        if (signVerification) {
          accountStore.add(account, accountStore.WalletProvider.WalletConnect);
          this.setState({ phase: "loading" });
          return this.props.onSuccess();
        }
      }
    } catch (error) {
      // TODO: This may have memory leak. Investigate and ensure we cleanly remove/cleanup wallet connect instance
      console.log("Error when connect wallet", error);
      this.walletConnectProvider.disconnect();
      resetWalletConnect();
      W_ELEMENT?.classList.remove("no-display");
      this.setState({ phase: "ready" });
      if (error.code !== 4001) {
        this.setState({ phase: "ready" });
        console.log("error when run transaction", error);
        W_ELEMENT?.classList.remove("no-display");
        toast.error(error.message);
      }
    }
  }

  render() {
    return (
      <>
        {this.state.phase === "loading" ? (
          <Loader color="primary" className="u-margin-top-1" />
        ) : (
          <Button
            color="secondary"
            expand="large"
            icon="icon-right"
            id="walletConnect"
            onClick={this.connect.bind(this)}
          >
            <img src={`${process.env.PUBLIC_URL + walletconnectIcon}`} alt="" />
            Login with wallet connect
            <BiChevronRight />
          </Button>
        )}
      </>
    );
  }
}
