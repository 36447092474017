import { FC, Fragment } from "react";
import { Web3Activity, extractTransferEventAssets } from "../../../../models/activity";
import { NftPictureItem } from "../search-results/address-component/profile-nft-assets";

interface MultipleNftsProps {
  activity: Web3Activity;
  collection_slug?: string;
  token_id?: string;
}

const MultipleNfts: FC<MultipleNftsProps> = ({
  activity,
  collection_slug,
  token_id,
}) => {
  const assets = extractTransferEventAssets(activity?.action?.payload?.nft_tokens_transfers || [], {collection_slug, token_id});

  return (
    <>
      {assets.length > 0 ? (
        <div className="nft-group-transfers">
          <>
            {assets.slice(0, 3).map((item: any, i: number) => (
              <Fragment key={item.token_id}>
                <NftPictureItem
                  nft={{
                    token_id: item.token_id,
                    address: item.contract_address,
                    size: "200",
                  }}
                />
                {assets.length > 3 && <span>...</span>}
              </Fragment>
            ))}
          </>
        </div>
      ) : (
        <NftPictureItem
          nft={{
            token_id: activity.action.payload.nft_token_id,
            address: activity.action.payload.token_address,
          }}
        />
      )}
    </>
  );
};

export default MultipleNfts;
