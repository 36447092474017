import WalletConnectProvider from "@walletconnect/web3-provider";

import metamaskIcon from "../../images/metamask.png";
import walletconnectIcon from "../../images/walletconnect.svg";
import magicIcon from "../../images/magic.svg";

//import { ethers, providers } from "ethers";
import { providers } from "ethers";

import * as accountStore from "../stores/account";

export {
  metamaskIcon,
  walletconnectIcon,
  magicIcon
}


let wcProvider;
let web3Provider;

export const getWeb3Provider = () => {
  return web3Provider;
}

export const getWalletConnectProvider = () => {
  if (!wcProvider) {
    wcProvider = new WalletConnectProvider({
      //infuraId: "681dd09eb6e74c719d58803269de03a8",
      rpc: {
        1: "https://eth-mainnet.gateway.pokt.network/v1/lb/61fa1d12928807003ad3104b",
        3: "https://ropsten.infura.io/v3/681dd09eb6e74c719d58803269de03a8",
        42: "https://kovan.infura.io/v3/681dd09eb6e74c719d58803269de03a8",
      },

    });
  }

  return wcProvider
}

// When a user initiate wallet connect and the QR form is show, then they close, without reset this we won't be able to trigger wallet connect to show that QR code again
// TODO: This may have memory leak. Investigate and ensure we cleanly remove/cleanup wallet connect instance
export const resetWalletConnect = () => {
  if (wcProvider && wcProvider?.wc?.uri) {
    wcProvider = undefined;
  }

  if (window.localStorage && window.localStorage.walletconnect) {
    window.localStorage.removeItem("walletconnect");
  }

  setupWalletConnect();
}

export async function setupWalletConnect(): void {
  console.log("wallet: setup wallet connect");
  getWalletConnectProvider();

  web3Provider = new providers.Web3Provider(wcProvider);
  if (window.localStorage && window.localStorage.walletconnect) {
    await wcProvider.enable();
  }
}

export const getCurrentWalletName = (): string|nulll => {
  let currentAccount: any;
  const addr = accountStore.getCurrentAccount();

  if (addr && accountStore.findByAddress(addr)) {
    currentAccount = accountStore.findByAddress(addr)
  }

  if (!currentAccount) {
    return
  }

  console.log("Detect current account for provider", currentAccount);

  return currentAccount.provider;
}
