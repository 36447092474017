import { FC, Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  tagTitleSeparator,
  getLabel,
  tagDecoratorText,
  Web3Activity,
} from "../../../../../models/activity";
import { isStaff } from "../../../../../stores/account";
import { shortAddress } from "../../../../../services/formatter";
import _ from "underscore";

import Numeral from "numeral";
import { collectionUrl, generateUrl } from "../../../../../models/nft";
import { BigNumber } from "ethers";
interface ActivityTitleProps {
  profileData?: any;
  name_from?: "collection_name" | "nft_name" | "no_nft_name";
  item: Web3Activity;
  collection_slug?: string;
}

const adminLabelLink = (item: Web3Activity): string => {
  const qs = {
    signature: "0x" + item.signature,
    address: item.to_address,
    method: item.action.action,
  };

  if (item.action.label_id) {
    return `/admin/activity-labels/${item.action.label_id}`;
  } else {
    return `/admin/activity-labels/create/?${new URLSearchParams(
      qs
    ).toString()}`;
  }
};

interface ModToolbarProps {
  item: Web3Activity;
}

const ModToolbar: FC<ModToolbarProps> = ({ item }) => {
  return (
    <div className="auxiliary-links">
      <a
        href={adminLabelLink(item)}
        className="tags-details__edit-label"
        target="_blank"
        rel="noreferrer"
      >
        [lbl]
      </a>
      <a
        href={`https://etherscan.io/tx/${item.tx_hash}`}
        className="tags-details__edit-label"
        target="_blank"
        rel="noreferrer"
      >
        [tx]
      </a>
    </div>
  );
};

const SHOW_TITLE_LIMIT = 3

const macros = [
  // TODO: migrate move macro var to here
  "token_symbol",
  "nft_name",
  "nft_id",
  "ens_name",
  "swap_symbol1",
  "swap_symbol2",
  "contract_name",
  "token_name",
  "nft_or_not",
  "quantity",

  "nft_swap1",
  "nft_swap2",

  "exchange_name",
];

const contractMaps = {
  "0x7be8076f4ea4a4ad08075c2508e481d6c946d12b": "OpenSea", // v1
  "0x7f268357a8c2552623316e2562d90e642bb538e5": "OpenSea", // v2
  "0x1e0049783f008a0085193e00003d00cd54003c71": "OpenSea", // SeaPort

  "0x59728544b08ab483533076417fbbb2fd0b17ce3a": "LooksRare",
  "0xcd4ec7b66fbc029c116ba9ffb3e59351c20b5b06": "Rarible",

  "0x74312363e45dcaba76c59ec49a7aa8a65a67eed3": "X2Y2",
  "0xf849de01b080adc3a814fabe1e2087475cf2e354": "X2Y2",
  "0x897249fef87fa6d1e7fedcb960c2a01ec99ecc6c": "X2Y2",
  "0xd823c605807cc5e6bd6fc0d7e4eea50d3e2d66cd": "X2Y2",

  "0xf24629fbb477e10f2cf331c2b7452d8596b5c7a5": "GemSwap",
  "0x83c8f28c26bf6aaca652df1dbbe0e1b56f8baba2": "GemSwap",

  "0x080bf510fcbf18b91105470639e9561022937712": "0x Exchange",
  "0x3b968d2d299b895a5fcf3bba7a64ad0f566e6f88": "BendDAO",

  "0xbd3f82a81c3f74542736765ce4fd579d177b6bc5": "Project Godjira",
};

const addressToName = (address: string): string => {
  if (!address.startsWith("0x")) {
    return address;
  }

  return contractMaps[address] || address;
};

const getLink = (macroVar: string, item: Web3Activity): string => {
  if (!item?.action?.payload) {
    return "#";
  }

  if (macroVar === "token_symbol" && item.action.payload.token_address) {
    return generateUrl("token", item.action.payload.token_address);
  }

  return "#";
};

const getMultiLink = (macroVar: string, item: Web3Activity): string => {
  if (!item?.action?.payload) {
    return "#";
  }
  if (macroVar === "nft_name" && item.action.payload.token_address) {
    return item.action?.payload?.nft_token_id
      ? collectionUrl(
        item.action?.payload?.collection.slug
          ? item.action?.payload?.collection?.slug
          : item.action.payload.token_address
      )
      : generateUrl(
        "nft",
        item.action.payload.token_address,
        item.action?.payload?.nft_token_id
      );
  }

  return "#";
};

const getSwapLink = (macroVar: string, item: Web3Activity): string => {
  if (!item?.action?.payload) {
    return "#";
  }

  if (macroVar === "swap_symbol1" && item.action.payload.token1_address) {
    return (
      item.action?.payload?.token1_address &&
      generateUrl("token", item.action.payload.token1_address)
    );
  }
  if (macroVar === "swap_symbol2" && item.action.payload.token2_address) {
    return (
      item.action?.payload?.token2_address &&
      generateUrl("token", item.action.payload.token2_address)
    );
  }

  return "#";
};

const isMacroVar = (text): boolean => {
  return macros.some((variable) => variable === text);
};

const macroVarHasData = (item, varname): boolean => {
  if (varname == "nft_swap1" || varname == "nft_swap2") {
    return (
      item?.action?.payload &&
      item.action.payload.nft_tokens_transfers?.length >= 2
    );
  }

  return item?.action?.payload && item.action.payload[varname];
};

// Render a text to either its string representation or a link
// If the text isn't a macro var, return it as a whole
// Otherwise, check to see if we can turn the var into meaningful data
// If we cannot, return empty string to avoid leak it
const MacroLink: FC<{ text: string; item: Web3Activity }> = ({
  text,
  item,
}) => {
  if (!isMacroVar(text)) {
    return <>{text}</>;
  }

  if (!macroVarHasData(item, text)) {
    return null;
  }

  switch (text) {
    case "nft_name":
      return (
        <NavLink
          to={generateUrl(
            "nft",
            item.action?.payload?.token_address,
            item.action?.payload?.nft_token_id
          )}
        >
          {item.action.payload.collection?.name !== ""
            ? item.action.payload.collection?.name
            : item.action.payload[text]
              ? item.action.payload[text].split("#")
              : item.action.payload.nft_token_id}
        </NavLink>
      );

    case "nft_swap1":
      let asset1 = _.first(item.action.payload.nft_tokens_transfers);
      return (
        <a href={generateUrl("nft", asset1.contract_address, asset1.token_id)}>
          {asset1.token_id}
        </a>
      );
    case "nft_swap2":
      let asset2 = _.last(item.action.payload.nft_tokens_transfers);
      return (
        <a href={generateUrl("nft", asset2.contract_address, asset2.token_id)}>
          {asset2.token_id}
        </a>
      );

    default:
      return <a href={getLink(text, item)}>{item.action.payload[text]}</a>;
  }
};

const MacroTransferedLabel: FC<{ text: string; item: Web3Activity }> = ({
  text,
}) => {
  return macros.some((variable) => variable === text) ? <></> : <>{text} </>;
};

const MultiMacroLink: FC<{ text: string; item: Web3Activity }> = ({
  text,
  item,
}) => {
  const { payload } = item.action;
  const totalNftTransfer = payload.nft_tokens_transfers.length;
  return macros.some((variable) => variable === text) ? (
    payload && payload[text] ? (
      <>
        {totalNftTransfer === 1 ? " a " : totalNftTransfer + " "}
        <NavLink to={getMultiLink(text, item)}>
          {payload.collection?.name !== ""
            ? payload.collection?.name
            : payload[text] === "nft_id"
              ? payload.nft_token_id || ""
              : payload[text].split("#") || payload.nft_token_id || ""}
        </NavLink>
      </>
    ) : (
      <></>
    )
  ) : (
    <>{text}</>
  );
};

const MacroLinkSwap: FC<{ text: string; item: Web3Activity }> = ({
  text,
  item,
}) => {
  return macros.some((variable) => variable === text) ? (
    item?.action?.payload && item.action.payload[text] ? (
      <>
        {item.action.payload[text] === "ETH" ? (
          item.action.payload[text]
        ) : (
          <NavLink to={getSwapLink(text, item)}>
            {item.action.payload[text]}
          </NavLink>
        )}
      </>
    ) : (
      <></>
    )
  ) : (
    <>{text}</>
  );
};

const MacroNFTActivity: FC<{
  text: string;
  item: Web3Activity;
  name_from?: "collection_name" | "nft_name" | "no_nft_name";
  collection_slug?: string;
}> = ({ text, item, name_from, collection_slug }) => {
  const { payload } = item.action;


  // This is just a text, render it as a whole
  if (!macros.some(macro_name => macro_name === text)) {
    return (
      <>
        {" " + String(text || "").trim()}
        {" "}
      </>
    )
  }

  // Start process macro variable replacement
  // if we have no associate data we cannot do anything
  if (!payload || !payload[text] || payload[text] === "") {
    // normally we can return null here but there is some expectation for a non null DOM down the line so we return an empty element
    return <></>
  }

  if (text != "nft_name") {
    return <MacroLink text={text} item={item} />
  }

  const collectionSlugMatch = payload?.nft_tokens_transfers.filter(
    (x) => x.asset?.collection?.slug === collection_slug
  );

  const groupedByCollectionName = _.uniq(
    payload?.nft_tokens_transfers || [],
    false,
    _.iteratee((item) => item.asset && item.asset.collection.name)
  );
  const groupTransferToken = _.uniq(
    payload?.nft_tokens_transfers || [],
    false,
    _.iteratee((item) => item.token_id)
  );

  return (
    <>
      {/* For home page */}
      {name_from === "collection_name" ? (
        <>
          {tagDecoratorText(payload.nft_tokens_transfers, {
            separator: "items in",
          })}
          <NavLink to={getMultiLink(text, item)}>
            {payload.collection?.name !== ""
              ? payload.collection?.name
              : payload[text]
                ? payload[text]
                : payload.nft_token_id}
          </NavLink>
        </>
      ) : // for nft and collection page
        name_from === "nft_name" ? (
          <NFTtitlesCollection
            collection={collectionSlugMatch}
            payload={payload}
            collection_slug={collection_slug || ""}
          />
        ) : name_from === "no_nft_name" ? (
          <></>
        ) : (
          <>
            {groupedByCollectionName && groupedByCollectionName.length > 1 ? (
              <SetDefaultCollectionName collection={groupedByCollectionName} />
            ) : (
              <MultiTransferGroup
                collection={groupTransferToken}
                payload={payload}
              />
            )}
            {collectionSlugMatch.length > 3 && "..."}
          </>
        )}
    </>
  )
};

const MultiTransferGroup: FC<{ collection: any; payload: any }> = ({
  collection,
  payload,
}) => {
  return (
    <>
      {tagDecoratorText(collection, { separator: "items;" })}
      {collection.slice(0, SHOW_TITLE_LIMIT).map((token, i) => (
        <>
          <NavLink
            to={generateUrl("nft", token.contract_address, token.token_id)}
          >
            {token?.asset ? (
              <>
                {token?.asset.name &&
                  token?.asset.name !== token.asset.collection?.name
                  ? token?.asset.name.replace(token?.asset.collection?.name, "")
                  : "#" + token.asset.token_id}
              </>
            ) : (
              <> {payload.nft_name ? payload.nft_name : payload.nft_token_id}</>
            )}
          </NavLink>

          {tagTitleSeparator(collection, i)}
        </>
      ))}
      {collection.length > 3 && "..."}
    </>
  );
};
const SetDefaultCollectionName: FC<{ collection: any }> = ({ collection }) => {
  return (
    <>
      {tagDecoratorText(collection, { separator: "items;" })}
      {collection?.map((token, i) => (
        <>
          <NavLink
            to={collectionUrl(token?.asset && token?.asset?.collection?.slug)}
          >
            {token?.asset && token?.asset?.collection?.name}
          </NavLink>
          {tagTitleSeparator(collection, i)}
        </>
      ))}
    </>
  );
};

const NFTtitlesCollection: FC<{
  collection: any;
  payload: any;
  collection_slug: string;
}> = ({ collection, payload, collection_slug }) => {
  return (
    <>
      {tagDecoratorText(collection, { separator: "items;" })}
      {collection_slug ? (
        <>
          {collection.length === 0 ? (
            <NavLink
              to={generateUrl(
                "nft",
                payload.token_address,
                payload.nft_token_id
              )}
            >
              <> {payload.nft_name ? payload.nft_name : payload.nft_token_id}</>
            </NavLink>
          ) : (
            <>
              {collection.slice(0, SHOW_TITLE_LIMIT).map((token, i) => (
                <Fragment key={`${token?.contract_address}-${token?.token_id}`}>
                  <NavLink
                    to={generateUrl(
                      "nft",
                      token.contract_address,
                      token.token_id
                    )}
                  >
                    {token.asset ? (
                      <>
                        {token.asset.name &&
                          token.asset.name !== token.asset.collection?.name
                          ? token.asset.name.replace(
                            token.asset.collection?.name,
                            ""
                          )
                          : "#" + token.asset.token_id}
                      </>
                    ) : (
                      <>{payload.nft_name ? payload.nft_name : payload.nft_token_id}</>
                    )}
                  </NavLink>
                  {tagTitleSeparator(collection, i)}
                </Fragment>
              ))}
              {collection.length > 3 && "..."}
            </>
          )}
        </>
      ) : (
        <>
          {payload[text] ? (
            <NavLink
              to={generateUrl(
                "nft",
                payload.token_address,
                payload.nft_token_id
              )}
            >
              {payload[text]}
            </NavLink>
          ) : (
            <>
              {collection ? (
                <>
                  {collection.slice(0, SHOW_TITLE_LIMIT).map((token) => (
                    <Fragment key={`${token?.contract_address}:${token?.token_id}`}>
                      <NavLink
                        to={generateUrl(
                          "nft",
                          token.contract_address,
                          token.token_id
                        )}
                      >
                        #{token.token_id}
                      </NavLink>
                      ,{" "}
                    </Fragment>
                  ))}
                  {collection.length > 3 && "..."}
                </>
              ) : (
                <NavLink
                  to={generateUrl(
                    "nft",
                    payload.token_address,
                    payload.nft_token_id
                  )}
                >
                  <> {payload.nft_name ? payload.nft_name : payload.nft_token_id}</>
                </NavLink>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const DefaultLinkLabel: FC<{ item: Web3Activity }> = ({ item }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MacroLink text={value} item={item} />
        </Fragment>
      ))}
    </>
  );
};

const TranferedLabel: FC<{ item: Web3Activity }> = ({ item }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MacroTransferedLabel text={value} item={item} />
        </Fragment>
      ))}
    </>
  );
};

const ActivityLinkLabel: FC<{ item: Web3Activity }> = ({ item }) => {
  if (item.action.raw_label) {
    const regExp = new RegExp(/{([^}]+)}/, "g");
    const splitted = item.action?.raw_label.split(regExp);
    return (
      <>
        {splitted.map((value, index) => (
          <Fragment key={index}>
            {macros.some((variable) => variable === value) ? (
              <>
                {item.action?.payload?.token_address && (
                  <>
                    {item.action?.payload[value] && (
                      <NavLink
                        to={
                          item.action?.payload?.nft_token_id
                            ? item.action?.payload?.collection?.slug
                              ? collectionUrl(
                                item.action?.payload?.collection?.slug
                              )
                              : generateUrl(
                                "nft",
                                item.action?.payload?.token_address,
                                item.action?.payload?.nft_token_id
                              )
                            : generateUrl(
                              "token",
                              item.action?.payload?.token_address
                            )
                        }
                      >
                        <>
                          {item.action?.payload?.collection?.name
                            ? item.action?.payload?.collection?.name
                            : String(item.action?.payload[value] || "").trim()}
                        </>
                      </NavLink>
                    )}
                  </>
                )}
                {String(item.action?.payload[value] || "").trim() === "ETH" &&
                  String(item.action?.payload[value] || "").trim()}
              </>
            ) : (
              <>
                <>
                  {item.action.payload[value] === null
                    ? ""
                    : " " + String(value || "").trim()}{" "}
                </>
              </>
            )}
          </Fragment>
        ))}
      </>
    );
  }
  return <>{getLabel(item)}</>;
};

const MultiActivityLinkLabel: FC<{ item: Web3Activity }> = ({ item }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MultiMacroLink text={value} item={item} />
        </Fragment>
      ))}
    </>
  );
};

const SwappedLabel: FC<{ item: Web3Activity }> = ({ item }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MacroLinkSwap text={value} item={item} />
        </Fragment>
      ))}
    </>
  );
};

const BurnActivityLabel: FC<{
  item: Web3Activity;
  name_from?: "collection_name" | "nft_name" | "no_nft_name";
  collection_slug?: string;
}> = ({ item, name_from, collection_slug }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MacroNFTActivity
            text={value}
            item={item}
            name_from={name_from}
            collection_slug={collection_slug}
          />
        </Fragment>
      ))}
      <ActivityLinkLabel item={item} />
    </>
  );
};

const MintNFTActivityLabel: FC<{
  item: Web3Activity;
  name_from?: "collection_name" | "nft_name" | "no_nft_name";
  collection_slug?: string;
}> = ({ item, name_from, collection_slug }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MacroNFTActivity
            text={value}
            item={item}
            name_from={name_from}
            collection_slug={collection_slug}
          />
        </Fragment>
      ))}
    </>
  );
};

const BuyNFTLabel: FC<{
  item: Web3Activity;
  name_from?: "collection_name" | "nft_name" | "no_nft_name";
  collection_slug?: string;
}> = ({ item, name_from, collection_slug }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MacroNFTActivity
            text={value}
            item={item}
            name_from={name_from}
            collection_slug={collection_slug}
          />
        </Fragment>
      ))}
    </>
  );
};

const NFTtransferLabel: FC<{
  item: Web3Activity;
  name_from?: "collection_name" | "nft_name" | "no_nft_name";
  collection_slug?: string;
}> = ({ item, name_from, collection_slug }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MacroNFTActivity
            text={value}
            item={item}
            name_from={name_from}
            collection_slug={collection_slug}
          />
        </Fragment>
      ))}
    </>
  );
};

const DefaultActivityLink: FC<{ title?: string }> = ({ title = "" }) => {
  // TOOD: Use is_cex to check if its exchange link or not
  if (title.length > 4 && (title.startsWith("0x") || title.endsWith(".eth"))) {
    let name = addressToName(title);
    return (
      <>
        {" "}
        <NavLink className="u-color-tertiary-5" to={`/profiles0/${title}`}>
          {name.startsWith("0x") ? shortAddress(name, 5, 0) : name}
        </NavLink>{" "}
      </>
    );
  }
  return <> {title}</>;
};

const DefaultActivity: FC<ActivityTitleProps> = ({ item }) => {
  return (
    <>
      {item.action.payload.nft_tokens_transfers &&
        item.action.payload.nft_tokens_transfers.length > 1 ? (
        <MultiActivityLinkLabel item={item} />
      ) : (
        <ActivityLinkLabel item={item} />
      )}
    </>
  );
};

const ApproveForTradeActivityLink: FC<ActivityTitleProps> = ({ item }) => {
  return (
    <>
      <ActivityLinkLabel item={item} />{" "}
    </>
  );
};

const NftTransferTitle: FC<ActivityTitleProps> = ({
  item,
  name_from,
  collection_slug,
}) => {
  return (
    <>
      <NFTtransferLabel
        item={item}
        name_from={name_from}
        collection_slug={collection_slug}
      />
      {item.action.payload.to ? (
        <NavLink
          className="u-color-tertiary-5"
          to={generateUrl("profile", item.action.payload.to)}
        >
          {shortAddress(item.action.payload.to, 5, 0)}
        </NavLink>
      ) : null}
    </>
  );
};


const SwapNFTActivityLabel: FC<ActivityTitleProps> = ({ item }) => {
  const regExp = new RegExp(/{([^}]+)}/, "g");
  const splitted = item.action?.raw_label.split(regExp);
  return (
    <>
      {splitted.map((value) => (
        <Fragment key={value}>
          <MacroLink text={value} item={item} />
        </Fragment>
      ))}
    </>
  );
};

const TransferActivityTitle: FC<ActivityTitleProps> = ({ item }) => {
  return (
    <>
      <TranferedLabel item={item} />
      {item.action.payload.title !== "" && (
        <>
          {item.action.payload.token_symbol === "ETH" ? (
            <span>
              {" "}
              {Numeral(item.action.payload.quantity)
                .format("0.00a")
                .toUpperCase() +
                " " +
                shortAddress(item.action.payload.token_symbol)}{" "}
            </span>
          ) : (
            <>
              {" "}
              <span>
                {Numeral(item.action.payload.quantity)
                  .format("0.00a")
                  .toUpperCase()}
              </span>{" "}
              <NavLink
                to={
                  item.action.payload.token_address
                    ? generateUrl("token", item.action.payload.token_address)
                    : "#"
                }
              >
                {shortAddress(item.action.payload.token_symbol)}
              </NavLink>
            </>
          )}

          {item.action.action === "received" ? " from " : " to "}

          {item.action.payload.to.length > 4 &&
            (item.action.payload.to.startsWith("0x") ||
              item.action.payload.to.endsWith(".eth")) ? (
            <NavLink
              className="u-color-tertiary-5"
              to={generateUrl("profile", item.action.payload.to)}
            >
              {shortAddress(item.action.payload.to, 5, 0)}
            </NavLink>
          ) : (
            item.action.payload.to
          )}
        </>
      )}
    </>
  );
};
const TransferTitle: FC<ActivityTitleProps> = ({
  profileData,
  item,
  children,
  ...rest
}) => {
  return (
    <ActivityLinkLabel item={item} />
  );
};

// TODO: fix signature
const displayMap: { [key: string]: any } = {
  received: TransferActivityTitle,
  deposited: TransferActivityTitle,
  transfer: TransferActivityTitle,
  transfer_token: TransferTitle,
  transfer_eth: TransferTitle,
  "Send NFT": NftTransferTitle,
  "Approve For Trade": ApproveForTradeActivityLink,
  swap: SwappedLabel,
  "Burn NFT": BurnActivityLabel,
  "Mint NFT": MintNFTActivityLabel,
  "Buy NFT": BuyNFTLabel,
  "Swap NFT": SwapNFTActivityLabel,
  "borrow eth with nft": SwapNFTActivityLabel,
};

const ActivityTitle: FC<ActivityTitleProps> = ({
  profileData,
  item,
  name_from,
  collection_slug,
  children,
  ...rest
}) => {
  const actionType = item?.action?.action;
  const Component = displayMap[actionType] || DefaultActivity;
  return actionType ? (
    <div className="tags-details">
      {isStaff() && <ModToolbar item={item} />}
      <Component
        profileData={profileData}
        item={item}
        name_from={name_from}
        collection_slug={collection_slug}
        {...rest}
      />
    </div>
  ) : null;
};

export default ActivityTitle;
