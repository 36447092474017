import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import {
  TextField,
  TextareaAutosize,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  Select, MenuItem,
  Button,
  List, ListItem,
  Chip,
  Typography,

  Grid,

  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';

import { toast } from "react-toastify";

import {
  Contract,
  getContract,
  ContractStatus,
  contractCategories,
  buildSignaturesList
} from "../../app/models/contract";

import { useParams } from "react-router-dom";

import {
  saveResource,
  deleteResource,
} from "../../app/models/admincrud";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },

    spacing: {
      margin: '0.25rem',
    },

    table: {
      minWidth: 300,
      maxHeight: 400,
      overflow: 'scroll',
      border: '1px solid rgba(255, 255, 255, 0.12)',

    },

  }),
);

const ContractPage = () => {
  const history = useHistory();

  const classes = useStyles();


  const { address } = useParams();
  const [contract, setContract] = useState(null);

  const [changedAttbs, setChangeAttbs] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    getContract(address).then(setContract);
  }, [address]);

  const changeCategory = (e) => {
    const value = e.target.value as string;
    setChangeAttbs({...changedAttbs, contract_categories: true});

    if (contract.contract_categories.indexOf(value) >= 0) {
      const contract_categories = contract.contract_categories.filter(x => x !== value);
      setContract({...contract, contract_categories: [...new Set(contract_categories)]})
    } else {
      const contract_categories = [...contract.contract_categories, value];
      setContract({...contract, contract_categories: [...new Set(contract_categories)]})
    }
  }

  const changeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChangeAttbs({...changedAttbs, contract_status: true});
    setContract({...contract, contract_status: event.target.value as string});
  };

  const changeName = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChangeAttbs({...changedAttbs, name: true});
    setContract({...contract, name: event.target.value as string});
  };

  const changeNote = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChangeAttbs({...changedAttbs, note: true});
    setContract({...contract, note: event.target.value as string});
  };

  const handleSave = () => {
    const newContract: Contract = Object.keys(changedAttbs).reduce((prev, current) => {
      prev[current] = contract[current];

      return prev;
    }, {id: contract.id})
    saveResource("/contracts/" + contract.id, newContract).then(() => {
      toast.success('update succeeded', {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    }).catch(e => toast.error('error saving' + e, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
    }))
  };

  const handleDelete = () => {
    deleteResource("/contracts/" + contract.id).then(history.push("/admin/pending-review"));
  };

  return contract === null ? (
    <span>Loading contract {address}...</span>
  ) : (
    <form className={classes.root} noValidate autoComplete="off">
      <FormControl component="fieldset">
        <TextField label="Name" value={contract.name} onChange={changeName}  />
      </FormControl>

      <div>
        <FormLabel component="legend">Contract Address</FormLabel>
        <Button
          variant="contained"
          href={`https://etherscan.io/address/${contract.address}`}
          target='_blank'
        >{contract.address}</Button>
      </div>

      <div>
        <FormLabel component="legend">Token Address</FormLabel>
        {contract.token ? <Button
          variant="contained"
          href={`https://etherscan.io/address/${contract.token.address}`}
          target='_blank'
        >{contract.token.address}</Button> : <span>NA</span>}
      </div>

      { contract.detected_at_tx && (<div>
        <FormLabel component="legend">Detected At</FormLabel>
        <Button
          variant="contained"
          href={`https://etherscan.io/tx/${contract.detected_at_tx}`}
          target='_blank'
        >{contract.detected_at_tx}</Button>
      </div>) }

      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">Contract Category</FormLabel>
          <FormGroup aria-label="position" row>
            {contractCategories.map(cat => ( <FormControlLabel
              key={cat.label}
              control={<Checkbox checked={contract.contract_categories.indexOf(cat.value) >= 0} onChange={changeCategory} value={cat.value} name="category" />}
              label={cat.label}
            />))}
          </FormGroup>
        </FormControl>
      </div>

      <List>
        { contract.added_by && 
        <ListItem>
          <Typography variant="subtitle2">
            Added by <Chip label={contract.added_by} color='primary' />
          </Typography>
        </ListItem> }

        <ListItem>
          <Typography variant="subtitle2">
            Classified by 
            <Chip label={contract.classified_by} color='primary' />
          </Typography>
        </ListItem>

        <ListItem>
          <Typography variant="subtitle2">
            Created on
            <Chip label={contract.created_at} color='primary' />
          </Typography>
        </ListItem>

        <ListItem>
          <Typography variant="subtitle2">
            Bytecode Hash
            <Chip label={contract.bytecode_hash} color='primary' />
          </Typography>
        </ListItem>

        {contract.method_fingerprint && <ListItem>
          <Typography variant="subtitle2">
            Method Fingerprint
            <Chip label={contract.method_fingerprint} color='primary' />
          </Typography>
        </ListItem>}

        {contract.proxy_address && <ListItem>
          <Typography variant="subtitle2">
            Proxy Address
            <Chip label={contract.proxy_address} color='primary' />
          </Typography>
        </ListItem>}
      </List>


      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">Contract Status</FormLabel>
          <Select
            value={contract.contract_status || ContractStatus.PendingReview}
            onChange={changeStatus}
          >
            <MenuItem value={ContractStatus.PendingReview}>Pending Review</MenuItem>
            <MenuItem value={ContractStatus.Confirmed}>Confirmed</MenuItem>
            <MenuItem value={ContractStatus.SystemCreated}>System Created</MenuItem>
            <MenuItem value={ContractStatus.UserCreated}>User Created</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className={classes.spacing}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Note</FormLabel>
          <TextareaAutosize
            cols={100}
            maxRows={5}
            minRows={3}
            placeholder=""
            onChange={changeNote}
          >
          {contract.note}
          </TextareaAutosize>

        </FormControl>
      </div>

      <TableContainer className={classes.table} >
        <Table size="small" >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Input</TableCell>
              <TableCell align="left">Output</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buildSignaturesList(contract).map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.inputs}</TableCell>
                <TableCell align="left">{row.outputs}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default ContractPage;
