import { FC } from "react";
import { NavLink } from "react-router-dom";
import { WidgetTitle } from "../../index";

interface HighlightTopicProps {
  category?: string;
}

const HighlightTopics: FC<HighlightTopicProps> = ({ category }) => {
  return (
    <>
      {(category === "defi" || category === "") && (
        <>
          <WidgetTitle>Unstake activity is on 🔥</WidgetTitle>

          <WidgetTitle>DeFi is down 20%.</WidgetTitle>
        </>
      )}

      {(category === "exchange" || category === "") && (
        <>
          <WidgetTitle className="add-fade-to-top">
            High tokens/<NavLink to="#">USDC</NavLink> withdrawal activity
            today.
          </WidgetTitle>
        </>
      )}

      {(category === "nft" || category === "") && (
        <>
          <WidgetTitle className=" add-fade-to-top">
            60% of NFTs fell below mint price the past week.
          </WidgetTitle>
          <WidgetTitle>
            High NFT selling from big holders this week.
          </WidgetTitle>
        </>
      )}

      {category === "dao" && (
        <>
          <WidgetTitle className="add-fade-to-top">
            Voting participation spiked 30% this week.
          </WidgetTitle>
          <WidgetTitle>
            Users actively vote on *The Graph* proposals
          </WidgetTitle>
        </>
      )}
    </>
  );
};

export default HighlightTopics;
