import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";
import { Chip } from "@material-ui/core";

export const ChipContent = styled(Chip)({
  border: "0",
  marginRight: "7px",
  marginBottom: "8px",
  height: "23px!important",
  backgroundColor: theme.colors.darkF5F5,
  ".MuiChip-label": {
    color: theme.colors.tertiary,
    fontFamily: theme.weight.medium2,
  },
});
export const FilterItem = styled(Chip)({
  border: "0",
  marginRight: "7px",
  background: "none",
  height: "23px!important",
  color: theme.colors.dark7474,
  "&::hover": {
    background: "none",
  },
  ".MuiChip-label": {
    overflow: "inherit",
    paddingRight: "25px",
    color: theme.colors.dark7474,
    fontFamily: theme.weight.medium2,
    ".counter": {
      ".MuiBadge-badge": {
        top: "-1px",
        right: "-14px",
        fontSize: "10px",
        height: "13px!important",
        color: theme.colors.contrast,
        background: theme.colors.dark7474,
      },
    },
  },
});

export const FilterCheck = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 1px",
  height: "30px",
  "label, .filter-item": {
    cursor: "pointer",
    border: "0",
    background: "none",
    fontSize: "12px",
    height: "23px!important",
    color: theme.colors.dark7474,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 5px",
    fontFamily: theme.weight.medium2,
    p: {
      marginLeft: "5px",
      padding: "1px 4px",
      fontSize: "10px",
      borderRadius: "7px",
      color: theme.colors.contrast,
      background: theme.colors.dark7474,
    },

  },


  input: {
    display: "none",
  },
  "input[type=radio]:checked + label": {
    color: theme.colors.primary,
  },
  ".filter-profile": {},
  "input[type=checkbox]:checked + label": {
    color: theme.colors.primary,
  },
  "input[type=checkbox]:checked + label > p": {
    background: theme.colors.primary,
  },
});

export const TagsSelection = styled.div({
  cursor: "pointer",
  border: "0",
  padding: "0 10px",
  fontSize: "15px",
  marginRight: "8px",
  marginBottom: "8px",
  alignItems: "center",
  display: "flex",
  borderRadius: "30px",
  height: "23px!important",
  fontFamily: theme.weight.medium2,
});
