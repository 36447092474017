import styled from "@emotion/styled";
import { theme } from "../../../../../theme/theme";

export const PictureNft = styled.div`
  position: relative;
  width: 28%;
  display: flex;
  align-items: center;
  a {
    display: inline-block !important;
    width: 300px;
    height: 300px;
  }
  img.img-nft-assets {
    width: 100%;
    height: 100%;
    margin: auto;
    display: block;
    object-fit: contain;
  }
  @media screen and (max-width: ${theme.breakpoint.tablet}) {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
`;
