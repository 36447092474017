import { FC } from "react";

import {
  shortAddress,
  ethscanAddressLink,
} from "../../../../../services/formatter";
import ethIcon from "../../../../../../images/eth.png";

interface NftProps {
  item: any;
  i: number;
  searchId?: string;        
}

const NFT: FC<NftProps> = ({ item, i }) => {
  const { address, image_url, name, permalink, flatten_traits, owner } =
    item.document;

  let ownerData = owner;

  if (
    !ownerData ||
    ownerData?.address === "0x0000000000000000000000000000000000000000" ||
    ownerData?.address === ""
  ) {
    ownerData = {};
    ownerData.address = "";
    ownerData.address_url = "#";
  } else {
    ownerData.address_url = ethscanAddressLink(owner.address);
    ownerData.address = shortAddress(ownerData.address);
  }

  function Permalink(props: any) {
    const permalink = props.permalink;
    let domain = new URL(permalink);
    let domainName = domain.hostname.replace("www.", "");
    return (
      <span>
        <a href={permalink} rel="noreferrer" target="_blank">
          {domainName}
        </a>
      </span>
    );
  }
  function Characteristics(props: any) {
    const flatten_traits = props.flatten_traits;
    const highlights = props.highlights;
    if (flatten_traits !== "") {
      let data = flatten_traits;
      for (let key in highlights) {
        let matched_tokens = highlights[key]["matched_tokens"];
        for (let itemKey in matched_tokens) {
          let word = matched_tokens[itemKey];
          data = data.replace(
            word,
            '<strong style="color: #100b0b">' + word + "</strong>"
          );
        }
      }
      return (
        <tr>
          <td>Characteristics</td>
          <td dangerouslySetInnerHTML={{ __html: data }}></td>
        </tr>
      );
    }
    return null;
  }
  return (
    <div className="search-list__item nft" key={`search-result${i}`}>
      <div className="nft__image">
        <img src={image_url} alt="" height="150" />
      </div>

      <div className="nft__content-details">
        <span className="nft__content-details--id">
          {name} {/*shortAddress(token_id)*/}
        </span>
        <table>
          <tbody>
            <tr>
              <td>Creator</td>
              <td>
                <div className="u-color-primary">{name}</div>
              </td>
            </tr>
            <tr>
              <td>Owner</td>
              <td>
                {ownerData?.user?.username && (
                  <a href={ownerData.address_url} target="_blank" rel="noreferrer">
                    {ownerData.user.username} {ownerData.address}
                  </a>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <img src={`${process.env.PUBLIC_URL + ethIcon}`} alt="" />
              </td>
              <td>
                <a href={ethscanAddressLink(address)}>
                  {shortAddress(address)}
                </a>
              </td>
            </tr>
            <Characteristics
              flatten_traits={flatten_traits}
              highlights={item.highlights}
            />
            <tr>
              <td>Current price </td>
              <td>
                <span>250.00</span> ETH ($740,080)
              </td>
            </tr>
            <tr>
              <td>You can buy at </td>
              <td>{permalink && <Permalink permalink={permalink} />}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NFT;
