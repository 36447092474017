import { Text } from "../app/components/ui/styled/index";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { assets } from "../app/models/company_asset";

const AboutPage = () => {
  const { pathname } = useLocation();
  return (
    <div className="about-search-page">
      <NavLink
        to={`/`}
        activeClassName={pathname === "/" ? "active-menu-search " : ""}
      >
        {" "}
        <img src={`${process.env.PUBLIC_URL + assets.logo}`} alt="logo" />
      </NavLink>

      <div className="container about">
        <Text
          color="dark"
          className="about__title"
          style={{ fontSize: "18px " }}
        >
          What’s happening on-chain
        </Text>
        <Text className="about__description">
          Openstory is not a siloed API aggregator, a hand-picked catalog, or a
          centralized recommendation web2 service.
          <br />
          <br />
          Openstory is an <b>indexer</b> that focuses on scanning and unveiling
          on-chain truth like a story so everyone can easily make decisions
          based on objective information.
          <br />
          <br />
          What you see in the platform is not a recommendation from an employee
          or the curated information of APIs; instead, our database starts and
          ends with indexing the public on-chain information, offering a pure
          outcome of how people use and interact with web3.
        </Text>
        <br />
        <br />
        <br />
        <Text color="dark" className="about__title">
          How to use Openstory
        </Text>
        <Text className="about__description">
          The initial token/NFT sale can be the beginning of the experience, not
          the end.
          <br />
          <br />
          A creator or a brand can use Openstory to see how web3 users
          use/interact with their creations, spot their audience (who is buying
          and what’s their engagement) and reward them accordingly.
          <br />
          <br />
          A collector can use Openstory to assess if an NFT is worth buying
          based on usage/investors attracted, discover the up-and-coming
          collections, meet other collectors/soulmates, and create deeper
          relationships with the artists they love.
          <br />
          <br />
          A flipper can discover the most recent collections that grow
          exponentially.
          <br />
          <br />
          Everyone can check key market metrics, e.g., active users in the
          market, market growth, and marketplaces with the highest traction, so
          they have a better picture of the market they invest their money and
          time.
        </Text>
        <br />
        <br />
        <Text color="dark" className="about__title">
          How challenging is this?
        </Text>
        <Text className="about__description">
          Mapping web3 is both a data intelligence challenge and a UX challenge.
          How do we display the information meaningfully and drive usage beyond?
          How deep do we go? Your feedback is essential to its success, so we'd
          love you to be part of this journey early on.
        </Text>
        <br />
        <a
          href="{ assets.discord }"
          className="btn-get-access"
          style={{ marginBottom: "3em" }}
        >
          Get early access
        </a>
        <Text color="dark" className="about__title">
          Why on-chain data and not marketplace APIs?
        </Text>
        <Text className="about__description">
          The information that marketplaces display/offer is highly curated for
          competitive reasons. For example, a marketplace doesn’t provide the
          entire history of an asset (transfers, sells etc.). This is valuable
          hidden information that could be used to give more intelligence.
        </Text>
      </div>
    </div>
  );
};

export default AboutPage;
