import { FC, useEffect, useState, KeyboardEvent, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { VscSearch } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  querySearch,
  suggestion,
  InputType,
  getPopularTopics,
  SearchInputParameter,
  parseQuery,
  SearchResult,
} from "../../../models/search";

import { assets } from "../../../models/company_asset";
import isoType from "../../../../images/isotype.svg";
import backIcon from "../../../../images/back-search.svg";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { TextField, useMediaQuery } from "@material-ui/core";

import { Subject } from "rxjs";
import { isHomePath } from "../../../services/navigation";

export const querySubject = new Subject<string>();

export type SearchStrategy = "jump" | "inline";

interface SearchBarProps {
  initInput?: string;
  strategy?: SearchStrategy;

  onSearchDone?: ({
    data,
    currentPage,
  }: {
    data: SearchResult;
    currentPage: number;
  }) => void;
  onInlineFilterSearch?: (q: SearchInputParameter) => Promise<void>;
  onNavigationSearch?: (q: SearchInputParameter) => Promise<void>;

  onStartSearch?: (q: SearchInputParameter) => Promise<void>;
  onEndSearch?: (q: SearchInputParameter) => Promise<void>;
}

const defaultSuggestQuery = [
  {
    title: "ens activity",
  },
  {
    title: "defi activity",
  },
  {
    title: "nft activity",
  },
  {
    title: "dao activity",
  },
  {
    title: "exchange activity",
  },
];

export const SearchBar: FC<SearchBarProps> = ({
  initInput,
  strategy,
  onSearchDone,
  onInlineFilterSearch,
  onStartSearch,
  onEndSearch,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [isHome, setIsHome] = useState<boolean>(
    isHomePath(location.pathname, location.search)
  );

  const params = new URLSearchParams(location.search);
  const qs = params.get("query");
  const isMobile = useMediaQuery("(max-width: 992px)");
  useEffect(() => {
    const isHome = isHomePath(location.pathname, location.search);
    setIsHome(isHome);
  }, [location]);

  const [suggestionCount, setSuggestionCount] = useState<number>(1);
  const inputRef = useRef();
  const [userInputQuery, setUserInputQuery] = useState<string>(initInput || "");

  useEffect(() => {
    setUserInputQuery(initInput || "");
  }, [initInput]);

  const [searchId, setSearchId] = useState<string>(
    params.get("search_id") || ""
  );
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(params.get("page") || "0")
  );

  const [suggestQueries, setSuggestQueries] = useState<any[]>([]);

  const cacheSuggest = useRef({});

  const asynSuggest = async (q: string): Promise<void> => {
    if (!q || q.trim() === "") {
      const defaultSuggest = await getPopularTopics();
      setSuggestQueries(
        defaultSuggest.map((topic) => {
          return { title: topic.term };
        })
      );

      return Promise.resolve();
    }

    if (cacheSuggest.current[q] && cacheSuggest.current[q].length >= 1) {
      setSuggestQueries(cacheSuggest.current[q]);
      return;
    }

    const whichRequest = suggestionCount + 1;
    setSuggestionCount(suggestionCount + 1);

    try {
      const resp = await suggestion(q, whichRequest);
      if (resp.count === whichRequest) {
        cacheSuggest.current[q] = resp.data?.map((x) => {
          return { title: x };
        });
        setSuggestQueries(cacheSuggest.current[q]);
      }
    } catch (e) {
      console.log("error fetching auto suggest", e);
    }
  };

  useEffect(() => {
    const unsub = querySubject.subscribe({
      next: (data: string) => {
        setUserInputQuery(data);
      },
    });

    if (params.has("query")) {
      if (params.get("query") !== "" && params.get("query") !== "undefined") {
        querySubject.next(params.get("query"));
      }
    }

    return () => {
      unsub.unsubscribe();
    };
  }, []);

  const updateURL = (queryString: string) => {
    let searchParams = [];
    if (queryString && queryString !== "" && queryString !== "undefined") {
      searchParams.push("query=" + queryString);
    }
    if (searchId && searchId !== "") {
      searchParams.push("search_id=" + searchId);
    }

    if (currentPage) {
      if (searchId !== "") {
        // when search id is empty, we can assume the search is reset
        // TODO: need to improve searchid, current page number state management
        searchParams.push(`page=${currentPage}`);
      }
    }

    history.push(`/?${searchParams.join("&")}`);
  };

  useEffect(() => {
    if (qs && qs !== "" && qs !== "undefined" && qs !== "null") {
      updateURL(qs);
      handleSearch({ qs, searchId, page: currentPage });
    }
  }, [currentPage]);

  const handleTyping = async (q: string) => {
    if (!q || q === "") {
      setOpen(false);
    }
    if (isMobile && (q === "" || !q)) {
      setOpen(true);
      setIsHome(false);
    }
    // when input change, reset search id
    setSearchId("");
    //querySubject.next(q);
    setUserInputQuery(q || "");

    asynSuggest(q);
  };

  const [queryInputType, setQueryInputType] = useState<InputType>(
    InputType.Freeform
  );

  const handleSearch = async (params: SearchInputParameter) => {
    if (strategy === "jump") {
      window.location = `/?query=${encodeURIComponent(params.qs)}`;
      return;
    }

    const { qs, page, searchId } = Object.assign(
      { page: 1, searchId: "" },
      params || {}
    );

    if (!qs || qs === "") {
      console.log("skip search because query string is empty", qs);
      return;
    }

    const _q = parseQuery(qs);
    switch (_q?.type) {
      case InputType.InlineFilter:
        if (onInlineFilterSearch) {
          return await onInlineFilterSearch(_q);
        }
      case InputType.NavigationTerm:
        window.location = _q.canonical_form;
        return;
      default:
      // This will trigger the normal search logic
    }

    onStartSearch && (await onStartSearch(params));
    setCurrentPage(page);
    setSearchId(searchId);
    querySubject.next(qs);

    try {
      setOpen(false);
      // TODO: when click on pagination, update setCurrentPage, then call handleSearch, or just pass page number into this function
      const data = await querySearch({
        q: qs,
        input_type: queryInputType,
        search_id: searchId || "",
        page: page || 1,
      });

      onSearchDone && onSearchDone({ data, currentPage });

      // when getting back response, take the search id and update our state
      if (data.search_id) {
        setSearchId(data.search_id);
      }
      updateURL(qs);
      if (data.data !== null) {
        setIsHome(false);
      } else {
        setIsHome(false);
        setOpen(false);
        // const inputFocus = document.querySelector("#inputFocus");
        // inputFocus.blur();
      }
    } catch (e) {
      console.log("search error", e);
    } finally {
      onEndSearch && onEndSearch(false);
      // const inputFocus = document.querySelector("#inputFocus");
      // inputFocus.blur();
      window.scrollTo(0, 0);
      console.log("perform search done at", new Date().getTime());
    }
  };

  const [open, setOpen] = useState(false);
  const onClearWords = () => {
    querySubject.next("");
  };

  const handleSearchKeyDown = (
    evt: KeyboardEvent<HTMLInputElement>,
    qs: string
  ) => {
    // Don't bubdle this event so formw on't be sumit
    // we will handle the behaviour ourself

    if (evt.key === "Enter") {
      evt.stopPropagation();
      setSearchId(null);
      setCurrentPage(1);
      handleSearch({ qs });
    } else {
      setQueryInputType(InputType.Freeform);
    }
  };

  const onFocus = (e: any) => {
    if (isMobile) {
      setIsHome(false); // hace que el search bar suba
    }
    if (e.target.value === "") {
      asynSuggest("");
      return;
    }

    const mediaQuery = window.matchMedia("(max-width: 992px)").matches;

    if (mediaQuery) {
      if (e.target.value === "") {
        setOpen(true);
      }

      window.scroll(0, 0);
      setIsHome(false);
    }
  };

  const onblur = () => {
    if (isMobile && (qs === "" || !qs)) {
      setOpen(true);
      setIsHome(false);
    } else {
      setOpen(false);
    }
  };

  const handleBackHome = () => {
    if (isMobile) {
      setOpen(false);
      setIsHome(true);
      if (qs) {
        setOpen(false);
        setIsHome(false);
      }
    }
  };

  const searchElement = useRef();
  useEffect(() => {
    if (isHome) {
      const scrollHandling = () => {
        if (window.scrollY >= 1) {
          searchElement.current?.classList.add("height-search-box");
          searchElement.current?.classList.add("sticky-search-bar");
        } else {
          searchElement.current?.classList.remove("height-search-box");
          searchElement.current?.classList.remove("sticky-search-bar");
        }
      };
      window.addEventListener("scroll", scrollHandling);
      return () => window.removeEventListener("scroll", scrollHandling);
    }
  }, []);

  return (
    <div
      ref={searchElement}
      className={`search-component ${!isHome && "height-search-box"} `}
    >
      <div className="u-display-flex search-component__search-bar">
        <form
          className="search-component__search"
          action="."
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <a href="/" className="nocliking">
            <img
              src={`${process.env.PUBLIC_URL + assets.logo}`}
              className="logo-search"
              alt={assets.companyName}
            />
          </a>

          <Autocomplete
            freeSolo
            autoSelect={false}
            open={open}
            clearOnBlur
            autoComplete
            id="inputFocus"
            disableClearable={false}
            handleHomeEndKeys
            onBlur={(e) => onblur(e)}
            options={[...defaultSuggestQuery, ...suggestQueries]}
            onFocus={(e) => onFocus(e)}
            onOpen={() => setOpen(true)}
            className="autocomplete-items"
            getOptionLabel={(option) => option.title || ""}
            onKeyUp={(e) => handleSearchKeyDown(e, userInputQuery)}
            onInputChange={(event) => {
              if (event?.target) {
                handleTyping(event.target?.value);
              }
            }}
            onChange={(event, newValue) => {
              if (!newValue) {
                querySubject.next("");
                return;
              }

              if (typeof newValue === "string") {
                querySubject.next(newValue);
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                querySubject.next(newValue.inputValue);
              } else {
                if (newValue?.title) {
                  querySubject.next(newValue.title);
                  handleSearch({ qs: newValue.title });
                }
              }
            }}
            renderInput={(params) => {
              params.inputProps.value = userInputQuery || "";
              return (
                <div className="search-component__input box-input-content">
                  <a href="/" className="isotype-logo-link">
                    <img src={isoType} alt="" className="isotype-logo" />
                  </a>

                  {userInputQuery && (
                    <VscChromeClose
                      onClick={onClearWords}
                      className="clean-word u-zindex-99"
                    />
                  )}

                  <VscSearch
                    className="u-cursor-pointer u-zindex-99"
                    onClick={(e) => handleSearch({ qs: userInputQuery })}
                  />
                  <TextField
                    {...params}
                    type="search"
                    placeholder="search web3"
                    className="hide-clear"
                    value={userInputQuery}
                    inputRef={inputRef}
                  />
                </div>
              );
            }}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.title, inputValue);
              const parts = parse(option.title, matches);

              return (
                <ul>
                  <li>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        className={`${!part.highlight && "active-bold"}`}
                      >
                        {part.text}
                      </span>
                    ))}
                  </li>
                </ul>
              );
            }}
          />
        </form>
        {open && (
          <button
            className="button-back-home"
            onClick={() => {
              handleBackHome();
            }}
          >
            <img src={backIcon} alt="" />
          </button>
        )}
      </div>
    </div>
  );
};
