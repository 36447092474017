import { FC, useEffect, useState } from "react";
import TokenList from "../app/components/pages/search/widgets/tokens-list";
import ProfileInfo from "../app/components/pages/profiles/profile";
import ProfileActivity from "../app/components/pages/profiles/profile-activity";
import { getProfileAddress, Profile } from "../app/models/search";
import { useParams } from "react-router-dom";
import NftAssetsList from "../app/components/pages/search/widgets/nft-assets-list";
import dayjs from "dayjs";
import { SectionTitle } from "../app/components/pages";
import Skeleton from "@material-ui/lab/Skeleton";
import CollapseWidget from "../app/components/pages/search/widgets/collapse-widget";
import SearchDropdown from "../app/components/SearchDropdown";
import { Loader } from "../app/components/ui/Loader";

interface Profiles0PageProps {}

const Profiles0Page: FC<Profiles0PageProps> = () => {
  const params = useParams();
  const [pending, setPending] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);

  const tokenLength = profile?.portfolio?.filter(
    (p) => p.token_symbol !== ""
  ).length;

  const asyncActivities = async () => {
    setPending(true);
    try {
      const dataProfile = await getProfileAddress(params.address);
      setProfile(dataProfile);
    } catch (err) {
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    asyncActivities();
  }, [params.address]);

  return (
    <>
      <SearchDropdown
        initInput={profile?.ens || profile?.address || params.address}
      />

      <hr className="line-bottom" />
      <div className="content-min-height">
        {pending ? (
          <div className="loader-results">
            <Loader color="primary" />
          </div>
        ) : (
          <>
            {" "}
            <div className="u-no-padding">
              <div className="update-ago">
                {pending ? (
                  <Skeleton width={120} height={25} variant="text" />
                ) : (
                  profile && `updated ${dayjs(profile.updated_at).fromNow()} `
                )}
              </div>

              {pending && (
                <div className="profile-info">
                  <div className="loader-tags">
                    <Skeleton variant="circle" width={80} height={80} />
                    <div className="skeleton-group">
                      <Skeleton variant="text" width={250} height={25} />
                      <Skeleton variant="text" width={200} height={25} />
                      <Skeleton variant="text" width={150} height={25} />
                    </div>
                  </div>
                </div>
              )}

              {profile && <ProfileInfo profile={profile} />}
            </div>
            <div className="profile-portfolio">
              <div className="profile-portfolio__left">
                <div className="token-list">
                  <CollapseWidget
                    title={`Tokens${tokenLength ? ` (${tokenLength})` : ""}`}
                    mobilePadding={0}
                    showDivider={false}
                  >
                    <div className="collapse-box">
                      {profile?.portfolio && (
                        <TokenList tokens={profile?.portfolio} />
                      )}
                      {profile?.portfolio === null && (
                        <p className="empty-data">No token</p>
                      )}
                      {pending && (
                        <div className="profile-info__nfts">
                          {[0, 1, 2].map((y) => (
                            <div className="loader-tags" key={y}>
                              <Skeleton
                                variant="circle"
                                width={40}
                                height={40}
                              />
                              <Skeleton
                                variant="text"
                                width={200}
                                height={45}
                                className="u-margin-left-1"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </CollapseWidget>
                </div>
                <div className="nft-widget">
                  <CollapseWidget
                    title={`NFTs${
                      profile?.nft_assets?.length
                        ? ` (${profile.nft_assets.length})`
                        : ""
                    }`}
                    mobilePadding={0}
                    showDivider={false}
                  >
                    <div className="collapse-box">
                      {profile?.nft_assets && (
                        <NftAssetsList assets={profile.nft_assets} />
                      )}

                      {(profile?.nft_assets === null ||
                        profile?.nft_assets.length === 0) && (
                        <p className="empty-data">No NFT</p>
                      )}
                      {pending && (
                        <div className="profile-info__nfts">
                          {[0, 1, 2].map((y) => (
                            <div className="loader-tags" key={y}>
                              <Skeleton variant="text" width={60} height={60} />
                              <Skeleton
                                variant="text"
                                width={200}
                                height={60}
                                className="u-margin-left-1"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </CollapseWidget>
                </div>
              </div>
              <div className="profile-portfolio__center">
                <div className="activity-profile">
                  <SectionTitle>What’s happening</SectionTitle>
                  <ProfileActivity profile={profile} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Profiles0Page;
