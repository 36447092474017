import { FC } from "react";
import AddressComponent from "../address-component/address-component";

interface TopHoldersWrapperProps {
  searchResults: any;
}

const TopHoldersWrapper: FC<TopHoldersWrapperProps> = ({ searchResults }) => {

  return (
    <>
       {searchResults.data.map((x: any, i: number) => (
        <AddressComponent key={i} item={x} position={i} variant="full"/>
      ))} 
    </>
  );
};

export default TopHoldersWrapper;
