import { FC, useState, useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { AiOutlineFilter } from "react-icons/ai";
import { useLocation } from "react-router-dom";

interface FilterContractProps {
  contractCategories: any;
  onChange: (model) => void;
  initialFilter: any;
}

const FilterContract: FC<FilterContractProps> = ({ contractCategories, onChange, initialFilter }) => {
  const [filter, setFilter] = useState(initialFilter);

  const changeContractName = (e) => {
    if (e.target.value !== "") {
      setFilter({...filter, name: e.target.value})
      onChange({...filter, name: e.target.value})
    } else {
      delete filter['name'];
      setFilter({...filter});
      onChange({...filter});
    }
  }
  const changeName = (e) => {
    if (e.target.value !== "") {
      setFilter({...filter, token: e.target.value})
      onChange({...filter, token: e.target.value})
    } else {
      delete filter['token'];
      setFilter({...filter});
      onChange({...filter});
    }
  }

  const changeStatus = (e) => {
    if (e.target.value !== "" && e.target !== "any") {
      setFilter({...filter, contract_status: e.target.value})
      onChange({...filter, contract_status: e.target.value})
    } else {
      delete filter['contract_status'];
      setFilter({...filter});
      onChange({...filter});
    }
  }

  const changeCategory = (e) => {
    if (e.target.value !== "" && e.target !== "any") {
      setFilter({...filter, contract_category: e.target.value})
      onChange({...filter, contract_category: e.target.value})
    } else {
      delete filter['contract_category'];
      setFilter({...filter});
      onChange({...filter});
    }
  }

  const changeInput = (e) => {
   if (e.target.value !== "" && e.target.value !== "any") {
      setFilter({...filter, [e.target.name]: e.target.value})
      onChange({...filter, [e.target.name]: e.target.value})
    } else {
      delete filter[e.target.name];
      setFilter({...filter});
      onChange({...filter});
    }

  }

  const location = useLocation();
  useEffect(() => {
    let o = {};
    let qs = new URLSearchParams(location.search);
    if (qs.get("token")) {
      o.token = qs.get("token");
    }

    if (qs.get("name") && qs.get('name') !== "") {
      o.name = qs.get("name");
    } else {
      delete o['name'];
    }

    if (qs.get('contract_status') && qs.get('contract_status') !== 'any') {
      o.contract_status = qs.get('contract_status');
    } else {
      delete o['contract_status'];
    }

    if (qs.get('contract_category') && qs.get('contract_category') !== 'any') {
      o.contract_category = qs.get('contract_category');
    } else {
      delete o['contract_category'];
    }

    setFilter(o);
    onChange(o);
  }, [location.search])

  return (
    <div className="u-margin-bottom-1 filter-admin-content">
      <span>
        <AiOutlineFilter /> Filter
      </span>
      <div>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            label="Contract name/address"
            variant="standard"
            onChange={changeContractName}
            value={filter?.name || "" }
          />
        </FormControl>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            id="standard-basic"
            label="Token name/address"
            variant="standard"
            onChange={changeName}
            value={filter?.token || "" }
          />
        </FormControl>

        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <InputLabel id="demo-simple-select-standard-label">
            Status{" "}
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            value={filter?.contract_status ? filter.contract_status : "any"}
            label="Contract Status"
            onChange={changeStatus}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"confirmed"}>Confirm</MenuItem>
            <MenuItem value={"pending_review"}>Pending Review</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="u-margin-right-ui-1 u-width-fc-400">
          <InputLabel id="demo-simple-select-standard-label">
            Category
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            value={filter?.contract_category ? filter.contract_category : "any"}
            label="Category"
            onChange={changeCategory}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            {contractCategories.map((cat: any) => (
              <MenuItem key={`cat${cat.label}`} value={cat.value}>{cat.label}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="u-margin-right-ui-1 u-width-fc-600">
          <InputLabel id="demo-simple-select-standard-label">
            Classified By
          </InputLabel>
          <Select
            name='classified_by'
            value={filter?.classified_by ? filter.classified_by : "any"}
            label="Classified"
            onChange={changeInput}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"human"}>Human</MenuItem>
            <MenuItem value={"machine"}>Machine</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default FilterContract;
