import { FC, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  FormControl,
  FormLabel,
  Button,
  Grid,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";

import {
  getQuestion,
  updateQuestion,
  deleteQuestion,
} from "../../app/models/question";

import { toast } from "react-toastify";

interface QuestionsDetailsPageProps {}

const QuestionsDetailsPage: FC<QuestionsDetailsPageProps> = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& > *": {
          margin: theme.spacing(1),
        },
      },

      spacing: {
        margin: "0.25rem",
      },

      table: {
        minWidth: 300,
        maxHeight: 400,
        overflow: "scroll",
        border: "1px solid rgba(255, 255, 255, 0.12)",
      },
    })
  );

  const [, setLoading] = useState<"ok" | "loading">("ok");
  const history = useHistory();
  const classes = useStyles();
  const { questionId } = useParams();
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    getQuestion(questionId).then(setQuestion);
  }, [questionId]);

  const handleUpdateQuestion = () => {
    setLoading("loading");

    updateQuestion("/questions/" + question.id, question)
      .then(() => {
        toast.success("update succeeded", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      })
      .catch((e) =>
        toast.error("error saving" + e, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        })
      );
  };

  const handleDelete = () => {
    deleteQuestion("/questions/" + question.id)
      .then(() => {
        history.push("/admin/questions");
        toast.success("delete succeeded", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      })
      .catch((e) =>
        toast.error("error delete" + e, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        })
      );
  };

  return (
    question && (
      <form className={classes.root} noValidate autoComplete="off">
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            label="Twitter Account"
            variant="standard"
            value={question?.twitter_account}
            onChange={(e) =>
              setQuestion({ ...question, twitter_account: e.target.value })
            }
            name="twitter"
          />
        </FormControl>

        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            label="ENS"
            variant="standard"
            value={question?.ens}
            onChange={(e) => setQuestion({ ...question, ens: e.target.value })}
            name="ens"
          />
        </FormControl>
        <br />
        <FormControl component="fieldset">
          <FormLabel component="legend">Query</FormLabel>
          <TextareaAutosize
            cols={50}
            maxRows={5}
            minRows={3}
            placeholder=""
            name="query"
            onChange={(e) =>
              setQuestion({ ...question, query: e.target.value })
            }
          >
            {question.query}
          </TextareaAutosize>
        </FormControl>

        <ul>
          <li>click_through: {question.click_through}</li>
          <li>hit_miss: {question.hit_miss}</li>
        </ul>

        <Grid
          container
          direction="row"
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUpdateQuestion}
            >
              Update
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="primary" onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        </Grid>
      </form>
    )
  );
};

export default QuestionsDetailsPage;
