import React from "react";
import ReactDOM from "react-dom";
//import reportWebVitals from "./reportWebVitals";
import Routing from "./app/routes/routing";

import "./styles/global.scss";

import { setupApp } from "./app/services/init";
import { ApplicationConfigProvider } from "./app/providers/config";

import { theme } from "./app/theme/mui-theme";
import { MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "@emotion/react";

setupApp();
ReactDOM.render(
  <ApplicationConfigProvider>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Routing />
      </ThemeProvider>
    </MuiThemeProvider>
  </ApplicationConfigProvider>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
