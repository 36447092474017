export interface AddressData {
  wallet_address?: string;
  tags: [];
  tag_amount: [];
  collection_name: string;
  nft_assets: NFTAssets;
  action_label: [];
  time_ago: string;
  activity: string;
}
export interface NFTAssets {
  image: string;
  price: string | number;
}

export const TabCategory = [
  {
    tab: "Most active",
    value: "mostactive",
  },
  {
    tab: "Emerging",
    value: "emerging",
  },
  {
    tab: "Following",
    value: "following",
  },
];

export const ProfileTabCategory = [
  {
    key: "mystory",
    label: "My story",
    value: "mystory",
    recentCounter: 0,
  },
  {
    key: "followers",
    label: "Followers",
    value: "followers",
    recentCounter: 55,
  },
  {
    key: "following",
    label: "Following",
    value: "following",
    recentCounter: 10,
  },
];

export const temp_data_filter = [
  { key: "creator", name: "creator" },
  { key: "manifoldCreator", name: "manifold creator" },
  { key: "pfpSerialMinter", name: "pfp minter" },
  { key: "collector", name: "collector" },
  { key: "newcomer", name: "newcomer" },
  { key: "devBuilder", name: "dev builder" },
  { key: "flipper", name: "flipper" },
  { key: "whale", name: "whale" },
  { key: "blueChipper", name: "blue chipper" },
];

export const temp_data_my_collectors = [
  {
    address: "0x33e07007f6500decc32d0adad8ba726f4c25aa1d",
    ens: "",
    tags: ["collector", "pfp minter"],
    emoji: "fire",
    action_label: "bought my first item.",
  },
  {
    address: "0x11e4388c68262777c80ab1c9c0acccd3a2df2b91",
    ens: "",
    tags: ["collector"],
    emoji: "fire",
    action_label: "bought my first item.",
  },
  {
    address: "0x77e4388c68262777c80ab1c9c0acccd3a2df2b91",
    ens: "",
    tags: ["collector"],
    emoji: "",
    action_label: "",
  },
  {
    address: "0x88e4388c68262777c80ab1c9c0acccd3a2df2b91",
    ens: "",
    tags: ["collector", "pfp minter"],
    emoji: "",
    action_label: "",
  },
  {
    address: "0x56e4388c68262777c80ab1c9c0acccd3a2df2b91",
    ens: "tomoyuki.eth",
    tags: ["collector", "creator"],
    emoji: "",
    action_label: "",
  },
];

export const temp_data_images = [
  {
    address: "0xaaa7a35e442a77e37cde2f445b359aabf5ad0387",
    token_id: "2146",
  },
  {
    address: "0x10b366bbf2304b52806b1c9881fc259bd9018d43",
    token_id: "4208",
  },
  {
    address: "0xb16dfd9aaaf874fcb1db8a296375577c1baa6f21",
    token_id: "3983",
  },
  {
    address: "0x7f9306fc1978938d5c1c57bf6044dc67e07eeb8a",
    token_id:
      "0x0000000000000000000000000000000000000000000000000000000000000004",
  },
];

export const _tab = [
  { address: "nerimo.eth", tags: ["creator"] },
  { address: "zoe525.eth", tags: ["newcomer"] },
  { address: "0x2k4", tags: ["creator", "collector"] },
  { address: "master1.eth", tags: ["flipper", "collector"] },
  { address: "0x4g9", tags: ["creator", "collector"] },
];

export const temp_data_following = [
  { address: "元西.eth", tags: ["pfp minter", "collector"] },
  { address: "wintersnow.eth", tags: ["creator"] },
  { address: "0x5m1", tags: ["newcomer"] },
  { address: "0x8r2", tags: ["whale"] },
  { address: "sirmaster.eth", tags: ["collector"] },
];

export const temp_data_followers_tab = [
  {
    address: "0x8156d5b77dfbf997d2c39466fc1c5d9dd478717c",
    ens: "rhinoceros",
    address_type: "",
    created_at: "2022-05-25T08:24:51.340173Z",
    updated_at: "2022-05-25T08:24:51.340173Z",
    first_actived_at: "0001-01-01T00:00:00Z",
    balance: "0",
    asset_type: "1on1",
    tags: ["collector"],
    nft_assets: [
      {
        name: "Tom #142",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "65490000000000000000",
        },
        address: "0x7f9306fc1978938d5c1c57bf6044dc67e07eeb8a",
        token_id:
          "0x0000000000000000000000000000000000000000000000000000000000000004",
      },
      {
        name: "Tom #142",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "65490000000000000000",
        },
        address: "0x7f9306fc1978938d5c1c57bf6044dc67e07eeb8a",
        token_id:
          "0x0000000000000000000000000000000000000000000000000000000000000004",
      },
      {
        name: "NP 579",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "80060000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        token_id:
          "72183888341013080712091146906465839377973816174062378564140460249840363765761",
      },
      {
        name: "HANS #1775",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "80880000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        token_id:
          "12073816454289757124064899170387589683414110958677342989044846242302806458369",
      },
    ],
    poap_assets: null,
    friends: null,
    dao_activities: null,
    stats: {
      nft_minted: 46,
      nft_collected: 134,
      nft_for_sale: 242,
    },
    surrogate_key: 1653467091,
  },
  {
    address: "0x020a2af8b5679dd73c04e97c945765602237955c",
    ens: "turtle",
    address_type: "",
    created_at: "2022-05-25T08:47:57.98342Z",
    updated_at: "2022-05-25T08:47:57.98342Z",
    first_actived_at: "0001-01-01T00:00:00Z",
    balance: "000000054255500",
    asset_type: "pfp",
    tags: ["pfp-minter", "collector"],
    nft_assets: [
      {
        name: "Tom #142",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "646180000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420044cb7b5e",
        token_id:
          "1023210432195743586673817700213854745094151053279783683778741030321859330049",
      },
      {
        name: "Tom #142",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "646180000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420044cb7b5e",
        token_id:
          "1023210432195743586673817700213854745094151053279783683778741030321859330049",
      },
      {
        name: "NP 579",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "476290000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        token_id:
          "72183888341013080712091146906465839377973816174062378564140460249840363765761",
      },
      {
        name: "HANS #1775",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "955270000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        token_id:
          "12073816454289757124064899170387589683414110958677342989044846242302806458369",
      },
    ],
    poap_assets: null,
    friends: null,
    dao_activities: null,
    stats: {
      nft_minted: 11,
      nft_collected: 152,
      nft_for_sale: 434,
    },
    surrogate_key: 1653468477,
  },
  {
    address: "0x74fe8fc1f2199b6ea35ce3fb302f469473342fc5",
    ens: "mole",
    address_type: "",
    created_at: "2022-05-24T06:10:45.437558Z",
    updated_at: "2022-05-24T06:10:46.875142Z",
    first_actived_at: "0001-01-01T00:00:00Z",
    balance: "0",
    asset_type: "pfp",
    tags: ["whale", "pfp-minter", "collector"],
    nft_assets: [
      {
        name: "Tom #142",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "262210000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420042cb7b5e",
        token_id:
          "1023210432195743586673817700213854745094151053279783683778741030321859330049",
      },
      {
        name: "NP 579",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "835600000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        token_id:
          "72183888341013080712091146906465839377973816174062378564140460249840363765761",
      },
      {
        name: "NP 579",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "835600000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        token_id:
          "72183888341013080712091146906465839377973816174062378564140460249840363765761",
      },
      {
        name: "HANS #1775",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "986760000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420041cb7b5e",
        token_id:
          "12073816454289757124064899170387589683414110958677342989044846242302806458369",
      },
    ],
    poap_assets: null,
    friends: null,
    dao_activities: null,
    stats: {
      nft_minted: 48,
      nft_collected: 96,
      nft_for_sale: 21,
    },
    surrogate_key: 1653372645,
  },
  {
    address: "0xf21c1b5d9a1a609ac23ed9b058cfa32be3e54ccc",
    ens: "seal",
    address_type: "",
    created_at: "2022-06-23T09:56:08.576559Z",
    updated_at: "2022-06-23T09:56:08.576559Z",
    first_actived_at: "0001-01-01T00:00:00Z",
    balance: "0",
    asset_type: "1on1",
    tags: ["whale", "collector"],
    nft_assets: [
      {
        name: "Tom #142",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "500260000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045c47b5e",
        token_id:
          "1023210432195743586673817700213854745094151053279783683778741030321859330049",
      },
      {
        name: "NP 579",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "846040000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb8b5e",
        token_id:
          "72183888341013080712091146906465839377973816174062378564140460249840363765761",
      },
      {
        name: "NP 579",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "846040000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb8b5e",
        token_id:
          "72183888341013080712091146906465839377973816174062378564140460249840363765761",
      },
      {
        name: "HANS #1775",
        price: {
          payment_token: {
            address: "0x",
            decimals: 18,
            symbol: "ETH",
          },
          amount: "212460000000000000000",
        },
        address: "0x495f947276749ce646f68ac8c248420045cb7b5e",
        token_id:
          "12073816454289757124064899170387589683414110958677342989044846242302806458369",
      },
    ],
    poap_assets: null,
    friends: null,
    dao_activities: null,
    stats: {
      nft_minted: 17,
      nft_collected: 176,
      nft_for_sale: 476,
    },
    surrogate_key: 1655978168,
  },
];
