import { useState, useEffect } from "react";

export const ClickOutideElement = (el: any, initialState: any) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const pageClickEvent = (e: any) => {
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(true);        
      }
    };

    if (isActive) {
      window.addEventListener("mousedown", pageClickEvent);
    }
    return () => {
      window.removeEventListener("mousedown", pageClickEvent);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};
