import { FC } from "react";
import { WidgetTitle } from "../../index";
interface TopHoldProps {}

const TopHold: FC<TopHoldProps> = () => {
  return (
    <div className="top-hold">
      <WidgetTitle>
        Top 1, 2 and 3 also hold <a href="" className="u-text-no-bold"> Tether</a>
      </WidgetTitle>
    </div>
  );
};

export default TopHold;
