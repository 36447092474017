import React, { FC } from "react";
import { AddressData } from "../../../models/address-data";
import { Text } from "../../ui/styled";
import { FooterContentRow } from "../../ui/styled/header-content-row";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "underscore";
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

interface ActivityRowFooterProps {
  item: any;
  type?: string;
  asset?: string;
  params: any;
}

const ActivityRowFooter: FC<ActivityRowFooterProps> = ({
  item,
  asset,
  type,
  params
}) => {

  const { updated_at } = item

  return (
    <FooterContentRow>
      {type !== "followers" && <><Text weight="light">
        {asset !== "1on1" && (
          <>
            {item[0].tag === "creator" && `created ${item?.length} item${item?.length > 1 ? "s" : ""}`}
            {(item[0].tag === "collector" ||
              item[0].tag === "whale" ||
              item[0].tag === "bluechipper") &&
              `bought ${item?.length} item${item?.length > 1 ? "s" : ""} recently`}
            {(item[0].tag === "minter" || item[0].tag === "pfp-minter") &&
              `minted ${item?.length} item${item?.length > 1 ? "s" : ""} recently`}
            {item[0].tag === "flipper" &&
              `flipped ${item?.length} item${item?.length > 1 ? "s" : ""} recently`}
            {item[0].tag === "dev-builder" &&
              `deployed ${item?.length > 1 ? item.length : 'a'} smart contract${item?.length > 1 ? "s" : ""} recently`}

          </>
        )}
        {asset !== "1on1" && !item[0].tag ? (
          <>
            {item?.length > 0 ? item?.length + " " + "items are for sale." : ""}
          </>
        ) : (
          ""
        )}
      </Text></>}

      {asset === "1on1" ? (
        <>
          {item.slice(0, 1).map((asset_name: any) => (
            <>
              <Text
                weight="light"
                className="u-margin-bottom-1 text-size-oneonone"
              >
                created <b>"{asset_name.name}"</b> for sale on SuperRare.
              </Text>
              <Text weight="light" className="u-margin-bottom-1">
                {dayjs(updated_at).fromNow()}
              </Text>
            </>
          ))}
        </>
      ) : (
        ""
      )}
    </FooterContentRow>
  );
};
export default ActivityRowFooter;
