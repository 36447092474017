import { FC, useState, useEffect, useRef, useCallback } from "react";
import {
  Web3Activity,
  getActivities,
  activityHasNft,
} from "../../../models/activity";

import { Profile } from "../../../models/search";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

import ActivityTitle from "../search/search-results/address-component/activity-title";

import { Loader } from "../../ui/Loader";
import { ActivityBox } from "..";
import { useTransition, animated, config } from "@react-spring/web";
import InfinityScroll from "../../ui/infinite-scroll";

import MultipleNfts from "../search/widgets/multiple-nfts";

interface ProfileActivityProps {
  profile: Profile;
}

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const ProfileStatus: FC<ProfileActivityProps> = ({ profile }) => {
  const { total_activity_30, total_activity_7d } = profile || {};

  return (
    <ActivityBox>
      {!total_activity_7d && !total_activity_30 && (
        <>
          <small className="sleeping-emoji"></small>
          <span>Low</span>
        </>
      )}
      {total_activity_7d ? (
        <>
          {total_activity_7d >= 10 && (
            <>
              <small className="fire-emoji"></small>
              <span>High</span>
            </>
          )}
          {total_activity_7d < 10 && (
            <>
              <small className="normal-emoji"></small>
              <span>Normal</span>
            </>
          )}
        </>
      ) : (
        <>
          {total_activity_30 > 28 && (
            <>
              <small className="sleeping-emoji"></small>
              <span>Low</span>
            </>
          )}
        </>
      )}
      <p className="activity-tag">activity</p>
    </ActivityBox>
  );
};

const ProfileTimeline: FC<ProfileActivityProps> = ({ profile }) => {
  const [pending, setPending] = useState(false);

  const [activities, setActivities] = useState<Web3Activity[]>(
    [] as Web3Activity[]
  );

  const transitions = useTransition(activities, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    delay: 200,
    config: config.molasses,
    //onRest: () => setItems([]),
  });

  const asyncActivities = async () => {
    return await getActivities({wallet_address: profile.address,});
  };

  const fetchMore = async () => {
    if (!activities || activities.length <= 0) {
      return Promise.resolve([]);
    }

    return await getActivities({wallet_address: profile.address, before: activities[activities.length-1].surrogate_key});
  }

  return (
    <div className="activity-profile__logs-activity">
      <InfinityScroll
        activities={activities}
        setActivities={setActivities}
        setPending={setPending}
        pending={pending}

        fetchNew={asyncActivities}
        fetchMore={fetchMore}
      >
        {activities.length >= 1 &&
          transitions(({ opacity }, activity, t, index) => (
            <animated.div
              style={{
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
              }}
            >
              <div className="top-holder-row" key={activity.tx_hash}>
                <div className="top-holder-row__content-item">
                  <div
                    className={`top-holder-row__right u-width-100 u-display-flex u-justify-spacebetween`}
                  >
                    <ActivityTitle item={activity} />

                    <span className="top-holder-row__time-ago profile-time-ago">
                      {activity.block_timestamp &&
                        dayjs(activity.block_timestamp).fromNow()}
                    </span>
                  </div>
                  <div className="profile-nfts">
                    {activityHasNft(activity) && (
                      <MultipleNfts activity={activity} />
                    )}
                  </div>
                </div>
              </div>
            </animated.div>
          ))}
      </InfinityScroll>
    </div>
  );
};

const ProfileActivity: FC<ProfileActivityProps> = ({ profile }) => {
  return (
    <>
      {profile && <ProfileStatus profile={profile} />}
      {profile && <ProfileTimeline profile={profile} />}
    </>
  );
};

export default ProfileActivity;
