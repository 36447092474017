import React from "react";
import Bugsnag from "@bugsnag/js";
import ErrorComponent from "../ui/not-found";

class CatchError extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      catchError: false,
      messageError: "",
    };
  }

  componentDidCatch(error) {
    Bugsnag.notify(error);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // in dev mode, we will throw error out
      throw error;
    }
  }

  static getDerivedStateFromError(error: any) {
    return { catchError: true, messageError: error.message };
  }

  render() {
    if (this.state.catchError) {
      return (
        <div className="container u-padding-mobil-top-3">
          <ErrorComponent
            title="Error!"
            description="An error occurred. Our team is notified"
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default CatchError;
