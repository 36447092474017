import React, { useState } from "react";
import { DataGrid, GridColDef } from "@material-ui/data-grid";

import Button from "@material-ui/core/Button";
import { Token, isTokenPending, isTokenRejected } from "../../app/models/token";
import FilterToken from "./components/filter-token";

import { listResources } from "../../app/models/admincrud";
import { chainNameFromID } from "../../app/models/chain";
import {
  createTheme,
  lighten,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme: Theme) => {
    const getBackgroundColor = (color) => lighten(color, 0.6);

    const getHoverBackgroundColor = (color) => lighten(color, 0.5);

    return {
      root: {
        "& .token-theme--Open": {
          backgroundColor: getBackgroundColor(theme.palette.info.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
          },
        },
        "& .token-theme--Filled": {
          backgroundColor: getBackgroundColor(theme.palette.success.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(
              theme.palette.success.main
            ),
          },
        },
        "& .token-theme--PartiallyFilled": {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(
              theme.palette.warning.main
            ),
          },
        },
        "& .token-theme--Rejected": {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
      },
    };
  },
  { defaultTheme }
);

const columns: GridColDef[] = [
  {
    field: "action",
    headerName: "Action",
    description: "Action",
    sortable: false,
    editable: false,
    filterable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <>
        <Button
          variant="contained"
          size="small"
          href={`/admin/tokens/${params.row.id}`}
        >
          Details
        </Button>
        &nbsp;
        <Button
          variant="contained"
          size="small"
          href={`/admin/contracts/?token=${params.row.address}`}
        >
          Contracts
        </Button>
      </>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    description: "Name",
    width: 120,
    editable: true,
    filterable: true,
  },
  {
    field: "symbol",
    headerName: "Symbol",
    description: "Symbol",
    width: 150,
    editable: true,
    filterable: true,
  },
  {
    field: "status",
    headerName: "Status",
    description: "Status",
    width: 130,
    editable: true,
    filterable: true,
  },
  {
    field: "chainid",
    headerName: "Chain",
    description: "Chain ID",
    width: 120,
    editable: true,
    filterable: true,
    renderCell: (params: GridRenderCellParams) => (
      <span>{chainNameFromID(params.value)}</span>
    ),
  },
  {
    field: "address",
    headerName: "Token Address",
    width: 350,
    editable: true,
    filterable: true,
    renderCell: (params: GridRenderCellParams) => (
      <a
        href={`https://etherscan.io/address/${params.value}#code`}
        target="_blank"
        rel="noreferrer"
      >
        {params.value}
      </a>
    ),
  },
];

export interface TokenListPageProps {}

const TokenListPage: FC<TokenListPageProps> = () => {
  const listPath = "tokens";

  let history = useHistory();

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<Token[]>([]);
  const [page, setPage] = useState(0);

  const onFilterChange = (filterModel) => {
    history.push("?" + new URLSearchParams(filterModel).toString());
    handlePageChange(page, filterModel);
  };

  const handlePageChange = async (newPage, f) => {
    setLoading(true);
    let o = { ...f };
    o.include_investment = false;

    o.start_at = newPage * 100;

    const r = await listResources(listPath, o);
    setRows(r);
    setLoading(false);
    setPage(newPage);
  };

  return (
    <div>
      <FilterToken onChange={onFilterChange} />
      <div style={{ height: 600, width: "100%" }} className={classes.root}>
        <DataGrid
          getRowClassName={(params) => {
            var status = "";
            if (isTokenPending(params.row)) {
              status = "PartiallyFilled";
            } else if (isTokenRejected(params.row)) {
              status = "Rejected";
            }
            return `token-theme--${status}`;
          }}
          filterMode="server"
          rows={rows}
          columns={columns}
          page={page}
          pagination
          pageSize={100}
          rowCount={10000}
          paginationMode="server"
          onPageChange={handlePageChange}
          loading={loading}
          autoHeight={true}
          checkboxSelection={false}
          disableSelectionOnClick={true}
        />
      </div>
    </div>
  );
};

export default function PendingReviewContract() {
  return <TokenListPage />;
}
