import React, { FC } from "react";

import { TokenScan } from "../../app/components/admin/token/scan.tsx";

interface ScanTokenPageProps {}
const ScanTokenPage: FC<ScanTokenPageProps> = () => {
  return <TokenScan />
};

export default ScanTokenPage;
