import { FC, useEffect, useState, useRef, useCallback } from "react";

import { Web3Activity, getActivities } from "../../../../models/activity";
import AddressComponent from "../search-results/address-component/address-component";
import { Loader } from "../../../ui/Loader";

import { useTransition, animated, config } from "@react-spring/web";
import { ActivityBox, SectionTitle } from "../../index";
import { FeedFilters } from "../../../../models/search";
import { ACTIVITY_LEVEL } from "../../../../models/emojis";

interface ActivityStatusProps {
  status: "low" | "medium" | "high";
}

export const ActivityStatus: FC<ActivityStatusProps> = ({ status }) => {
  return (
    <ActivityBox>
      <small className={ACTIVITY_LEVEL[status]?.emoji}></small>
      <span>{ACTIVITY_LEVEL[status]?.description}</span>
      <p className="activity-tag">activity</p>
    </ActivityBox>
  );
};

interface ActivityOnWebProps {
  filter: {
    q?: string;
    chainIDs?: number[];
  };
}

// https://easings.net/#easeInOutQuad
// Using monotonic time since we only need delta
let refreshDuraration = 30000; // we will make expand the refresh life cycle to about 40s
let startLoadAt = performance.now();
let elapseRefresh = 0;
function easeInOutQuad(x: number): number {
  return x * x * x;
  //return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}
const refreshInterval = () => {
  let wait = 3000 + Math.random() * 1000;
  return wait;
};

const ActivityOnWeb: FC<ActivityOnWebProps> = ({ filter }) => {  
  const [activities, setActivities] = useState<Web3Activity[]>([]);

  const timerRef = useRef<NodeJS.Timeout>();
  const bf = useRef(null);
  const queue = useRef<Web3Activity[]>([]);
  //queue.current = [] as Web3Activity[];

  const filterRef = useRef(filter);
  filterRef.current = filter;

  const sinceRef = useRef(0);

  const mouseOnViewportRef = useRef(false);
  const [pending, setPending] = useState(false);
  const pendingTs = useRef(0);
  const togglemouseOnViewport = (state?: boolean) => {
    mouseOnViewportRef.current = state;
  };

  // Don't relly need it but the feed change so often so maybe worth to cache this
  const refresh = useCallback(async () => {
    // only refresh when having mouseOnViewport
    if (document.visibilityState !== "visible") {
      timerRef.current = setTimeout(refresh, refreshInterval());
      return;
    }

    try {
      let expired = false;
      if (
        // Temporarily to workaround pocket issue
        Math.floor(sinceRef.current / 100000) + 240 <=
        Math.floor(new Date().getTime() / 1000)
      ) {
        // throw away all existing data and refresh whole thing since it's quite old now
        queue.current = [];
        const dataActivities = await getActivities({
          since: sinceRef.current,
          q: filterRef?.current?.q,
          chainIDs: filterRef?.current?.chainIDs,
          at_ts: new Date().getTime(),
        });
        queue.current = dataActivities.filter(
          (elem) => !bf.current[elem.tx_hash]
        );
        expired = true;
        if (queue.current?.length > 0) {
          sinceRef.current = queue.current[0].surrogate_key;
        }
      }

      if (queue.current.length === 0) {
        queue.current = await getActivities({
          since: sinceRef.current,
          q: filterRef?.current?.q,
          chainIDs: filterRef?.current?.chainIDs,
          at_ts: new Date().getTime(),
        });
        queue.current = queue.current.filter(
          (elem) => !bf.current[elem.tx_hash]
        );
        sinceRef.current = queue.current[0].surrogate_key;
        expired = true;
      }

      if (queue.current.length > 0 && expired) {
        let howmanyToAdd = 5;
        if (timerRef.current) {
          // If we have some existed data or feed is already initialied, add it slowly
          howmanyToAdd = 2;
        }
        if (!mouseOnViewportRef.current) {
          setActivities((old: Web3Activity[]) => [
            ...queue.current.slice(-howmanyToAdd),
            ...old,
          ]);
        }

        queue.current
          .slice(-howmanyToAdd)
          .forEach((elem) => (bf.current[elem.tx_has] = true));
        queue.current = queue.current.slice(0, queue.current.length-howmanyToAdd);
      } else {
        const newActivity = queue.current.pop();
        bf.current[newActivity.tx_hash] = true;
        if (!mouseOnViewportRef.current) {
          setActivities((old) => [newActivity, ...old]);
        }
      }
    } catch (err) {
      //Bugsnag.notify(err);
      console.log("error update new feed ", err);
    } finally {
      timerRef.current = setTimeout(refresh, refreshInterval());
    }
  }, []);

  useEffect(() => {
    setActivities([]);
    queue.current = [];
    bf.current = {} as { [key: string]: boolean };

  }, [filter]);

  useEffect(() => {
    refresh();
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  const transitions = useTransition(activities, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    delay: 200,
    config: config.molasses,
    //onRest: () => setItems([]),
  });

  const category = filter?.q
    ? FeedFilters.find((item) => item.value === filter?.q)?.name
    : "web3";
  const filterName = category || filter?.q || "web3";
  useEffect(() => {
    const scrollHandling = () => {
      if (window.scrollY === document.documentElement.offsetTop) {
        return (mouseOnViewportRef.current = false);
      }

      if (window.screenY > 0) {
        mouseOnViewportRef.current = true;
      }

      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight
      ) {
        if (activities.length > 0) {
          if (pendingTs.current !== activities[activities.length -1].surrogate_key) {
            pendingTs.current = activities[activities.length -1].surrogate_key;

            setPending(true);
            getActivities({
              before: activities[activities.length - 1].surrogate_key,
              q: filterRef?.current?.q,
              chainIDs: filterRef?.current?.chainIDs,
              at_ts: new Date().getTime(),
            })
              .then((response) => {
                setActivities((old) => [...old, ...response]);
              })
              .finally(() => {
                pendingTs.current = 0;
                setPending(false);
              });
          }
          mouseOnViewportRef.current = true;
        } else {
          setPending(false);
        }
      }
    };
    window.addEventListener("scroll", scrollHandling);
    return () => window.removeEventListener("scroll", scrollHandling);
  }, [activities]);
  return (
    <div className="home-activities">
      <SectionTitle>What’s happening</SectionTitle>
      {!activities?.length ? (
        <div className="u-display-flex u-justify-center u-margin-top-8 u-margin-bottom-5">
          <Loader color="primary" className="label-loader-icon" />
        </div>
      ) : (
        <div
          onMouseEnter={() => togglemouseOnViewport(true)}
          onMouseLeave={() => togglemouseOnViewport(false)}
        >
          {/* TODO: Show activity status based on activities */}
          <ActivityStatus status="medium" />
          {activities.length >= 1 &&
            transitions(({ opacity }, item, t, index) => (
              <animated.div
                style={{
                  opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                }}
              >
                <AddressComponent
                  key={item.transaction_hash}
                  item={item}
                  position={index}
                  variant="front"
                  name_from={"collection_name"}
                />
              </animated.div>
            ))}
          {pending && (
            <div className="u-display-flex u-justify-center u-margin-top-5 u-margin-bottom-5">
              <Loader color="primary" className="label-loader-icon" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ActivityOnWeb;
