export const theme = {
  // Color primary
  colors: {
    primary: "#8F4AE7",
    primaryDark: "#7626DC",
    primaryLight: "#A670EB",
    primaryExtraLight: "#f7f0ff",
    primaryMediumLight: "#b38ae8",
    primaryNoFill: "#e9d9ff",
    primaryf2e8ff: "#f2e8ff",
    // Color secondary
    secondary: "#4AC9DB",
    secondaryDark: "#04A2B8",
    secondaryLight: "#65dded",
    secondaryExtraLight: "#e9f6f8",
    secondaryNoFill: "#d4faff",
    // Color tertiary
    tertiary: "#3F3F3F",
    tertiaryDark: "#100b0b",
    tertiaryLight: "#999999",
    tertiaryExtraLight: "#EFEFEF",
    tertiaryNoFill: "#f0f0f0",
    // Color test
    extra: "#63AE28",
    extraDark: "#315f0e",
    extraLight: "#97d667",
    extraExtraLight: "#f2ffe8",
    extraNoFill: "#efffe2",
    // Color danger
    danger: "#d80000",
    dangerLight: "#fdadad",
    dangerExtraLight: "#fff0f0",
    // Color dark
    dark: "#3F3F3F",
    darkDark: "#000000",
    darkLight: "#929191",
    darkExtraLight: "#f0f0f0",
    dark777: "#777777",
    contrast: "#fff",
    dark7474: "#747474",
    darkFAFA: "#fafafa",
    darkDCDC: "#dcdcdc",
    darkF5F5: "#f5f5f5",
    darkF3F3: "#F3F3F3",
    darkF1F1: "#F1F1F1",
    darkCACA: "#cacaca",
    warning: "#ffc800",
    secondaryGrey: "#CBDFE2",
    colorLightGrey: "#dadada",
  },
  size: {
    extraSmall: ".7rem",
    small: "0.87rem",
    medium: "1.12rem",
    large: "1.37em",
    extraLarge: "1.875rem",
    default: "1rem",
  },
  sizeButton: {
    extraSmall: ["85px;", "30px"],
    small: ["115px", "30px"],
    medium: ["160px", "42px"],
    large: ["180px", "45px"],
  },
  weight: {
    medium: "Roboto",
    medium2: "Roboto Medium",
    bold: "Roboto Bold",
    light: "Roboto Light",
  },
  expand: {
    small: ["175px", "30px"],
    medium: ["220px", "40px"],
    large: ["280px", "45px"],
  },
  align: {
    center: "center",
    left: "left",
    right: "right ",
    justify: "justify",
  },
  icon: {
    left: "0.6rem",
    right: "0.6rem",
  },
  transform: {
    uppercase: "uppercase",
    lowercase: "lowercase",
    capitalize: "capitalize",
  },
  fontSize: {
    small: "0.813rem",
    medium: "1rem",
    large: "1.125",
  },
  sizeFlatButton: {
    small: "30px",
    medium: "40px",
    large: "60px",
  },
  textDecoration: {
    underline: "underline",
  },
  breakpoint: {
    xxl: "1400px",
    xl: "1200px",
    lg: "992px",
    tablet: "870px",
    smartphone: "580px",
  },
};
