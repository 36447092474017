import { FC } from "react";

import { Transaction, getTxUrl } from "../../../../../models/transaction";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

import ResultLink from '../result-link';

dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

interface ActivityProps {
  item: any;
  searchId?: string;
}


const Activity: FC<ActivityProps> = ({ item, searchId }) => {
  const { token_address } = item;
  
  return (
    <>
      {item.recent_transactions.map((x: Transaction, i: any) => (
        <div className="search-list__item" key={`item-${i}`}>
          <div className="search-list__item__number">
            <span>{i + 1}</span>
          </div>
          <div className="item-top-list">
            <small className="using-emoji"></small>
          </div>

          <div className="search-list__item__image">
            {token_address && (
              <ResultLink
                href={getTxUrl(x)}
                target="_blank"
                className="address-wallet"
                searchId={searchId}
              >
                {x.tx_hash.substr(0, 8) + "..." + x.tx_hash.slice(-7)}
              </ResultLink>
            )}
     
            <span className="amount-smartphone u-text-left">
              {dayjs().to(dayjs(x.block_timestamp))}
            </span>
          </div>
 
          <div className="search-list__item__amount u-margin-left-5">
            <>&nbsp;</> {dayjs().to(dayjs(x.block_timestamp))}
          </div>
        </div>
      ))}
    </>
  );
};

export default Activity;
