import { FC } from "react";
import { WidgetTitle } from "../../index";
import AddressComponent from "../search-results/address-component/address-component";

interface CharacteristicsOnWebProps {
  data: any;
}

const CharacteristicsOnWeb: FC<CharacteristicsOnWebProps> = ({ data }) => {
  return (
    <div className="widget-characteristics">
      <WidgetTitle>Top gainers on web3</WidgetTitle>
      {data.map((x: any, i: number) => (
        <AddressComponent key={i} item={x} position={i} variant="gainers" />
      ))}
      <button className="btn-top-all">see top 10</button>
    </div>
  );
};

export default CharacteristicsOnWeb;
