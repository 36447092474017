import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";
import { Box } from "@material-ui/core";


export const ListItems = styled(Box)({
    ".small-chip": {
        border: "0",
        cursor: "pointer",
        marginBottom: "2px",
        marginLeft: "2px",
        marginRight: "2px",
        height: "16px!important",
        backgroundColor: theme.colors.primaryf2e8ff,
        ".MuiChip-label": {
            fontSize: "12px",
            color: theme.colors.primary,
            fontFamily: theme.weight.medium2,
        },
    },
    ".text-address": {
        marginRight: "5px",
        a: {
            color: theme.colors.tertiary
        },
    },
    ".collector-description": {
        marginTop: "3px",
        fontSize: "14px",
        wordSpacing: "2px",
        fontFamily: theme.weight.light,
        color: theme.colors.tertiaryLight
    },
    small: {
        margin: "0 10px"
    }
});
