import { fromEvent } from "rxjs";

import { FC, useState, useEffect, Fragment, useRef } from "react";
import { Link } from "react-router-dom";
import { AllNftsCollections, ItemNft, NftsProfileCollections } from "./styled";

import ethLogo from "../../../../../../images/temp_img/image 131.png";

import CurrentOwnersStats from "../../widgets/current-owners-stats";
import AddressComponent from "../address-component/address-component";
import { SectionTitle } from "../../..";
import { ActivityStatus } from "../../widgets/activity-on-web";

import SortBy from "../../../../pages/search/widgets/sort-by";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";


import OwnersCollectionJustBought from "./owners-collection-bought";
import OwnersCollectionOwnChips from "./owners-collection-own-chips";
import {
  wrapNumber,
  formatNumber,
  shortAddress,
} from "../../../../../services/formatter";
import { NftPictureItem } from "../address-component/profile-nft-assets";
import { useMediaQuery } from "@material-ui/core";

import { useParams } from "react-router-dom";
import {
  getNftCollectionAssets,
  getNftCollectionActivities,
  NftAsset,
  NftCollectionInfo,
} from "../../../../../models/nft";
import { useTransition, animated, config } from "@react-spring/web";
import { Loader } from "../../../../ui/Loader";
import { getTagName, tagToSlug } from "../../../../../models/tag";

import { Web3Activity } from "../../../../../models/activity";
// import dayjs from "dayjs";
import days from "../../../../../services/daysjs";

import InfinityScroll from "../../../../ui/infinite-scroll";

import { HeaderMeta } from "../../../../base/header-meta";
import ActvityRowAddress from "../../../activity-row-address";
import { LeftContent, RightContent, Separator, TextDate, TimeLineItem, YearLabel } from "../../../../ui/styled/time-line-tools";
import { Timeline, TimelineConnector, TimelineDot } from "@material-ui/lab";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

interface NftCollectionsProps {
  collection: NftCollectionInfo;
}

const LIMIT_RECORDS = 24;

let trackScroll: any;
const NftCollections: FC<NftCollectionsProps> = ({ collection }) => {
  const isXl = useMediaQuery("(max-width: 1200px)");
  const isMobil = useMediaQuery("(max-width: 870px)");
  const [nftAssets, setNftAssets] = useState([] as NftAsset[]);
  const [activities, setActivities] = useState([] as Web3Activity[]);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const { slug } = useParams<{
    slug: string;
  }>();

  const [afterNft, setAfterNft] = useState<string>("");
  const transitions = useTransition(activities, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    delay: 200,
    config: config.molasses,
    //onRest: () => setItems([]),
  });

  const loadCollectionData = async ({ after, limit }) => {
    setLoading(true);
    try {
      const dataAssets = await getNftCollectionAssets(slug, { after, limit });
      if (dataAssets && dataAssets.length > 0) {
        setAfterNft(
          dataAssets.length > 0 ? dataAssets[dataAssets.length - 1].token_id : ""
        );
      } else {
        trackScroll.unsubscribe();
      }
      if (after) {
        setNftAssets((nftAssets) => [...nftAssets, ...dataAssets]);
      } else {
        setNftAssets(dataAssets);
      }
    } catch (e) {
      console.log("error when loading collection info", e);
    }
    setLoading(false);
  };

  useEffect(() => {
    const element_collection = document.querySelector(".element-collection");
    const scrollHandling = () => {
      if (
        element_collection?.scrollTop + element_collection?.offsetHeight >=
        element_collection?.scrollHeight
      ) {
        loadCollectionData({ after: afterNft, limit: LIMIT_RECORDS });
      }
    };

    if (element_collection) {
      trackScroll = fromEvent(element_collection, "scroll").subscribe(scrollHandling);
    }

    return () =>
      trackScroll?.unsubscribe()
  }, [showMore, afterNft]);

  const loadCollectionActivity = async (before?: string) => {
    return await getNftCollectionActivities(slug, before);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadCollectionData({ limit: LIMIT_RECORDS });
  }, [slug]);

  return (
    <>
      {HeaderMeta({
        title: collection?.name && collection?.name,
        description: collection?.name && collection?.name,
        image: collection?.large_image_url && collection?.large_image_url,
      })}
      <div className="nfts-collections">
        <div className="update-ago">updated 2 min ago</div>
        <div className="nfts-collections__profile timeline-design">
          <NftsProfileCollections>
            {collection?.large_image_url && (
              <img src={collection?.large_image_url} />
            )}

            <p className="name-collection">{collection?.name}</p>
            <p className="profile-address">
              <span className="profile-months-ago">
                {days(collection.created_at).fromNow()}
              </span>
              <span className="profile-address__by">by</span>
              {collection?.owner && (
                <>
                  <div className="u-display-flex u-align-items-center">
                    <Link to={`/profiles0/${collection?.owner?.address}`}>
                      {collection.owner?.ens
                        ? collection.owner.ens
                        : shortAddress(collection?.owner?.address, 5, 0)}
                    </Link>
                  </div>
                  <p className="profile-address__tags">
                    {collection?.owner?.tags?.map((tag, index) => (
                      <Fragment key={index}>
                        <Link
                          className="tag-button u-text-lowercase"
                          to={`/tags/${tagToSlug(tag)}`}
                        >
                          {getTagName(tag)}
                        </Link>
                      </Fragment>
                    ))}
                  </p>
                </>
              )}
            </p>
          </NftsProfileCollections>

          {/* <DetailCollections collection={collection} /> */}


          <AllNftsCollections className="u-margin-top-2 element-collection">
            {nftAssets &&
              (
                nftAssets.slice(
                  0,
                  showMore
                    ? nftAssets.length
                    : isXl && !isMobil
                      ? 6
                      : isXl && isMobil
                        ? 2
                        : 7
                ) || []
              ).map((nft, i) => (
                <ItemNft key={i}>
                  <NftPictureItem
                    nft={{
                      token_id: nft.token_id,
                      address: nft.address,
                      size: "500",
                    }}
                  />

                  {nft?.name !== collection?.name ? (
                    <p className="name-nft">
                      {nft.name.replace(collection?.name, "").trim()}
                    </p>
                  ) : (
                    <p className="name-nft">#{nft.token_id.replace(nft?.name, "").trim()}</p>
                  )}
                  {/* TODO: ENABLE WHEN WE HAVE PRICE API <p className="price-nft">$20,0385</p>*/}
                  <small>
                    <img
                      className="eth-logo"
                      src={`${process.env.PUBLIC_URL + ethLogo}`}
                      alt=""
                    />
                    0.199
                  </small>
                </ItemNft>
              ))}

            {loading && showMore && (
              <div className="u-width-100 u-display-flex u-justify-center u-margin-top-5 u-margin-bottom-5">
                <Loader color="primary" className="label-loader-icon" />
              </div>
            )}
          </AllNftsCollections>
          {nftAssets.length > 8 && (
            <div>
              <button
                className="btn-see-more-nft"
                onClick={() => setShowMore((showMore) => !showMore)}
              >
                {!showMore ? "Show more" : "Show less"}{" "}
              </button>
              {collection.stats?.count && (
                <span className="total-all-nft">
                  {wrapNumber(
                    formatNumber(String(collection.stats?.count), 0),
                    "(",
                    ")"
                  )}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="nfts-collections__container">
          <div className="u-padding-left-2 nfts-collections__left">
            <div className="u-margin-bottom-2">
              <CurrentOwnersStats slug={slug} />
            </div>
            <div className="u-margin-bottom-2">
              <OwnersCollectionJustBought slug={slug} />
            </div>
            <OwnersCollectionOwnChips slug={slug} />
            {/* <div className="highlight-sp">
            <HighlightTransactions category={""} />
          </div> */}
          </div>
          <div className="nfts-collections__center">
            <div className="nfts-collections__content">

              <SectionTitle>What’s happening</SectionTitle>
              {/* <ActivityStatus status="medium" />  */}
              <SortBy type="profile" />
              <Timeline className="u-padding-none collection-timeline" style={{ position: "relative" }}>
                {activities.length >= 1 && <YearLabel className="date-collections">2022</YearLabel>}
                <InfinityScroll
                  activities={activities}
                  setActivities={setActivities}
                  setPending={setPending}
                  pending={pending}
                  fetchNew={loadCollectionActivity}
                  fetchMore={() =>
                    activities &&
                    loadCollectionActivity(
                      activities[activities.length - 1].surrogate_key
                    )
                  }
                >
                  {activities.length >= 1 &&
                    transitions(({ opacity }, activity, t, index) => (
                      <animated.div
                        style={{
                          opacity: opacity.to({
                            range: [0.0, 1.0],
                            output: [0, 1],
                          }),
                        }}
                      >
                        {/* <AddressComponent
                        key={index}
                        item={activity}
                        position={index}
                        variant="front"
                        name_from="nft_name"
                        collection_slug={slug}
                      /> */}

                        <TimeLineItem>
                          <LeftContent style={{ minHeight: "125px" }}>
                            <ActvityRowAddress
                              type="collection"
                              item={activity}
                              widthContainer="350px"
                            />
                          </LeftContent>
                          <Separator>
                            <TimelineDot>
                              <TextDate >
                                {activity.block_timestamp &&
                                  dayjs(activity.block_timestamp).format('MMM DD')}
                              </TextDate>
                            </TimelineDot>
                            <TimelineConnector />
                          </Separator>
                          <RightContent>
                            <span>Sold to a colletor for 1.21 ETH</span>
                          </RightContent>
                        </TimeLineItem>



                      </animated.div>
                    ))}
                </InfinityScroll>
              </Timeline>
            </div>
          </div>
          {/* <div className="u-padding-left-1 nfts-collections__right highlight-no-desktop">
          <div className="collapse-box u-width-100 u-border-none u-padding-top-none">
            <HighlightTransactions category={""} />
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default NftCollections;
