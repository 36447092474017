import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { LandingContent, Link, Text } from "../app/components/ui/styled";
import { assets } from "../app/models/company_asset";
import exampleapi from "../images/temp_img/example_api.PNG";
import img1 from "../images/temp_img/img1.png";
import img2 from "../images/temp_img/img2.png";
import img3 from "../images/temp_img/img3.png";

interface ApiLandingPageProps { }

const ApiLandingPage: FC<ApiLandingPageProps> = () => {

  return (
    <Box className="after-footer">
      <LandingContent className="u-margin-bottom-5">
        <Grid container spacing={2} className="u-margin-top-5 u-justify-center">
          <Grid item md={12} className="u-text-center">

            <Text color="dark" size="medium" className="left-side-text__title">
              Openstory API - unleashing creativity
            </Text>
          </Grid>
          <Grid item md={6}>
            <Text color="dark" className="left-side-text__description">
              Openstory was built to fill a need we had internally as {" "}
              <Link className="u-color-primary u-display-inline-block" rel="noreferrer" target="_blank" href={assets.owner_company}> Monday Capital </Link>: How can we extract useful information from raw on-chain data? To execute this, we had to build an indexer - a platform that reads blockchain activity in real-time, decodes it, and stores the data in a searchable database that we can query to get results.
              <br />
              <br />
              Openstory API gives access to this data, without limits and for free, to the creator economy. Our goal is to provide a platform where builders can build and creators can create by providing access to data that, up until now, was only available to those who had the financial resources to do so. So enjoy it, and feel free to reach out to us.
            </Text>
          </Grid>
          <Grid item md={6}>
            <div>
              <img
                src={`${process.env.PUBLIC_URL + exampleapi}`}
                alt=""
                className="api-image"
              />
            </div>
          </Grid>
          <Grid item md={12} className="u-text-center">
            <div className="left-side-text__buttons">
              <NavLink to="/apikeys">Get API key</NavLink>
              <a href={assets.apidocs_url} target="_blank" rel="noreferrer">
                Read Docs
              </a>
            </div>
          </Grid>
          <Grid item md={12} sm={12} style={{ margin: "auto" }}>
            <Text align="center" weight="light"
              className="text-subtitle-text"
            >
              What you can build with Openstory API
            </Text>
          </Grid>
          <Grid item md={4} sm={6} xs={12} className="card-widget-content u-margin-none">
            <Text weight="medium2" className="u-margin-bottom-1 u-text-center">
              Real-time feed
            </Text>
            <div className="card-widget">
              <img
                src={`${process.env.PUBLIC_URL + img1}`}
                alt=""
                className="build-image"
              />
            </div>
            <Text weight="light" className="u-text-justify text-card-padding">
              Get a human-readable real-time feed of all the on-chain activity without the need to translate and decode transactions.
            </Text>
          </Grid>
          <Grid item md={4} sm={6} xs={12} className="card-widget-content">
            <Text weight="medium2" className="u-margin-bottom-1 u-text-center">
              User insights
            </Text>
            <div className="card-widget">
              <img
                src={`${process.env.PUBLIC_URL + img2}`}
                alt=""
                className="build-image"
              />
            </div>
            <Text weight="light" className="u-text-justify text-card-padding">
              Get insights about the owners of an NFT or a collection. <br />
              Who is your audience, what else do they own, and how long do they hold.
            </Text>
          </Grid>
          <Grid item md={4} sm={6} xs={12} className="card-widget-content">
            <Text weight="medium2" className="u-margin-bottom-1 u-text-center">
              Highlights
            </Text>
            <div className="card-widget">
              <img
                src={`${process.env.PUBLIC_URL + img3}`}
                alt=""
                className="build-image"
              />
            </div>
            <Text weight="light" className="u-text-justify text-card-padding">
              Display a summary of the latest/trending on-chain activity: what’s minting, selling, burning.
            </Text>
          </Grid>
        </Grid>
      </LandingContent>
    </Box>
  );
};

export default ApiLandingPage;
