import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";

export const HeaderContent = styled.header`
  z-index: 9;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #bbb6b6;
  /* background: ${theme.colors.contrast}; */
  @media screen and (max-width: ${theme.breakpoint.lg}) {
    padding: 0;
    height: 45px;
    margin: 0;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    /* background: ${theme.colors.contrast}; */
    /* border-bottom: 1px solid ${theme.colors.tertiaryNoFill}; */
  }
`;
export const ButtonMenu = styled.button`
  left: 0;
  top: 0;
  z-index: 9;
  width: 40px;
  height: 40px;
  display: flex;
  border: none;
  display: none;
  cursor: pointer;
  font-size: 1.5em;
  background: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.tertiaryLight};
  @media screen and (max-width: ${theme.breakpoint.lg}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const Nav = styled.nav`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  .menu-element {
    align-items: center;
  }
  .link-logo {
    display: flex;
    align-items: center;
    span {
      position: relative;
      bottom: 2px;
      font-size: 14px;
      font-family: "Roboto Medium";
      color: #d6d6d6;
      left: 5px;
    }
  }
  ul {
    display: flex;
    li {
      margin: 0 1rem;
      position: relative;
      a {
        font-size: 1.1rem;
        display: inline-block;
        text-align: center;
        padding-bottom: 0;
        color: ${theme.colors.tertiary};
      }
      a {
        font-family: "Roboto Light";
        font-size: 16px;
        position: relative;
        transition: 0.2s ease all;
        &::after {
          display: block;
          content: "";
          transform: scaleX(0);
          transition: transform 0.2s ease-in-out;
          border-bottom: solid 2px ${theme.colors.primary};
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100%;
        }
        &:hover:after {
          transform: scaleX(1);
        }
      }
      a.active {
        color: #222222;
        font-family: "Roboto";
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }

  @media screen and (max-width: ${theme.breakpoint.lg}) {
    justify-content: center;
    ul {
      left: 0;
      z-index: 9;
      width: 100%;
      position: fixed;
      padding: 0.5em 1em;
      top: calc(0px + 56px);
      flex-direction: column;
      height: calc(100% - 56px);
      background: ${theme.colors.contrast};
      display: flex;
      align-items: center;
      padding-top: 3em;
      li {
        padding: 0.7em 0.5em;
      }
    }
    .menu-element {
      transform: translateX(-100%);
    }
    .hiddeMenu {
      transform: translateX(0%);
      animation: 0.3s hidde-menu 1 forwards;
      @keyframes hidde-menu {
        to {
          transform: translateX(-100%);
        }
      }
    }
    .showMenu {
      animation: 0.3s show-menu 1 forwards;
      @keyframes show-menu {
        to {
          transform: translateX(0%);
        }
      }
    }
  }
`;

export const HeaderAvatar = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;  
  justify-content: flex-end;
  @media screen and (max-width: ${theme.breakpoint.lg}) {
    position: absolute;
    right: -5px;
    top: 5px;
  }
  p {
    margin-right: 1em;
    font-size: 0.95rem;
    position: relative;
    color: ${theme.colors.primary};
    span {
      display: block;
    }
    &::before {
      content: "";
      top: 5px;
      left: -20px;
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 50%;
      background: ${theme.colors.primary};
    }
    @media screen and (max-width: ${theme.breakpoint.lg}) {
      span {
        display: none;
      }
      &::before {
        top: -5px;
        left: -5px;
      }
    }
  }
  img {
    width: 32px;
    padding: 0.35em;
    border-radius: 50%;
    border: 1px solid ${theme.colors.primary};
  }
  svg {
    margin: 0 0.3em;
    font-size: 1.2em;
    color: ${theme.colors.tertiary};
  }
`;
