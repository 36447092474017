import { FC } from "react";

import { isSingle, isList } from "../../../../models/search";

import SearchList from "./search-list";
import SingleResult from "./single-result";
import NoData from "./no-data";
import SimilarProjects from "./similar-projects";
import TextResults from "./texts-results";

interface SearchResultProps {
  searchResults: any;
  pageNumber: number;
  similarQuerys?: string[];
  handleSearch?: any;
}
const SearchResult: FC<SearchResultProps> = ({
  searchResults,
  similarQuerys,
  handleSearch,
  pageNumber,
}) => {
  const { error, data } = searchResults;
  return (
    <>
      <div className="all-results">
        {searchResults && isList(searchResults) && (
          <SearchList
            pageNumber={pageNumber}
            searchResults={searchResults}
            handleSearch={handleSearch}
          />
        )}

        {searchResults && isSingle(searchResults) && (
          <div className="search-list">
            <div className="u-margin-bottom-05 similar-tokens">
              <TextResults results={searchResults} />
              <SimilarProjects
                similarProjects={searchResults.similar_projects}
                handleSearch={handleSearch}
              />
            </div>
            <hr className="line-bottom" />
            {/* {searchResults?.token && (
              <div className="header-search-list">
                <img src={searchResults?.token.logo} alt="" />
                {searchResults?.token.name} {searchResults?.token.symbol}
              </div>
            )} */}
            <SingleResult
              pageNumber={pageNumber}
              searchResults={searchResults}
              item={searchResults.data}
              item_type={searchResults.item_type}
              result_format={searchResults.result_format}
            />
          </div>
        )}

        {(error === "malform_query" ||
          error === "resource_notfound" ||
          data === null) && (
          <NoData similarQuerys={similarQuerys} handleSearch={handleSearch} />
        )}
      </div>
    </>
  );
};

export default SearchResult;
