import { Box } from "@material-ui/core";
import { FC } from "react";
import { ListItems } from "../pages/activity-row-address/styled";
import CollapseWidget from "../pages/search/widgets/collapse-widget";
import { Text } from "../ui/styled";
import { ChipContent } from "../ui/styled/chipers";
import image136 from "../../../images/temp_img/image 136.png";
import image137 from "../../../images/temp_img/image 137.png";
import image138 from "../../../images/temp_img/image 138.png";
import image139 from "../../../images/temp_img/image 139.png";
import { TastersImages } from "../pages/profile-address/style";
import { tagToSlug } from "../../models/tag";
import { temp_data_images } from "../../models/address-data"
import { NftPictureItem } from "../pages/search/search-results/address-component/profile-nft-assets";
interface SimilarTastesProps { }

const SimilarTastes: FC<SimilarTastesProps> = () => {
  return (
    <CollapseWidget
      className="u-margin-top-3"
      title="Others with similar tastes as mine"
    >
      <Box className="u-margin-bottom-2 u-margin-left-05">
        <ListItems className="u-margin-bottom-1">
          <Box display="flex" alignItems="center" className="u-margin-bottom-05">
            <Text className="text-address" weight="medium2">
              <a href={`/profiles/0x61e07007f6500decc32d0adad8ba726f4c25aa1d`}>
                nerimo.eth
              </a>
            </Text>
            {[1].map((tag) => (
              <a href={`/tags/${tagToSlug("creator")}`} key={tag}>
                <ChipContent size="small" className="small-chip" label="creator" />
              </a>
            ))}
          </Box>
          <TastersImages>
            {temp_data_images.map(x => (
              <NftPictureItem
                nft={{
                  token_id: x.token_id,
                  address: x.address,
                  size: "500",
                }}
              />
            ))}
          </TastersImages>
        </ListItems>
      </Box>
    </CollapseWidget>
  );
};

export default SimilarTastes;

