import { Skeleton } from "@material-ui/lab";
import React, { FC } from "react";

interface SearchLoaderProps {
  type: string;
}

const SearchLoader: FC<SearchLoaderProps> = ({ type }) => {
  return (
    <>
      {type === "default" && (
        <>
          {" "}
          {[0, 1, 2].map((sk) => (
            <div
              className="u-margin-top-05 u-align-items-center u-display-flex"
              key={sk}
            >
              <Skeleton
                style={{ transform: "scale(1)" }}
                width={40}
                height={40}
                variant="text"
              />
              <Skeleton
                className="u-margin-left-1"
                style={{ transform: "scale(1)" }}
                width={150}
                height={15}
                variant="text"
              />
            </div>
          ))}
        </>
      )}
      {type === "nfts" && (
        <>
          {" "}
          {[0, 1, 2].map((sk) => (
            <div
              className="u-margin-top-05 u-align-items-center u-display-flex"
              key={sk}
            >
              <Skeleton
                style={{ transform: "scale(1)" }}
                width={40}
                height={40}
                variant="text"
              />
              <div className="u-margin-left-1">
                <Skeleton
                  style={{ transform: "scale(1)" }}
                  width={110}
                  height={10}
                  variant="text"
                />
                <Skeleton
                  style={{ transform: "scale(1)", marginTop: "5px" }}
                  width={140}
                  height={15}
                  variant="text"
                />
              </div>
            </div>
          ))}
        </>
      )}
      {type === "users" && (
        <>
          {" "}
          {[0, 1, 2].map((sk) => (
            <div
              className="u-margin-top-05 u-align-items-center u-display-flex"
              key={sk}
            >
              <Skeleton
                style={{ transform: "scale(1)" }}
                width={40}
                height={40}
                variant="text"
              />
              <div className="u-margin-left-1 u-display-flex">
                <Skeleton
                  style={{ transform: "scale(1)" }}
                  width={70}
                  height={20}
                  variant="text"
                />
                <Skeleton
                  style={{
                    transform: "scale(1)",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                  width={25}
                  height={20}
                  variant="text"
                />
                <Skeleton
                  style={{
                    transform: "scale(1)",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                  width={25}
                  height={20}
                  variant="text"
                />
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default SearchLoader;
