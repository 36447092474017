import avaxIcon from "../../images/avax.png";
import bscIcon from "../../images/bsc.png";
import maticIcon from "../../images/matic.png";
import ethIcon from "../../images/eth.png";

enum ChainID {
  MAINNET = 1,
  ROPSTEN = 3,
  BSC = 56,
  MATIC = 137,
  AVAX = 43114,
}

export { ChainID };

export interface Chain {
  id: ChainID;
  name: String;
}

export const getChainIcon = (chainid: ChainID): string => {
  switch (chainid) {
    case 56:
      return bscIcon;
    case 137:
      return maticIcon;
    case 43114:
      return avaxIcon;
    default:
      return ethIcon;
  }
};

export const chainNameFromID = (chainid: ChainID): string => {
  switch (chainid) {
    case 56:
      return "BSC";
    case 137:
      return "MATIC";
    case 43114:
      return "AVAX";
    default:
      return "ETH";
  }
};
