import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Web3Activity } from "../../models/activity";
import { Loader } from "./Loader";

import { fromEvent } from "rxjs";

interface InfinityScrollProps {
  // TODO: correct signature
  activities: any;
  setActivities: any;
  pending: boolean;
  setPending: any;
  fetchNew: any;
  fetchMore: any;
}

const atBottom = (activityRef: any): boolean => {
  if (!activityRef || !activityRef.current) {
    return false;
  }
  return (
    window.innerHeight + window.scrollY >=
      activityRef.current.offsetTop + activityRef.current.offsetHeight ||
    window.innerHeight + window.pageYOffset >=
      activityRef.current?.offsetTop + activityRef.current?.offsetHeight - 2
  );
};

let trackScroll;
const InfinityScroll: FC<InfinityScrollProps> = ({
  activities,
  setActivities,
  pending,
  setPending,
  fetchNew,
  fetchMore,

  children,
}) => {
  const pendingTs = useRef(0);
  const pendingNewest = useRef(0);
  const activityRef = useRef(null);
  const scrollBottom = async () => {
    // handle cross browser and os height check
    // https://stackoverflow.com/questions/9439725/how-to-detect-if-browser-window-is-scrolled-to-bottom
    if (activities && activities.length > 0 && atBottom(activityRef)) {
      if (pendingTs.current <= 0) {
        pendingTs.current = new Date().getTime();
        setPending(true);
        try {
          const entries = await fetchMore();

          if (entries && entries.length > 0) {
            setActivities((old) => [...old, ...entries]);
          } else {
            // this mean we reach the end of the feed, no need to track reload
            trackScroll.unsubscribe();
          }
        } finally {
          pendingTs.current = 0;
          setPending(false);
        }
      } else {
        console.log(
          "there is a pending load at ",
          pendingTs.current,
          "ignore this"
        );
      }
    }
  };

  const refresh = async () => {
    setPending(true);
    try {
      if (pendingNewest.current <= 0) {
        pendingNewest.current = new Date().getTime();
        const data = await fetchNew();
        if (data && data?.length > 0) {
          pendingNewest.current = 0;
        } else {
          trackScroll?.unsubscribe();
        }
        setActivities((old) => [...data, ...old]);
      } else {
        console.log(
          "there is a pending load new data at ",
          pendingTs.current,
          "ignore this"
        );
      }
    } catch (e) {
      console.log("error fetching newest data", e);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    if (!activities || activities.length == 0) {
      refresh();
    }
    trackScroll = fromEvent(window, "scroll").subscribe(() => scrollBottom());
    return () => trackScroll?.unsubscribe();
  }, [activities]);

  return (
    <div ref={activityRef}>
      {children}
      {pending && (
        <div className="u-display-flex u-justify-center u-margin-top-5 u-margin-bottom-5">
          <Loader color="primary" className="label-loader-icon" />
        </div>
      )}
    </div>
  );
};

export default InfinityScroll;
