import { FC, useState, useEffect } from "react";
import CollapseWidget from "../../widgets/collapse-widget";
import { NftRow } from "./styled";
import { Link } from "react-router-dom";
import { NftPictureItem } from "../address-component/profile-nft-assets";

import {
  NftAsset,
  getNftCollectionOwnerRecentTrades,
  generateUrl,
  nftFriendlyName,
} from "../../../../../models/nft";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface OwnersCollectionBoughtProps {
  slug: string;
}

const OwnersCollectionBought: FC<OwnersCollectionBoughtProps> = ({ slug }) => {
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [nftAssets, setNftAssets] = useState<NftAsset[]>([] as NftAsset[]);

  const getData = async () => {
    try {
      setLoading(true);
      const result = await getNftCollectionOwnerRecentTrades(slug);
      setNftAssets(result);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [slug]);  
  return (
    <CollapseWidget title="Owners of this collection just bought">
      {loading && (
        <>
          {[0, 1, 3].map((i) => (
            <Box pl={1} mb={2} key={i}>
              <div className="u-display-flex u-align-items-center">
                <Skeleton
                  width={50}
                  height={50}
                  style={{ marginRight: "15px", transform: "scale(1)" }}
                />
                <div>
                  <Skeleton width={200} height={15} />
                  <Skeleton width={200} height={30} />
                </div>
              </div>
            </Box>
          ))}
        </>
      )}
      <div className="u-scroll-y u-max-scroll-400">
        {(
          (nftAssets || []).slice(0, showMore ? nftAssets.length : 3) || []
        ).map((nft, i) => (
          <NftRow key={i}>
            <div className="owner-nfts-details owner-collection">
              <NftPictureItem
                nft={{
                  token_id: nft.token_id,
                  address: nft.address,
                  size: "100",
                }}
              />
              <div className="owner-nfts-amounts">
                <Link to={generateUrl("nft", nft.address, nft.token_id)}>
                  
                  {nftFriendlyName(nft, "#")}
                </Link>
              </div>
            </div>
          </NftRow>
        ))}
      </div>
      {(nftAssets || []).length > 3 && (
        <button
          className="btn-see-more"
          onClick={() => setShowMore((showMore) => !showMore)}
        >
          {!showMore ? "Show more" : "Show less"}
        </button>
      )}
      {nftAssets && (
        <>          
          {nftAssets.length === 0 && !loading && (
            <span className="text-placeholder u-padding-bottom-2 u-display-flex">
              No records
            </span>
          )}
        </>
      )}
    </CollapseWidget>
  );
};

export default OwnersCollectionBought;
