import React, {FC, useState, useEffect} from "react";
import {useParams, useHistory} from "react-router-dom";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
  FormControl,
  FormLabel,
  Button,
  Grid,
  TextField,
  Switch,
  MenuItem,
  Box,
} from "@material-ui/core";
import {
  TrendingQuery,
  getTrendingQuery,
  updateTrendingQuery,
  deleteTrendingQuery,
  addTrendingQuery,
} from "../../app/models/trending_query";

import {
  FormIndicator
} from "./components/indicator";

import {toast} from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    spacing: {
      margin: "0.25rem",
    }
  })
);

const TrendingQueryDetailsPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const {id} = useParams<TrendingQuery>();

  const emptyTrendingQuery = {
    id: null,
    text: "",
    category: "defi",
    term: "",
    active: false,
  }
  const [trendingQuery, setTrendingQuery] = useState<TrendingQuery>(emptyTrendingQuery);

  const [formIndicator, setFormIndicator] = useState<FormIndicator>({})
  const [phase, setPhase] = useState<"ready" | "loading">("ready");

  const asyncLoadTrendingQuery = async (trendingQueryId: number) => {
    setPhase("loading");
    try {
      const trendingQuery = await getTrendingQuery(trendingQueryId);
      setTrendingQuery(trendingQuery);
    } catch (e) {
      console.log("Error loading TrendingQuery", e);
    } finally {
      setPhase("ready");
    }
  }

  useEffect(() => {
    if (id === "create") {
      setTrendingQuery(emptyTrendingQuery);
    } else {
      asyncLoadTrendingQuery(parseInt(id));
    }
  }, [id]);

  const handleInputValue = (e: React.ChangeEvent) => {
    const {name, value} = e.target;
    if (name) {
      setTrendingQuery({...trendingQuery, [name]: (value as string) || ""});
    }
  };
  const handleSwitchValue = (e: React.ChangeEvent) => {
    const name = e.target.name;
    const value = e.target.checked;
    if (name) {
      setTrendingQuery({...trendingQuery, [name]: (value as boolean) || false});
    }
  };
  const validate = (editedModel: TrendingQuery) => {
    const errors: FormIndicator = {};
    if (editedModel.text.trim() == "") {
      errors["text"] = {
        error: true,
        helperText: "Text cannot be empty",
      }
    }

    if (editedModel.term.trim() == "") {
      errors["term"] = {
        error: true,
        helperText: "Term cannot be empty",
      }
    }

    setFormIndicator(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSave = React.useCallback(async (next: "edit" | "new" | "list") => {
    if (validate(trendingQuery)) {
      setPhase("loading");
      try {
        let result = {}
        if (trendingQuery.id) {
          result = await updateTrendingQuery("/trending_queries/" + trendingQuery.id, trendingQuery)
        } else {
          result = await addTrendingQuery(trendingQuery)
        }
        switch (next) {
          case "new":
            setTrendingQuery(emptyTrendingQuery);
            history.push("/admin/trending-queries/create");
            break;
          case "edit":
            history.push("/admin/trending-queries/" + result.id);
            break;
          case "list":
            history.push("/admin/trending-queries/");
            break;
        }

        toast.success(`saving ${trendingQuery?.text} succeeded`, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });


      } catch (e) {
        toast.error("error saving: " + e.error_msg, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        })
      } finally {
        setPhase("ready");
      }
    }
  }, [trendingQuery])

  const handleDelete = async (evt: React.MouseEventHandler<HTMLButtonElement>) => {
    setPhase("loading");
    try {
      await deleteTrendingQuery("/trending_queries/" + trendingQuery.id)
      history.push("/admin/trending-queries");
      toast.success("Delete succeeded", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } catch (e) {
      toast.error("error delete: " + e.error_msg, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
      })
    } finally {
      setPhase("ready");
    }
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          required
          label="Text"
          variant="outlined"
          value={trendingQuery?.text}
          onChange={handleInputValue}
          name="text"
          {...formIndicator['text']}
        />
      </FormControl>
      <br/>
      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <FormLabel id="select-category-label">Category
          <Box sx={{marginLeft: '20px', display: "inline"}}>
            <Select
              labelId="select-category-label"
              id="select-category"
              value={trendingQuery?.category || "defi"}
              label="Category"
              name="category"
              onChange={handleInputValue}
            >
              <MenuItem value={"defi"}>DeFi</MenuItem>
              <MenuItem value={"nft"}>NFT</MenuItem>
            </Select>
          </Box>
        </FormLabel>
      </FormControl>
      <br/>
      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <TextField
          required
          label="Term"
          variant="outlined"
          value={trendingQuery?.term}
          onChange={handleInputValue}
          name="term"
          {...formIndicator['term']}
        />
      </FormControl>
      <br/>
      <FormControl className="u-margin-right-ui-1 u-width-fc-500">
        <FormLabel>Active
          <Switch
            checked={trendingQuery?.active}
            onChange={handleSwitchValue}
            name="active"/>
        </FormLabel>
      </FormControl>

      {phase === "ready" ? (
        <Grid
          container
          direction="row"
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSave("edit")}
            >
              Save
            </Button>
            &nbsp;
            <Button
              variant="contained"
              color="default"
              onClick={() => handleSave("new")}
            >
              Save and Add another
            </Button>

            &nbsp;
            <Button
              variant="contained"
              color="default"
              onClick={() => handleSave("list")}
            >
              Save and go back to the list
            </Button>
          </Grid>

          {trendingQuery.id !== null && (
            <Grid item>
              <Button variant="contained" color="secondary" onClick={handleDelete}>
                Delete
              </Button>
            </Grid>)}
        </Grid>
      ) : (
        <CircularProgress/>
      )}
    </form>
  );
};

export default TrendingQueryDetailsPage;
