import { FC, useEffect, useState } from "react";
import _ from "underscore";
import Highcharts from "highcharts";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import { getActiveUsersTags } from "../../../../models/search";
import { getTagName } from "../../../../models/tag";
import { ActiveUsersTagsInfo } from "../../../../models/active-users";
import { theme } from "../../../../theme/theme";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Text } from "../../../ui/styled/index";
import { useMediaQuery } from "@material-ui/core";

require("highcharts/highcharts-more")(Highcharts);

const initialData = {
  artist: 144122,
  defi: 228402,
  "dev-builder": 4413,
  flipper: 416115,
  holder: 119530,
  "land-owner": 39950,
  newcomer: 229501,
  "nft-collector": 414672,
  voter: 42402,
};
const total = Object.values(initialData).reduce((a, b) => a + b, 0);
const initialTagsData = _.mapObject(initialData, (value) => {
  return Number((((value || 0) * 100) / (total || 1)).toFixed(2));
});

interface StatsBubbleWidgetProps {
  category?: string;
  collection?: string;
}

const StatsBubbleWidget: FC<StatsBubbleWidgetProps> = ({
  category = "",
  collection = "",
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const isTablet = useMediaQuery("(max-width: 992px)");
  const [tagsData, setTagsData] =
    useState<ActiveUsersTagsInfo>(initialTagsData);
  const [lastFetched, setLastFetched] = useState("");

  const getData = async (filter: any) => {
    try {
      let result = await getActiveUsersTags(filter);

      const total = Object.values(result).reduce((a, b) => a + b, 0);
      result = _.mapObject(result, (value) => {
        return Number((((value || 0) * 100) / (total || 1)).toFixed(2));
      });
      setTagsData(result);
      setLastFetched(new Date().toISOString());
      setLoading(true);
      setTimeout(() => setLoading(false), 300);
    } catch (e) {
      toast.error("Cannot get active users tags");
    }
  };

  useEffect(() => {
    // TODO: add filter to call API
    getData({ category, collection });
  }, [category, collection]);

  const bubbleWidgetOptions = {
    chart: {
      type: "packedbubble",

      animation: false,
    },
    credits: { enabled: false },
    title: { text: "" },
    tooltip: { enabled: false },
    legend: { enabled: false },
    plotOptions: {
      packedbubble: {
        minSize: 60,
        maxSize: 240,
        zMin: 10,
        zMax: 180,
        draggable: false,
        layoutAlgorithm: {
          enableSimulation: false,
        },
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          padding: 0,
          useHTML: true,
          format: `
            <div style="display: ${
              loading ? "none" : "block"
            }; font-size: {point.fontSize}px;" class="highcharts-data-label-tag-item" id="highcharts-data-label-{point.tag}">
              <div>{point.name}</div>
              <div>{point.value}%</div>
            </div>
          `,
          style: {
            textOutline: "none",
            fontSize: "15px",
            textAlign: "center",
            textDecoration: "none",
          },
        },
        className: "bubble-widget-chart",
        marker: {
          fillColor: loading ? "#5957571C" : theme.colors.darkFAFA,
          fillOpacity: 1,
          lineColor: loading ? "#5957571C" : theme.colors.darkDCDC,
          states: {
            hover: {
              lineColor: theme.colors.primary,
              lineWidthPlus: 0,
            },
          },
        },
      },
      series: {
        point: {
          events: {
            click: function (e) {
              history.push(collection ? `/collections/${encodeURIComponent(collection)}/tags/${e.point.tag}?ref=buble&query=collections/${encodeURIComponent(collection)}` : `/tags/${e.point.tag}?ref=buble&query=tags/${e.point.tag}`);
            },
            mouseOver: function (e) {
              const el = document.getElementById(
                `highcharts-data-label-${e.target.tag}`
              );
              el?.classList.add("hover");
            },
            mouseOut: function (e) {
              const el = document.getElementById(
                `highcharts-data-label-${e.target.tag}`
              );
              el?.classList.remove("hover");
            },
          },
        },
      },
    },
    series: [
      {
        name: "Users",
        data: Object.entries(tagsData || {}).map(([name, value]) => {
          // TODO: remove "name !== holder" when we have a new name instead of holder
          return ( 
            name !== "holder" && {
              tag: name,
              fontSize: 10 + Math.floor(value / 6),
              name: getTagName(name) || name,
              value,
            }
          );
        }),
      },
    ],
  };

  return Object.keys(tagsData || {}).length ? (
    <>
    <p className="graph-label">and their profile is</p>
      <HighchartsReact
        highcharts={Highcharts}
        options={bubbleWidgetOptions}
        immutable={true}
      />
      {lastFetched && (
        <Text
          align="right"
          size="extra-small"
          color="tertiary-light"
          style={{ marginRight: "25px" }}
        >
          {dayjs(lastFetched).fromNow()}
        </Text>
      )}
    </>
  ) : null;
};

export default StatsBubbleWidget;
