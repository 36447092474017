import { FC, useState, useEffect } from "react";
import { AssetContainer } from "../../ui/styled/header-content-row";
import ethLogo from "../../../../images/temp_img/image 131.png";

import { Box, capitalize } from "@material-ui/core";
import { AddressData, NFTAssets } from "../../../models/address-data";
import ProfileNftAssets, {
  NftPictureItem,
} from "../search/search-results/address-component/profile-nft-assets";
import { AddressProfile, getAddressProfile } from "../../../models/address";
import { BigNumber } from "ethers";

import { NFT_NUMBER_TO_SHOW } from "../../../models/search";
import { activityHasNft } from "../../../models/activity";
import MultipleNfts from "../search/widgets/multiple-nfts";

import { formatEther } from "../../..//services/formatter";
import { PictureNft } from "../search/search-results/nfts-details/styled";
import { maybeAnimationUrlType } from "../../../models/nft"
import VideoPlayer from "../../ui/video-player";
import ModelViewer from "../../ui/model-viwer";
import AudioPlayer from "../../ui/audio-player";
import { NavLink } from "react-router-dom";
interface ActivityRowBodyProps {
  token_id?: string;
  item: any;
  type?: string;
  params?: any;
  asset_type?: string;
}

const DefaultAssets: FC<ActivityRowBodyProps> = ({ item, token_id, params }) => {
  return null;
};

const FrontPageAsset: FC<ActivityRowBodyProps> = ({
  item,
  params,
}) => {
  const { asset_type } = params
  return (
    <AssetContainer style={{ margin: "10px 0px 10px" }}>
      <Box className="images">
        {item
          .slice(0, asset_type === "1on1" ? 1 : 3)
          .map((x: any) => (
            <div
              key={x.token_id}
              className={`item-image ${asset_type === "1on1" ? "oneonone-image" : ""} `}
            >

              {x?.animation_url && <>
                {maybeAnimationUrlType(x?.animation_url) === "video" && (
                  <Box position="relative" className="audio-image">
                    <NavLink className="video-url" to={`/nfts/${x.address}/${x.token_id}`}></NavLink>
                    <VideoPlayer controls="only-play" source={x?.animation_url} />
                  </Box>
                )}

                {maybeAnimationUrlType(x?.animation_url) === "3dmodel" && (
                  <Box position="relative" className="audio-image">
                    <ModelViewer source={x?.animation_url} />
                  </Box>
                )}
                {maybeAnimationUrlType(x?.animation_url) === "audio" && (
                  <Box position="relative" className="audio-image">
                    {/* <AudioPlayer source={x?.animation_url} /> */}
                    <NftPictureItem
                      nft={{
                        token_id: x.token_id,
                        address: x.address,
                        size: "500",
                      }}
                    />
                    <VideoPlayer controls="only-play" source={x?.animation_url} />
                  </Box>
                )}
                {x?.for_sale && <small>
                  <img
                    className="eth-logo"
                    src={`${process.env.PUBLIC_URL + ethLogo}`}
                    alt=""
                  />
                  {formatEther(x?.price?.amount)}
                </small>}
              </>
              }
              {(asset_type !== "video" || asset_type !== "music") &&
                <>
                  {maybeAnimationUrlType(x?.animation_url) === "image" && <NftPictureItem
                    nft={{
                      token_id: x.token_id,
                      address: x.address,
                      size: "500",
                    }}
                  />}
                  {(x?.for_sale && x?.price?.amount !== "0") ? <small style={{ fontSize: "12px" }}>
                    <img
                      className="eth-logo"
                      src={`${process.env.PUBLIC_URL + ethLogo}`}
                      alt=""
                    />
                    {formatEther(x?.price?.amount)}
                  </small> : <small style={{ fontSize: "10px", textTransform: "capitalize" }}>
                    For Sale
                  </small>}
                </>
              }
            </div>
          ))}
        {asset_type !== "1on1" && (
          <>{item && <>{item?.length > 3 && <span>...</span>}</>}</>
        )}

        {item?.length === 0 && (
          <div className="text-placeholder u-margin-top-2">No records</div>
        )}
      </Box>
    </AssetContainer>
  );
};

const ProfilePageAsset: FC<ActivityRowBodyProps> = ({ item }) => {
  return (
    <>
      {item?.action?.payload?.nft_tokens_transfers ? <AssetContainer>
        <Box className="profile-images">
          <div className="item-image">
            {activityHasNft(item) && (
              <MultipleNfts activity={item} />
            )}
          </div>
        </Box>
      </AssetContainer > : ""}
    </>

  )
}

const NftOwnerPageAsset: FC<ActivityRowBodyProps> = ({ item }) => {
  return (
    <AssetContainer>
      <Box className="profile-images">
        <div className="item-image">
          {activityHasNft(item) && (
            <MultipleNfts activity={item} />
          )}

          {/* <small>
            <img
              className="eth-logo"
              src={`${process.env.PUBLIC_URL + ethLogo}`}
              alt=""
            />
            45.5
          </small> */}
        </div>
      </Box>
    </AssetContainer>
  );
};

const FollowersAsset: FC<ActivityRowBodyProps> = ({ item, asset_type }) => {

  return (
    <AssetContainer>
      <Box className={`images ${asset_type === "1on1" ? "columns-of-three" : "columns-of-four"}`}>
        {item
          .slice(0, asset_type === "1on1" ? 3 : 4)
          .map((x: any) => (
            <div
              key={x.token_id}
              className={`item-image`}
            >
              <NftPictureItem
                nft={{
                  token_id: x.token_id,
                  address: x.address,
                  size: "500",
                }}
              />

              {(x?.for_sale && x?.price?.amount !== "0") ? <small style={{ fontSize: "12px" }}>
                <img
                  className="eth-logo"
                  src={`${process.env.PUBLIC_URL + ethLogo}`}
                  alt=""
                />
                {formatEther(x?.price?.amount)}
              </small> : <small style={{ fontSize: "10px", textTransform: "capitalize" }}>
                For Sale
              </small>}
            </div>
          ))}

      </Box>
    </AssetContainer>
  );
};

const CollectionAsset: FC<ActivityRowBodyProps> = ({ item }) => {
  return (
    <AssetContainer>
      <Box className="profile-images">
        <div className="item-image">
          {activityHasNft(item) && (
            <MultipleNfts activity={item} />
          )}
        </div>

      </Box>
    </AssetContainer >
  )
}


const assetType: { [key: string]: any } = {
  front: FrontPageAsset,
  profile: ProfilePageAsset,
  "followers": FollowersAsset,
  collection: CollectionAsset,
  nftowner: NftOwnerPageAsset,
};

const ActivityRowBody: FC<ActivityRowBodyProps> = ({
  type,
  item,
  params,
  asset_type
}) => {
  const Component = assetType[type] || DefaultAssets;
  return <Component item={item} params={params} asset_type={asset_type} />;
};

export default ActivityRowBody;
