import { Box } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TokenCharacteristics from "../app/components/pages/search/search-results/token-characteristics";
import SearchDropdown from "../app/components/SearchDropdown";
import { Loader } from "../app/components/ui/Loader";
import { SearchResult } from "../app/models/search";
import { getTokenInfo } from "../app/models/token";

const TokensPage: FC = () => {
  const { address } = useParams<{ address: string }>();

  const [tokenInfo, setTokenInfo] = useState<SearchResult | null>(null);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      setLoading(true);
      const result = await getTokenInfo(address);      
      setTokenInfo(result);      
    } catch (e) {
      console.log("error load token information", e);
      toast.error("Fail to load token information. Try again");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [address]);

  return (
    <>
      <div className="container">
        <SearchDropdown initInput={tokenInfo?.token?.name || address} />
      </div>

      <div
        className="container content-min-height"
        style={{ position: "relative" }}
      >
        <Box mb={8} width="100%">
          {loading ? (
            <div className="u-display-flex u-justify-center u-margin-top-5 u-margin-bottom-5">
              <Loader color="primary" className="label-loader-icon" />
            </div>
          ) : (
            <TokenCharacteristics dataToken={tokenInfo} />
          )}
        </Box>

        {/* TODO: Implement infinite scroll */}
      </div>
    </>
  );
};

export default TokensPage;
