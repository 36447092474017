import { FC, useEffect, useState } from "react";

import FormControl from "@material-ui/core/FormControl";

import TextField from "@material-ui/core/TextField";
import { AiOutlineFilter } from "react-icons/ai";

import { useLocation } from "react-router-dom";

interface FilterQuestionsProps {
  onChange: (model) => void;
  initialFilter: any;
}

const FilterQuestions: FC<FilterQuestionsProps> = ({
  onChange,
  initialFilter,
}) => {
  const [filter, setFilter] = useState(initialFilter);
  const location = useLocation();

  const changeQuery = (e: any) => {
    if (e.target.value !== "") {
      let o = { ...filter, [e.target.name]: e.target.value}
      setFilter(o);
      onChange(o);
    } else {
      delete filter[e.target.name];
      setFilter({ ...filter });
      onChange({ ...filter });
    }
  };

  useEffect(() => {
    let o = {};
    let qs = new URLSearchParams(location.search);
    for(const [key, value] of qs) {
      o[key] = value;
    }

    setFilter(o);
    onChange(o);
  }, [location.search])


  return (
    <div className="u-margin-bottom-1 filter-admin-content">
      <span>
        <AiOutlineFilter /> Filter
      </span>
      <div>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            label="Question"
            variant="standard"
            name='question'
            value={filter?.question}
            onChange={changeQuery}
          />
        </FormControl>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            label="ENS"
            variant="standard"
            value={filter?.ens}
            name='ens'
            onChange={changeQuery}
          />
        </FormControl>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            label="twitter"
            variant="standard"
            value={filter?.twitter_account}
            name='twitter_account'
            onChange={changeQuery}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default FilterQuestions;
