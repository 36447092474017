import { FC, useState, useEffect, useMemo, useRef } from "react";
import { Box, Divider, Grid, Tab } from "@material-ui/core";
import { ProfileTabCategory } from "../app/models/address-data";
import ProfileInformation from "../app/components/pages/profile-address/profile-information";
import TimelineRecord from "../app/components/pages/profile-address/timeline-record";
import {
  TabBadge,
  TabItem,
  TabsContent,
} from "../app/components/ui/styled/tabs";
import MyCollectors from "../app/components/widgets/my-collectors";
import SimilarTastes from "../app/components/widgets/similar-tastes";
import { Skeleton, TabContext, TabList, TabPanel } from "@material-ui/lab";
import ActvityRowAddress from "../app/components/pages/activity-row-address";

import { useParams, useHistory, useLocation } from "react-router-dom";
import { getProfileAddress, Profile } from "../app/models/search";
import SearchDropdown from "../app/components/SearchDropdown";
import ProfileInfo from "../app/components/pages/profiles/profile";
import { ProfileBox } from "../app/components/ui/styled/custom-skeleton";
import SortComponent from "../app/components/pages/search/widgets/sort-by";
import { Loader } from "../app/components/ui/Loader";

interface ProfilesPageProps { }

const getFilters = (params: URLSearchParams) => {
  const filter = Object.fromEntries(params.entries());
  return {
    ...filter,
    tab: filter?.tab || "mystory",
    asset_type: filter.asset_type || "all",
  };
};

const ProfilesPage: FC<ProfilesPageProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const filterParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [filter, setFilter] = useState(getFilters(filterParams));

  const filterRef = useRef(filter);
  filterRef.current = filter;


  const [pending, setPending] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);

  const updateURL = (newValues: { [key: string]: any }) => {
    Object.keys(newValues).forEach((key) => {
      let value = newValues[key];
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (!value) {
        filterParams.delete(key);
      } else {
        filterParams.set(key, value);
      }
    });
    history.push(`/profiles/${params?.address}/?${filterParams.toString()}`);
  };

  const asyncActivities = async () => {
    setPending(true);
    try {
      const dataProfile = await getProfileAddress(params.address);
      setProfile(dataProfile);
    } catch (err) {
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    asyncActivities();
  }, [params]);

  const onChangeFilter = (filter: any) => {
    updateURL(filter);
    setFilter(filter);
  };
  return (
    <Box>
      <SearchDropdown
        initInput={profile?.ens || profile?.address || params?.address}
      />
      <hr className="line-bottom" />
      {pending ? (
        <ProfileBox>
          <Box position="relative">
            <Skeleton className="skeleton-circle"></Skeleton>
            <Skeleton width={150} height={30}></Skeleton>
            <Box display="flex">
              <Skeleton
                width={70}
                height={20}
                style={{ marginRight: "5px" }}
              ></Skeleton>
              <Skeleton
                width={70}
                height={20}
                style={{ marginRight: "5px" }}
              ></Skeleton>
              <Skeleton
                width={70}
                height={20}
                style={{ marginRight: "5px" }}
              ></Skeleton>
            </Box>
            <Skeleton width={150} height={20}></Skeleton>
          </Box>
        </ProfileBox>
      ) : (
        <>{profile && <ProfileInformation profile={profile} />}</>
      )}

      <Grid className="u-margin-top-2" container spacing={5}>
        <Grid item xs={4} className="u-padding-top-2-5">
          <MyCollectors />
          <Divider light />
          <SimilarTastes />
        </Grid>
        <Grid item xs={8}>
          <TabsContent
            value={filter.tab ?? "mystory"}
            centered            
          >
            {ProfileTabCategory.map((tab) => (
              <TabItem
                key={tab.value}
                label={
                  <>
                    <div>{tab.label}</div>
                    {tab.recentCounter !== 0 && <small>{tab.recentCounter}</small>}
                  </>
                }
                onClick={() => onChangeFilter({ ...filter, tab: tab.value })}
                value={tab.value}
              />
            ))}
          </TabsContent>
          <Divider light />
          {filter.tab === "followers" || filter.tab === "following" ? (
            <div className="u-margin-top-2"></div>
          ) : (
            <SortComponent
              type="profile"
              filter={filter}
              onChangeFilter={(filter) => {
                updateURL(filter);
                setFilter(filter);
              }}
            />
          )}
          {pending && (
            <div className="u-display-flex u-justify-center u-margin-top-5 u-margin-bottom-5">
              <Loader color="primary" className="label-loader-icon" />
            </div>
          )}
          {profile && <TimelineRecord profile={profile} params={filter} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilesPage;
