import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { assets } from "../../models/company_asset";

import { useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { BsPencil } from "react-icons/bs";
import { BiCopyAlt } from "react-icons/bi";
import { Loader } from "../ui/Loader";
import { NavLink } from "react-router-dom";
import { HeaderContent, Nav, HeaderAvatar, ButtonMenu } from "./styled/index";
import { styled } from "@material-ui/styles";
import { DropDown, Button } from "../ui/styled/index";
import { modalStyles, Text } from "../ui/styled/index";

import { Container } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import { Input } from "../ui/Input";
import { BiCaretDown, BiWalletAlt, BiPlus, BiLogOut } from "react-icons/bi";

import * as accountStore from "../../stores/account";
import ModalConnect from "../account/modal-connect";

import {
  isApiPath,
  isHomePath,
  isAdmin,
  isFeedPath,
} from "../../services/navigation";
import HeaderSearch from "../../components/pages/search/header-search";
import ApiHeader from "./api/header";
import UserNav from "./user-nav";

const getModalStyle = () => {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

// TODO: Should we move this out?
const ContentContainer = styled(Container)(({ theme }) => ({
  "&.MuiContainer-root": {
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 22,
      paddingRight: 22,
    },
  },
}));

const getSubHeader = (pathname: string) => {
  if (isApiPath(pathname)) {
    return <ApiHeader />;
  }

  if (isAdmin(pathname)) {
    return <HeaderApp />;
  }

  if (isFeedPath(pathname)) {
    return <UserNav />;
  }
  return <HeaderSearch />;
};

const Header = () => {
  const location = useLocation();

  return (
    <ContentContainer
      className={` ${
        isHomePath(location.pathname) || isApiPath(location.pathname)
          ? "stiky-header"
          : "hidde-content-header"
      } ${
        accountStore.isConnectedWallet()
          ? `u-zindex-9 u-position-relative`
          : "header-home-page"
      } ${isAdmin(location.pathname) && "admin-header"}`}
    >
      {getSubHeader(location.pathname)}
    </ContentContainer>
  );
};

const HeaderApp = () => {
  const location = useLocation();
  const [menu] = useState([]);
  const [showMenu, setMenuActive] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(
    accountStore.getCurrentAccount()
  );
  const [displayName, setDisplayName] = useState(
    currentAccount ? accountStore.shortAccountAddress(currentAccount) : null
  );

  const handleShowMenu = () => {
    setMenuActive(!showMenu);
    const element = document.querySelector(".menu-element");
    const mediaQuery = window.matchMedia("(max-width: 992px)").matches;
    if (mediaQuery) {
      if (!showMenu) {
        if (element.classList.contains("hiddeMenu")) {
          element.classList.remove("hiddeMenu");
          element.classList.add("showMenu");
        } else {
          element.classList.add("showMenu");
        }
      } else {
        element.classList.add("hiddeMenu");
        element.classList.remove("showMenu");
      }
      setTimeout(() => {
        element.classList.remove("hiddeMenu");
      }, 500);
    }
  };

  const handleShowDropDown = (e) => {
    if (!myRef.current?.contains(e.target)) {
      setOutSideElement(true);
    } else {
      setOutSideElement(false);
    }
  };
  const handleShowList = () => {
    const ELEMENT_LIST = document.querySelector(".element-dropdown");
    if (ELEMENT_LIST.classList.contains("show-dropdown")) {
      handleCloseDropDown();
    } else {
      ELEMENT_LIST.classList.add("show-dropdown");
    }
  };
  const handleCloseDropDown = () => setOutSideElement(true);

  const [outSideElement, setOutSideElement] = useState(true);
  const myRef = useRef();

  // const handleClickInside = () => setOutSideElement(false);
  const onSwitchAccount = (a: Account) => {
    handleCloseDropDown();
    accountStore.switchAccount(a.account);
  };

  const [modalStyle] = useState(getModalStyle);
  const classes = modalStyles();

  useEffect(() => {
    const s = accountStore.currentAccount.subscribe({
      next: (v) => {
        setCurrentAccount(v);
        if (v) {
          setDisplayName(accountStore.shortAccountAddress(v));
        }
      },
    });

    const ELEMENT_DROPDOWN = document.querySelector(".element-dropdown");
    if (ELEMENT_DROPDOWN !== null) {
      if (outSideElement) {
        ELEMENT_DROPDOWN.classList.remove("show-dropdown");
      } else {
        // ELEMENT_DROPDOWN.classList.add("show-dropdown");
      }
      document.addEventListener("mousedown", handleShowDropDown);
      return () => {
        s.unsubscribe();
        document.removeEventListener("mousedown", handleShowDropDown);
      };
    }
  });
  const [openModal, setOpenModal] = useState(false);
  const [label, setLabel] = useState("");
  const [errorsText, setErrorsText] = useState("");
  const handleChange = (e) => {
    const { value } = e.target;
    setLabel(value);
    if (value.length > 20) {
      setErrorsText("Maximum characters 20");
    } else {
      setErrorsText("");
    }
  };
  // TODO: REFACTOR
  const [pending, setPending] = useState(false);
  const [editAccount, setEditAccount] = useState(null);
  const onEditName = async (a: accountStore.Account) => {
    handleCloseDropDown();
    setErrorsText("");
    setLabel(a.name);
    // trigger form to show and store a reference to the current editing account so we can access later
    setEditAccount(a);
  };

  const onSaveName = async () => {
    setPending(true);
    if (!editAccount) {
      return;
    }
    if (errorsText === "") {
      editAccount.name = label;
      try {
        setPending(false);
        setEditAccount(null);
        setDisplayName(accountStore.shortAccountAddress(currentAccount));
      } catch (e) {
        toast.error("Fail to save account name. Try again");
      }
    }
  };

  const [, setCopied] = useState(false);
  const copyToClipboard = (text: string) => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setCopied(true);
    toast.success("Copied");
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <>
      {location.pathname === "/form-search" ||
      location.pathname === "/search" ? (
        ""
      ) : (
        <HeaderContent className="container-full element-header nav-bar-scroll">
          <div className="container">
            <Nav>
              <a href="/" className="link-logo logo-no-home-page">
                <img
                  src={`${process.env.PUBLIC_URL + assets.logo}`}
                  alt="OpenStory"
                  width="110"
                />
                <span>V0</span>
              </a>
              <ButtonMenu className="u-color-dark" onClick={handleShowMenu}>
                <FaBars />
              </ButtonMenu>
              {accountStore.isConnectedWallet() ? (
                <div className="u-display-flex">
                  <ul className="menu-element">
                    {menu.map((m, i) => (
                      <li key={m.link}>
                        <NavLink
                          onClick={handleShowMenu}
                          to={`/${m.link}`}
                          activeClassName="active"
                        >
                          {m.item}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                  <DropDown ref={myRef}>
                    <HeaderAvatar
                      onClick={(e) => {
                        handleShowDropDown(e);
                        handleShowList();
                      }}
                    >
                      <p>
                        <span className="display-name">
                          {currentAccount &&
                          currentAccount !== "" &&
                          displayName !== ""
                            ? displayName
                            : "Not Connected"}
                        </span>{" "}
                      </p>
                      <img
                        src={`${
                          process.env.PUBLIC_URL +
                          accountStore.getIcon(currentAccount)
                        }`}
                        alt=""
                      />
                      <BiCaretDown
                        className={`${!outSideElement ? "rotate-arrow" : ""}`}
                      />
                    </HeaderAvatar>
                    <div className="dropdown-content element-dropdown">
                      {Object.keys(accountStore.getAllAccounts()).map(
                        (address, index) => {
                          const a = accountStore.findByAddress(address);
                          return a ? (
                            <div key={`account-${address}`}>
                              {currentAccount === address && (
                                <div className="dropdown-content__item u-flex-direction-column">
                                  <div>
                                    <span className="active-account">
                                      Active
                                    </span>
                                    <div className="dropdown-content__item--wallet u-space-between">
                                      <div
                                        className="dropdown-content__item--wallet u-width-100"
                                        onClick={() => onSwitchAccount(a)}
                                      >
                                        <div className="dropdown-content__item--identicon">
                                          <img
                                            src={`${
                                              process.env.PUBLIC_URL +
                                              accountStore.getIcon(a)
                                            }`}
                                            alt={a.provider}
                                            width={40}
                                            height={40}
                                          />
                                        </div>
                                        <div
                                          className="dropdown-content__item--contract u-width-100"
                                          onClick={() => onSwitchAccount(a)}
                                        >
                                          <span>
                                            {accountStore.shortAccountAddress(
                                              address
                                            )}
                                          </span>
                                          <small>{a.provider}</small>
                                        </div>
                                      </div>

                                      <div className="u-display-flex">
                                        <button
                                          className="header-btn-icon"
                                          onClick={() => onEditName(a)}
                                        >
                                          <BsPencil />
                                        </button>
                                        <button
                                          className="header-btn-icon"
                                          onClick={() =>
                                            copyToClipboard(address)
                                          }
                                        >
                                          <BiCopyAlt />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null;
                        }
                      )}

                      <div className="dropdown-content__items">
                        {Object.keys(accountStore.getAllAccounts()).map(
                          (address, index) => {
                            const a = accountStore.findByAddress(address);
                            return a ? (
                              <div key={`account-content-${address}`}>
                                {currentAccount !== address && (
                                  <div className="dropdown-content__item u-flex-direction-column">
                                    <div className="dropdown-content__item--wallet u-space-between">
                                      <div
                                        className="dropdown-content__item--wallet u-width-100"
                                        onClick={() => onSwitchAccount(a)}
                                      >
                                        <div className="dropdown-content__item--identicon">
                                          <img
                                            src={`${
                                              process.env.PUBLIC_URL +
                                              accountStore.getIcon(a)
                                            }`}
                                            alt={a.provider}
                                            width={40}
                                            height={40}
                                          />
                                        </div>
                                        <div
                                          className="dropdown-content__item--contract u-width-100"
                                          onClick={() => onSwitchAccount(a)}
                                        >
                                          <span>
                                            {accountStore.shortAccountAddress(
                                              address
                                            )}
                                          </span>
                                          <small>{a.provider}</small>
                                        </div>
                                      </div>

                                      <div className="u-display-flex">
                                        <button
                                          className="header-btn-icon"
                                          onClick={() => onEditName(a)}
                                        >
                                          <BsPencil />
                                        </button>
                                        <button
                                          className="header-btn-icon"
                                          onClick={() =>
                                            copyToClipboard(address)
                                          }
                                        >
                                          <BiCopyAlt />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null;
                          }
                        )}
                      </div>

                      <>
                        <div
                          className="dropdown-content__item"
                          onClick={() => {
                            setOpenModal(true);
                            handleCloseDropDown();
                          }}
                        >
                          <BiPlus className="dropdown-content__item--icon" />
                          Connect another wallet
                        </div>

                        <div
                          className="dropdown-content__item"
                          onClick={() => handleCloseDropDown()}
                        >
                          <BiWalletAlt className="dropdown-content__item--icon" />{" "}
                          Manage Wallets
                        </div>

                        <div
                          className="dropdown-content__item"
                          onClick={() => {
                            handleCloseDropDown();
                            accountStore.logoutAll();
                          }}
                        >
                          <BiLogOut className="dropdown-content__item--icon" />
                          Sign out of all accounts
                        </div>
                      </>
                      {/* <div
                      className="dropdown-content__item"
                      onClick={() => setOpenModal(true)}
                    >
                      Connect your wallet
                    </div> */}
                    </div>
                  </DropDown>
                </div>
              ) : (
                <ul className="menu-element">
                  <li>
                    <NavLink
                      onClick={handleShowMenu}
                      to={`/about`}
                      activeClassName="active"
                    >
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={handleShowMenu}
                      to={`/contact`}
                      activeClassName="active"
                    >
                      Contact
                    </NavLink>
                  </li>

                  {/*<li key="profile">
                      <Button
                        className="btn-home-connect-header"
                        color="primary"
                        onClick={() => setOpenModal(true)}
                      >
                        Connect Wallet
                      </Button>
                    </li>*/}
                </ul>
              )}
            </Nav>
          </div>

          <ModalConnect openModal={openModal} setOpenModal={setOpenModal} />

          <Modal open={editAccount !== null}>
            <div
              className={`${classes.paper}  modalsize-create-label`}
              style={modalStyle}
            >
              <BiPlus
                className="close-modal-button"
                onClick={() => setEditAccount(null)}
              />
              <div className="modal-content modal-custom-padding">
                <Text
                  color="primary"
                  size="large"
                  weight="medium2"
                  transform="uppercase"
                  className="modal-content__title-modal u-margin-bottom-2"
                >
                  CREATE LABEL
                </Text>
                <Input
                  placeholder="Enter Name"
                  name="nameLabel"
                  value={label}
                  onchange={handleChange}
                  align="left"
                  autofocus={true}
                  label="Name"
                  className={`input-create-label u-margin-top-1 u-margin-bottom-1 ${
                    errorsText !== "" ? "u-input-border-red" : ""
                  }`}
                >
                  <Text color="danger" size="small">
                    {errorsText}
                  </Text>
                </Input>
                <div className="modal-content__butttons">
                  <Button
                    size="medium"
                    color="primary"
                    icon="icon-right"
                    onClick={() => setEditAccount(null)}
                  >
                    Cancel
                  </Button>
                  {!pending ? (
                    <Button
                      size="medium"
                      color="primary"
                      icon="icon-right"
                      onClick={() => onSaveName()}
                    >
                      Save
                    </Button>
                  ) : (
                    <Loader color="primary" className="label-loader-icon" />
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </HeaderContent>
      )}
    </>
  );
};

export default Header;
