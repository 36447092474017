import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";
import { Box, Badge } from "@material-ui/core";
import { Button } from "../../ui/styled";

export const ProfileInfo = styled(Box)({
  ".small-chip": {
    cursor: "pointer",
    border: "0",
    marginBottom: "0",
    marginLeft: "5px",
    marginRight: "3px",
    height: "16px!important",
    backgroundColor: theme.colors.primaryf2e8ff,
    "&:first-child": {
      marginLeft: "0px"
    },
    ".MuiChip-label": {
      fontSize: "12px",
      color: theme.colors.primary,
      fontFamily: theme.weight.medium2,
    },
  },
  ".name-profile": {
    fontSize: "17px",
    marginBottom: "5px",
    color: theme.colors.dark,
    fontFamily: theme.weight.medium2,
  },
  ".active-since-date": {
    fontSize: "11px",
    marginTop: "8px"
  },
});

export const FollowButton = styled(Button)({
  background: "none",
  marginTop: "10px",
  fontSize: "12px!important",
  color: theme.colors.primary,
  fontFamily: theme.weight.medium2,
  "&:hover": {
    background: "none",
  },
  svg: {
    marginRight: "8px",
    position: "relative",
    fontSize: "12px!important",
    fontFamily: theme.weight.medium2,
  },
});

export const ProfileIcon = styled(Box)({
  width: "30px",
  height: "30px",
  display: "flex",
  marginRight: "15px",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  background: "#D0ACFF",

  top: "-5px",
  left: "5px",
  position: "relative",

});


export const TastersImages = styled(Box)({
  img: {
    width: "65px",
    height: "65px",
    marginRight: "8px"
  }
})