import * as apiClient from "../services/api";
import { clearPref, getPref } from "../stores/pref";

import {
  InputType
} from './search';

export interface Question {
  twitter_account?: string;
  ens?: string;
  query: string;
  query_type?: InputType;
  hit_miss?: boolean;
  click_through?: boolean;
};

export interface QuestionCollectionParams {
  twitter_account?: string;
  ens?: string;
  question_list: Array<string>;
};

export const QuestionCacheKey = "cached_question";

export const addQuestion = async <T>(): Promise<T> => {
  const params = getPref<QuestionCollectionParams>(QuestionCacheKey, {
    twitter_account: "",
    ens: "",
    question_list: []
  });

  if(params.question_list.length > 0) {
    try {
      const resp = await apiClient.create("/questions", params)
      clearPref(QuestionCacheKey);
      return resp;
    } catch(err) {
      return Promise.reject(err)
    }
  } else {
    throw new Error("Does not provide any questions.")
  }
}

export const listQuestions = (): Promise<Question[]> => {
  return apiClient.list<Question>("/questions");
}


export const getQuestion = (question: string): Promise<Question> => {
  return apiClient.find<Question>("/questions/" + question);
}


export const deleteQuestion = <Res>(path: string): Promise<Res> => {
  return apiClient.destroy(path);
}


export const updateQuestion = <Res>(path: string, body: any): Promise<Res> => {
  return apiClient.update(path, body)
}
