import { Box } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NftCollections from "../app/components/pages/search/search-results/nft-collections";
import SearchDropdown from "../app/components/SearchDropdown";

import { getNftCollectionInfo, NftCollectionInfo } from "../app/models/nft";
import { Loader } from "../app/components/ui/Loader";
interface CollectionsPageProps {}

const CollectionsPage: FC<CollectionsPageProps> = () => {
  const { slug } = useParams<{ slug: string }>();
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState<NftCollectionInfo>({});
  const loadCollectionData = async () => {
    setLoading(true);
    try {
      const dataInfo = await getNftCollectionInfo(slug);
      setCollection(dataInfo);
    } catch (e) {
      console.log("error when loading collection info", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCollectionData();
  }, [slug]);
  return (
    <>
      <div className="container">
        <SearchDropdown initInput={collection?.name && collection?.name} />
      </div>
      <hr className="line-bottom" />
      <div
        className="container content-min-height"
        style={{ position: "relative" }}
      >
        <Box width="100%">
          {Object.keys(collection).length === 0 ? (
            <div className="loader-results">
              <Loader color="primary" />
            </div>
          ) : (
            <NftCollections collection={collection} />
          )}
        </Box>
      </div>
    </>
  );
};

export default CollectionsPage;
