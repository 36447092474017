import { FC, useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { AiOutlineFilter } from "react-icons/ai";
import Button from "@material-ui/core/Button";

import { useLocation } from "react-router-dom";

interface FilterTokenProps {
  onChange: (model) => void;
  initialFilter: any;
}

const FilterToken: FC<FilterTokenProps> = ({
  onChange,
  initialFilter
}) => {
  const [filter, setFilter] = useState(initialFilter);

  const onFilterChange= (e) => {
    if (e.target.value && e.target.value !== "" && e.target.value !== "any" && e.target.value !== "null") {
      setFilter({...filter, [e.target.name]: e.target.value})
    } else {
      delete filter[e.target.name];
      setFilter({...filter});
    }
  }

  const onSearch = () => {
    onChange({...filter});
  }

  const location = useLocation();
  useEffect(() => {
    let o = {};
    let qs = new URLSearchParams(location.search);

    if (qs.get("q") && qs.get('q') !== "" && qs.get('q') !== 'any' && qs.get('q') !== 'null') {
      o.q = qs.get("");
    } else {
      delete o['q'];
    }

    if (qs.get('status') && qs.get('status') !== 'any') {
      o.status = qs.get('status');
    } else {
      delete o['status'];
    }

    if (qs.get('chainid') && qs.get('chainid') !== 'any') {
      o.chainid = qs.get('chainid');
    } else {
      delete o['chainid'];
    }

    setFilter(o);
    onChange(o);
  }, [location.search])
  return (
    <div className="u-margin-bottom-1 filter-admin-content">
      <span>
        <AiOutlineFilter /> Filter
      </span>
      <div>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            label="Token name/address"
            variant="standard"
            value={filter?.q}
            name="q"
            onChange={onFilterChange}
          />
        </FormControl>

        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <InputLabel id="demo-simple-select-standard-label">
            Status{" "}
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={filter?.status || "any"}
            label="Status"
            name="status"
            onChange={onFilterChange}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"pending"}>Pending</MenuItem>
            <MenuItem value={"verified"}>Verified</MenuItem>
            <MenuItem value={"rejected"}>Rejected</MenuItem>
          </Select>
        </FormControl>

        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <InputLabel id="demo-simple-select-standard-label">
            Chain{" "}
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={filter?.chainid || "any"}
            label="Chain"
            name="chainid"
            onChange={onFilterChange}
          >
            <MenuItem value={"any"}>Any</MenuItem>
            <MenuItem value={"1"}>Ethereum Mainnet</MenuItem>
            <MenuItem value={"56"}>Binance Smart Chain</MenuItem>
            <MenuItem value={"137"}>Polygon POS</MenuItem>
            <MenuItem value={"43114"}>AVAX</MenuItem>
          </Select>
        </FormControl>

        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <Button
            variant="contained"
            size="small"
            onClick={onSearch}
          >
            Filter
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

export default FilterToken;
