import { FC, useRef, useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import { ClickOutideElement } from "../../../services/click-outside-element";
import { useLocation } from "react-router-dom";
import { assets } from "../../../models/company_asset";

import { HeaderBox, MenuButton } from "..";
import { isHomePath } from "../../../services/navigation";

interface HeaderSearchProps { }

const HeaderSearch: FC<HeaderSearchProps> = () => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = ClickOutideElement(dropdownRef, false);
  const { pathname } = useLocation();
  const [isHome, setIsHome] = useState(true);
  const headerElement = useRef(null);
  useEffect(() => {
    setIsHome(isHomePath(pathname));
    if (!isHome) {
      headerElement?.current?.offsetParent?.classList.remove("stiky-header");
    }
  }, [pathname, isHome]);
  return (
    <HeaderBox ref={headerElement}>
      <div
        ref={dropdownRef}
        className={`${isActive ? "show-menu-search" : ""}`}
      >
        {isActive && pathname === "/" && (
          <a href="/" className="only-home-logo">
            <img
              src={`${process.env.PUBLIC_URL + assets.logo}`}
              alt={assets.companyName}
            />
          </a>
        )}

        <MenuButton onClick={() => setIsActive(!isActive)}>
          <FaBars />
        </MenuButton>

        <ul className={`list-search-menu menu-search`}>
          <li>
            <Link
              href="/"
              className={pathname === "/" ? "active-menu-search " : ""}
            >
              Home
            </Link>
          </li>
          <li>
            <NavLink
              to={`/data-api`}
              onClick={() => setIsActive(!isActive)}
              activeClassName={
                pathname === "/data-api" ? "active-menu-search " : ""
              }
            >
              API
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/about`}
              onClick={() => setIsActive(!isActive)}
              activeClassName={
                pathname === "/about" ? "active-menu-search " : ""
              }
            >
              About
            </NavLink>
          </li>

        </ul>
      </div>
    </HeaderBox>
  );
};

export default HeaderSearch;
