import styled from "@emotion/styled";

export const ShadowBox = styled.div`
  color: turquoise;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  margin: 3em;
`;

export const CircleBox = styled.div(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: "50%",
  border: "1px solid #f3f3f3",
  background: "#fafafa",
  fontSize: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 24,

  [theme.breakpoints.down("sm")]: {
    width: 40,
    height: 40,
    fontSize: "20px",
  },
}));
