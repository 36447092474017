import { FC } from "react";
import { WidgetTitle } from "../../index";
import umbLogo from "../../../../../images/icon1.png";
interface TopCoinListProps {}

const TopCoinList: FC<TopCoinListProps> = () => {
  const topData = {
    symbol: "UMB",
    address: " 0x4k1...",
    logo: umbLogo,
    top: [
      { address: "0x4k1...", tags: ["Land Owner", "Gamer"] },
      { address: "0x85s...", tags: ["Degen"] },
      { address: "0xq96...", tags: ["Gamer"] },
    ],
  };
  return (
    <div className="top-list">      
      <WidgetTitle>
        Top 1 is also on the {""} <a href="" className="u-text-no-bold"> Ape coin top 10</a>
      </WidgetTitle>
      <ul>
        {topData.top.map((x: any, i: number) => (
          <li key={i}>
            <span>Top {i + 2}</span>
            <a href="" className="top-list__address">
              {x.address}
            </a>
            {x.tags.map((t: any, j: number) => (
              <a key={j} href="" className="top-list__tag">
                {t}
              </a>
            ))}
          </li>
        ))}
      </ul>
      <button className="btn-top-all">see top 10</button>
    </div>
  );
};

export default TopCoinList;
