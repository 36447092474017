import { FC, Fragment, useEffect, useState } from "react";
import { ChipContent } from "../../ui/styled/chipers";
import { HeaderContentRow } from "../../ui/styled/header-content-row";
import { FaUserCheck, FaUserPlus } from "react-icons/fa";
import _ from "underscore";
import { Link, Text } from "../../ui/styled";
import { shortAddress } from "../../../services/formatter";

import { isStaff } from "../../../stores/account";
import { Web3Activity } from "../../../models/activity";
import { Box } from "@material-ui/core";
import { getTagName, tagToSlug } from "../../../models/tag";
import { NavLink } from "react-router-dom";
import ActivityTitle from "../search/search-results/address-component/activity-title";
import { toggleFollow } from "../../../models/profile";
import { toast } from "react-toastify";
interface ActivityRowHeaderProps {
  item: any;
  tagItems?: any;
  type?: string;
  params?: any;
}

const adminLabelLink = (item: Web3Activity): string => {
  const qs = {
    signature: "0x" + item?.signature,
    address: item?.to_address,
    method: item?.action?.action,
  };

  if (item?.action?.label_id) {
    return `/admin/activity-labels/${item?.action?.label_id || ""}`;
  } else {
    return `/admin/activity-labels/create/?${new URLSearchParams(
      qs
    ).toString()}`;
  }
};

interface ModToolbarProps {
  item: Web3Activity;
}

const ModToolbar: FC<ModToolbarProps> = ({ item }) => {
  return (
    <Box display="flex" className="aux-links">
      <Link
        href={adminLabelLink(item || [])}
        target="_blank"
        rel="noreferrer"
      >
        [lbl]
      </Link>
      <Link
        href={`https://etherscan.io/tx/${item?.tx_hash}`}
        target="_blank"
        rel="noreferrer"
      >
        [tx]
      </Link>
    </Box>
  );
};

const DefaultActivity: FC<ActivityRowHeaderProps> = ({ item, tagItems, params }) => {


  const { address, ens, stats, tags, followed } = item
  const [follow, setFollow] = useState(followed)
  const tagsSelected = (params?.tags || "").split(",").filter((e: string) => e != "")

  // when user not selected any tags, we attempt to display all tag on profile
  const profileTagsToDisplay = tagsSelected.length == 0 ?
    tags :
    _.filter(tagsSelected, (tag) => {
      return _.iteratee((tagItems: []) => tagItems === tag)
    })

  const handleFollowProfile = async (address: string, flag: boolean) => {
    setFollow(!follow)
    try {
      await toggleFollow(address, flag);
    } catch (err) {
      toast.error("fail to follow this profile. try again")
    }
  }

  const [tagItem, setTagItem] = useState<any>([])

  useEffect(() => {
    Object.keys(tagItems).filter(x => {
      if (stats.nft_collected !== 0 && (x === "collector" || x === "whale" || x === "bluechipper")) {
        setTagItem(oldArray => [...oldArray, `collected ${stats.nft_collected}`])
      }
      if (stats.nft_for_sale !== 0 && x === "flipper") {
        setTagItem(oldArray => [...oldArray, `flipped ${stats.nft_for_sale}`])
      }

      if (stats.nft_minted !== 0 && x === "pfp-minter") {
        setTagItem(oldArray => [...oldArray, `minted ${stats.nft_minted}`])
      }

      if (x === "creator") {
        setTagItem(oldArray => [...oldArray, `created ${stats.nft_collected}`])
      }

      if (x === "dev-builder") {
        setTagItem(oldArray => [...oldArray, `deployed ${stats.contract_deployed} smart contracts`])
      }
    })
  }, [])

  return (
    <HeaderContentRow>

      <div className="activity">
        <span className="circle-item"></span>

        {ens ? (
          <Link color="dark"
            href={`/profiles/${address}`}
          >
            {ens && shortAddress(ens, 40, 0)}
          </Link>
        ) : (
          <Link color="dark"
            href={`/profiles/${address}`}>
            {
              ens !== "" ? ens : address.substr(0, 5)
            }
          </Link>
        )}

        {profileTagsToDisplay.slice(0, 3).map((tag: string) => (
          <Fragment key={tag}>
            {tag ? <NavLink style={{ paddingRight: "0px" }}
              to={`/tags/${tagToSlug(tag)}`}
            >
              <ChipContent
                size="small"
                color="primary"
                className="small-chip u-text-transform-lowercase"
                label={getTagName(tag)}
              />
            </NavLink> : ""}
          </Fragment>
        ))}
        {tagItem.map((tag: string) => (
          <small key={tag}>
            {tag}
          </small>
        ))}
      </div>
      <div className="u-margin-left-1" onClick={() => handleFollowProfile(address, !follow)}>
        {follow ? <FaUserCheck className="u-color-dark" /> : <FaUserPlus />}
      </div>
    </HeaderContentRow >
  )
}

const ProfileActivityTitle: FC<ActivityRowHeaderProps> = ({ item }) => {
  return (
    <HeaderContentRow>
      <div className="activity">
        <Text className="profile-title">
          <ActivityTitle item={item} />
          {/* Sold an item of <span>Dream Bears Official</span> on Opensea */}
        </Text>
      </div>
    </HeaderContentRow>
  )
}

const CollectionActivityTitle: FC<ActivityRowHeaderProps> = ({ item }) => {
  return (
    <HeaderContentRow>
      <div className="activity">
        <p>{item.wallet_address}</p>
        <Text className="profile-title">
          <ActivityTitle item={item} />
        </Text>
      </div>
    </HeaderContentRow>
  )
}


const TabFollowersTitle: FC<ActivityRowHeaderProps> = ({ item, tagItems, params }) => {
  const { address, ens, stats, tags, followed } = item
  const [follow, setFollow] = useState(false)



  const tagsSelected = params?.tags?.split(",")

  const profileTagsToDisplay = _.filter(tagsSelected, (tag) => {
    return _.iteratee((tagItems: []) => tagItems === tag)
  })

  return (
    <HeaderContentRow>
      <div className="activity">
        <span className="circle-item"></span>

        {ens ? (
          <Link color="dark"
            href={`/profiles/${address}`}
          >
            {ens && shortAddress(ens, 40, 0)}
          </Link>
        ) : (
          <Link color="dark"
            href={`/profiles/${address}`}>
            {
              ens !== "" ? ens : address.substr(0, 5)
            }
          </Link>
        )}

        {(tags || []).slice(0, 3).map((tag: string) => (
          <Fragment key={tag}>
            {tag ? <NavLink
              to={`/tags/${tagToSlug(tag)}`}
            >
              <ChipContent
                size="small"
                color="primary"
                className="small-chip u-text-transform-lowercase"
                style={{ paddingRight: "0px" }}
                label={getTagName(tag)}
              />
            </NavLink> : ""}
          </Fragment>
        ))}
      </div>
      {/* TODO: change follow with the api (followed) */}
      <div onClick={() => setFollow(!follow)}>
        {follow ? <FaUserCheck className="u-color-dark" /> : <FaUserPlus />}
      </div>
    </HeaderContentRow>
  )
}


const typeActivity: { [key: string]: any } = {
  "profile": ProfileActivityTitle,
  "collection": CollectionActivityTitle,
  "nftowner": ProfileActivityTitle,
  "followers": TabFollowersTitle
}

const ActivityRowHeader: FC<ActivityRowHeaderProps> = ({ type, item, tags, params }) => {
  const Component = typeActivity[type] || DefaultActivity // TODO: change layout according to activity and change "type" to actionType
  return <>

    {isStaff() && <ModToolbar item={item} />}

    <Component item={item} tagItems={tags} params={params} />
  </>
};

export default ActivityRowHeader;
