import { useEffect, useRef } from "react";
import { assets } from "../../models/company_asset";

interface HeaderMetaProps {
  title?: string;
  description?: string;
  image?: string;
}

export const HeaderMeta = ({ title, description, image }: HeaderMetaProps) => {
  const metaRef = useRef(null);
  const metaAdder = (queryProperty: string, value: string) => {
    metaRef.current = document.querySelector(`meta[${queryProperty}]`);    

    if (metaRef || metaRef?.current) {
      metaRef?.current?.setAttribute("content", value);
    } else {
      metaRef.current = `<meta ${queryProperty} content="${value}" />`;
      document.head.insertAdjacentHTML("beforeend", metaRef.current);
    }
  };
  useEffect(() => {
    if (title) {
      document.title = title + " | " + assets.companyName;
      metaAdder(`name="title"`, title + " | " + assets.companyName);
      metaAdder(`property="og:title"`, title + " | " + assets.companyName);
      metaAdder(`property="twitter:title"`, title + " | " + assets.companyName);
    }
    if (description) {
      metaAdder(`name="description"`, description);
      metaAdder(`property="og:description"`, description);
      metaAdder(`property="twitter:description"`, description);
      
    }
    if (image) {
      metaAdder(`property="og:image"`, image);
      metaAdder(`property="twitter:image"`, image);
    }
  }, [title, description, image]);
};
