
import { useState, useEffect } from 'react';

const storage = window.localStorage;
let prefs:{[key: string]: any} = {}

if (storage.getItem('prefs')) {
  prefs = JSON.parse(storage.getItem('prefs') || '{}')
}

const savePref = <T>(key: string, value: T) => {
  prefs[key] = value;
  storage.setItem('prefs', JSON.stringify(prefs))
}

export const getPref = <T>(key: string, defaultValue: T): T => {
  let data = prefs[key]

  console.log('get pref', key, 'found current value in storage', data, prefs);

  if (data) {
    return data;
  }

  return defaultValue;
}

export const clearPref = (key: string) => {
  delete prefs[key];
  storage.setItem('prefs', JSON.stringify(prefs))
}

export const usePref = <T>(key: string, defaultValue: T): [T, (v: T) => void] => {
  const [value, setValue] = useState(getPref<T>(key, defaultValue));
  console.log("found current pref", value, "with default value", defaultValue);

  useEffect(() => {
    console.log('update pref', key, value);
    savePref(key, value);
  }, [key, value]);

  return [value, setValue];
};
