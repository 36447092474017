import { BigNumber } from "ethers";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { AddressProfile } from "../../../../../models/address";
import {
  nftImage,
  NftImageRequest,
  DEFAULT_NFT_PLACEHOLDER,
} from "../../../../../models/avatar";

import { NFT_NUMBER_TO_SHOW } from "../../../../../models/search";

export const NftPictureItem: FC<{ nft: NftImageRequest; type?: string }> = ({
  nft,
  type,
}) => {  
  return (
    <NavLink to={`/nfts/${nft.address}/${nft.token_id}`}>
      <img
        className="img-nft-assets"
        src={nftImage(nft, type)}
        data-src={nftImage(nft, type)}
        alt=""
        loading="lazy"
        onError={({ currentTarget }) => {
          // If we cannot load the images for whatever reason, fallback to our placeholder
          // Example NFT returns this https://storage.googleapis.com/phape/2022.png but it's invalid since it's a bucket on google cloud
          currentTarget.src = DEFAULT_NFT_PLACEHOLDER;
          // TODO: Pass down NFT name and display here so when user hover the image it show the alt text
          // currentTarget.alt=`${x.token_id}`
        }}
        // onLoad={({ currentTarget }) => {
        //   currentTarget.src = nftImage(x);
        // }}
      />
    </NavLink>
  );
};

interface ProfileNftAssetsProps {
  data_nfts?: AddressProfile;
  token_id?: string;
}

const ProfileNftAssets: FC<ProfileNftAssetsProps> = ({
  token_id,
  data_nfts,
}) => {
  return (
    <div className="profile-nfts">
      {data_nfts?.poap_assets
        ?.concat(
          data_nfts?.nft_assets?.filter((nft) =>
            !token_id || !BigNumber.from(nft.token_id || 0).eq(BigNumber.from(token_id || 0))
          ) || []
        )
        .slice(0, NFT_NUMBER_TO_SHOW)
        .map((x: any, i: number) => (
          <NftPictureItem key={i} nft={x} />
        ))}
      {data_nfts?.poap_assets?.concat(
        data_nfts?.nft_assets?.filter((nft) =>
          !token_id || !BigNumber.from(nft.token_id || 0).eq(BigNumber.from(token_id || 0))
        ) || []
      ).length === 0 && (
        <div className="text-placeholder u-margin-top-2">No records</div>
      )}
    </div>
  );
};

export default ProfileNftAssets;
