import styled from "@emotion/styled";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme/theme";

interface ButtonProps {
  color?: string;
  size?: string;
  expand?: string;
  icon?: string;
  transform?: string;
}
interface SectionTitleProps {
  color?: string;
  size?: string;
  weight?: string;
}
interface TextProps {
  color?: string;
  size?: string;
  weight?: string;
  align?: string;
  transform?: string;
}
interface LabelProps {
  color?: string;
  size?: string;
  weight?: string;
  align?: string;
  transform?: string;
}
interface BadgeProps {
  color?: string;
  size?: string;
  transform?: string;
}
interface ButtonIconProps {
  color?: string;
  size?: string;
}
interface InputProps {
  transform?: string;
  align?: string;
}
interface LoadingProps {
  color?: string;
}
interface LinkProps {
  color?: string;
  decoration?: string;
  size?: string;
}
// Table component
export const Table = styled.table`
  width: 415px;
  border-collapse: collapse;
  margin: 1.5em 0;
  @media screen and (max-width: ${theme.breakpoint.lg}) {
    /* width: 1220px; */
  }
`;
export const TableContrctAddress = styled.div``;
export const TableItemRowSpan = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  flex-wrap: wrap;
  padding-left: 1em;
  padding-right: 1em;
  border-top: 1px solid ${theme.colors.secondaryExtraLight};
  p.u-word-break-all {
    font-size: 13px;
  }
`;
export const NoDataTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4em auto;
  p {
    margin-bottom: 1em;
  }
`;
export const TableHead = styled.thead`
  font-size: 13px;
  /* border-top: 1px solid ${theme.colors.secondaryExtraLight}; */
`;
export const TableHeadCell = styled.th`
  font-weight: 300;
  padding: 0.7em 1em;
  text-transform: uppercase;
  font-family: "Roboto";
  color: ${theme.colors.tertiaryLight};
  border-left: 1px solid #c5c5c5;
  &:first-child {
    border-left: none;
  }
`;
export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  background: ${theme.colors.contrast};
  border-bottom: 1px solid #c5c5c5;
  /* &:nth-of-type(1) {
    border-top: 1px solid ${theme.colors.secondaryExtraLight};
  } */
`;

export const TableCell = styled.td`
  word-break: break-word;
  padding: 0.9em 0.5em;
  font-size: 12px;
  color: #777777;
  .cell-content {
    display: flex;
    position: relative;
    .button-outline-icon {
      padding: 0;
      right: 0;
      position: absolute;
    }
  }

  img {
    margin-left: 25px;
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
  .td-balance {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    justify-content: center;
    tr {
      td {
        padding: 0.2rem;
      }
    }
  }
`;
// Title
export const SectionTitle = styled.h2<SectionTitleProps>`
  font-family: ${(props) =>
    props.weight === "light"
      ? `${theme.weight.light}`
      : props.weight === "medium"
        ? `${theme.weight.medium}`
        : props.weight === "bold"
          ? `${theme.weight.bold}`
          : `${theme.weight.medium}`};

  padding-left: 12px;
  color: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.dark}`};
  font-size: ${(props) =>
    props.size === "small"
      ? `${theme.size.small}`
      : props.size === "medium"
        ? `${theme.size.medium}`
        : props.size === "large"
          ? `${theme.size.large}`
          : `${theme.size.default}`};
  position: relative;
  margin-right: 1.5em;
  &:before {
    left: 0px;
    content: "";
    width: 3px;
    bottom: 1px;
    height: 25px;
    position: absolute;
    background: ${theme.colors.primary};
  }
`;
export const ListOrder = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 520px) {
    flex-wrap: wrap;
    align-items: flex-start;

    .button-add-investment {
      margin-left: 0;
      margin-top: 1em;
      height: 30px;
    }
  }
`;
export const Text = styled.p<TextProps>`
  font-family: ${(props) =>
    props.weight === "light"
      ? `${theme.weight.light}`
      : props.weight === "medium"
        ? `${theme.weight.medium}`
        : props.weight === "bold"
          ? `${theme.weight.bold}`
          : props.weight === "medium2"
            ? `${theme.weight.medium2}`
            : `${theme.weight.medium}`};
  text-transform: ${(props) =>
    props.transform === "uppercase"
      ? `${theme.transform.uppercase}`
      : props.transform === "lowercase"
        ? `${theme.transform.lowercase}`
        : props.transform === "capitalize"
          ? `${theme.transform.capitalize}`
          : `initial`};
  color: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.dark}`};
  font-size: ${(props) =>
    props.size === "small"
      ? `${theme.size.small}`
      : props.size === "medium"
        ? `${theme.size.medium}`
        : props.size === "large"
          ? `${theme.size.large}`
          : props.size === "extra-large"
            ? `${theme.size.extraLarge}`
            : props.size === "extra-small"
              ? `${theme.size.extraSmall}`
              : `${theme.size.default}`};
  font-weight: ${(props) => (props.size === "extra-large" ? 600 : "default")};
  text-align: ${(props) =>
    props.align === "center"
      ? `${theme.align.center}`
      : props.align === "left"
        ? `${theme.align.left}`
        : props.align === "right"
          ? `${theme.align.right}`
          : props.align === "justify"
            ? `${theme.align.justify}`
            : `${theme.align.left}`};
`;
export const Label = styled.label<LabelProps>`
  display: block;
  text-transform: ${(props) =>
    props.transform === "uppercase"
      ? `${theme.transform.uppercase}`
      : props.transform === "lowercase"
        ? `${theme.transform.lowercase}`
        : props.transform === "capitalize"
          ? `${theme.transform.capitalize}`
          : `initial`};
  font-family: ${(props) =>
    props.weight === "medium"
      ? `${theme.weight.medium}`
      : props.weight === "light"
        ? `${theme.weight.bold}`
        : props.weight === "bold"
          ? `${theme.weight.light}`
          : `${theme.weight.medium}`};
  color: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-dark"
                                            ? `${theme.colors.darkDark}`
                                            : props.color === "dark-extra-light"
                                              ? `${theme.colors.darkExtraLight}`
                                              : props.color === "contrast"
                                                ? `${theme.colors.contrast}`
                                                : `${theme.colors.dark}`};
  font-size: ${(props) =>
    props.size === "small"
      ? `${theme.size.small}`
      : props.size === "medium"
        ? `${theme.size.medium}`
        : props.size === "large"
          ? `${theme.size.large}`
          : props.size === "extra-small"
            ? `${theme.size.extraSmall}`
            : `${theme.size.default}`};
  text-align: ${(props) =>
    props.align === "center"
      ? `${theme.align.center}`
      : props.align === "left"
        ? `${theme.align.left}`
        : props.align === "right"
          ? `${theme.align.right}`
          : `${theme.align.left}`};
`;

// Buttons
// Buttons
export const ButtonText = styled.button<ButtonProps>`
  border: none;
  display: flex;
  cursor: pointer;
  font-size: ${(props) =>
    props.size === "small"
      ? `${theme.fontSize.small}`
      : props.size === "medium"
        ? `${theme.fontSize.medium}`
        : props.size === "large"
          ? `${theme.fontSize.large}`
          : props.expand === "small"
            ? `${theme.fontSize.small}`
            : props.expand === "medium"
              ? `${theme.fontSize.medium}`
              : props.expand === "large"
                ? `${theme.fontSize.medium}`
                : `initial`};
  position: relative;
  padding: 0 1em;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease all;
  font-family: "Roboto";
  padding-left: 1em;
  padding-right: 1em;
  color: ${theme.colors.contrast};
  background: none;
  text-transform: ${(props) =>
    props.transform === "uppercase"
      ? `${theme.transform.uppercase}`
      : props.transform === "lowercase"
        ? `${theme.transform.lowercase}`
        : props.transform === "capitalize"
          ? `${theme.transform.capitalize}`
          : `initial`};
  color: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.dark}`};
  &:hover {
    color: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primaryDark}`
      : props.color === "primary-light"
        ? `${theme.colors.primaryDark}`
        : props.color === "secondary"
          ? `${theme.colors.secondaryDark}`
          : props.color === "tertiary"
            ? `${theme.colors.tertiaryDark}`
            : props.color === "extra"
              ? `${theme.colors.extraDark}`
              : props.color === "danger"
                ? `${theme.colors.danger}`
                : props.color === "danger-light"
                  ? `${theme.colors.dangerLight}`
                  : props.color === "dark"
                    ? `${theme.colors.darkLight}`
                    : `${theme.colors.dark}`};
  }
  &:active {
    color: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primaryLight}`
      : props.color === "primary-light"
        ? `${theme.colors.primary}`
        : props.color === "secondary"
          ? `${theme.colors.secondaryLight}`
          : props.color === "tertiary"
            ? `${theme.colors.tertiaryLight}`
            : props.color === "extra"
              ? `${theme.colors.extraLight}`
              : props.color === "danger"
                ? `${theme.colors.danger}`
                : props.color === "danger-light"
                  ? `${theme.colors.dangerLight}`
                  : props.color === "dark"
                    ? `${theme.colors.darkLight}`
                    : `${theme.colors.dark}`};
  }
  padding-left: ${(props) => (props.icon === "icon-left" ? `1em` : "1em")};
  padding-right: ${(props) => (props.icon === "icon-right" ? `2em` : "1em")};
  svg {
    font-size: 1.5rem;
    position: absolute;
    left: ${(props) =>
    props.icon === "icon-left" ? `${theme.icon.left}` : "unset"};
    right: ${(props) =>
    props.icon === "icon-right" ? `${theme.icon.right}` : "unset"};
  }
  .loader-btn {
    position: absolute;
    left: ${(props) =>
    props.icon === "icon-left" ? `${theme.icon.left}` : "unset"};
    right: ${(props) =>
    props.icon === "icon-right" ? `${theme.icon.right}` : "unset"};
  }
`;
export const Button = styled.button<ButtonProps>`
  border: none;
  display: flex;
  cursor: pointer;
  font-size: ${(props) =>
    props.size === "small"
      ? `${theme.fontSize.small}`
      : props.size === "medium"
        ? `${theme.fontSize.medium}`
        : props.size === "large"
          ? `${theme.fontSize.large}`
          : props.expand === "small"
            ? `${theme.fontSize.small}`
            : props.expand === "medium"
              ? `${theme.fontSize.medium}`
              : props.expand === "large"
                ? `${theme.fontSize.medium}`
                : `initial`};
  position: relative;
  padding: 0 1em;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease all;
  font-family: "Roboto Light";
  color: ${theme.colors.contrast};
  text-transform: ${(props) =>
    props.transform === "uppercase"
      ? `${theme.transform.uppercase}`
      : props.transform === "lowercase"
        ? `${theme.transform.lowercase}`
        : props.transform === "capitalize"
          ? `${theme.transform.capitalize}`
          : `initial`};
  background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.dark}`};
  width: ${(props) =>
    props.size === "small"
      ? `${theme.sizeButton.small[0]}`
      : props.size === "extra-small"
        ? `${theme.sizeButton.extraSmall[0]}`
        : props.size === "medium"
          ? `${theme.sizeButton.medium[0]}`
          : props.size === "large"
            ? `${theme.sizeButton.large[0]}`
            : props.expand === "small"
              ? `${theme.expand.small[0]}`
              : props.expand === "medium"
                ? `${theme.expand.medium[0]}`
                : props.expand === "large"
                  ? `${theme.expand.large[0]}`
                  : `${theme.sizeButton.medium}`};
  height: ${(props) =>
    props.size === "small"
      ? `${theme.sizeButton.small[1]}`
      : props.size === "extra-small"
        ? `${theme.sizeButton.extraSmall[1]}`
        : props.size === "medium"
          ? `${theme.sizeButton.medium[1]}`
          : props.size === "large"
            ? `${theme.sizeButton.large[1]}`
            : props.expand === "small"
              ? `${theme.expand.small[1]}`
              : props.expand === "medium"
                ? `${theme.expand.medium[1]}`
                : props.expand === "large"
                  ? `${theme.expand.large[1]}`
                  : `${theme.sizeButton.medium[1]}`};
  &:hover {
    background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primaryDark}`
      : props.color === "primary-light"
        ? `${theme.colors.primaryDark}`
        : props.color === "secondary"
          ? `${theme.colors.secondaryDark}`
          : props.color === "tertiary"
            ? `${theme.colors.tertiaryDark}`
            : props.color === "extra"
              ? `${theme.colors.extraDark}`
              : props.color === "danger"
                ? `${theme.colors.danger}`
                : props.color === "danger-light"
                  ? `${theme.colors.dangerLight}`
                  : props.color === "dark"
                    ? `${theme.colors.darkLight}`
                    : `${theme.colors.dark}`};
  }
  &:active {
    background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primaryLight}`
      : props.color === "primary-light"
        ? `${theme.colors.primary}`
        : props.color === "secondary"
          ? `${theme.colors.secondaryLight}`
          : props.color === "tertiary"
            ? `${theme.colors.tertiaryLight}`
            : props.color === "extra"
              ? `${theme.colors.extraLight}`
              : props.color === "danger"
                ? `${theme.colors.danger}`
                : props.color === "danger-light"
                  ? `${theme.colors.dangerLight}`
                  : props.color === "dark"
                    ? `${theme.colors.darkLight}`
                    : `${theme.colors.dark}`};
  }
  padding-left: ${(props) => (props.icon === "icon-left" ? `1em` : "2em")};
  padding-right: ${(props) => (props.icon === "icon-right" ? `2em` : "2em")};
  svg {
    font-size: 1.1rem;
    position: absolute;
    left: ${(props) =>
    props.icon === "icon-left" ? `${theme.icon.left}` : "unset"};
    right: ${(props) =>
    props.icon === "icon-right" ? `${theme.icon.right}` : "unset"};
  }
  .loader-btn {
    position: absolute;
    left: ${(props) =>
    props.icon === "icon-left" ? `${theme.icon.left}` : "unset"};
    right: ${(props) =>
    props.icon === "icon-right" ? `${theme.icon.right}` : "unset"};
  }
`;
export const ButtonIcon = styled.button<ButtonIconProps>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) =>
    props.size === "small"
      ? `1rem`
      : props.size === "medium"
        ? `1.4rem`
        : props.size === "large"
          ? `1.5rem`
          : `1.1rem`};
  cursor: pointer;
  background: none;
  align-items: center;
  border-radius: 50%;
  height: ${(props) =>
    props.size === "small"
      ? `${theme.sizeFlatButton.small}`
      : props.size === "medium"
        ? `${theme.sizeFlatButton.medium}`
        : props.size === "large"
          ? `${theme.sizeFlatButton.large}`
          : `${theme.sizeFlatButton.medium}`};
  width: ${(props) =>
    props.size === "small"
      ? `${theme.sizeFlatButton.small}`
      : props.size === "medium"
        ? `${theme.sizeFlatButton.medium}`
        : props.size === "large"
          ? `${theme.sizeFlatButton.large}`
          : `${theme.sizeFlatButton.medium}`};
  transition: 0.2s ease all;
  color: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.secondaryGrey}`};
  /* &:hover {
    background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primaryExtraLight}`
      : props.color === "primary-light"
        ? `${theme.colors.primaryExtraLight}`
        : props.color === "secondary"
          ? `${theme.colors.secondaryExtraLight}`
          : props.color === "secondary-light"
            ? `${theme.colors.secondaryExtraLight}`
            : props.color === "tertiary"
              ? `${theme.colors.tertiaryExtraLight}`
              : props.color === "extra"
                ? `${theme.colors.extraExtraLight}`
                : props.color === "danger"
                  ? `${theme.colors.dangerExtraLight}`
                  : props.color === "danger-light"
                    ? `${theme.colors.dangerExtraLight}`
                    : props.color === "dark"
                      ? `${theme.colors.darkExtraLight}`
                      : `${theme.colors.darkExtraLight}`};
  } */
`;

export const Badge = styled.div<BadgeProps>`
  display: inline-block;
  padding: 0.2em 0.7em;
  font-size: 0.8em;
  border-radius: 2px;
  color: ${theme.colors.contrast};
  font-family: "Roboto Light";
  text-transform: ${(props) =>
    props.transform === "uppercase"
      ? `${theme.transform.uppercase}`
      : props.transform === "lowercase"
        ? `${theme.transform.lowercase}`
        : props.transform === "capitalize"
          ? `${theme.transform.capitalize}`
          : `initial`};
  background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : props.color === "warning"
                                                ? `${theme.colors.warning}`
                                                : `${theme.colors.dark}`};
`;
export const InputToggle = styled.div`
  display: flex;
  label {
    cursor: pointer;
    background: ${theme.colors.tertiaryLight};
    padding: 1px 8px;
    border-radius: 30px;
    font-size: 0.9rem;
    color: ${theme.colors.contrast};
    width: 80px;
    height: 30px;
    position: relative;
    span {
      width: 25px;
      height: 25px;
      left: 3px;
      top: 2px;
      border-radius: 50%;
      position: absolute;
      background: ${theme.colors.contrast};
    }
    &::before {
      content: "";
      position: absolute;
      left: 8px;
      top: 6px;
    }
    &::after {
      content: "OFF";
      position: absolute;
      right: 15px;
      top: 6px;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"]:checked ~ label {
    background: ${theme.colors.primary};
    span {
      left: 3px;
      animation: 0.3s left-side 1 forwards;
      @keyframes left-side {
        to {
          left: calc(100% - 28px);
        }
      }
    }
    &::before {
      content: "ON";
      position: absolute;
      left: 17px;
      top: 6px;
    }
    &::after {
      content: "";
      position: absolute;
      right: 8px;
      top: 6px;
    }
  }
`;
export const InputContent = styled.div<InputProps>`
  width: 350px;
  position: relative;
  display: inline-block;
  z-index: 9;
  input {
    height: 40px;
    width: 350px;
    margin: auto;
    display: block;
    font-size: 1rem;
    padding: 0 0.5em;
    border-radius: 10px;
    text-align: center;
    color: #222222;
    text-align: ${(props) =>
    props.align === "center"
      ? `${theme.align.center}`
      : props.align === "left"
        ? `${theme.align.left}`
        : props.align === "right"
          ? `${theme.align.right}`
          : props.align === "justify"
            ? `${theme.align.justify}`
            : `center`};
    text-transform: ${(props) =>
    props.transform === "uppercase"
      ? `${theme.transform.uppercase}`
      : props.transform === "lowercase"
        ? `${theme.transform.lowercase}`
        : props.transform === "capitalize"
          ? `${theme.transform.capitalize}`
          : `initial`};
    border: 1px solid #c5c5c5;
    outline: none !important;
    &:focus {
      border: 1px solid ${theme.colors.primary};
    }
    &:focus-visible {
      border: 1px solid ${theme.colors.primary};
    }
  }
  svg {
    color: ${theme.colors.tertiary};
  }
  .icon-left {
    position: absolute;
    left: 13px;
    top: 11px;
    /* transform: rotate3d(1, 1, -10, 270deg); */
  }
  .icon-right {
    right: 13px;
    position: absolute;
    top: 11px;
  }
`;

export const TextArea = styled.textarea`
  font-size: 1rem;
  border: 1px solid #c4c4c4;
  padding: 0.5em;
  border-radius: 10px;
  text-align: center;
  width: 350px;
  margin: auto;
  display: block;
  resize: none;
  outline: none !important;
  &:focus {
    border: 1px solid ${theme.colors.primary}!important;
  }
  &:focus-visible {
    border: 1px solid ${theme.colors.primary}!important;
  }
`;

export const InputFile = styled.div`
  width: 100%;
  input[type="file"] {
    display: none;
  }
  div {
    display: flex;
    align-items: center;
    height: 41px;
    width: 100%;
    font-size: 1rem;
    padding: 0 0.5em;
    border-radius: 10px;
    text-align: center;
    border: 1px solid #c4c4c4;
  }
  span {
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-weight: "Roboto Light";
    color: ${theme.colors.contrast};
    font-size: 16px;
    padding: 0.3em 1em;
    background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.dark}`};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }
  small {
    display: block;
    margin: auto;
    font-size: 0.9rem;
    color: ${theme.colors.tertiaryLight};
  }
`;

export const SelectOption = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  .content-input-select {
    &__input {
      font-size: 1rem;
      width: 100%;
      border: 1px solid #c4c4c4;
      height: 41px;
      text-align: center;
      border-radius: 10px;
    }
    &__list {
      width: 100%;
      overflow: hidden;
      height: 0;
      max-height: 0;
      z-index: 9;
      position: absolute;
      border-radius: 10px;
      overflow: hidden;

      transition: 0.1s ease all;
      ul {
        li {
          font-size: 1rem;
          cursor: pointer;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s ease all;
          &:hover {
            background: ${theme.colors.primaryExtraLight};
          }
        }
      }
    }
  }

  .show-options {
    overflow-y: auto;
    max-height: 180px;
    height: auto;
    border: 1px solid #c4c4c4;
    background: ${theme.colors.contrast};
  }
`;

export const SelectContent1 = styled.div`
  height: 41px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 0 0.5rem;
  font-size: 1rem;
`;
export const SelectContent = styled.select`
  height: 41px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 0 0.5rem;
  font-size: 1rem;
`;

export const SearchInvestment = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: 0.3s ease all;
`;

export const ListSearchItem = styled.div`
  padding: 1em 0;
  width: 345px;
  margin: 2px 0;
  display: flex;
  cursor: pointer;
  /* overflow: hidden; */
  position: relative;
  border-radius: 15px;
  transition: 0.3s ease all;
  justify-content: space-between;
  &:hover {
    /* box-shadow: inset 0 0 0px 3px ${theme.colors.tertiaryExtraLight}; */
    .button-add {
      right: 20px;
      opacity: 1;
    }
  }
  img {
    height: 40px;
  }
  .icon-project-name {
    display: inherit;
  }
  .project-name {
    display: inherit;
    margin-left: 1em;
    flex-direction: column;
    &__verify {
      position: relative;
      &--image {
        width: 13px;
        height: 13px;
        margin-left: 0.3em;
        margin-top: 0.08em;
      }
    }
  }
  .button-add {
    top: 25px;
    right: -100px;
    position: absolute;
  }
  .buttons-confirm {
    top: 25px;
    right: -130px;
    display: flex;
    background: none;
    position: absolute;
    transition: 0.3s ease all;
    opacity: 0;
    button {
      width: auto;
      padding: 0;
      margin: 0 0.7em;
      font-size: 0.8em;
      background: inherit;
    }
    button:first-child {
      color: ${theme.colors.primary};
    }
    button:nth-of-type(2) {
      color: ${theme.colors.dark};
      margin-left: 0;
      margin-right: 13px;
    }
  }
  .show-buttons {
    display: flex;
    right: 15px;
    opacity: 1;
    z-index: 999999;
    /* background: #e8f6f9; */
  }
  &:hover {
    .hidde-add-button {
      right: -100px;
      opacity: 0;
    }
  }
`;

// Dropdown

export const DropDown = styled.div`
  /* position: relative; */
  .dropdown-content {
    margin-top: 5px;
    right: 15px;
    height: 0;
    border: 0;
    z-index: 9;
    transform: scaleX(0);
    opacity: 0;
    overflow: hidden;
    position: absolute;
    border-radius: 10px;
    background: ${theme.colors.contrast};
    &__wallet {
      display: flex;
      align-items: center;
    }
    &__items {
      max-height: 220px;
      overflow-y: auto;
    }
    &__item {
      display: flex;
      /* flex-direction: column; */
      cursor: pointer;
      padding: 1em 1em;
      transition: 0.2s ease all;
      color: ${theme.colors.tertiary};
      border-bottom: 1px solid ${theme.colors.tertiaryExtraLight};
      &:first-child {
        flex-direction: column;
      }
      &:hover {
        background: ${theme.colors.tertiaryExtraLight};
      }
      button {
        cursor: pointer;
      }
      &--wallet {
        display: flex;
        align-items: center;
        span {
          font-family: "Roboto Bold";
          color: ${theme.colors.tertiary};
        }
        small {
          color: ${theme.colors.tertiaryLight};
        }
      }
      &--icon {
        font-size: 1.2rem;
        margin-right: 0.5em;
        color: ${theme.colors.tertiary};
      }
      &--contract {
        display: inherit;
        flex-direction: column;
        padding: 0 1em 0 0.5em;
      }
      &--identicon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: ${theme.colors.primaryExtraLight};
      }
    }
  }
  .dropdown-content-btn {
    left: 0;
    margin-top: 5px;
    width: 160px;
    right: 15px;
    height: 0;
    border: 0;
    z-index: 9;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    border-radius: 10px;
    background: ${theme.colors.tertiaryExtraLight};
    &__button-drop {
      cursor: pointer;
      font-size: 0.9rem;
      padding: 0.7em;

      &:hover {
        background: ${theme.colors.tertiaryLight};
      }
    }
  }
  .show-dropdown {
    height: auto;
    opacity: 1;
    transform: scaleX(1);
    border: 1px solid ${theme.colors.tertiaryExtraLight};
  }
`;

export const modalStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    maxWidth: 450,
    minWidth: 350,
    overflowY: "hidden",
    overflowX: "hidden",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
  },
}));

export const LoaderContent = styled.div<LoadingProps>`
  /* width: auto !important; */
  .full-screen {
    top: 0;
    left: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.323);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(2);
    z-index: 9999;
  }
  .content-loader {
    /* height: 30px;
    width: 30px; */
    img {
      width: 38px;
      margin-bottom: 0;
    }
  }
  .content-loader > div {
    height: inherit;
    width: inherit;
    position: absolute;
    animation-name: rotate-loader;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
  .content-loader > div > div {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.dark}`};
    position: absolute;
    opacity: 1;
    top: 0%;
    right: 50%;
    transform: translate(50%, 0%);
  }
  .content-loader > div:nth-of-type(2) {
    opacity: 0.9;
    animation-delay: 0.2s;
  }
  .content-loader > div:nth-of-type(3) {
    opacity: 0.8;
    animation-delay: 0.4s;
  }
  .content-loader > div:nth-of-type(4) {
    opacity: 0.7;
    animation-delay: 0.6s;
  }
  .content-loader > div:nth-of-type(5) {
    opacity: 0.6;
    animation-delay: 0.8s;
  }
  .content-loader > div:nth-of-type(6) {
    opacity: 0.5;
    animation-delay: 1s;
  }
  @keyframes rotate-loader {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Link = styled.a<LinkProps>`
  display: block;
  position: relative;
  font-size: ${(props) =>
    props.size === "small"
      ? `.8rem`
      : props.size === "medium"
        ? `1.1rem`
        : props.size === "large"
          ? `1.5rem`
          : `1rem`};
  text-decoration: ${(props) =>
    props.decoration === "underline"
      ? `${theme.textDecoration.underline}`
      : `none`};
  color: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.secondaryGrey}`};
`;

export const BorderedBox = styled.div`
  width: 80%;
  margin: 3em auto;
  padding: 1em 2em;
  border-radius: 10px;
  border: 1px solid ${theme.colors.tertiaryExtraLight};
  @media screen and (max-width: ${theme.breakpoint.lg}) {
    width: 100%;
  }
`;

// Tabla tokens

export const TContent = styled.div`
  margin: 1em 0;
`;
export const THead = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: ${theme.colors.tertiaryLight};
  .thead-contain {
    display: grid;
    grid-template-columns: 43% 35% 22%;
    height: 40px;
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
  }
  @media screen and (max-width: 993px) {
    display: none;
  }
`;

export const TBody = styled.div`
  overflow-x: hidden;
`;

export const THeadCell = styled.div`
  /* width: 41.33%; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #c5c5c5;
  &:last-child {
    border-right: none;
    /* width: 23.6%; */
  }
  &:nth-of-type(2) {
    /* width: 35%; */
  }
`;
export const TCell = styled.div`
  /* width: 33.33%; */
  text-align: center;
`;

export const TRow = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 1px solid #c5c5c5;
  .trow-name-company {
  }
  .trow-no-data {
    padding-top: 1.3em;
    padding-left: 0.5em;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    @media screen and (max-width: 993px) {
      padding: 0;
    }
  }
  .trow-content {
    width: 100%;
    display: grid;
    grid-template-columns: calc(43% - 2em) 35% 22%;
    margin-top: 0em;
    margin-left: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #c5c5c5;
    &:last-child {
      border-bottom: none;
    }
  }
  .tcell-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &:nth-of-type(3) {
      border-right: none;
      /* width: 23.6%; */
    }
    &:nth-of-type(2) {
      /* width: 35%; */
    }
    img {
      margin-right: 1em;
    }
  }
`;

export const NoRecord = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em 0;
  p {
    margin: 0.5em 0;
  }
  svg {
    font-size: 5em;
    color: ${theme.colors.tertiaryExtraLight};
  }
  .button-only-text {
    margin-top: 2em;
    width: 210px !important;
    align-items: center;
    svg {
      color: ${theme.colors.primary};
      right: 10px;
      top: 5px;
      font-size: 1.3em;
    }
  }
`;

export const NoRecordToken = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1em;
  p {
    margin: 0 0 0.5em;
  }
  svg {
    opacity: 0.4;
    font-size: 2em;
    color: ${theme.colors.dark};
  }
  .btn-token-table {
    margin-top: 0.3em;
    border-bottom: 1px solid ${theme.colors.primary};
    button {
      width: auto !important;
      padding: 0;
    }
    svg {
      right: -22px;
      position: absolute;
      margin-left: 2em;
      color: ${theme.colors.primary};
    }
  }
`;

export const IGrid = styled.div`
  margin: 1em 0;
`;
export const IGridHead = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: ${theme.colors.tertiaryLight};
  border-top: 1px solid ${theme.colors.secondaryGrey};
`;
export const IGridCell = styled.div`
  position: relative;
  .token-symbol {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 38px;
      height: 38px;
      object-fit: contain;
    }
    &__symbol-token {
      margin-left: 1em;
    }
    &__symbol {
      display: flex;
      margin-left: 1.5em;
      align-items: center;
      justify-content: center;
    }
  }
  .status-project {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .td-balance {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 2em;
    color: #222222;
    font-size: 15px;
  }

  .td-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__group {
      display: flex;
      padding: 0 0.3em;
      position: relative;
      align-items: center;
      flex-direction: column;
      margin: 1em 0;
      button {
        width: auto;
        font-size: 14px;
        padding: 0 1.7em;
        height: 35px;
        svg {
          font-size: 0.95rem;
          right: 4px;
          top: 11px;
        }
      }
    }
  }
`;
export const IGridRow = styled.div`
  .row-grid {
    display: grid;
    padding: 0.8em 0;
    position: relative;
    grid-template-columns: 25% 30% 45%;
    border-bottom: 1px solid ${theme.colors.secondaryGrey};
  }
  .row-padding {
    padding: 1.5em 0;
  }
`;
export const IGridBody = styled.div``;

// Buttons
export const RoundButton = styled.button<ButtonProps>`
  border: none;
  display: flex;
  cursor: pointer;
  font-size: ${(props) =>
    props.size === "small"
      ? `${theme.fontSize.small}`
      : props.size === "medium"
        ? `${theme.fontSize.medium}`
        : props.size === "large"
          ? `${theme.fontSize.large}`
          : props.expand === "small"
            ? `${theme.fontSize.small}`
            : props.expand === "medium"
              ? `${theme.fontSize.medium}`
              : props.expand === "large"
                ? `${theme.fontSize.medium}`
                : `initial`};
  position: relative;
  padding: 0 1em;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease all;
  font-family: "Roboto Light";
  color: ${theme.colors.contrast};
  background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primary}`
      : props.color === "primary-dark"
        ? `${theme.colors.primaryDark}`
        : props.color === "primary-light"
          ? `${theme.colors.primaryLight}`
          : props.color === "secondary"
            ? `${theme.colors.secondary}`
            : props.color === "secondary-dark"
              ? `${theme.colors.secondaryDark}`
              : props.color === "secondary-light"
                ? `${theme.colors.secondaryLight}`
                : props.color === "secondary-extra-light"
                  ? `${theme.colors.secondaryExtraLight}`
                  : props.color === "tertiary"
                    ? `${theme.colors.tertiary}`
                    : props.color === "tertiary-dark"
                      ? `${theme.colors.tertiaryDark}`
                      : props.color === "tertiary-light"
                        ? `${theme.colors.tertiaryLight}`
                        : props.color === "tertiary-extra-light"
                          ? `${theme.colors.tertiaryExtraLight}`
                          : props.color === "extra"
                            ? `${theme.colors.extra}`
                            : props.color === "extra-dark"
                              ? `${theme.colors.extraDark}`
                              : props.color === "extra-light"
                                ? `${theme.colors.extraLight}`
                                : props.color === "extra-extra-light"
                                  ? `${theme.colors.extraExtraLight}`
                                  : props.color === "danger"
                                    ? `${theme.colors.danger}`
                                    : props.color === "danger-light"
                                      ? `${theme.colors.dangerLight}`
                                      : props.color === "dark"
                                        ? `${theme.colors.dark}`
                                        : props.color === "dark-light"
                                          ? `${theme.colors.darkLight}`
                                          : props.color === "dark-extra-light"
                                            ? `${theme.colors.darkExtraLight}`
                                            : props.color === "contrast"
                                              ? `${theme.colors.contrast}`
                                              : `${theme.colors.dark}`};
  width: 44px;
  height: 44px;
  border: 3px solid #ececec;
  box-shadow: 0px 0px 0px 0.5px ${theme.colors.tertiaryLight};
  &:hover {
    background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primaryDark}`
      : props.color === "primary-light"
        ? `${theme.colors.primaryDark}`
        : props.color === "secondary"
          ? `${theme.colors.secondaryDark}`
          : props.color === "tertiary"
            ? `${theme.colors.tertiaryDark}`
            : props.color === "extra"
              ? `${theme.colors.extraDark}`
              : props.color === "danger"
                ? `${theme.colors.danger}`
                : props.color === "danger-light"
                  ? `${theme.colors.dangerLight}`
                  : props.color === "dark"
                    ? `${theme.colors.darkLight}`
                    : `${theme.colors.dark}`};
  }
  &:active {
    background: ${(props) =>
    props.color === "primary"
      ? `${theme.colors.primaryLight}`
      : props.color === "primary-light"
        ? `${theme.colors.primary}`
        : props.color === "secondary"
          ? `${theme.colors.secondaryLight}`
          : props.color === "tertiary"
            ? `${theme.colors.tertiaryLight}`
            : props.color === "extra"
              ? `${theme.colors.extraLight}`
              : props.color === "danger"
                ? `${theme.colors.danger}`
                : props.color === "danger-light"
                  ? `${theme.colors.dangerLight}`
                  : props.color === "dark"
                    ? `${theme.colors.darkLight}`
                    : `${theme.colors.dark}`};
  }
  padding-left: ${(props) => (props.icon === "icon-left" ? `1em` : "1em")};
  padding-right: ${(props) => (props.icon === "icon-right" ? `2em` : "1em")};
  svg {
    font-size: 1.5rem;
    position: absolute;
    left: ${(props) =>
    props.icon === "icon-left" ? `${theme.icon.left}` : "unset"};
    right: ${(props) =>
    props.icon === "icon-right" ? `${theme.icon.right}` : "unset"};
  }
  .loader-btn {
    position: absolute;
    left: ${(props) =>
    props.icon === "icon-left" ? `${theme.icon.left}` : "unset"};
    right: ${(props) =>
    props.icon === "icon-right" ? `${theme.icon.right}` : "unset"};
  }
`;

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: 600,
  color: theme.palette.primary.main,

  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));


export const BoxInput = styled.div`  
  margin-bottom: 25px;
  span{
    display: block;
    margin-bottom:5px;
    font-family: "Roboto Medium";
  }
  div{
    display: flex;
    position: relative;
    button{
      border: none;
      right: 13px;
      font-size: 20px;
      background: none;
      position: absolute;
      top: 10px;
      cursor: pointer;
      color: ${theme.colors.primary};
    }
  }
  p{
    width: 100%;
    height: 40px;
    display: block;
    overflow: hidden;
    border-radius: 3px;
    padding: 12px 15px;
    padding-right: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: ${theme.colors.darkDCDC};
  }
`

export const ContentApiHeader = styled.header`
.logo-for-developers {
  a {
    display: flex;
    align-items: center;
  }
  p {
    font-size: 15px;
    margin-left: 10px;
    color: ${theme.colors.tertiaryLight};
  }
  @media screen and (max-width: ${theme.breakpoint.lg}){
    width: 300px!important;
    a {
      width: 300px!important;
    }
  }
}
  background: white;
  margin-bottom: 5em;
  margin-top: 1.5em;  
  padding: 1em 0;
  border-bottom: none !important;
  nav {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
    ul {
      display: flex;
      a {
        display: block;
        font-size: 16px;
        color: #3f3f3f;
        margin-right: 3em;
        font-family: "Roboto Light";

        &:hover {
          color: #8f4ae7 !important;
          text-decoration: underline;
        }
      }
      li {
        &:last-child {
          a {
            margin-right: 0;
          }
        }
      }
    }
  }

  `

export const LandingContent = styled.div`
  .left-side-text__title {   
    text-align: center;
    line-height: 1.35;
    margin-bottom: 1em;
    font-size: 28px!important;
    font-family: "Roboto Medium";
    @media screen and (max-width: ${theme.breakpoint.lg}){
      width: 100%;
    }
    @media screen and (max-width: ${theme.breakpoint.tablet}){
      margin-top: 1em;
      font-size: 18px!important;
    }
  }
  .left-side-text__buttons {
    display: flex;
    margin-bottom: 2em;
    margin-top: 2em;
    justify-content: center;
    a {
      height: 50px;
      display: flex;
      padding: 0 30px;
      font-size: 17px;
      margin-right: 20px;
      border-radius: 30px;
      align-items: center;
      font-family: "Roboto";
      color: ${theme.colors.contrast};
      background: ${theme.colors.primaryLight};
      &:hover{
        background: ${theme.colors.primary};
      }
    }
    @media screen and (max-width: ${theme.breakpoint.smartphone}){
      a{        
        width: 100%;
        height: 36px;
        text-align: center;
        justify-content: center;
        font-size:16px;
      }
    }
    @media screen and (max-width: ${theme.breakpoint.tablet}){
      margin-bottom: 1em;
      a{
        margin: 0;
      }
      a:first-child{
        margin-right: 10px;
      }
      a:last-child{
        margin-left: 10px;
      }

    }
 
  }
  .left-side-text__description {
    width: 475px;
    line-height: 30px;
    margin-bottom: 2em;
    font-size: 18px !important;
    a{
        font-size: 18px!important;
    }
    @media screen and (max-width: ${theme.breakpoint.lg}){
      width: 100%;
    }
    @media screen and (max-width: ${theme.breakpoint.tablet}){
      line-height: 25px;      
      font-size: 15px !important;
      a{
        font-size: 15px!important;
      }
    }
   
  }
  .api-image {
    width: 520px;
    @media screen and (max-width: ${theme.breakpoint.lg}){
      width: 100%;      
  }
  }
  .build-image {
    width: 100%;
    margin: auto;
    height: 364px;
    display: block;
    object-fit: contain;
    object-position: top;
  }
  .card-widget {
    padding: 20px;
    border: 1px solid ${theme.colors.darkDCDC};
  }
  
  .card-widget-content {
    padding: 8px 10px;
    @media screen and (max-width: ${theme.breakpoint.smartphone}) {
        margin-top: 2em;      
    }
    .u-text-center {
      width: 250px;
      margin: auto;
      font-size: 15px;
      line-height: 25px;
      text-align: center;
    }
    .u-margin-bottom-1.u-text-center {
        font-size: 19px;
        margin-top: 1em;
        padding-top: 0.5em;
        padding-bottom: 1.4em;
        margin: auto!important;
        @media screen and (max-width: ${theme.breakpoint.smartphone}) {
          font-size: 17px;
      }
    }
  }
  
`

export const VideoContent = styled.div`
  .video-container{
    width: 100%;
    height: auto;
  }
  position: relative;
  width: fit-content;
  .video-controls__controls {
    width: 100%;
    bottom: 0;
    padding: 20px;
    position: absolute;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 0, 0, 0.66) 35%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .time_progressbarContainer {
    background-color: rgba(139, 139, 139, 0.603);
    border-radius: 15px;
    width: 100%;
    height: 5px;
    z-index: 30;
    position: relative;
    margin: 0 20px;
  }

  .time_progressBar {
    border-radius: 15px;
    background-color: ${theme.colors.primary};
    height: 100%;
  }
  .timecontrols {
    width: 100%;
    display: none;
    align-items: center;
  }
  .controlsTime {
    font-family: "Roboto Light";
    font-size: 12px;
    color: ${theme.colors.contrast};
  }

  .controlsContainer {
    position: absolute;
    top: -80px;
    justify-content: center;
    display: flex;
    width: 100%;
    left: 0;
    right: 0;
  }
  .controls {
    top: 50%;
    width: 100%;
    display: flex;
    padding: 0 60px;
    position: absolute;
    align-items: center;
    transform: translateY(-50%);
    justify-content: space-between;
    div {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      color: ${theme.colors.contrast};
      background: rgba(31, 31, 31, 0.329);
    }
    .btn-play {
      width: 50px;
      height: 50px;
      margin: auto;
      border-radius: 50%;
      align-items: center;
      color: ${theme.colors.contrast};
      justify-content: center;
      background: rgba(31, 31, 31, 0.329);
      svg {        
        font-size: 1.3em;
        padding-left: 3px;
      }
    }
  }
`