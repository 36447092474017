export interface Transaction {
  tx_hash: string;
  from_address: string;
  to_address: string;
  block_timestamp: string;
}

export const getTxUrl = (tx: Transaction): string => {
  return "https://etherscan.io/tx/" + encodeURIComponent(tx.tx_hash);
}
