import { Box, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "../app/components/ui/Loader";
import {
  BoxInput,
  Button,
  Input,
  Link,
  Text,
} from "../app/components/ui/styled";
import { ApiKey, ApiKeyRequest, createApiKey, createApiKeyRequest } from "../app/models/apikey";
import { VscCopy } from "react-icons/vsc";
import { FaDiscord } from "react-icons/fa";

import { assets } from "../app/models/company_asset";

interface ApikeysPageProps {
  requestOrCreate: "request" | "create";
}

const ConfirmationRequest = () => {
  return (
    <div className="max-height-screen max-height-screen-2">
      <div style={{ width: "450px" }}>
        <Text
          size="large"
          weight="bold"
          align="center"
          style={{ marginBottom: "1.5em" }}
        >
          Thank you for requesting
        </Text>
        <Text align="center" style={{ lineHeight: "25px" }}>
          Thank you for requesting an API Key. Our team will be in touch.
          Meantime, you can reach out to us on{" "}
          <span style={{ display: "inline-block", marginRight: "3px" }}>
            <Link
              target="_blank"
              rel="noreferrer"
              color="primary"
              href={assets.discord_invite}
            >
              <FaDiscord style={{ position: "relative", top: "3px" }} />{" "}
              Discord
            </Link>
          </span>
          or chat with us on our discord
        </Text>
        <div className="u-display-flex u-justify-center u-margin-top-2">
          <div className="u-text-center u-margin-left-1 u-margin-right-1">
            <Link
              href="/data-api"
              color="primary"
              size="small"
              className="u-margin-top-2 u-display-inline-block"
            >
              Back home
            </Link>
          </div>
          <div className="u-text-center u-margin-left-1 u-margin-right-1">
            <Link
              href={assets.apidocs_url}
              color="primary"
              size="small"
              target="_blank" rel="noreferrer"
              className="u-margin-top-2 u-display-inline-block"
            >
              Check our api doc here
            </Link>
          </div>
        </div>

      </div>
    </div>
  )
}


const ApiKeyResponse: FC<ApiKey> = ({
  responseData
}) => {
  return (
    <Box
      className="max-height-screen"
      component="form"
    >
      <div style={{ width: "550px" }}>
        <p>Please send this key to our user together with link to the api documents</p>
        <BoxInput>
          <span>SECRET KEY</span>
          <div>
            <p>{responseData?.secret_key}</p>
            <button type="button" onClick={() => copyToClipboard(responseData?.secret_key)}><VscCopy /></button>
          </div>
        </BoxInput>
      </div>
    </Box>
  )
}

const ApikeysPage: FC<ApikeysPageProps> = ({
  requestOrCreate,
}) => {
  const emailRegex = RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const [inputRequestData, setInputRequestData] = useState<ApiKeyRequest>({});
  const [messageError, setMessageError] = useState({});
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [foundFields, setFoundFields] = useState(false);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setMessageError({
          email:
            value === ""
              ? "This field is required"
              : "" || emailRegex.test(value)
                ? ""
                : "Invalid email",
        });
        break;
      case "wallet_address":
        setMessageError({
          wallet_address:
            value === "" ? "" : value.startsWith("0x") ? "" : "Invalid address",
        });
        break;
    }
    setInputRequestData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formValidate = (errors, ...data) => {
    let valid = true;
    Object.values(errors).some((val) => val && (valid = false));
    Object.values(data).some((val) => val === "" && (valid = false));
    return valid;
  };

  const validForm = "";

  const handleGetAccessKey = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (formValidate(inputRequestData, messageError)) {
      setLoading(false);
      return toast.error("Please check your input data");
    }
    try {
      const response = await (requestOrCreate == "create" ? createApiKey(inputRequestData) : createApiKeyRequest(inputRequestData));
      setResponseData(response);
    } catch (e) {
      console.log("error getting secret key", e);
    } finally {
      setLoading(false);
    }
  };
  const onRequestGrantChanged = () => {
    setFoundFields(!foundFields);
  };
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  return (
    <>
      {requestOrCreate == "request" && responseData?.success && <ConfirmationRequest />}
      {requestOrCreate == "create" && responseData?.secret_key && <ApiKeyResponse responseData={responseData} />}
      {!responseData?.success && (
        <Box
          className="max-height-screen"
          component="form"
          onSubmit={handleGetAccessKey}
        >
          <div style={{ width: "450px" }}>
            <Text
              size="large"
              weight="bold"
              align="center"
              style={{ marginBottom: "1.5em" }}
            >
              Generate API Key
            </Text>
            <TextField
              onChange={handleChange}
              type="email"
              name="email"
              className="u-width-100 u-margin-bottom-1"
              placeholder="Email"
              hiddenLabel
              variant="outlined"
            />
            <span className="u-required-field">
              {messageError?.email || ""}
            </span>

            <TextField
              onChange={handleChange}
              type="text"
              name="wallet_address"
              className="u-width-100 u-margin-bottom-1"
              placeholder="wallet address(optional)"
              hiddenLabel
              variant="outlined"
            />
            <span className="u-required-field">
              {messageError?.wallet_address || ""}
            </span>
            <TextField
              onChange={handleChange}
              type="text"
              name="app_name"
              className="u-width-100 u-margin-bottom-1"
              placeholder="Name for the Api key"
              hiddenLabel
              variant="outlined"
            />

            <FormControlLabel
              className="u-margin-bottom-1"
              control={
                <Checkbox
                  checked={foundFields}
                  onChange={onRequestGrantChanged}
                  color="primary"
                />
              }
              label="Request Grant"
            />

            {foundFields && (
              <div>
                <TextField
                  onChange={handleChange}
                  type="text"
                  name="app_url"
                  className="u-width-100 u-margin-bottom-1"
                  placeholder="Your website/app url"
                  hiddenLabel
                  variant="outlined"
                />
                <TextField
                  onChange={handleChange}
                  multiline
                  rows={4}
                  type="text"
                  name="description"
                  className="u-width-100 u-margin-bottom-1"
                  placeholder="Describe what you're building"
                  hiddenLabel
                  variant="outlined"
                />
              </div>
            )}

            {validForm && validForm}
            <Button
              type="submit"
              color="primary"
              size="large"
              style={{
                height: "50px",
                width: "100%",
                marginTop: "10px",
                fontSize: "16px",
              }}
            >
              Get Key
            </Button>
            <div className="u-text-center">
              <Link
                href={assets.apidocs_url}
                color="primary"
                size="small"
                target="_blank" rel="noreferrer"
                className="u-margin-top-2 u-display-inline-block"
              >
                Check our api doc here
              </Link>
            </div>
          </div>
        </Box>
      )}

      {loading && (
        <div className="u-display-flex u-justify-center u-margin-top-1 u-margin-bottom-2">
          <Loader color="primary" className="label-loader-icon form-loader" />
        </div>
      )}
    </>
  );
};

export default ApikeysPage;
