export const showUserMenu = (path: string): boolean => {
  return (
    path.startsWith("/admin") ||
    path.startsWith("/investment") ||
    path.startsWith("/login") ||
    path.startsWith("/projects")
  );
};

export const isApiPath = (path: string): boolean => {
  return path.startsWith("/data-api") || path.startsWith("/apikeys");
};

export const isAdmin = (path: string): boolean => {
  return path.startsWith("/admin");
};

export const isFeedPath = (path: string): boolean => {
  return path.startsWith("/feed2") || path.startsWith("/profile");
};

export const isHomePath = (path: string, search?: string) =>
  (path === "/" || path === "/feed") && !search?.includes("query=");

export const isAboutPath = (path: string) => path === "/about";
