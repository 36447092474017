import { FC } from "react";
interface TokenInfoProps {
  info: { name: string; symbol: string; logo: string };
}

const TokenInfo: FC<TokenInfoProps> = ({info}) => {
  const { name, symbol, logo } = info;
  return (
    <div className="token-info">
      <div className="token-info__left">
        <img src={logo} alt="" />
        <p>{name} {""} {symbol}</p>
      </div>
    </div>
  );
};

export default TokenInfo;
