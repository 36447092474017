import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";
import { Text } from "../../ui/styled";

import { TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";

export const Separator = styled(TimelineSeparator)({
    marginLeft: "100px",
    marginRight: "28px",
    position: "relative",
    ".MuiTimelineDot-root": {
        margin: "0",
        width: "10px",
        left: "-33px",
        height: "10px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        background: theme.colors.tertiaryLight,
        boxShadow: "none",
        "&::after": {
            top: "3px",
            left: "10px",
            content: "''",
            height: "3px",
            position: "absolute",
            width: "calc(33px - 10px)",
            background: theme.colors.darkF5F5
        }
    },
    ".MuiTimelineConnector-root": {
        width: "3px",
        background: theme.colors.darkF5F5
    }
})

export const Dot = styled(TimelineDot)({
    margin: "0",
    top: "50%",
    position: "absolute",
    transform: "translateY(-50%)"
});
export const YearLabel = styled(Text)({
    top: "-3px",
    zIndex: "999",
    width: "47px",
    height: "24px",
    display: "flex",
    fontSize: "12px",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    left: "calc(629px - 188px)",
    color: theme.colors.dark7474,
    background: theme.colors.darkF5F5
})
export const TimeLineItem = styled(TimelineItem)({
    "&::before": {
        display: "none"
    },
})

export const LeftContent = styled(TimelineOppositeContent)({
    padding: "10px 0",
    display: "flex",
    alignItems: "center",
    ".u-hover-box": {
        margin: "0!important",
        paddingRight: "2em!important",        
    },

})

export const RightContent = styled(TimelineContent)({
    fontSize: "12px",
    padding: "10px 0",
    lineHeight: "20px",
    position: "relative",
    color: theme.colors.dark,
    fontFamily: theme.weight.light,
    span: {
        width: "200px",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
    }
})

export const TextDate = styled.small({
    left: "-60px",
    fontSize: "15px",
    position: "absolute",
    color: theme.colors.dark,
    fontFamily: theme.weight.light,
    transform: "translateY(-50%)",
    small: {
        background: "tomato!important"
    }
})