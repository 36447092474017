import React, {FC, useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {AiOutlineFilter} from "react-icons/ai";
import Button from "@material-ui/core/Button";

import {useLocation} from "react-router-dom";
import {MenuItem, InputLabel} from "@material-ui/core";
import Select from "@material-ui/core/Select";

interface FilterHighlightProps {
  onChange: (model) => void;
  initialFilter: any;
}

const FilterHighlight: FC<FilterHighlightProps> = ({onChange, initialFilter}) => {
  const [filter, setFilter] = useState(initialFilter);
  const onFilterChange = (e) => {
    if (e.target.value && e.target.value !== "" && e.target.value !== "any" && e.target.value !== "null") {
      setFilter({...filter, [e.target.name]: e.target.value})
    } else {
      delete filter[e.target.name];
      setFilter({...filter});
    }
  }
  const onSearch = () => {
    onChange({...filter});
  }

  const location = useLocation();
  useEffect(() => {
    let newData = {};
    let qs = new URLSearchParams(location.search);

    if (qs.get('text') && qs.get('text') !== 'any') {
      newData.text = qs.get('text');
    } else {
      delete newData['text'];
    }

    if (qs.get('category') && qs.get('category') !== 'any') {
      newData.category = qs.get('category');
    } else {
      delete newData['category'];
    }
    setFilter(newData);
    onChange(newData);
  }, [location.search])
  return (
    <div className="u-margin-bottom-1 filter-admin-content">
          <span>
            <AiOutlineFilter/> Filter
          </span>
      <div>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <TextField
            label="Text"
            variant="standard"
            value={filter?.text}
            name="text"
            onChange={onFilterChange}
          />
        </FormControl>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <InputLabel id="select-category-label">Category</InputLabel>
          <Select
            labelId="select-category-label"
            id="select-category"
            value={filter?.category || ""}
            label="Category"
            name="category"
            onChange={onFilterChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"defi"}>DeFi</MenuItem>
            <MenuItem value={"nft"}>NFT</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="u-margin-right-ui-1 u-width-fc-200">
          <Button
            variant="contained"
            size="small"
            onClick={onSearch}
          >
            Filter
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

export default FilterHighlight;
