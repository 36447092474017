import React, {FC, useState, useEffect} from "react";

import {
  FormControl,
  Button,
  Grid,
  TextField,
  Box,
  CircularProgress
} from "@material-ui/core";

import {toast} from "react-toastify";

import Alert from '@material-ui/lab/Alert';

import {
  getResource,
  updateResource
} from "../../../app/models/admincrud";

interface LabelManagerProps { }
const LabelManager: FC<LabelManagerProps> = () => {
  const [phase, setPhase] = useState<"loading"|"ready">("loading");
  const [keywords, setKeywords] = useState("");

  const loadRule = async () => {
    //TODO: move to model
    try {
      const data = await getResource("/features/highlight-excluded");
      if (data.settings) {
        setKeywords(data.settings.join(","));
      } else {
        setKeywords("");
      }
      setPhase("ready");
    } catch (e) {
      console.log("error fetching config", e);
    }
  }

  useEffect(() => {
    loadRule()
  }, [])

  const handleInputValue = (e: React.ChangeEvent) => {
    if (e.target.value.trim()) {
      setKeywords(e.target.value);
    }
  };

  const handleSave = async (e) => {
    try {
      updateResource("/features/highlight-excluded", {
        settings: keywords.split(",").map(word => word.trim()).filter(word => word.length > 0),
      });
      toast.success("saving rule succesfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } catch (e) {
      toast.error("error saving rule. try again!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
      });
    }
  }

  return (
    <div>
      <FormControl className="u-margin-right-ui-1" fullWidth>
        <TextField
          required
          fullWidth
          multiline
          label="Exclude keyword list. Separate by ,"
          variant="outlined"
          onChange={handleInputValue}
          name="exclude_keyword"
          value={keywords}
        />
      </FormControl>
      <Box m={[0.5]}>
      <Alert severity="info">
        Keyword in this list will be calculated but is hidden from API response.
        It can be the raw string itself or the keyword variable.
      </Alert>
      </Box>
      <FormControl className="u-margin-right-ui-1">
        {phase === "loading" ?
          <CircularProgress/>
          :
          <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave()}>
          Update
          </Button>}
      </FormControl>
    </div>
  )
}

export default LabelManager;
