import React, { useState } from "react";
import { DataGrid, GridColDef } from "@material-ui/data-grid";

import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import Checkbox from "@material-ui/core/Checkbox";
import { ListItemText } from "@material-ui/core";

import {
  createTheme,
  lighten,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";

import { listResources } from "../../app/models/admincrud";

import {
  Contract,
  ContractStatus,
  contractCategories,
} from "../../app/models/contract";

import FilterContract from "./components/filter-contract";

import { useHistory } from "react-router-dom";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme: Theme) => {
    const getBackgroundColor = (color) => lighten(color, 0.6);

    const getHoverBackgroundColor = (color) => lighten(color, 0.5);

    return {
      root: {
        "& .contract-theme--Open": {
          backgroundColor: getBackgroundColor(theme.palette.info.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
          },
        },
        "& .contract-theme--Filled": {
          backgroundColor: getBackgroundColor(theme.palette.success.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(
              theme.palette.success.main
            ),
          },
        },
        "& .contract-theme--PartiallyFilled": {
          backgroundColor: getBackgroundColor(theme.palette.warning.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(
              theme.palette.warning.main
            ),
          },
        },
        "& .contract-theme--Rejected": {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          "&:hover": {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
      },
    };
  },
  { defaultTheme }
);

const multiselectcol: GridColTypeDef = {
  editable: true,
  cellClassName: "font-tabular-nums",
  renderCell: (params: GridRenderCellParams) => {
    const { id, api, field } = params;
    const values = params.getValue(params.id, "contract_categories");

    const handleChange = (event) => {
      api.setEditCellValue({ id, field, value: event.target.value }, event);
      // Check if the event is not from the keyboard
      // https://github.com/facebook/react/issues/7407
      if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
        api.commitCellChange({ id, field });
        api.setCellMode(id, field, "view");
      }
    };

    return (
      <Select
        multiple
        value={values}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(", ")}
        onChange={handleChange}
      >
        {contractCategories.map((c) => (
          <MenuItem key={c.label} value={c.value}>
            <Checkbox checked={values.indexOf(c.value) > -1} />
            <ListItemText primary={c.label} />
          </MenuItem>
        ))}
      </Select>
    );
  },
};

const AbiCell = ({ params }) => {
  return (
    <div>
      {params
        .getValue(params.id, "abi")
        ?.filter((x) => x["name"])
        ?.slice(0, 10)
        ?.map((x, i) => (
          <strong key={`${params.id}${i}${x["name"]}`}>
            {x["name"]},&nbsp;
          </strong>
        ))}
    </div>
  );
};

const columns: GridColDef[] = [
  {
    field: "action",
    headerName: "Action",
    description: "Action",
    sortable: false,
    editable: false,
    filterable: false,
    width: 150,
    //valueGetter: (params: GridValueGetterParams) => {
    // return params.getValue(params.id, 'abi')?.filter(x => x['name'])?.map(x => x['name'])
    //},
    renderCell: (params: GridRenderCellParams) => (
      <>
        <Button
          variant="contained"
          size="small"
          href={`/admin/contracts/${params.row.id}`}
        >
          Detail
        </Button>
        &nbsp;
      </>
    ),
  },
  {
    field: "contract_status",
    headerName: "Status",
    description: "Approve or Need Review",
    sortable: false,
    type: "boolean",
    width: 100,
    editable: true,
    filterable: true,
    valueGetter: (params: GridValueFormatterParams) => {
      return (params.value as string) === ContractStatus.Confirmed;
    },
  },
  {
    field: "address",
    headerName: "Contract Address",
    width: 300,
    editable: false,
    filterable: true,
    renderCell: (params: GridRenderCellParams) => (
      <a
        href={`https://etherscan.io/address/${params.value}#code`}
        target="_blank"
        rel="noreferrer"
      >
        {params.value}
      </a>
    ),
  },
  {
    field: "token",
    headerName: "Token",
    width: 150,
    editable: false,
    filterable: true,
    valueFormatter: (params: GridValueFormatterParams) => {
      return params.value?.name;
    },
    renderCell: (params: GridRenderCellParams) => {
      return params?.value ? (
        <a href={`/admin/contracts/?token=${params.value?.address}`}>
          {params.value?.name}
        </a>
      ) : null;
    },
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: true,
    filterable: true,
  },
  {
    field: "contract_categories",
    headerName: "Type",
    width: 110,
    editable: true,
    filterable: true,
    ...multiselectcol,
  },
  {
    field: "ABI",
    headerName: "ABI",
    description: "Contract ABI",
    sortable: false,
    editable: false,
    filterable: false,
    flex: 1,
    //valueGetter: (params: GridValueGetterParams) => {
    // return params.getValue(params.id, 'abi')?.filter(x => x['name'])?.map(x => x['name'])
    //},
    renderCell: (params: GridRenderCellParams) => <AbiCell params={params} />,
  },
];

export interface ListResourcesProps {
  listPath?: string;
  handleEdit?: any;
}

const ListResources: FC<ListResourcesProps> = ({ listPath, handleEdit }) => {
  let history = useHistory();

  const classes = useStyles();
  // TODO: FIX

  const [filter, setFilter] = useState({});

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRow, setTotalRow] = useState(50);

  const onFilterChange = (filterModel) => {
    //if (filterModel.items) {
    //  let o: ResouceFilter = { ...filter };

    //  filterModel.items.forEach((v) => {
    //    if (v.value) {
    //      o[v.columnField] = v.value;
    //    } else {
    //      delete o[v.columnField];
    //    }
    //  });
    //  handlePageChange(page, o);
    //}
    history.push("?" + new URLSearchParams(filterModel).toString());
    handlePageChange(page, filterModel);
  };

  const handlePageChange = async (newPage, f) => {
    setLoading(true);
    if (!f || Object.keys(f).length === 0) {
      f = filter;
    }
    console.log(f, filter);

    // to dup obj
    if (!f) {
      f = {};
    }
    let o = { ...f };
    setFilter(o);

    if (page < newPage) {
      o.after = rows[rows.length - 1].id;
    }
    if (page > newPage && rows[0]?.id) {
      o.before = rows[0].id;
    }

    const newData = await listResources<Contract>(listPath, o);
    setRows(newData);
    setLoading(false);
    if (newData && newData.length < 50) {
      setTotalRow(newData.length);
    } else {
      setTotalRow(10000);
    }
    setPage(newPage);
  };

  return (
    <>
      <FilterContract
        contractCategories={contractCategories}
        onChange={onFilterChange}
      />
      <div style={{ height: 600, width: "100%" }} className={classes.root}>
        <DataGrid
          getRowClassName={(params) => {
            return `contract-theme--${
              params.getValue(params.id, "contract_status")
                ? ""
                : "PartiallyFilled"
            }`;
          }}
          filterMode="server"
          rows={rows}
          columns={columns}
          page={page}
          pagination
          pageSize={50}
          rowCount={totalRow}
          paginationMode="server"
          onPageChange={handlePageChange}
          loading={loading}
          autoHeight={true}
          checkboxSelection={false}
          disableSelectionOnClick={true}
        />
      </div>
    </>
  );
};

export default function PendingReviewContract() {
  return (
    <ListResources
      listPath="contracts"
      handleEdit={({ id, field, value }: GridCellEditCommitParams) => {
        console.log(id, field, value);
      }}
    />
  );
}
