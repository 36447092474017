import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FC, useEffect, useState } from "react";
import { UserStatsByTag } from "../../../../models/active-users";
import { getActiveUsersStatsByTag } from "../../../../models/search";
import { formatNumber } from "../../../../services/formatter";
import { Text } from "../../../ui/styled";
import {
  ProgressBar,
  TextDescriptionProgress,
} from "../../../ui/styled/progress-bar";

interface ProgressNumberProps {
  tags?: string
}

const ProgressNumber: FC<ProgressNumberProps> = ({ tags }) => {
  const [stats, setStats] = useState<UserStatsByTag>({
    change_24: 2.4,
    daily_all: 50249,
    daily_new: 5717,
    daily_returning: 44532,
    overall_total: 20252579,
  })
  const [pending, setPending] = useState(false)

  const newPercentage = (
    !stats?.overall_total
      ? 0
      : (stats.daily_new * 100) / (stats.daily_all || 1)
  ).toFixed(0);

  const getStatsByTag = async (filter: any) => {
    try {
      const result = await getActiveUsersStatsByTag(filter);
      setStats(result);
      setPending(true);
      setTimeout(() => setPending(false), 3000);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getStatsByTag({ category: "emerging", tags: tags })
  }, [tags])

  return (
    <Box mt={2} style={{ marginTop: "3em" }}>
      {tags ? <>
        <TextDescriptionProgress>
          # of {tags.split(',').map((tag) => (
            <span key={tag}>
              {tag.replace("-", " ")}
            </span>
          ))}
        </TextDescriptionProgress>
        <Text
          style={{
            fontSize: "30px",
            fontFamily: "Roboto Medium",
            marginBottom: "0.2em",
          }}
        >
          {pending ? (
            <Skeleton width={120} />
          ) : (
            formatNumber(String(stats?.daily_all))
          )}
        </Text>
        <Box display="flex" alignItems="center">
          {pending ? <Skeleton width="100%" /> :
            <>
              <ProgressBar>
                <div className="progress" style={{ width: `${newPercentage}%` }} />
              </ProgressBar>
              <Box ml={1.5}>
                <Text color="tertiary" size="small" style={{ fontSize: "13px" }}>
                  new {newPercentage}%
                </Text>
              </Box>
            </>
          }
        </Box>
      </> : ""}


    </Box>
  );
};

export default ProgressNumber;
