import { FC, useEffect, useState } from "react";
import { TextsInfo } from "../../index";
import { getNftHistory, NftHistoryInfo } from "../../../../models/nft";
import CollapseWidget from "./collapse-widget";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

interface NftsHistoryProps {}

const NftsHistory: FC<NftsHistoryProps> = () => {
  const [nftHistory, setNftHistory] = useState<NftHistoryInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const { address, tokenId } = useParams<{
    address: string;
    tokenId: string;
  }>();

  const getData = async (address: string, tokenId: string) => {
    try {
      setLoading(true);
      const result = await getNftHistory(address, tokenId);
      setNftHistory(result);
    } catch (e) {
      console.log(e);
      toast.error("Error while loading NFT data");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData(address, tokenId);
  }, [address, tokenId]);

  return (
    <>
      <CollapseWidget title="NFT history">
        {loading
          ? [0, 1, 2].map((item) => (
              <TextsInfo key={item}>
                <p>
                  <Skeleton width={120} />
                </p>
                <span>
                  <Skeleton width={120} />
                </span>
              </TextsInfo>
            ))
          : (nftHistory || []).map((item, i) => (
              <TextsInfo key={item.title}>
                {item.title === "Median holding period" && (
                  <>
                    <p>Half of the past owners held this item for</p>
                    <span>{item.sub_title}</span>
                  </>
                )}
                {item.title === "Unique items exchanged" && (
                  <>
                    <p>Their actual demand for this collection is</p>
                    <span>{item.sub_title}</span>
                  </>
                )}
              </TextsInfo>
            ))}
      </CollapseWidget>
      {(nftHistory?.length === 0 || nftHistory === undefined) && (
        <span className="text-placeholder">No records</span>
      )}
    </>
  );
};

export default NftsHistory;
