import { FC } from "react";
import Contract from "./single-result/contract";
import TotalHolder from "./single-result/total-holder";
import Wallet from "./single-result/wallet";
import Activity from "./single-result/activity";
import TokenUsage from "./single-result/token_usage";

import NFT from "./single-result/nft";
import ListActiveAddress from "./single-result/list-active-address";
import MostTradeToken from "./single-result/most-trade-token";
import TopPortfolio from "./single-result/top-portfolio";

import {
  SearchItemType,
  getTempDataActivityOnWeb,
} from "../../../../models/search";
import TokenCharacteristics from "./token-characteristics";

interface SingleResultProps {
  item: any;
  i?: number;
  item_type: SearchItemType;
  pageNumber: any;
  searchResults: any;
}

const SingleResult: FC<SingleResultProps> = ({
  item,
  i,
  item_type,
  pageNumber,
  searchResults,
}) => {
  return (
    <>
      {/* token_profile */}

      {item_type === SearchItemType.TokenProfile && (
        <TokenCharacteristics dataToken={searchResults} />
      )}

      {item_type === SearchItemType.Wallet && (
        <Wallet
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          pageNumber={pageNumber}
          item={item}
          i={i}
        />
      )}

      {item_type === SearchItemType.Contract && (
        <Contract
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          item={item}
          i={i}
        />
      )}
      {item_type === SearchItemType.ContractOfType && (
        <Contract
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          item={item}
          i={i}
        />
      )}

      {item_type === SearchItemType.TotalHolder && (
        <TotalHolder
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          pageNumber={pageNumber}
          item={item}
          i={i}
        />
      )}
      {item_type === SearchItemType.ListActiveAddress && (
        <ListActiveAddress
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          item={item}
          i={i}
        />
      )}
      {item_type === SearchItemType.Activity && (
        <Activity
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          item={item}
        />
      )}
      {item_type === SearchItemType.TokenUsage && (
        <TokenUsage
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          item={item}
        />
      )}
      {item_type === SearchItemType.NFT && (
        <NFT
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          item={item}
          i={i}
        />
      )}
      {item_type === SearchItemType.MostTradeToken && (
        <MostTradeToken
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          item={item}
          i={i}
        />
      )}
      {item_type === SearchItemType.TopHolderPortfolio && (
        <TopPortfolio
          pagination={searchResults.pagination}
          searchId={searchResults.search_id}
          pageNumber={pageNumber}
          item={item}
          i={i}
        />
      )}
    </>
  );
};

export default SingleResult;
