import * as apiClient from "../services/api";

export interface ActivityLabel {
  id: number | string;
  address?: string;
  method: string;
  translation: string;
  full_argument?: string;
  signature: string;
  keywords: object;
  sample_transactions: string;
  script: string;
};

export const addActivityLabel = (params: ActivityLabel): Promise<ActivityLabel> => {
  return apiClient.create("/activity_labels", params);
}

export const listActivityLabels = (): Promise<ActivityLabel[]> => {
  return apiClient.list<ActivityLabel>("/activity_labels");
}


export const getActivityLabel = (id: number): Promise<ActivityLabel> => {
  return apiClient.find<ActivityLabel>("/activity_labels/" + id);
}


export const deleteActivityLabel = <Res>(path: string): Promise<Res> => {
  return apiClient.destroy(path);
}


export const updateActivityLabel = <Res>(path: string, body: any): Promise<Res> => {
  return apiClient.update(path, body)
}
