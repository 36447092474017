import { FC } from "react";

import {
  visitResult
} from "../../../../models/search";

interface ResultLinkProps {
  href: string;
  target?: string;
  className: string;
  searchId?: string;
}

const ResultLink: FC<ResultLinkProps> = ({
  href,
  target,
  className,
  children,
  searchId
}) => {
  const clickResult = async () => {
    try {
      await visitResult(searchId);
    } catch {
      // we don't care about this
    }
  }

  return (
    <a href={href} target={target} className={className} onClick={e => clickResult()}>
      {children}
    </a>
  )
}

export default ResultLink
