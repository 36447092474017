import { FC } from "react";

import MainSearch from "../app/components/pages/search/index";

interface QueryPageProps {}

const QueryPage: FC<QueryPageProps> = () => {
  return (
    <div className="search-main-content">
      <div className="search-content">
        <MainSearch />
      </div>
    </div>
  );
};

export default QueryPage;
