import { FC } from "react";
import { WidgetTitle, SectionTitle } from "../../index";

interface UpAndComingProps {}

const UpAndComing: FC<UpAndComingProps> = () => {
  const dataUpComming = {
    tag: ["Gamer"],
    details: [
      "Swapped 5 min ago",
      "Swapped 3 hours ago",
      "Swapped 8hours ago",
      "Staked 1 day ago",
    ],
  };
  return (
    <>
    <SectionTitle>Characteristics</SectionTitle>
    <div className="top-up-coming">
      <WidgetTitle>
        Top 1 is up-and-coming <a href="#">{dataUpComming.tag}</a>
      </WidgetTitle>
      <ul>
        {dataUpComming.details.map((x: any, i:number) => (
          <li key={i}>{x}</li>
        ))}
      </ul>
    </div>
    </>
  );
};

export default UpAndComing;
