import { Chip } from "@material-ui/core";
import { FC, useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { CurrentOwnerTitle, NftsImages, PLText, ProfileBox } from "../..";

import { getEmoji } from "../../../../models/emojis";
import { NftHoldingDuration, NftUserInfo } from "../../../../models/nft";
import { getProfileAddress, Profile } from "../../../../models/search";
import { getTagName, tagToSlug } from "../../../../models/tag";
import { shortAddress } from "../../../../services/formatter";
import ProfileNftAssets from "../search-results/address-component/profile-nft-assets";

import CollapseWidget from "./collapse-widget";

interface CurrentOwnerProps {
  owner: NftUserInfo;
  holding_duration: NftHoldingDuration;
  list_price: string | number;
  token_id?: string;
}

const CurrentOwner: FC<CurrentOwnerProps> = ({
  owner,
  holding_duration,
  list_price,
  token_id,
}) => {
  const [profileOwner, setProfileOwner] = useState<Profile | null>(null);

  const { duration, unit } = holding_duration;
  const asyncOwnerData = async () => {
    try {
      const dataOwner = await getProfileAddress(owner.address);      
      setProfileOwner(dataOwner);
    } catch (err) {}
  };
  useEffect(() => {
    asyncOwnerData();
  }, []);
    
  
  return (
    <CollapseWidget title="Current owner">
      <ProfileBox className="u-margin-bottom-2">
        <div className="profile-avatar">
          {/* {owner.tags && (
            <span
              className={owner.tags.length > 0 ? getEmoji(owner.tags[0]) : ""}
            ></span>
          )} */}
        </div>
        <div className="profile-owner">
          {owner?.ens ? (
            <NavLink
              to={`/profiles0/${owner.address}`}
              className="current-profile-address"
            >
              {owner?.ens}
            </NavLink>
          ) : (
            <NavLink
              to={`/profiles0/${owner.address}`}
              className="current-profile-address"
            >
              {shortAddress(owner.address, 5, 5)}
            </NavLink>
          )}

          <div className="profile-owner__links">
            {owner?.tags?.map((tag) => (
              <NavLink
                className="tag-chip u-text-transform-lowercase"
                key={tag}
                to={`/tags/${tagToSlug(tag)}`}
              >
                <Chip label={getTagName(tag)} />
              </NavLink>
            ))}
          </div>
        </div>
      </ProfileBox>

      <CurrentOwnerTitle className="u-margin-top-1">
        Other NFTs held
      </CurrentOwnerTitle>

      <NftsImages>
        <div className="nft-images">
          {profileOwner?.nft_assets && (
            <ProfileNftAssets token_id={token_id} data_nfts={profileOwner} />
          )}
        </div>
      </NftsImages>
    </CollapseWidget>
  );
};

export default CurrentOwner;
