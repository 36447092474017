import * as apiClient from "../services/api";
import { Account } from "../stores/account";

export interface Profile {
  newsletter?: boolean;
  alert?: boolean;
  token_sale?: boolean;
  email?: string;
};

export type ProfileAttb = "newsletter" | "alert" | "token_sale" | "email";

export const saveProfile = (key: ProfileAttb, value: string|boolean): Promise<Profile> => {
  const profile: Profile = {};

  switch (key) {
    case "newsletter":
      profile.newsletter = value as boolean;
      break;
    case "alert":
      profile.alert = value as boolean;
      break;
    case "token_sale":
      profile.token_sale = value as boolean;
      break;
    case "email":
      profile.email = value as string;
      break;
    default:
      throw new Error("Invalid attrb");
  }

  return apiClient.create("/profiles", profile);
}

export const getProfile = (): Promise<Profile> => {
  return apiClient.find<Profile>("/profiles");
}

export const saveAccount = async (acc: Account): Promise<Account|null> => {
  if (!acc?.id) {
    return Promise.resolve(null);
  }

  return apiClient.save<Account>(`/profiles/${acc.id}`, {
    name: acc.name
  });
}


interface FollowResponse {
  success: boolean;
}

export const toggleFollow = async (address: string, follow: boolean): Promise<FollowResponse> => {
  try {
    const response = await apiClient.flagshipCreate(`/profiles/${address}/${follow ? 'follow' : 'unfollow'}`, null);
    return response.data;
  } catch (error) {
    console.log("error follow", error);
  }
};