import { Box } from "@material-ui/core";
import { FC } from "react";
import { temp_data_my_collectors } from "../../models/address-data";
import { ListItems } from "../pages/activity-row-address/styled";
import CollapseWidget from "../pages/search/widgets/collapse-widget";
import { Text } from "../ui/styled";
import { ChipContent } from "../ui/styled/chipers";
import { tagToSlug } from "../../../app/models/tag";

interface MyCollectorsProps { }

const MyCollectors: FC<MyCollectorsProps> = () => {
  return (
    <CollapseWidget title="My collectors">
      <Box className="u-margin-bottom-2 u-margin-left-05">
        {temp_data_my_collectors.map((collector) => (
          <ListItems className="u-margin-bottom-1-2">
            <Box display="flex" alignItems="center">
              <Text className="text-address" weight="medium2">
                <a href={`/profiles/${collector.address}`}>
                  {collector.address.substring(0, 5)}
                </a>
              </Text>
              {collector.tags.map((tag) => (
                <a href={`/tags/${tagToSlug(tag)}`} key={tag}>
                  <ChipContent size="small" className="small-chip" label={tag} />
                </a>
              ))}

              {collector.emoji && (
                <small className={`${collector.emoji}-emoji`}></small>
              )}
            </Box>
            <Box>
              <Text className="collector-description">
                {collector.action_label}
              </Text>
            </Box>
          </ListItems>
        ))}
      </Box>
    </CollapseWidget>
  );
};

export default MyCollectors;
