import { FC, useState } from "react";
import { Portfolio } from "../../../../models/search";
import Numeral from "numeral";
import { displayLogo } from "../../../../models/address";
import { NavLink } from "react-router-dom";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";

interface PortfolioList {
  tokens: Portfolio[];
}

const TokenList: FC<PortfolioList> = ({ tokens }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className="u-scroll-y u-max-scroll-400">
        {tokens
          .filter((p: Portfolio) => p.token_symbol !== "")
          .slice(0, showMore ? tokens.length : 3)
          .map((item: any, i: number) => {
            const {
              token_symbol,
              balance,
              token_address,
              price_change_percentage_24h,
              price,
              usd_balance,
            } = item;
            return (
              <NavLink
                key={i}
                style={{ margin: "0.5em 0" }}
                className={`token-list__row ${
                  token_address === "ethereum" && "no-link"
                }`}
                to={
                  token_address === "ethereum"
                    ? "#"
                    : `/tokens/${token_address}`
                }
              >
                <img src={displayLogo(item.token_address)} alt="" />
                <div className="token-list__symbol">
                  <span>{token_symbol}</span>
                  {/* <span className="u-text-uppercase">
                    ${Numeral(price).format("0.0a")}
                  </span> */}
                </div>
               {/* <div className="token-list__amount ">
                  <span className="u-text-uppercase">
                    $
                    {balance && parseFloat(balance) > 0
                      ? Numeral(usd_balance).format("0.0a")
                      : "0"}
                  </span>
                  <span className="u-text-uppercase">
                    {Numeral(balance).format("0.0a")}
                  </span>
                </div>  */}
                {/* {price_change_percentage_24h ? (
                  <span
                    className={`token-list__percentage ${
                      Numeral(price_change_percentage_24h)
                        .format("+0f")
                        .includes("+")
                        ? "gain-color"
                        : "lost-color"
                    }`}
                  >
                    {price_change_percentage_24h > 0 ? (
                      <CgArrowLongUp style={{ height: 11 }} />
                    ) : price_change_percentage_24h < 0 ? (
                      <CgArrowLongDown style={{ height: 11 }} />
                    ) : null}{" "}
                    {Numeral(price_change_percentage_24h).format("0f")} %
                  </span>
                ) : (
                  <span className="token-list__percentage color-no-gain-lost">
                    {price_change_percentage_24h} %
                  </span>
                )} */}
              </NavLink>
            );
          })}
      </div>
      {tokens.filter((p: Portfolio) => p.token_symbol !== "").length > 3 && (
        <button
          onClick={() => setShowMore((showMore) => !showMore)}
          className="btn-top-all"
        >
          {!showMore ? "Show more" : "Show less"}
        </button>
      )}
      {tokens.filter((p: Portfolio) => p.token_symbol !== "").length === 0 && (
        <p className="empty-data">No token</p>
      )}
    </>
  );
};

export default TokenList;
