import { FC } from "react";

import { shortAddress } from "../../../../../services/formatter";

import ResultLink from '../result-link';

interface ActivityProps {
  item: { usages: [] };
  searchId?: string;        
}

const TokenUsage: FC<ActivityProps> = ({ item, searchId }) => {      
  const { usages } = item;

  return (
    <>
      {Object.keys(usages).map((usage: string, index: any) => (
        <div className="search-list__item" key={`item-${usage}`}>
          <div className="search-list__item__number">
            <span>{index + 1}</span>
          </div>
          <div className="item-top-list">
            <small className="brain-emoji"></small>
          </div>

          <div className="search-list__item__text no-show-smartphone u-color-dark">
            <span>{usage}</span>
          </div>
          <div className="search-list__item__image brain-icon-address">
            <span className="amount-smartphone u-color-dark u-text-left">
              {usage}
            </span>
            {usages[usage]?.to_address && (
              <ResultLink
                href={`https://etherscan.io/address/${usages[usage]?.to_address}#code`}
                target="_blank"
                className="address-wallet"
                searchId={searchId}
              >
                {shortAddress(usages[usage]?.to_address)}
              </ResultLink>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default TokenUsage;
