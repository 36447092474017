import { FC } from "react";
import { NavLink } from "react-router-dom";
import { getTagName, tagToSlug } from "../../../../../models/tag";
import { theme } from "../../../../../theme/theme";
interface ProfileTagsProps {
  data_tags: any;
  limit?: number;
}

const ProfileTags: FC<ProfileTagsProps> = ({ data_tags, limit = 2 }) => {  
  return (
    <>
      <div className="profile-tags">
        {(data_tags?.tags || [])
          .filter((x: any) => x !== "Holder") // TODO: remove this line when we find a better name for Holder
          .slice(0, limit)
          .map((tag: string) => (
            <NavLink
              to={`/tags/${tagToSlug(tag)}`}
              key={tag}
              style={{ color: theme.colors.tertiary }}
            >
              <span>{getTagName(tag)} </span>
              <span className="backslash-item">/</span>
            </NavLink>
          ))}
      </div>
    </>
  );
};

export default ProfileTags;
