import React, { FC } from "react";
import { Link } from "react-router-dom";
import { NftName } from "../../..";
import { NftBasicInfo } from "../../../../../models/nft";

interface TitleNftProps {
  nft: NftBasicInfo;
}

const TitleNft: FC<TitleNftProps> = ({ nft }) => {
  return (
    <NftName>
      <Link className="nft-tag" to={`/collections/${nft.collection?.slug || nft.collection?.contract_address}`}>
        {nft.collection.name}
      </Link>

      <p className="nft-name">{nft.name ? nft.name : nft.nft_name}</p>
    </NftName>
  );
};

export default TitleNft;
