import { FC, useEffect, useState } from "react";
import { getPopularTopics, Topic } from "../../../../models/search";

import Skeleton from "@material-ui/lab/Skeleton";
import Link from "@material-ui/core/Link";
import CollapseWidget from "./collapse-widget";

interface PopularTopicsProps {
  handleSearch?: any;
  category?: string;
}

const PopularTopics: FC<PopularTopicsProps> = ({ handleSearch, category }) => {
  const [topics, setTopics] = useState<Topic[]>();
  const [pending, setPending] = useState(false);

  const asyncLoadProfile = async () => {
    try {
      setPending(true);
      const t = await getPopularTopics(category);
      setTopics(t);
    } catch (err) {
      console.log("error loading topic", err);
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    asyncLoadProfile();
  }, [category]);

  return (
    <CollapseWidget title="Trending searches">
      <div className="collapse-box">
        <div className="popular-topics">
          {pending && (
            <div className="loader-tags">
              {[0, 1, 2].map((x: number) => (
                <Skeleton
                  key={x}
                  animation="wave"
                  className="u-margin-right-05"
                  variant="text"
                  width={80}
                  height={35}
                />
              ))}
            </div>
          )}
          {topics && !pending && (
            <ul className="add-fade-to-top">
              {topics.map((t: any, i: number) => (
                <li
                  key={`topic${i}`}
                  className={`${
                    t.title.length > 40
                      ? "u-tiny-text"
                      : t.title.length > 33
                      ? "u-tiny-text-2"
                      : ""
                  }`}
                >
                  <Link href={`/?query=${t.term}`}>
                    {t.icon} {t.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </CollapseWidget>
  );
};

export default PopularTopics;
