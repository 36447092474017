import { FC, useState, useEffect } from "react";
// Images
import { MetaMask, WalletConnect } from "../app/components/account/add_wallet";
import { Text } from "../app/components/ui/styled";
import { Loader } from "../app/components/ui/Loader";
import { assets } from "../app/models/company_asset";
import * as accountStore from "../app/stores/account";

// Page components
import { Login, BoxLogin } from "../app/components/pages/index";
import { useParams, Redirect } from "react-router-dom";
import { saveCompany } from "../app/models/company";
import { authURL, isQA } from "../app/services/appenv";

type LoginParams = {
  actas?: string;
};

const onUserSuccessLogin = () => {
  window.location.href = "/admin/";
};

const onCompanySuccessLogin = async () => {
  await saveCompany({
    name: "an awesome name",
  });
  window.location.href = "/project";
};

type Phase = "loading" | "ready";

const LoginPage: FC = () => {
  const params = useParams<LoginParams>();
  const [, setPhase] = useState<Phase>("ready");

  const onSuccessLogin = () => {
    if (params.actas && params.actas === "company") {
      setPhase("loading");
      return onCompanySuccessLogin();
    } else {
      return onUserSuccessLogin();
    }
  };

  return (
    <Login className="container u-position-relative">
      {/* <a href="#" className="only-link-button active">
        For Founders
      </a> */}
      <BoxLogin>
        <img
          src={`${process.env.PUBLIC_URL + assets.logo}`}
          alt="logo"
          width="210"
          className="u-margin-bottom-3"
        />
        <Text color="dark" align="center" className="login-text ">
          Opening up the world of smart contracts to web3 investors.
        </Text>
        <Text
          align="center"
          size="large"
          color="primary"
          className="u-margin-tb-2 login-text-2"
        >
          Get started by connecting your wallets.
        </Text>
        <div className="login-buttons">
          <MetaMask onSuccess={onSuccessLogin} />

          <WalletConnect onSuccess={onSuccessLogin} />
        </div>
      </BoxLogin>
    </Login>
  );
};

interface Props {
  children: any;
}

export const RequiredAuth: FC<Props> = ({ children, ...props }) => {
  const [authed, setAuthed] = useState(accountStore.isConnectedWallet());

  const [loading, setLoading] = useState(true);

  const asyncLoadAccount = async () => {
    await accountStore.loadAccounts();
    setLoading(false);
  };

  useEffect(() => {
    const a1 = accountStore.currentAccount.subscribe({
      next: (v) => {
        setAuthed(true);
      },
    });

    asyncLoadAccount();

    return () => {
      a1.unsubscribe();
    };
  }, []);

  return authed ? (
    <>{children}</>
  ) : (
    <>
      {loading ? (
        <Loader color="primary" className="full-screen" />
      ) : (
        <Redirect to={authURL} />
      )}
    </>
  );
};

export const RequiredAdmin: FC<Props> = ({ children, ...props }) => {
  const [isStaff, setStaff] = useState(false);

  useEffect(() => {
    const s = accountStore.currentAccount.subscribe({
      next: (v) => {
        const a = accountStore.findByAddress(v);
        setStaff(a.staff);
      },
    });

    return () => {
      s.unsubscribe();
    };
  }, []);

  return isStaff ? { ...children } : null;
};

export default LoginPage;
