export interface FilterType {
  category?: string;
  chainIDs?: number[];
  value?: string;
  tags?: string;
  asset_type?: string;
  tab?: string;
  // TODO: add more filters here
}

export interface FilterCategory {
  asset_type: string;
  name: string;
}

export interface FilterProfile {
  key: string;
  name: string;
  count: string | number;
}

export interface FilterTags {
  tag: string;
  tag_item: string;
}

const temp_data_category = [
  { asset_type: "pfp", name: "Collections" },
  { asset_type: "1on1", name: "1-1" },
  { asset_type: "video", name: "Video" },
  { asset_type: "3d", name: "3D" },
  { asset_type: "music", name: "Music" },
];

const temp_data_filter = [
  { tag: "creator", tag_item: "creator" },
  { tag: "pfp-minter", tag_item: "pfp minter" },
  { tag: "collector", tag_item: "collector" },  
  { tag: "dev-builder", tag_item: "dev builder" },
  { tag: "flipper", tag_item: "flipper" },
  { tag: "whale", tag_item: "whale" },
  { tag: "bluechipper", tag_item: "blue chipper" },
];

export const filterProfile = [
  { key: "all", name: "All" },
  { key: "created", name: "created", count: 30 },
  { key: "availableForSale", name: "available for sale", count: 11 },
  { key: "minted", name: "minted", count: 12 },
  { key: "bought", name: "bought", count: 39 },
  { key: "sold", name: "sold", count: 9 },
];

export const getFilterProfile = () => {
  return filterProfile
}

export const getNftCategory = () => {
  return temp_data_category;
};

export const getTagsFilter = () => {
  return temp_data_filter;
};

