import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";
import { Box } from "@material-ui/core";

export const ProfileBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "4em",
    ".skeleton-circle": {
        left: "-40px",
        position: "absolute",
        width: "30px!important",
        height: "30px!important",
        borderRadius: "50%!important",
        transform: "scale(1)!important"
    }
})