import * as apiClient from "../services/api";

import {
  Contract
} from "./contract";
import { InputType, querySearch, SearchResult } from "./search";

export interface Arg {
  name: string
  type: string
}

export interface ContractAction {
  id?: number;
  name: string;
  method: string;
  contract_id?: number;
  contract?: Contract;

  token_id?: number;
  params: { [key: string]: string };
}

export interface TokenInfo {
  
}

export interface Token {
  id?: number;
  name?: string;
  symbol?: string;
  logo?: string;

  address: string;
  ethereum_address?: string;

  company_id: string;
  chainid?: number;

  coingecko_id?: string;
  coinmarketcap_id?: number;
  contract_actions?: ContractAction[];

  status?: "verified" | "rejected" | "pending";
  verified_at?: string;
  rejected_at?: string;

  has_invested?: boolean;

  ownership_proof?: string

  marketcap?: number;
  circulating_supply: number;
  total_supply: number;
  max_supply: number;

  token1_address?: string;
  token1_logo?: string;
  token1_symbol?: string;

  token2_address?: string;
  token2_logo?: string;
  token2_symbol?: string;

  token_address?: string;

  claimed_by?: string;
  note?: string;
  categories?: string[];
  token_category?: string[];
};

export interface ListTokenQuery {
  q?: string;
  startAt?: number;
}

export const listTokens = (query?: ListTokenQuery): Promise<Token[]> => {
  let url = "/tokens?";

  if (query && query.includeInvestment) {
    url += `include_investment=true`
  } else {
    url += `include_investment=false`
  }
  if (query && query.q) { url += `&q=` + query.q }
  if (query && query.startAt) { url += `&start_at=${query.startAt}` }
  if (query && query.status) { url += `&status=${query.status}` }
  if (query && query.name) { url += `&q=${query.name}` }

  return apiClient.list<Token>(url);
}

export const addToken = (contract: Token): Promise<Token> => {
  return apiClient.create("/tokens", contract);
}

export const getToken = (address: string): Promise<Token> => {
  return apiClient.find<Token>("/tokens/" + address);
}

export const getTokenInfo = (address: string): Promise<SearchResult> => {
  return apiClient.flagshipRequest(`/tokens/${address}`);
}

export const deleteToken = (token: Token): Promise<Token> => {
  if (!token.id) {
    throw new Error("missing token id");
  }

  return apiClient.destroy<Token>("/tokens/" + token.id);
}

export const addContractAction = (action: ContractAction): Promise<ContractAction> => {
  if (!action.contract_id) {
    throw new Error("action missing token or contract id");
  }

  return apiClient.create<ContractAction>(`/tokens/${action.token_id}/contract_actions`, action);
}

export const saveContractAction = (action: ContractAction): Promise<ContractAction> => {
  if (!action.contract_id || !action.id) {
    throw new Error("action missing id or contract id");
  }

  return apiClient.save<ContractAction>(`/tokens/${action.token_id}/contract_actions/${action.id}`, action);
}

export const deleteContractAction = (action: ContractAction): Promise<ContractAction> => {
  let id = action.token_id;
  if (!id) {
    throw new Error("contract has no id yet");
  }

  return apiClient.destroy<ContractAction>(`/tokens/${id}/contract_actions/${action.id}`);
}

// Scan on-chain log data to find contract related to this token
export interface ScanToken {
  token_address: string;
  jobid: string;
}

export const scanContractForToken = (tokenAddress: string): Promise<ScanToken> => {
  return apiClient.create<ScanToken>(`/tokens/scan`, {token_address: tokenAddress});
}


export const lpPercentageToken = (token1: any, token2: any, type: number): any => {
  if(!type) return "";
  if(!token1 && !token2) return 0;
  const p1 =  token1 / (token1 + token2) * 100;
  const p2 = token2 / (token2 + token1) * 100;
  if(isNaN(p1) ||isNaN(p2)) return 0;
  if(type === 1) return p1;
  return p2;
}


// TO DO: THIS IS A HACK PLEASE FIX

export const isRewardToken = (token: string): any => {  
  if(token[0] !== "r" && !parseInt(token.substr(-1))) return false
  if(token[0] === "r" && parseInt(token.substr(-1))) return true
}

export const isTokenRejected = (token: Token): any => {
  return token.status === 'rejected';
}

export const isTokenVerified = (token: Token): any => {
  return token.status === 'verified';
}

export const isTokenPending = (token: Token): any => {
  return token.status === 'pending';
}
