import ErrorComponent from "../app/components/ui/not-found";
const NotFoundPage = () => {
  return (
    <div className="container u-padding-mobil-top-3">
      <ErrorComponent title="404" description="Ooops! Page not found." />
    </div>
  );
};

export default NotFoundPage;
