import { assets } from "../../../../models/company_asset";

import { NavLink } from "react-router-dom";
import { ContentApiHeader, Link } from "../../../ui/styled";

import { useRef, useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";

import { ClickOutideElement } from "../../../../services/click-outside-element";
import { useLocation } from "react-router-dom";
import { isAboutPath, isApiPath, isHomePath } from "../../../../services/navigation";
import { HeaderBox, MenuButton } from "../../../pages";

const ApiHeader = () => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = ClickOutideElement(dropdownRef, false);
  const { pathname } = useLocation();
  const headerElement = useRef(null);

  return (
    <div className="u-display-flex u-justify-spacebetween u-align-items-center u-margin-top-1 u-margin-bottom-2 header-app-content">
      <div className="logo-for-developers">
        <a href="/data-api" className="u-display-flex u-align-items-center">
          <img src={assets.logoURL()} alt={assets.companyName} width="110" />
          <p className="u-margin-left-03 u-color-99999">for developers</p>
        </a>
      </div>
      <HeaderBox className="position-header-content" ref={headerElement}>
        <div
          ref={dropdownRef}
          className={`${isActive ? "show-menu-search" : ""}`}
        >
          <MenuButton onClick={() => setIsActive(!isActive)}>
            <FaBars />
          </MenuButton>

          <ul className={`list-search-menu menu-search`}>
            <li>
              <Link
                href="/"
                className={`${isHomePath(pathname) && "active-menu-search"}`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link href={`/data-api`} className={`${isApiPath(pathname) && "active-menu-search"}`}>API</Link>
            </li>
            <li>
              <Link
                href={`/about`}
                className={`${isAboutPath(pathname) && "active-menu-search"}`
                }
              >
                About
              </Link>
            </li>

          </ul>
        </div>
      </HeaderBox>
    </div>
  );
};

export default ApiHeader;
