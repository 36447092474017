import { FC, useEffect, useRef, useState } from "react";
import AddressComponent from "../address-component/address-component";
import Activity from "./activity";
import TokenInfo from "./token-info";
import TokenUserStats from "./token-user-stats";
import { getTempDataActivityOnWeb } from "../../../../../models/search";
import WidgetTopUsers from "../../widgets/top-users";
import UserEngagement from "../../widgets/user-engagement";
import { getActivities, Web3Activity } from "../../../../../models/activity";
import { Loader } from "../../../../ui/Loader";
import { useApplicationConfig } from "../../../../../providers/config";
import ActiveUsersStats from "../../widgets/active-users";
import HighlightTransactions from "../../widgets/highlight-transactions";
import CollapseWidget from "../../widgets/collapse-widget";
import InfinityScroll from "../../../../ui/infinite-scroll";
import { useTransition, animated, config } from "@react-spring/web";
interface TokenCharacteristicsProps {
  dataToken: any;
}

const TokenCharacteristics: FC<TokenCharacteristicsProps> = ({ dataToken }) => {
  const { token } = dataToken;
  const tempData = getTempDataActivityOnWeb();
  const [pending, setPending] = useState(false);
  const [activities, setActivities] = useState<Web3Activity[]>([]);
  const pendingTs = useRef(0);

  // Load feature flags
  const { userPrefs } = useApplicationConfig();
  const transitions = useTransition(activities, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    delay: 200,
    config: config.molasses,
  });

  const fetchNew = async () => {
    return await getActivities({
      token_address: token.ethereum_address,
    });
  };

  const fetchMore = async () => {
    const filter = {token_address: token.ethereum_address}
    if (activities?.length > 0) {
      filter.before = activities[activities.length - 1].surrogate_key;
    }

    return await getActivities(filter);
  };

  return (
    <div className="token-characteristics">
      <div className="update-ago">updated 2 min ago</div>
      <div className="token-characteristics__symbol-info">
        <TokenInfo info={token} />
      </div>
      <div className="token-characteristics__users">
        {userPrefs.ActiveUsersStats ? (
          <ActiveUsersStats category={""} />
        ) : (
          <TokenUserStats />
        )}
      </div>
      <div className="token-characteristics__center">
        <div className="token-activity">
          <Activity dataResult={dataToken} />
            <InfinityScroll
              activities={activities}
              setActivities={setActivities}
              pending={pending}
              setPending={setPending}

              fetchNew={fetchNew}
              fetchMore={fetchMore}
            >
              {activities?.length >= 1 &&
                transitions(({ opacity }, activity, t, index) => (
                  <animated.div
                    style={{
                      opacity: opacity.to({
                        range: [0.0, 1.0],
                        output: [0, 1],
                      }),
                    }}
                  >
                    <AddressComponent
                      key={`activity${activity.transaction_hash}`}
                      item={activity}
                      position={index}
                      variant="front"
                      token={token}
                    />
                  </animated.div>
                ))}
            </InfinityScroll>
        </div>
      </div>

      <div className="token-characteristics__right">
        {userPrefs.HighlightTransactions ? (
          <div className="collapse-box u-width-100 u-border-none u-padding-top-none">
            <HighlightTransactions category={""} />
          </div>
        ) : (
          <>
            <WidgetTopUsers token_address={token.ethereum_address} />
            <UserEngagement data={tempData} token={token} />
          </>
        )}
        <div className="token-characteristics__users">
          {userPrefs.ActiveUsersStats ? (
            <ActiveUsersStats category={""} />
          ) : (
            <TokenUserStats />
          )}
        </div>
      </div>
    </div>
  );
};

export default TokenCharacteristics;
