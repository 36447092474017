import { FC } from "react";
import {
  SIMILAR_PROJECT_THRESHOLD,
  SimilarProject,
} from "../../../../models/search";

interface SimilarProjectsProps {
  similarProjects: [SimilarProject];
  handleSearch: Function;
}

const SimilarProjects: FC<SimilarProjectsProps> = ({
  similarProjects,
  handleSearch,
}) => {
  const goSearch = (x: any) => {
    handleSearch({ qs: x });
  };
  return (
    <>
      {similarProjects && (
        <div className="similar-projects">
          <p>
            There are similar token's result
            {similarProjects.length !== 1 && "s"} of
            {similarProjects.slice(0, SIMILAR_PROJECT_THRESHOLD).map((x, i) => {
              const { q, name } = x;
              return (
                <span
                  key={`symbol-${i}`}
                  className={`${similarProjects.length !== 1 && "add-and"}`}
                >
                  {""} “<small onClick={() => goSearch(q)}>{name}</small>” {""}
                </span>
              );
            })}
          </p>
        </div>
      )}
    </>
  );
};

export default SimilarProjects;
