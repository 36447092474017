import styled from "@emotion/styled";
import { theme } from "../../../theme/theme";

export const InputDropdown = styled.div`
  z-index: 999;
  padding: 0;
  width: 100%;
  height: 45px;
  outline: none;
  position: relative;
  border-radius: 30px;
  background: ${theme.colors.contrast};
  border: 1px solid ${theme.colors.darkDCDC};
  input {
    width: 100%;
    height: 45px;
    border: none;
    outline: none;
    font-size: 16px;
    background: none;
    padding-left: 25px;
    position: relative;
    padding-right: 3.5em;
    color: ${theme.colors.tertiary};
    &::placeholder {
      color: #cccccc;
    }
  }
  svg {
    top: 13px;
    right: 20px;
    font-size: 20px;
    color: #8f4ae7;
    position: absolute;
    color: ${theme.colors.primary};
    @media screen and(max-width: ${theme.breakpoint.tablet}) {
      top: 14px;
      right: 12px;
      font-size: 20;
    }
  }
  .btn-clear-text {    
    cursor: pointer;
    color: ${theme.colors.darkCACA};
  }
`;

export const ContentDropdown = styled.div`
  top: -45px;
  position: relative;
  .card-suggestion-space {
    z-index: 99;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    padding: 30px;
    padding-top: 60px;
    padding-right: 5px;
    border-radius: 30px;
    transform: scaleY(0);
    transform-origin: 0% 0%;
    transition: 0.1s ease all;
    background: ${theme.colors.contrast};
    border: 1px solid ${theme.colors.darkDCDC};
    &__scroll {
      overflow-y: auto;
    }
  }
  .active-dropdown-search {
    max-height: calc(100vh - 225px);
  }
  .inactive-dropdown-search {
    max-height: calc(100vh - 260px);
  }
  ul {
    li {
      padding: 6px;
      display: flex;
      cursor: pointer;
      text-align: left;
      align-items: center;
      &:hover {
        .suggestion-link p {
          transform: scale(1.03);
        }
        img {
          opacity: 0.5;
        }
        small {
          opacity: 0.5;
        }
        p.user-ens-name {
          transform: scale(1.03);
        }
        .profile-user-ens {
          width: 100%;
        }
        .tags-profile-suggestions {
          a {
            border: 1px solid #9a9a9a;
          }
        }
      }
      img {
        width: 35px;
        height: 35px;
      }
      .suggestion-link.u-flex-direction-column.u-align-item-start {
        justify-content: center;
      }
      .suggestion-link {
        width: 100%;
        display: flex;
        align-items: center;

        p {
          margin-left: 20px;
        }
      }
    }
  }
`;
export const DropdownItemTitle = styled.p`
  display: flex;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: flex-start;
  color: ${theme.colors.tertiaryLight};
  svg {
    margin-left: 5px;
    path {
      stroke: ${theme.colors.tertiaryLight}!important;
    }
  }
`;
export const DropdownItem = styled.ul`
  img.img-nft-assets {
    display: inline-block;
    width: 35px;
    height: 35px;
  }
  p {
    font-size: 15px;
    margin-left: 20px;
    color: ${theme.colors.dark};
    font-family: ${theme.weight.medium2};
  }
  .profile-user-suggestion {
    small {
      width: 35px;
      height: 35px;
      display: flex;
      font-size: 16px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      border: 1px solid ${theme.colors.darkF3F3};
      background: ${theme.colors.darkFAFA};
    }
    .profile-user-ens {
      width: auto;
      height: auto;
      display: flex;
      font-size: 15px;
      margin-right: 10px;
      align-items: center;
      color: ${theme.colors.tertiary};
      font-family: ${theme.weight.medium2};
    }
    .tags-profile-suggestions {
      p {
        width: auto;
        padding: 2px 5px;
        height: auto;
        font-size: 12px;
        margin-right: 6px;
        margin-left: 10px;
        border-radius: 10px;
        display: inline-block;
        color: ${theme.colors.tertiary};
        font-family: ${theme.weight.medium2};
        background: ${theme.colors.darkF5F5};
      }
    }
  }
  .nfts-texts {
    margin-left: 20px;
    color: ${theme.colors.primary};

    width: 100%;
    height: auto;
    margin-bottom: 2px;
    font-size: 13px;
    font-family: "Roboto Medium";
    color: rgb(153, 153, 153);
    a {
      width: 100%;
      height: auto;
      margin-bottom: 2px;
      font-size: 13px;
      font-family: ${theme.weight.medium2};
      color: ${theme.colors.tertiaryLight};
    }
    .suggestion-link {
      p {
        margin: 0;
      }
    }
  }
`;
