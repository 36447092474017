import React, { FC, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { h } from "gridjs";
import { TrendingQuery } from "../../app/models/trending_query";
import { listResources } from "../../app/models/admincrud";
import FilterHighlight from "./components/filter-highlight";
import { Grid } from "gridjs-react";

import { Button } from "@material-ui/core";

interface TrendingQueryPageProps {}

const TrendingQueryList: FC<TrendingQueryPageProps> = () => {
  let history = useHistory();

  const columns = [
    {
      id: "id",
      name: "Actions",
      formatter: (cell, row) => {
        return h(
          "a",
          {
            href: `/admin/trending-queries/${row.cells[0].data}`,
          },
          "Edit"
        );
      },
    },
    "Id",
    "Text",
    "Category",
    "Term",
    {
      id: "active",
      name: "Active",
      formatter: (cell, row) => {
        return row.cells[4].data ? "True" : "False";
      },
    },
  ];

  const classNa = { table: "question-table" };

  const [dataTrendingQueries, setDataTrendingQueries] = useState<
    TrendingQuery[]
  >([]);
  const asyncLoadTrendingQueries = async (filterModel?: {
    [key: string]: string;
  }) => {
    const r = await listResources("trending_queries", filterModel);
    setDataTrendingQueries(r);
  };

  const onFilterChange = (filterModel) => {
    asyncLoadTrendingQueries(filterModel);
    history.push("?" + new URLSearchParams(filterModel).toString());
  };

  return (
    <div>
      <FilterHighlight onChange={onFilterChange} />
      <NavLink to="/admin/trending-queries/create" color="primary">
        <Button color="primary" variant="contained">
          Add New
        </Button>
      </NavLink>
      <br />
      <br />
      <Grid
        data={dataTrendingQueries}
        search={false}
        columns={columns}
        className={classNa}
        pagination={{
          enabled: true,
          limit: 25,
        }}
      />
    </div>
  );
};

export default TrendingQueryList;
