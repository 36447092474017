import { Box } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import CollapseWidget from "./collapse-widget";
import { ChipContent, TagsSelection } from "../../../ui/styled/chipers";
import { FilterTags, getTagsFilter } from "../../../../models/filter";
import _ from "underscore";
import { Skeleton } from "@material-ui/lab";

interface FilterByProfileProps {
  filter: any;
  onChangeFilter: (newFilter: any) => void;
}

const FilterByProfile: FC<FilterByProfileProps> = ({
  filter,
  onChangeFilter,
}) => {
  const currentTags = filter.tags !== "" ? filter.tags.split(",") : [];
  const [pending, setPending] = useState(false);
  const [tags, setTags] = useState<FilterTags[]>();
  const [selected, setSelected] = useState(currentTags);
  const getFilterTags = () => {
    try {
      const response = getTagsFilter();
      setTags(response);
    } catch {
      console.log("error getting tags");
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    getFilterTags();
  }, []);
  const handleSelectionChanged = (tag: string) => {
    let tagsGroup: string[] = [];
    const newSet = new Set(selected);
    if (newSet.has(tag)) {
      newSet.delete(tag);
    } else {
      if (newSet.size < 4) {
        newSet.add(tag);
      }
    }
    if (newSet.size < 4) {
      setSelected(newSet);
      newSet.forEach((element: any) => {
        tagsGroup.push(element);
      });
      onChangeFilter({ ...filter, tags: tagsGroup.toString() });
    }
  };

  return (
    <CollapseWidget title="By profile">
      <Box
        display="flex"
        flexWrap="wrap"
        className="u-margin-bottom-1 u-padding-bottom-2"
      >
        {pending ? (
          <Box flexWrap="wrap" display="flex">
            {[0, 1, 2, 3, 4, 5].map((ske) => (
              <Skeleton
                width={60}
                height={25}
                className="u-margin-right-05"
                style={{
                  borderRadius: "15px",
                  transform: "scale(1)",
                  marginBottom: "0.5em",
                  marginLeft: 0,
                  marginRight: "10px",
                }}
              />
            ))}
          </Box>
        ) : (
          <>
            {" "}
            {tags?.map((tag) => (
              <TagsSelection
                key={tag.tag}
                onClick={() => handleSelectionChanged(tag.tag)}
                className={
                  _.contains(currentTags, tag.tag) ? "no-checked" : "checked"
                }
              >
                <small>{tag.tag_item}</small>
              </TagsSelection>
            ))}
          </>
        )}
      </Box>
    </CollapseWidget>
  );
};

export default FilterByProfile;
