import logo from "../images/Openstory_logo_large.png";
import { Text } from "../app/components/ui/styled/index";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
const AboutPeaks = () => {
  const { pathname } = useLocation();

  return (
    <div className="about-search-page">
      <NavLink
        to={`/`}
        activeClassName={pathname === "/" ? "active-menu-search " : ""}
      >
        {" "}
        <img src={`${process.env.PUBLIC_URL + logo}`} alt="logo" />
      </NavLink>

      <div className="container u-display-flex u-justify-center">
        <form-widget ucid="i1SFo73WfWn4OhW8bU6NEUP8wzs"></form-widget>
      </div>
    </div>
  );
};

export default AboutPeaks;
