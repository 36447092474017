import { FC, useState } from "react";
import {
  Badge,
  Box,
  Collapse,
  Divider,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IoIosArrowDown } from "react-icons/io";
import { SectionTitle } from "../../index";
interface WidgetWrapperProps {
  title: string;
  className?: string;
  showDivider?: boolean;
  mobilePadding?: number;
  badge_count?: number
}

const CollapseWidget: FC<WidgetWrapperProps> = ({
  title,
  children,
  className,
  showDivider = true,
  mobilePadding = 2,
  badge_count
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <SectionTitle
        className={className}
        collapse
        onClick={() => setIsActive((isActive: boolean) => !isActive)}
      >
        {title}&nbsp;
        <IoIosArrowDown className={`${isActive && "u-rotate-180"}`} />
        {badge_count && <Badge className="badge-count" badgeContent={badge_count} />}
      </SectionTitle>
      <Collapse
        in={isActive}
        timeout="auto"
        className="show-smartphone"
        style={{ marginTop: 16 }}
      >
        <Box px={isMobile ? mobilePadding : 0} width="100%">
          {children}
        </Box>
        <Hidden smUp>
          <Box width="100%" mt={2} mb={3}>
            {showDivider && <Divider />}
          </Box>
        </Hidden>
      </Collapse>
    </>
  );
};

export default CollapseWidget;
